/** @jsxImportSource @emotion/react */
import { ContentCopy } from "@mui/icons-material";
import useId from "@mui/material/utils/useId";
import { useContext, useState } from "react";
import { useAccountingRowStyle } from "../AccountingRow/Style";
import { useBlockGridStyle } from "./Style";
import { BlockModel } from "../../../../../Models/BlockModel";
import {
  onlyDigits,
  stringToValue,
  dateToString,
} from "../../../../../Utils/String";
import { EditShowAccountingsContext } from "../ShowAccountingsBlocks/Model";
import { Badge } from "@mui/material";

function BlockGrid({
  list,
  gridRef,
  notifyRef,
}: {
  list: BlockModel[];
  gridRef?: React.RefObject<HTMLDivElement>;
  notifyRef?: React.MutableRefObject<
    React.Dispatch<React.SetStateAction<string>>
  >;
}) {
  const [searchText, setSearchText] = useState("");

  const style = useBlockGridStyle();
  const accountingRowStyle = useAccountingRowStyle();

  const [setBlockDetails, setOpenDetails] = useContext(
    EditShowAccountingsContext
  );

  const id = useId();

  if (notifyRef) {
    notifyRef.current = setSearchText;
  }

  const blockActivated = (index: number) =>
    searchText === "" ||
    dateToString(list[index].date).includes(searchText) ||
    stringToValue(list[index].value).includes(searchText) ||
    onlyDigits(dateToString(list[index].date)).includes(searchText) ||
    list[index].value.includes(searchText);

  return (
    <div
      ref={gridRef}
      css={accountingRowStyle.grid}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      {list.map((block, index) => (
        <Badge
          css={[
            style.badge,
            block.viewed ? accountingRowStyle.badgeHidden : undefined,
          ]}
          variant="dot"
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          overlap="rectangular"
          color="warning"
          key={"BlockCard(" + id + ")-" + index.toString()}
        >
          <div
            onClick={
              blockActivated(index)
                ? () => {
                    setBlockDetails(block);
                    setOpenDetails(true);
                  }
                : undefined
            }
            css={[
              style.block,
              blockActivated(index) ? style.activated : style.deactivated,
            ]}
          >
            <ContentCopy
              css={[
                style.icon,
                block.contabilizedDatetime
                  ? blockActivated(index)
                    ? style.contabilizedIcon
                    : style.deactivatedContabilizedIcon
                  : undefined,
              ]}
            />
            <div css={style.column}>
              <span
                css={[
                  style.date,
                  blockActivated(index)
                    ? style.dateActivated
                    : style.dateDeactivated,
                ]}
              >
                {dateToString(block.date)}
              </span>
              <span css={style.value}>{stringToValue(block.value)}</span>
            </div>
          </div>
        </Badge>
      ))}
    </div>
  );
}

export default BlockGrid;
