// Filtra todos os símbolos de uma string exceto digítos
export const onlyDigits = (text: string) => text.replaceAll(/[^0-9]/g, "");

// Normalize string para facilitar buscas
export function normalizeString(text: string) {
  return text
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .toLocaleLowerCase();
}

// Converte Datetime para Date String
export function dateToString(a: Date) {
  return a.toLocaleDateString("pt-BR");
}

// Converte Datatime para Time String
export function timeToString(a: Date) {
  return a.toLocaleTimeString("pt-BR");
}

// Converte uma string de número inteiro para preço em real
export const stringToValue = (value: string) =>
  value === ""
    ? ""
    : "R$ " +
      (Number(onlyDigits(value)) / 100).toLocaleString("pt-BR", {
        minimumFractionDigits: 2,
      });

export function randomString(length: number) {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export function fullNameToShortName(name: string) {
  const nameSplitted = name.split(" ");
  return nameSplitted[0][0] + nameSplitted.slice(-1)[0][0];
}
