import { useContext, useEffect, useRef, useState } from "react";
import { BlockModel } from "../../../../../../Models/BlockModel";
import { EditHomeContext } from "../../../../../../Pages/Client/Home/Model";
import { dateIsValid, mapMonth, months } from "../../../../../../Utils/Dates";
import { onlyDigits, stringToValue } from "../../../../../../Utils/String";
import { saveBlock, setBlockViewed } from "../../../../Presenter";

export function useEditBlockEntriesPresenter(
  time: number,
  modal: boolean,
  categorize: boolean,
  show: boolean,
  editBlock: BlockModel,
  setDisabledButton: React.Dispatch<React.SetStateAction<boolean>>,
  onSaveRef: React.MutableRefObject<() => void>
) {
  const heightOfFixContent = modal ? undefined : 282;

  const [hiddenMask, setHiddenMask] = useState(false);
  const [hiddenTransition, setHiddenTransition] = useState(false);

  const [currentBlock, setCurrentBlock] = useState(editBlock);
  const [value, setValue] = useState(stringToValue(editBlock.value));
  const [selectedAccountId, setSelectedAccountId] = useState(
    editBlock.accountId
  );
  const [day, setDay] = useState(
    categorize ? "" : editBlock.date.getDate().toString()
  );
  const [month, setMonth] = useState(
    mapMonth[editBlock.date.getMonth().toString()]
  );
  const [year, setYear] = useState(editBlock.date.getFullYear().toString());

  const mountRef = useRef<HTMLDivElement>(null);
  const setValueRef = useRef<React.Dispatch<React.SetStateAction<string>>>(
    (_) => null
  );
  const setDayRef = useRef<React.Dispatch<React.SetStateAction<string>>>(
    (_) => null
  );
  const editContext = useContext(EditHomeContext);

  useEffect(() => {
    if (categorize) {
      setSelectedAccountId("");
      setValueRef.current(editBlock.value);
      setDayRef.current("");
    }
  }, [editBlock, categorize]);

  useEffect(() => {
    if (!show) {
      setHiddenMask(false);
      setHiddenTransition(false);
    }
  }, [show]);

  useEffect(() => {
    setTimeout(() => setHiddenMask(true), time);
    const observer = new IntersectionObserver(() => setHiddenTransition(true));
    if (mountRef.current) {
      observer.observe(mountRef.current);
    }
    // eslint-disable-next-line
  }, [mountRef]);

  useEffect(() => {
    setTimeout(() => {
      if (!editBlock.viewed) {
        editContext((value) => {
          for (const block of value!) {
            if (!block.viewed && block.id === editBlock.id) {
              block.viewed = true;
              setBlockViewed(block.id);
              break;
            }
          }

          return [...value!];
        });
      }
    }, time * 2);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const currentDate = new Date(
      (Number(mapMonth[month]) + 1).toString() + "/" + day + "/" + year
    );
    if (!dateIsValid(currentDate)) {
      setDisabledButton(true);
      return;
    }
    if (value === "" || value === "R$ 0,00" || selectedAccountId === "") {
      setDisabledButton(true);
      return;
    }
    setDisabledButton(false);
  }, [
    day,
    month,
    year,
    value,
    currentBlock,
    selectedAccountId,
    setDisabledButton,
  ]);

  onSaveRef.current = async () => {
    const newBlock = { ...currentBlock };

    newBlock.date = new Date(
      Number(year),
      Number(mapMonth[month]),
      Number(day)
    );
    newBlock.value = onlyDigits(value);
    newBlock.accountId = selectedAccountId;

    await saveBlock(newBlock);
    if (!categorize) {
      editContext((value) => {
        editBlock.date = newBlock.date;
        editBlock.value = newBlock.value;
        editBlock.accountId = newBlock.accountId;

        const newValue = value!.filter((e) => e.id !== currentBlock.id);
        newValue.push(newBlock);
        setCurrentBlock(currentBlock);
        return [...newValue];
      });
    }
  };

  const getMonths = () => {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth().toString();
    const currentYear = currentDate.getFullYear().toString();
    if (year === currentYear) {
      const currentMonths = months.slice(-(Number(currentMonth) + 1));
      if (!currentMonths.includes(month)) {
        setMonth(currentMonths.slice(-1)[0]);
      }
      return currentMonths;
    } else {
      return months;
    }
  };

  return {
    day,
    setDay,
    setDayRef,
    month,
    setMonth,
    getMonths,
    heightOfFixContent,
    year,
    setYear,
    hiddenMask,
    hiddenTransition,
    mountRef,
    selectedAccountId,
    setSelectedAccountId,
    value,
    setValue,
    setValueRef,
  };
}
