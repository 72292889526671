/** @jsxImportSource @emotion/react */
import { Button, MenuItem, Select, TextField } from "@mui/material";
import { useState } from "react";
import { useColorLabStyle } from "./Style";

function ColorLab() {
  const style = useColorLabStyle();
  const [colorSelected, setColorSelected] = useState("primary");
  const [isWhite, setIsWhite] = useState(false);
  const [number, setNumber] = useState("");
  const colorOptions = [
    "background",
    "primary",
    "secondary",
    "error",
    "warning",
    "info",
    "success",
  ];

  return (
    <div
      css={[
        style.div,
        colorSelected === "background"
          ? style.background
          : colorSelected === "primary"
          ? style.primary
          : colorSelected === "secondary"
          ? style.secondary
          : colorSelected === "error"
          ? style.error
          : colorSelected === "warning"
          ? style.warning
          : colorSelected === "info"
          ? style.info
          : colorSelected === "success"
          ? style.success
          : "",
      ]}
    >
      <div css={style.divTransparency(Number(number), isWhite)}>
        <Select
          value={colorSelected}
          css={style.backgroundWhite}
          onChange={(selected) => setColorSelected(selected.target.value)}
        >
          {colorOptions.map((value) => (
            <MenuItem value={value}>{value}</MenuItem>
          ))}
        </Select>
        <Button variant="contained" onClick={() => setIsWhite(!isWhite)}>
          {isWhite ? "white" : "black"}
        </Button>
        <TextField
          value={number}
          onChange={(e) => setNumber(e.target.value)}
          css={style.backgroundWhite}
        />
      </div>
    </div>
  );
}

export default ColorLab;
