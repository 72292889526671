import { css } from "@emotion/react";
import CacheTheme from "Theme/CacheTheme";
import useCustomTheme from "Theme/CustomTheme";

var styleCached = false;
export function useOverlapPanelStyle() {
  const name = "OverlapPanelCSS";
  const theme = useCustomTheme();
  if (!styleCached) {
    const label = theme.extras.label(name);
    var _style = {
      background: css({
        label: label("background"),
        display: "flex",
        position: "absolute",
        zIndex: "2",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
      }),
      transitionOpacity: css({
        label: label("transitionOpacity"),
        transition: theme.extras.transition.fast("opacity"),
      }),
      visible: css({
        label: label("visible"),
        opacity: 1,
      }),
      notVisible: css({
        label: label("notVisible"),
        opacity: 0,
      }),
    };
    CacheTheme(name, theme.palette.type, _style);
    styleCached = true;
  }
  var style = CacheTheme(name, theme.palette.type);
  return style as typeof _style;
}
