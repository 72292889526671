import { createContext } from "react";
import { KeyedDates } from "../../../Hooks/ShowAccountingsBlocks/Model";
import { ClientAccountModel } from "../../../Models/AccountModel";
import { BlockModel } from "../../../Models/BlockModel";
import { PendencyModel } from "../../../Models/PendencyModel";

export const IsBusyContext = createContext<boolean | undefined>(undefined);

export const AccountingListContext = createContext<{
  setClientAccounts: React.Dispatch<
    React.SetStateAction<ClientAccountModel[] | undefined>
  >;
  clientId: string;
}>({
  setClientAccounts: () => {},
  clientId: "",
});

export type AccountingInfos = {
  blocks: BlockModel[];
  uncategorizedCount: number;
};

export type KeyedDatesPendencyModel = {
  [year: string]: { [month: string]: { [day: string]: PendencyModel[] } };
};

export type KeyedPendencyCount = {
  [year: string]: { [month: string]: number };
};

export type ShowAccountingsPendencyState = [
  KeyedDatesPendencyModel,
  KeyedDates,
  KeyedPendencyCount,
  string,
  React.Dispatch<React.SetStateAction<string>>,
  string,
  React.Dispatch<React.SetStateAction<string>>
];
