import { css } from "@emotion/react";
import CacheTheme from "Theme/CacheTheme";
import useCustomTheme from "Theme/CustomTheme";

var styleCached = false;
export function useOpacityTransitionStyle() {
  const name = "OpacityTransitionCSS";
  const theme = useCustomTheme();
  if (!styleCached) {
    const label = theme.extras.label(name);
    var _style = {
      transition: css({
        label: label("transition"),
        transition: theme.extras.transition.fast("opacity"),
        position: "absolute",
      }),
      hidden: css({
        label: label("hidden"),
        pointerEvents: "none",
        opacity: 0,
      }),
    };
    CacheTheme(name, theme.palette.type, _style);
    styleCached = true;
  }
  var style = CacheTheme(name, theme.palette.type);
  return style as typeof _style;
}
