import React from "react";
import FrameDrawer from "../../../../../Components/Frame/Components/FrameDrawer/View";
import DrawerAccountingPlan from "../DrawerAccountingPlan/View";
import DrawerShared from "../DrawerShared/View";
import DrawerTransfer from "../DrawerTransfer/View";
import { AccountingDrawerOptions } from "./Model";
import { useAccountingDrawerPresenter } from "./Presenter";

function AccountingDrawer({
  choosedDrawerRef,
}: {
  choosedDrawerRef: React.MutableRefObject<
    (choice: AccountingDrawerOptions) => void
  >;
}) {
  const presenter = useAccountingDrawerPresenter(choosedDrawerRef);
  return (
    <FrameDrawer
      drawerOpened={presenter.expanded}
      onClose={() => presenter.setShowerDrawer(false)}
      notifyRef={presenter.notifyRef}
      searchTitle={presenter.searchTitle}
      setDisabledRef={presenter.setDisabledSearchRef}
      big={presenter.drawerBigSize}
    >
      {presenter.showDrawer ? (
        presenter.choosed === "shared" ? (
          <DrawerShared notifyRef={presenter.notifyRef} />
        ) : presenter.choosed === "accountingPlan" ? (
          <DrawerAccountingPlan
            setDisabledSearchRef={presenter.setDisabledSearchRef}
            notifyRef={presenter.notifyRef}
          />
        ) : presenter.choosed === "transfer" ? (
          <DrawerTransfer notifyRef={presenter.notifyRef} />
        ) : (
          <div />
        )
      ) : (
        <div />
      )}
    </FrameDrawer>
  );
}

export default AccountingDrawer;
