/** @jsxImportSource @emotion/react */
import React from "react";
import { Button } from "@mui/material";
import { useSideBarStyle } from "./Style";
import SideBarIcon from "./SideBarIcon/View";
import {
  ContentCopy,
  KeyboardDoubleArrowRight,
  ViewStreamOutlined,
  GridView,
  Share,
  CreateNewFolderOutlined,
  GroupOutlined,
  DeleteOutlined,
} from "@mui/icons-material";
import { useButtonSquaredStyle } from "../../../../../Components/ButtonSquared/Style";
import { useFrameStyle } from "../../../../../Components/Frame/Style";
import { AccountingDrawerOptions } from "../AccountingDrawer/Model";
import { useHistory } from "react-router-dom";
import { useButtonColorsPick } from "../../../../../Hooks/ButtonColorsPick/View";
import { CategorizationStateArgs } from "../../../Categorization/Model";
import SideBarContabilizeIcon from "./SideBarContabilizeIcon/View";
import { OperatorAvatar } from "Models/OperatorModel";

function SideBar({
  chooseDrawer,
  showAccountings,
  changeAccountingPanelRef,
  setRemoveDialog,
  uncategorizedBlocks,
  uncontabilizedBlocks,
  categorizationStateArgs,
  isResponsable,
  operatorInAccounting,
  expandSideBar,
  setExpandSidebar,
}: {
  chooseDrawer: (choose: AccountingDrawerOptions) => void;
  showAccountings: boolean;
  changeAccountingPanelRef: React.MutableRefObject<() => void>;
  setRemoveDialog: () => void;
  uncategorizedBlocks: number;
  uncontabilizedBlocks: number;
  categorizationStateArgs: CategorizationStateArgs;
  isResponsable: boolean;
  operatorInAccounting: OperatorAvatar | undefined;
  expandSideBar: boolean;
  setExpandSidebar: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const history = useHistory();

  const style = useSideBarStyle();
  const squaredButtonStyle = useButtonSquaredStyle();
  const backgroundWhite10Color = useButtonColorsPick("backgroundWhite10");
  const frameStyle = useFrameStyle();

  return (
    <div css={style.sidebarColumn}>
      <div css={style.sidebar}>
        <SideBarIcon
          text="Visualizar"
          icon={
            showAccountings ? (
              <ViewStreamOutlined css={style.marginIcon} />
            ) : (
              <GridView css={style.marginIcon} />
            )
          }
          onClick={() => changeAccountingPanelRef.current()}
          isExpanded={expandSideBar}
        />
        <div css={frameStyle.sidebarSeparator} />
        <SideBarIcon
          text="Categorizar"
          badgeNumber={uncategorizedBlocks}
          icon={<ContentCopy css={[frameStyle.iconMirror, style.marginIcon]} />}
          onClick={() =>
            history.push({
              pathname: "/home/client/categorization",
              state: categorizationStateArgs,
            })
          }
          isExpanded={expandSideBar}
        />
        <div css={frameStyle.sidebarSeparator} />
        <SideBarContabilizeIcon
          expandSideBar={expandSideBar}
          uncontabilizedBlocks={uncontabilizedBlocks}
          categorizationStateArgs={categorizationStateArgs}
          operatorInAccounting={operatorInAccounting}
        />
        <div css={frameStyle.sidebarSeparator} />
        <SideBarIcon
          text="Gerenciar contas"
          icon={<CreateNewFolderOutlined css={style.marginIcon} />}
          onClick={() => chooseDrawer("accountingPlan")}
          isExpanded={expandSideBar}
        />
        <div css={frameStyle.sidebarSeparator} />
        <SideBarIcon
          text="Compartilhar cliente"
          icon={<Share css={style.marginIcon} />}
          onClick={() => chooseDrawer("shared")}
          isExpanded={expandSideBar}
          disabled={!isResponsable}
        />
        <div css={frameStyle.sidebarSeparator} />
        <SideBarIcon
          text="Transferir cliente"
          icon={<GroupOutlined css={style.marginIcon} />}
          onClick={() => chooseDrawer("transfer")}
          isExpanded={expandSideBar}
          disabled={!isResponsable}
        />
        <div css={style.sidePanelSeparator} />
        <SideBarIcon
          text="Excluir cliente"
          icon={<DeleteOutlined css={style.marginIcon} />}
          onClick={setRemoveDialog}
          isExpanded={expandSideBar}
          disabled={!isResponsable}
        />
      </div>
      <Button
        onClick={(e) => {
          e.stopPropagation();
          setExpandSidebar(!expandSideBar);
        }}
        css={[
          squaredButtonStyle.iconSettings,
          backgroundWhite10Color,
          style.expandButton,
        ]}
      >
        <KeyboardDoubleArrowRight
          css={[
            style.arrowRotateBase,
            expandSideBar ? style.arrowRotate : undefined,
          ]}
        />
      </Button>
    </div>
  );
}

export default SideBar;
