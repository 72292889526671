import { useState } from "react";

function useLoginSelectPlataform() {
  const [hideLoginButtons, setHideLoginButtons] = useState(false);
  const [showSelectPlataform, setShowSelectPlataform] = useState(false);

  const startSelectPlataform = () => {
    if (!hideLoginButtons) {
      setHideLoginButtons(true);
      setTimeout(() => setShowSelectPlataform(true), 500);
    }
  };

  return [hideLoginButtons, showSelectPlataform, startSelectPlataform] as const;
}

export default useLoginSelectPlataform;
