export type Person = {
  name: string;
  code: string;
  phone: string;
  email: string;
  pictureUrl?: string;
  address?: Address;
};

export type Address = {
  number: string;
  state: string;
  city: string;
  neighboor: string;
  street: string;
  cep: string;
};

export type AccountingContact = {
  accounting: {
    name: string;
    phone: string;
    email: string;
    photoUrl?: string;
  };
  operator: {
    name: string;
    phone: string;
    email: string;
    photoUrl?: string;
  };
};

export type AccountingInvited = {
  id: string;
  email: string;
  datetime: Date;
};

export const emptyAccountingAcontact = {
  accounting: {
    name: "",
    phone: "",
    email: "",
  },
  operator: {
    name: "",
    phone: "",
    email: "",
  },
};
