import { css } from "@emotion/react";
import { CSSProperties } from "react";
import CacheTheme from "Theme/CacheTheme";
import useCustomTheme from "Theme/CustomTheme";

var styleCached = false;
export function useDrawerAccountingPlanStyle() {
  const name = "DrawerAccountingPlanCSS";
  const theme = useCustomTheme();
  if (!styleCached) {
    const label = theme.extras.label(name);
    var _style = {
      title: css({
        label: label("title"),
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: theme.spacing(2),
      }),
      tooltip: css({
        label: label("noMarginBottom"),
        marginBottom: theme.spacing(0),
        "> svg": {
          marginRight: theme.spacing(2),
        },
      }),
      subtitle: css({
        label: label("subtitle"),
        marginBottom: theme.spacing(1),
      }),
      textEntry: css({
        label: label("textEntry"),
        margin: theme.spacing(2, 0, 4, 0),
      }),
      tooltipCss: {
        maxWidth: theme.spacing(53.5),
      } as CSSProperties,
    };
    CacheTheme(name, theme.palette.type, _style);
    styleCached = true;
  }
  var style = CacheTheme(name, theme.palette.type);
  return style as typeof _style;
}
