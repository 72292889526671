/** @jsxImportSource @emotion/react */
import { useContext, useEffect, useState } from "react";
import DisplayList from "../../../../../Components/DisplayList/View";
import { useFrameDrawerStyle } from "../../../../../Components/Frame/Components/FrameDrawer/Style";
import LoadingTransition from "../../../../../Components/LoadingTransition/View";
import useLoadingTransition from "../../../../../Hooks/LoadingTransition";
import { SharedOperatorAvatar } from "../../../../../Models/OperatorModel";
import { AccountingListContext } from "../../Model";
import DrawerSharedItem from "./Components/DrawerSharedItem";
import { getSharedOperators } from "./Presenter";
import { useDrawerSharedStyle } from "./Style";
import { normalizeString } from "Utils/String";

function DrawerShared({
  notifyRef,
}: {
  notifyRef: React.MutableRefObject<
    React.Dispatch<React.SetStateAction<string>>
  >;
}) {
  const style = useDrawerSharedStyle();
  const frameDrawerStyle = useFrameDrawerStyle();

  const [searchText, setSearchText] = useState("");
  notifyRef.current = setSearchText;

  const [sharedOperators, setSharedOperators] =
    useState<SharedOperatorAvatar[]>();

  const [changeLoading] = useLoadingTransition(sharedOperators !== undefined);
  const { clientId } = useContext(AccountingListContext);

  useEffect(() => {
    getSharedOperators(clientId, setSharedOperators);
  }, [clientId]);

  return (
    <LoadingTransition window>
      {(_changeLoading) => {
        changeLoading.current = _changeLoading;
        return (
          <>
            <div css={[frameDrawerStyle.title, style.title]}>
              Compartilhe este cliente com outros operadores
            </div>
            <DisplayList
              paddingTopHeight={3}
              heightOfFixContent="56px"
              itens={(
                sharedOperators?.filter((e) =>
                  normalizeString(e.name).includes(normalizeString(searchText))
                ) ?? []
              ).map((e) => (
                <DrawerSharedItem
                  clientId={clientId}
                  operator={e}
                  key={"DrawerSharedItem-" + e.id}
                />
              ))}
            />
          </>
        );
      }}
    </LoadingTransition>
  );
}

export default DrawerShared;
