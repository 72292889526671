/** @jsxImportSource @emotion/react */
import { ArrowBack } from "@mui/icons-material";
import ContabilizationBlock from "../../../Components/ContabilizationBlock/View";
import ButtonSquared from "../../../Components/ButtonSquared/View";
import DisplayList from "../../../Components/DisplayList/View";
import { useFrameDrawerStyle } from "../../../Components/Frame/Components/FrameDrawer/Style";
import TextEntry from "../../../Components/TextEntry/View";
import TurquesaAvatar from "../../../Components/TurquesaAvatar/View";
import { useContabilizationMobileStyle } from "../ContabilizationMobile/Style";
import { useContabilizationListMobileStyle } from "./Style";
import { useEffect, useState } from "react";
import { accountFilter, mobileSettings } from "../../../Utils/Utils";
import { useHistory, useLocation } from "react-router-dom";
import { ClientAccountModel } from "Models/AccountModel";
import { ContabilizationServices } from "Services/Interfaces";

function ContabilizationListMobile() {
  const {
    state,
  }: {
    state: {
      accounts: (ClientAccountModel & { uncontabilizedNumber: number })[];
      operatorName: string;
      photoUrl: string;
    };
  } = useLocation();

  const history = useHistory();

  const [filterText, setFilterText] = useState("");
  const style = useContabilizationListMobileStyle();
  const contabilizationMobileStyle = useContabilizationMobileStyle();
  const frameDrawerStyle = useFrameDrawerStyle();

  useEffect(mobileSettings, []);

  return (
    <div css={[contabilizationMobileStyle.wrapper, style.wrapper]}>
      <div css={contabilizationMobileStyle.innerWrapper}>
        <div css={contabilizationMobileStyle.headRow}>
          <ButtonSquared onClick={history.goBack}>
            <ArrowBack />
          </ButtonSquared>
          <div css={contabilizationMobileStyle.avatarInfo}>
            {state.operatorName}
            <TurquesaAvatar
              nameToInitials={state.operatorName}
              src={state.photoUrl}
              size={4}
            />
          </div>
        </div>
        <div css={contabilizationMobileStyle.separator} />
        <div css={[frameDrawerStyle.title, contabilizationMobileStyle.title]}>
          Selecione uma conta
        </div>
        <TextEntry
          searchIcon
          ccss={style.textEntry}
          placeholder="Buscar conta"
          onChange={setFilterText}
        />
        <DisplayList
          itens={state.accounts
            .filter((e) => accountFilter(e, filterText))
            .map((account, index) => (
              <ContabilizationBlock
                key={"AccountingContabilizationBlock-" + index.toString()}
                code={account.code}
                name={account.description}
                number={account.uncontabilizedNumber}
                onClick={() => {
                  ContabilizationServices.contabilizationLinkState.accountSelected =
                    account.id;
                  history.goBack();
                }}
              />
            ))}
          heightOfFixContent={"273px"}
          paddingTopHeight={2}
        />
      </div>
    </div>
  );
}

export default ContabilizationListMobile;
