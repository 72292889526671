/** @jsxImportSource @emotion/react */
import { useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { devolveBlock } from "../../../Components/FrameViewBlock/Presenter";
import FrameViewBlock from "../../../Components/FrameViewBlock/View";
import LoadingTransition from "../../../Components/LoadingTransition/View";
import useLoadingTransition from "../../../Hooks/LoadingTransition";
import { BlockModel } from "../../../Models/BlockModel";
import { OperatorAvatar } from "../../../Models/OperatorModel";
import { CategorizationStateArgs } from "./Model";
import { categorizeSync, getBlockToSolve } from "./Presenter";
import ReportDialog from "./ReportDialog/View";

function Categorization() {
  const history = useHistory();
  const {
    state: client,
  }: {
    state: CategorizationStateArgs;
  } = useLocation<CategorizationStateArgs>();

  const showReportDialogRef = useRef<() => void>(() => null);

  const [operators, setOperators] = useState<OperatorAvatar[]>([]);
  const [uncategorizedBlocks, setUncategorizedBlocks] = useState(0);
  const [blockToSolve, setBlockToSolve] = useState<BlockModel | null>();

  const [changeLoading] = useLoadingTransition(
    blockToSolve !== undefined && operators !== undefined
  );

  useEffect(() => {
    if (!client) {
      history.push("/home");
    }
    getBlockToSolve(client.id, setBlockToSolve);
    categorizeSync(client, setUncategorizedBlocks, setOperators);
    return () => {
      client.id = "";
    };
    //eslint-disable-next-line
  }, [client]);

  const nextBlock = async () => getBlockToSolve(client.id, setBlockToSolve);

  return (
    <LoadingTransition>
      {(_changeLoading) => {
        changeLoading.current = _changeLoading;
        return (
          <>
            <FrameViewBlock
              onBack={() => history.goBack()}
              block={blockToSolve}
              fullScreen
              devolveBlock={async () => {
                await devolveBlock(blockToSolve!.id);
                nextBlock();
              }}
              categorizationArgs={{
                accountingName: client.name,
                showReportDialogRef: showReportDialogRef,
                logoUrl: client.logoUrl,
                operators: operators,
                uncategorizedCount: uncategorizedBlocks,
                nextBlock: nextBlock,
              }}
            />
            {blockToSolve ? (
              <ReportDialog
                showReportDialogRef={showReportDialogRef}
                nextBlock={nextBlock}
              />
            ) : undefined}
          </>
        );
      }}
    </LoadingTransition>
  );
}

export default Categorization;
