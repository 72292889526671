import { css } from "@emotion/react";
import CacheTheme from "Theme/CacheTheme";
import useCustomTheme from "Theme/CustomTheme";

var styleCached = false;
export function useReportDialogStyle() {
  const name = "ReportDialogCSS";
  const theme = useCustomTheme();
  if (!styleCached) {
    const label = theme.extras.label(name);
    var _style = {
      panel: css({
        label: label("panel"),
        color: "white",
        position: "absolute",
        backgroundColor: theme.palette.secondary.main,
        borderRadius: theme.extras.borderRadius,
        padding: theme.spacing(2, 4, 4, 4),
        top: theme.spacing(4),
        right: theme.spacing(13),
        width: theme.spacing(60),
      }),
      headRow: css({
        label: label("headRow"),
        display: "flex",
        justifyContent: "space-between",
      }),
      icon: css({
        label: label("icon"),
        margin: theme.spacing(2),
      }),
      separator: css({
        label: label("separator"),
        backgroundColor: theme.palette.secondary.black(10),
        margin: theme.spacing(2, 0),
        minHeight: "1px",
        width: "100%",
      }),
      subtitle: css({
        label: label("subtitle"),
        marginTop: theme.spacing(4),
      }),
      reportButton: css({
        label: label("reportButton"),
        marginTop: theme.spacing(2),
        backgroundColor: theme.palette.warning.main,
        color: "white",
        fontWeight: "bold",
        ":hover": {
          backgroundColor: theme.palette.warning.white(10),
        },
      }),
      time: theme.extras.transitionTime.fast,
    };
    CacheTheme(name, theme.palette.type, _style);
    styleCached = true;
  }
  var style = CacheTheme(name, theme.palette.type);
  return style as typeof _style;
}
