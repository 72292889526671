import { css } from "@emotion/react";
import CacheTheme from "Theme/CacheTheme";
import useCustomTheme from "Theme/CustomTheme";

var styleCached = false;
export function useContabilizationBlockItemStyle() {
  const name = "ContabilizationBlockItemCSS";
  const theme = useCustomTheme();
  if (!styleCached) {
    const label = theme.extras.label(name);
    var _style = {
      item: css({
        label: label("item"),
        display: "flex",
        height: "unset",
      }),
      itemSelected: css({
        label: label("itemSelected"),
        backgroundColor: theme.palette.primary.main,
        ":hover": {
          backgroundColor: theme.palette.primary.main,
        },
      }),
      itemNotSelected: css({
        label: label("itemNotSelected"),
        ":hover": {
          backgroundColor: theme.palette.secondary.white(20),
          " > div:last-of-type": {
            backgroundColor: theme.palette.secondary.white(30),
          },
        },
      }),
      startContent: css({
        label: label("name"),
        display: "flex",
        justifyContent: "start",
      }),
      info: css({
        label: label("info"),
        width: theme.spacing(5),
        height: theme.spacing(5),
        borderRadius: theme.extras.borderRadius,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontWeight: "bold",
        transition: theme.extras.transition.faster("background-color"),
        flexShrink: 0,
      }),
      infoSelected: css({
        label: label("infoSelected"),
        backgroundColor: theme.palette.primary.white(10),
      }),
      infoNotSelected: css({
        label: label("infoNotSelected"),
        backgroundColor: theme.palette.secondary.white(20),
      }),
      iconSize: css({
        label: label("iconSize"),
        fontSize: "18px",
        color: "white",
      }),
    };
    CacheTheme(name, theme.palette.type, _style);
    styleCached = true;
  }
  var style = CacheTheme(name, theme.palette.type);
  return style as typeof _style;
}
