import { css } from "@emotion/react";
import CacheTheme from "Theme/CacheTheme";
import useCustomTheme from "Theme/CustomTheme";

var styleCached = false;
export function useSiteStyle(siteImageBackground: string) {
  const name = "SiteCSS";
  const theme = useCustomTheme();
  const columnWidth = theme.spacing(150);
  const commumPadding = "calc(calc(100% - " + columnWidth + ") / 2)";
  if (!styleCached) {
    const label = theme.extras.label(name);
    var _style = {
      container: css({
        label: label("container"),
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        width: "100%",
        height: "100%",
        minWidth: theme.spacing(166),
      }),
      column: css({
        label: label("column"),
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        width: columnWidth,
        overflowX: "clip",
        margin: "auto",
      }),
      section: css({
        label: label("section"),
        width: "100%",
        overflowY: "hidden",
      }),
      firstSection: css({
        label: label("firstSection"),
        backgroundColor: theme.palette.primary.main,
      }),
      firstColumn: css({
        label: label("firstColumn"),
        backgroundColor: theme.palette.primary.main,
        paddingBottom: theme.spacing(5),
      }),
      topBar: css({
        label: label("topBar"),
        justifyContent: "space-between",
        flexDirection: "row",
        backgroundColor: theme.palette.primary.main,
      }),
      sticky: css({
        label: label("sticky"),
        position: "sticky",
        top: theme.spacing(-5),
        paddingTop: theme.spacing(8),
        paddingBottom: theme.spacing(3),
        marginBottom: theme.spacing(-3),
        zIndex: 2,
      }),
      wrapTopBar: css({
        label: label("wrapTopBar"),
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
      }),
      topBarAtTop: (top: number) =>
        css({
          label: label("topBarAtTop"),
          top: top + "px",
        }),
      topBarAtLeft: (left: number) =>
        css({
          label: label("topBarAtLeft"),
          left: "-" + left + "px",
        }),
      topBarButton: css({
        label: label("topBarButton"),
        marginLeft: theme.spacing(2),
        padding: theme.spacing(2, 4),
        backgroundColor: theme.palette.primary.main,
        fontWeight: 400,
        lineHeight: "18px",
        ":hover": {
          transition: "background-color 300ms",
          backgroundColor: theme.palette.primary.white(10),
        },
      }),
      loginButton: css({
        label: label("loginButton"),
        backgroundColor: "white",
        color: "black",
        fontWeight: 700,
        lineHeight: "18px",
        ":hover": {
          transition: "background-color 300ms",
          backgroundColor: "#e6e6e6",
        },
      }),
      logo: css({
        label: label("logo"),
        width: theme.spacing(22),
        height: theme.spacing(5),
      }),
      commomSection: css({
        label: label("commomSection"),
        backgroundColor: "white",
      }),
      commomColumn: css({
        label: label("commomColumn"),
        padding: theme.spacing(14, 0),
        backgroundColor: "white",
      }),
      sectionTitle: css({
        label: label("sectionTitle"),
        fontWeight: 700,
        fontSize: "48px",
        lineHeight: "60px",
        textAlign: "center",
        marginBottom: theme.spacing(6),
      }),
      sectionDescription: css({
        label: label("sectionDescription"),
        fontSize: "24px",
        lineHeight: "30px",
        textAlign: "center",
        color: "#9EA6B0",
        marginBottom: theme.spacing(14),
      }),
      howWorks: css({
        label: label("howWorks"),
        display: "grid",
        gridTemplateColumns: "1fr 1fr 1fr",
        width: "100%",
        gap: theme.spacing(4),
      }),
      onlyBottomPadding: css({
        label: label("noPaddingSection"),
        paddingBottom: theme.spacing(8),
        paddingTop: theme.spacing(0),
      }),
      thirthSection: css({
        label: label("thirthSection"),
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
      }),
      thirthSectionA: css({
        label: label("thirthSection"),
        background: "linear-gradient(304.38deg, #B3D3FF 3.86%, #E6E6E6 96.14%)",
        padding: theme.spacing(14, 0),
        width: "821px",
        boxSizing: "content-box",
        paddingLeft: commumPadding,
        flexShrink: 0,
      }),
      thirthSectionB: css({
        label: label("thirthSectionB"),
        width: "100%",
        height: "684px",
        backgroundImage: "url(" + siteImageBackground + ")",
        backgroundSize: "cover",
      }),
      thirthSectionTitle: css({
        label: label("thirthSectionTitle"),
        fontSize: "40px",
        lineHeight: "50px",
        marginBottom: theme.spacing(2),
      }),
      thirthSectionSubtitle: css({
        label: label("thirthSectionSubtitle"),
        fontSize: "24px",
        lineHeight: "30px",
        marginTop: theme.spacing(4),
        fontWeight: 700,
      }),
      thirthSectionDescription: css({
        label: label("thirthSectionDescription"),
        marginTop: theme.spacing(1),
        fontSize: "20px",
        lineHeight: "25px",
      }),
      floatingLogo: css({
        label: label("floatingLogo"),
        boxSizing: "content-box",
        width: theme.spacing(6),
        height: theme.spacing(6),
        padding: theme.spacing(4),
        backgroundColor: theme.palette.primary.main,
        borderRadius: "24px",
        position: "relative",
        marginLeft: theme.spacing(-7),
        marginRight: theme.spacing(-7),
      }),
      resourcesGrid: css({
        label: label("resourcesGrid"),
        width: "100%",
        display: "grid",
        gridTemplateColumns: "1fr 1fr 1fr",
        gridTemplateRows: "1fr 1fr",
        gap: theme.spacing(4),
        marginBottom: theme.spacing(14),
      }),
      createAccountingButton: css({
        label: label("createAccountingButton"),
        fontWeight: 700,
        lineHeight: "30px",
        fontSize: "24px",
        padding: theme.spacing(4, 12),
        height: "unset",
        marginLeft: "unset",
      }),
      separator: css({
        label: label("separator"),
        height: "3px",
        width: "calc(" + columnWidth + " - " + theme.spacing(4) + ")",
        backgroundColor: theme.palette.transparency.black(5),
        margin: theme.spacing(1, "auto", 10, "auto"),
      }),
      contact: css({
        label: label("contact"),
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        width: "100%",
        alignItems: "end",
      }),
      contactDiv: css({
        label: label("contactDiv"),
        width: theme.spacing(50),
      }),
      contactTitle: css({
        label: label("contactTitle"),
        fontWeight: 700,
        fontSize: "20px",
        lineHeight: "25px",
        textAlign: "right",
      }),
      contactInformations: css({
        label: label("contactInformations"),
        lineHeight: "20px",
        textAlign: "right",
        marginTop: theme.spacing(1),
      }),
      contactLogo: css({
        label: label("contactLogo"),
        width: theme.spacing(16),
        height: theme.spacing(4),
        marginBottom: theme.spacing(2),
      }),
      hikersCopyrights: css({
        label: label("hikersCopyrights"),
        fontSize: "12px",
        lineHeight: "15px",
      }),
      iconMirror: css({
        label: label("iconMirror"),
        transform: "scaleX(-1)",
      }),
      iconFill: css({
        label: label("iconFill"),
        fill: theme.palette.primary.main,
      }),
    };
    CacheTheme(name, theme.palette.type, _style);
    styleCached = true;
  }
  var style = CacheTheme(name, theme.palette.type);
  return style as typeof _style;
}
