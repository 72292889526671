/** @jsxImportSource @emotion/react */

import { SerializedStyles } from "@emotion/react";
import { useResourcesItemStyle } from "./Style";

function ResourcesItem({
  icon,
  alt,
  title,
  description,
}: {
  icon: (css: SerializedStyles) => React.ReactChild;
  alt: string;
  title: string;
  description: string[];
}) {
  const style = useResourcesItemStyle();
  return (
    <div css={style.item}>
      {icon(style.icon)}
      <div css={style.title}>{title}</div>
      <div>
        {description.map((text, index) => (
          <div key={"resourceItem: " + index}>{text}</div>
        ))}
      </div>
    </div>
  );
}

export default ResourcesItem;
