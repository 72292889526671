/** @jsxImportSource @emotion/react */
import { useSiteStyle } from "./Style.";
import LogoBlintesLight from "./../../../Assets/logo_blintes_light.png";
import LogoBlintesBottom from "./../../../Assets/logo_blintes_bottom.png";
import howWorks1 from "./../../../Assets/how_works_1.png";
import howWorks2 from "./../../../Assets/how_works_2.png";
import howWorks3 from "./../../../Assets/how_works_3.png";
import floatingLogo from "./../../../Assets/floating_logo.png";
import siteImageBackground from "./../../../Assets/site_section_background.png";
import { Button } from "@mui/material";
import Carousel from "./Components/Carousel/View";
import HowWorksItem from "./Components/HowWorksItem/View";
import ResourcesItem from "./Components/ResourcesItem/View";
import { useCallback, useEffect, useState } from "react";
import { ReactComponent as Priority } from "@material-symbols/svg-700/rounded/priority.svg";
import {
  ContentCopy,
  CreateNewFolderOutlined,
  ErrorOutline,
  PersonOutline,
  Search,
} from "@mui/icons-material";
import CreateAccoungingDialog from "./Components/CreateAccountingDialog/View";
import { useHistory } from "react-router-dom";

function Site() {
  const history = useHistory();
  const style = useSiteStyle(siteImageBackground);

  const [container, setContainer] = useState<HTMLElement>();
  const [topBarHeight, setTopBarHeight] = useState<number>(0);
  const [openCreateDialog, setOpenCreateDialog] = useState(false);

  const onClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, section: string) => {
      e.preventDefault();
      container &&
        container.scrollTo({
          behavior: "smooth",
          top:
            section === "Inicio"
              ? 0
              : document.getElementById(section)!.offsetTop - topBarHeight - 48,
        });
    },
    [container, topBarHeight]
  );

  useEffect(() => {
    const _container = document.getElementsByTagName("html")[0];
    _container.style.backgroundColor = "white";
    (_container.style as any).scrollbarWidth = "thin";
    setContainer(_container);
    setTopBarHeight(document.getElementById("topBar")!.clientHeight);
    _container.scrollTo({ top: 0, behavior: "auto" });
  }, []);

  return (
    <div id="container" css={style.container}>
      <div css={[style.section, style.firstSection, style.sticky]}>
        <div id="topBar" css={[style.column, style.topBar]}>
          <img css={style.logo} alt="logo" src={LogoBlintesLight} />
          <div css={style.wrapTopBar}>
            <Button
              onClick={(e) => onClick(e, "ComoFunciona")}
              css={style.topBarButton}
            >
              Como funciona
            </Button>
            <Button
              onClick={(e) => onClick(e, "Vantagens")}
              css={style.topBarButton}
            >
              Vantagens
            </Button>
            <Button
              onClick={(e) => onClick(e, "Recursos")}
              css={style.topBarButton}
            >
              Recursos
            </Button>
            <Button
              onClick={(e) => onClick(e, "CriarConta")}
              css={style.topBarButton}
            >
              Criar conta
            </Button>
            <Button
              css={[style.topBarButton, style.loginButton]}
              onClick={() => history.push("/login")}
            >
              Login
            </Button>
          </div>
        </div>
      </div>
      <div id="Inicio" css={[style.section, style.firstSection]}>
        <div css={[style.column, style.firstColumn]}>
          <Carousel />
        </div>
      </div>
      <div id="ComoFunciona" css={[style.section, style.commomSection]}>
        <div css={[style.column, style.commomColumn]}>
          <div css={style.sectionTitle}>Como funciona?</div>
          <div css={style.sectionDescription}>
            <div>
              O Blintes foi criado pensando em ajudar escritórios e
              profissionais de contabilidade à
            </div>
            <div>
              controlar e gerenciar documentos, servindo como um meio para
              melhorar o processo de
            </div>
            <div>
              compartilhamento, armazenamento, categorização e uso de
              informações contábeis.
            </div>
          </div>
          <div css={style.howWorks}>
            <HowWorksItem
              src={howWorks1}
              alt="Como funciona 1"
              text={[
                "Convide seus clientes à",
                "participarem do Blintes,",
                "permitindo que compartilhem ",
                "documentos contábeis com ",
                "você",
              ]}
            />
            <HowWorksItem
              src={howWorks2}
              alt="Como funciona 2"
              text={[
                "O envio de documentos pode ",
                "ser feito individualmente ou ",
                "em blocos, fazendo com que ",
                "documentos que se ",
                "complementam sejam ",
                "agrupados.",
              ]}
            />
            <HowWorksItem
              src={howWorks3}
              alt="Como funciona 3"
              text={[
                "Os documentos recebidos",
                "ficam disponíveis para que ",
                "você possa categorizá-los, ",
                "transformando-os em dados",
                "à serem lançados no seu ",
                "software de contabilidade.",
              ]}
            />
          </div>
        </div>
      </div>
      <div id="Vantagens" css={[style.section, style.thirthSection]}>
        <div css={style.thirthSectionA}>
          <div css={style.thirthSectionTitle}>
            <div>Vantagens de migrar</div>
            <div>para o digital</div>
          </div>
          <div css={style.thirthSectionSubtitle}>Agilidade</div>
          <div css={style.thirthSectionDescription}>
            <div>Receba e categorize documentos rapidamente, tudo na nuvem</div>
            <div>e acessíveis de qualquer lugar.</div>
          </div>
          <div css={style.thirthSectionSubtitle}>Controle</div>
          <div css={style.thirthSectionDescription}>
            <div>Otimize seu tempo de trabalho substituindo documentos em</div>
            <div>apel por digitais.</div>
          </div>
          <div css={style.thirthSectionSubtitle}>Segurança</div>
          <div css={style.thirthSectionDescription}>
            <div>Documentos protegidos por criptografia de ponta a ponta.</div>
            <div>
              <a href="/">Saiba mais</a>
            </div>
          </div>
        </div>
        <img css={style.floatingLogo} alt="Logo flutuante" src={floatingLogo} />
        <div css={style.thirthSectionB} />
      </div>
      <div id="Recursos" css={[style.section, style.commomSection]}>
        <div css={[style.column, style.commomColumn]}>
          <div css={style.sectionTitle}>Recursos</div>
          <div css={style.sectionDescription}>
            <div>
              Os recursos do Blintes foram pensados para simplificar a gestão de
              documentos
            </div>
            <div>contábeis, otimizando o tempo de trabalho.</div>
          </div>
          <div css={style.resourcesGrid}>
            <ResourcesItem
              icon={(css) => <PersonOutline color="primary" css={css} />}
              alt="Recursos 1"
              title="Gerenciar clientes"
              description={[
                "Acesso rápido aos documentos dos",
                "seus clientes",
              ]}
            />
            <ResourcesItem
              icon={(css) => (
                <CreateNewFolderOutlined color="primary" css={css} />
              )}
              alt="Recursos 2"
              title="Gerenciar plano de contas"
              description={["Monte um plano de contas", "personalizado"]}
            />
            <ResourcesItem
              icon={(css) => (
                <ContentCopy color="primary" css={[css, style.iconMirror]} />
              )}
              alt="Recursos 3"
              title="Categorizar"
              description={[
                "Acesso rápido aos documentos dos",
                "seus clientes",
              ]}
            />
            <ResourcesItem
              icon={(css) => <Search color="primary" css={css} />}
              alt="Recursos 4"
              title="Pesquisar e encontrar"
              description={[
                "Todos os documentos categorizados",
                "podem ser encontrados rapidamente",
              ]}
            />
            <ResourcesItem
              icon={(css) => <ErrorOutline color="primary" css={css} />}
              alt="Recursos 5"
              title="Acompanhar pendências"
              description={[
                "Mantenha o controle do que for",
                "para correção ou ajuste",
              ]}
            />
            <ResourcesItem
              icon={(css) => <Priority css={[css, style.iconFill]} />}
              alt="Recursos 6"
              title="Contabilizar"
              description={[
                "Tudo pronto para ser lançado em seu",
                "sistema contábil",
              ]}
            />
          </div>
          <Button
            css={[style.topBarButton, style.createAccountingButton]}
            onClick={() => setOpenCreateDialog(true)}
          >
            Criar conta
          </Button>
        </div>
      </div>
      <div id="CriarConta" css={[style.section, style.commomSection]}>
        <div css={[style.column, style.commomColumn, style.onlyBottomPadding]}>
          <div css={style.separator} />
          <div css={style.contact}>
            <div css={style.contactDiv}>
              <img
                css={style.contactLogo}
                src={LogoBlintesBottom}
                alt="logo Blintes dark"
              />
              <div css={style.hikersCopyrights}>
                © 2023. Uma solução desenvolvida pela{" "}
                <a href="http://hikers.io">Hikers</a>
              </div>
            </div>
            <Button
              onClick={(e) => onClick(e, "Inicio")}
              css={style.loginButton}
            >
              Voltar ao início
            </Button>
            <div css={style.contactDiv}>
              <div css={style.contactTitle}>Contato</div>
              <div css={style.contactInformations}>
                <div>contato@blintes.com</div>
                <div>(81) 9 8888-8888 (WhatsApp)</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CreateAccoungingDialog
        open={openCreateDialog}
        onClose={() => setOpenCreateDialog(false)}
      />
    </div>
  );
}

export default Site;
