import { css } from "@emotion/react";
import CacheTheme from "Theme/CacheTheme";
import useCustomTheme from "Theme/CustomTheme";

var styleCached = false;
export function useContabilizationMobileStyle() {
  const name = "ContabiliztionMobileCSS";
  const theme = useCustomTheme();
  if (!styleCached) {
    const label = theme.extras.label(name);
    var _style = {
      wrapper: css({
        label: label("wrapper"),
        display: "flex",
        justifyContent: "center",
        minWidth: theme.spacing(60),
      }),
      innerWrapper: css({
        label: label("innerWrapper"),
        width: theme.spacing(60),
        padding: theme.spacing(4),
      }),
      placeholder: css({
        label: label("placeholder"),
        height: theme.spacing(7),
      }),
      headRow: css({
        label: label("headRow"),
        display: "flex",
        flexDirection: "row",
        color: "white",
        justifyContent: "space-between",
        alignItems: "center",
      }),
      avatarInfo: css({
        label: label("avatarInfo"),
        display: "flex",
        alignItems: "center",
        " > .MuiAvatar-root": {
          marginLeft: theme.spacing(1),
        },
      }),
      separator: css({
        label: label("separator"),
        minHeight: "1px",
        backgroundColor: theme.palette.background.black(10),
        margin: theme.spacing(2, 0, 3, 0),
      }),
      title: css({
        label: label("title"),
        marginBottom: theme.spacing(2),
      }),
      contabilizationTitle: css({
        label: label("contabilizationTitle"),
        color: "white",
        fontSize: "18px",
        fontWeight: "bold",
        lineHeight: "18px",
      }),
      subtitleRow: css({
        label: label("subtitleRow"),
        display: "flex",
        justifyContent: "space-between",
      }),
      blockInfoMessage: css({
        label: label("blockInfoMessage"),
        marginTop: theme.spacing(3),
      }),
      normalWeight: css({
        label: label("normalWeight"),
        fontWeight: "normal",
        marginBottom: theme.spacing(1),
      }),
      blockInfos: css({
        label: label("blockInfos"),
        display: "flex",
      }),
      halfWidth: css({
        label: label("halfWidth"),
        width: "50%",
      }),
      button: css({
        label: label("button"),
        marginTop: theme.spacing(1),
      }),
      month: css({
        label: label("month"),
        width: theme.spacing(18),
      }),
      year: css({
        label: label("year"),
        width: theme.spacing(14),
        marginLeft: theme.spacing(2),
      }),
      selectDate: css({
        label: label("selectDate"),
        display: "flex",
      }),
      dateRow: css({
        label: label("dateRow"),
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: theme.spacing(3),
      }),
      noBlocksButton: css({
        label: label("noBlocksButton"),
        height: "unset",
        lineHeight: "16px",
        color: "white !important",
      }),
      selectABlockButton: css({
        label: label("selectABlockButton"),
        height: "64px",
      }),
    };
    CacheTheme(name, theme.palette.type, _style);
    styleCached = true;
  }
  var style = CacheTheme(name, theme.palette.type);
  return style as typeof _style;
}
