import {
  OperatorAccounting,
  OperatorClient,
} from "../../../../Models/OperatorModel";
import { OperatorServices } from "../../../../Services/Interfaces";

export const operatorChangeBlock = OperatorServices.operatorChangeBlock;

export const getOperatorsListClient = async (
  setOperatorsList: React.Dispatch<
    React.SetStateAction<OperatorClient[] | OperatorAccounting[] | undefined>
  >
) => setOperatorsList(await OperatorServices.getOperatorClientList());

export const getOperatorListAccoutning = async (
  setOperatorsList: React.Dispatch<
    React.SetStateAction<OperatorClient[] | OperatorAccounting[] | undefined>
  >
) => setOperatorsList(await OperatorServices.getOperatorAccountingList());

export const removeOperatorAccounting =
  OperatorServices.removeOperatorAccounting;
export const removeOperatorClient = OperatorServices.removeOperatorClient;
export const changeAdmin = OperatorServices.changeAdmin;
