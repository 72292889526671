const styles = [] as Object[];
const nameToPosition = {} as { [name: string]: number };

export default function CacheTheme(
  name: string,
  themeType: string,
  theme?: Object
) {
  const themedNameStyle = name + "_" + themeType;
  if (theme) {
    nameToPosition[themedNameStyle] = styles.length;
    styles.push(theme);
    return theme;
  } else {
    return styles[nameToPosition[themedNameStyle]];
  }
}
