import { css } from "@emotion/react";
import CacheTheme from "Theme/CacheTheme";
import useCustomTheme from "Theme/CustomTheme";

var styleCached = false;
export function useProfileOfficeDialogStyle() {
  const name = "ProfileOfficeDialogCSS";
  const theme = useCustomTheme();
  if (!styleCached) {
    const label = theme.extras.label(name);
    var _style = {
      dialogHead: css({
        label: label("dialogHead"),
        display: "flex",
        justifyContent: "space-between",
        " > div": {
          marginTop: theme.spacing(0),
          width: theme.spacing(32),
        },
      }),
      isJuridic: css({
        label: label("isJuridic"),
        width: theme.spacing(102),
      }),
      isIndividual: css({
        label: label("isIndividual"),
        width: theme.spacing(86),
      }),
      dialogContent: css({
        label: label("dialogContent"),
        display: "flex",
      }),
      separator: css({
        label: label("separator"),
        marginBottom: theme.spacing(4),
      }),
      formColumn: css({
        label: label("formColumn"),
        marginLeft: theme.spacing(4),
        width: theme.spacing(39),
      }),
      avatar: css({
        label: label("avatar"),
        width: theme.spacing(16),
        height: theme.spacing(16),
      }),
      time: theme.extras.transitionTime.faster,
      timeFast: theme.extras.transitionTime.fast,
    };
    CacheTheme(name, theme.palette.type, _style);
    styleCached = true;
  }
  var style = CacheTheme(name, theme.palette.type);
  return style as typeof _style;
}
