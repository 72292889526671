import { css } from "@emotion/react";
import CacheTheme from "Theme/CacheTheme";
import useCustomTheme from "Theme/CustomTheme";

var styleCached = false;
export function useOperatorActionDisplayStyle() {
  const name = "OperatorActionDisplayCSS";
  const theme = useCustomTheme();
  if (!styleCached) {
    const label = theme.extras.label(name);
    var _style = {
      wrapper: css({
        label: label("wrapper"),
        display: "flex",
        borderRadius: theme.extras.borderRadius,
        transition: theme.extras.transition.fast("background-color"),
        backgroundColor: theme.palette.secondary.main,
        flexDirection: "row-reverse",
        padding: theme.spacing(1.5),
      }),
      highlight: css({
        label: label("highlight"),
        backgroundColor: theme.palette.secondary.white(10),
      }),
      display: css({
        label: label("display"),
        position: "absolute",
        borderRadius: theme.extras.borderRadius,
        backgroundColor: theme.palette.secondary.main,
        transition: theme.extras.transition.fast(["width", "background-color"]),
        borderTopRightRadius: theme.spacing(0),
        borderBottomRightRadius: theme.spacing(0),
        marginRight: theme.spacing(4),
        marginTop: theme.spacing(-1.5),
        height: theme.spacing(7),
        padding: theme.spacing(1.5, 0),
        overflow: "hidden",
        zIndex: 2,
      }),
      innerDisplay: (elementSize: number) =>
        css({
          label: label("innerDisplay"),
          width: "calc(" + elementSize + "px + " + theme.spacing(4) + ")",
          display: "flex",
          flexDirection: "column",
          alignItems: "end",
          paddingRight: theme.spacing(2),
        }),
      expanded: (elementSize: number) =>
        css({
          label: label("expanded"),
          width: "calc(" + elementSize + "px + " + theme.spacing(4) + ")",
          backgroundColor: theme.palette.secondary.white(10),
        }),
      notExpanded: css({
        label: label("notExpanded"),
        width: theme.spacing(0),
      }),
      title: css({
        label: label("title"),
        color: theme.palette.transparency.white(70),
        whiteSpace: "pre",
        fontSize: "10px",
      }),
      name: css({
        label: label("name"),
        color: "white",
        whiteSpace: "pre",
        fontWeight: "bold",
        fontSize: "12px",
      }),
      fast: theme.extras.transitionTime.fast,
    };
    CacheTheme(name, theme.palette.type, _style);
    styleCached = true;
  }
  var style = CacheTheme(name, theme.palette.type);
  return style as typeof _style;
}
