import { map } from "rxjs/operators";
import { ajax } from "rxjs/ajax";
import { callAuthApiPromise } from "../App";

export const login = (credentials = {}) =>
  ajax({
    url: "/api/token/",
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: credentials,
  }).pipe(
    map(({ response }) => ({
      accessToken: response.access,
      refreshToken: response.refresh,
    }))
  );

export const me = (token: string) =>
  ajax.getJSON<{ id: string }>("/api/me/", {
    Authorization: `Bearer ${token}`,
  });

export const refresh = (refreshToken: any) =>
  ajax({
    url: "/api/token/refresh/",
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: { refresh: refreshToken },
  }).pipe(
    map(({ response }) => ({
      refreshToken,
      accessToken: response.access,
    }))
  );

export async function callPromiseWrapper(url: string, body?: any) {
  return (
    await callAuthApiPromise(
      (token: string) => () =>
        body
          ? ajax({
              url: url,
              method: "POST",
              headers: {
                Authorization: token ? `Bearer ${token}` : undefined,
                "Content-Type": "application/json",
              },
              body: JSON.stringify(body),
            }).toPromise()
          : ajax({
              url: url,
              method: "GET",
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }).toPromise()
    )
  ).response;
}
