/** @jsxImportSource @emotion/react */
import { GridView, ViewStreamOutlined } from "@mui/icons-material";
import { Button } from "@mui/material";
import OverlapPanel from "../../../../../Components/OverlapPanel/View";
import { useChangeAccountingPanelStyle } from "./Style";

function ChangeAccountingPanel({
  showAccountings,
  setShowAccountings,
  changeAccountingPanelRef,
  client = false,
}: {
  showAccountings: boolean;
  setShowAccountings: React.Dispatch<React.SetStateAction<boolean>>;
  changeAccountingPanelRef: React.MutableRefObject<() => void>;
  client?: boolean;
}) {
  const style = useChangeAccountingPanelStyle();

  return (
    <OverlapPanel showPanelRef={changeAccountingPanelRef}>
      {(openedShowContent, setOpenedShowContent) => (
        <div
          onClick={(e) => {
            e.stopPropagation();
          }}
          css={style.changeAccountingPanel(client)}
        >
          <Button
            disableRipple={showAccountings}
            onClick={() => {
              if (!showAccountings && openedShowContent) {
                setShowAccountings(true);
                setOpenedShowContent(false);
              }
            }}
            css={[
              style.item,
              showAccountings ? style.selected : style.notSelected,
            ]}
          >
            <span>
              Visualizar
              <span css={style.bold}> Contas</span>
            </span>
            <ViewStreamOutlined css={style.icon} />
          </Button>
          <div css={style.separator} />
          <Button
            disableRipple={!showAccountings}
            onClick={() => {
              if (showAccountings && openedShowContent) {
                setShowAccountings(false);
                setOpenedShowContent(false);
              }
            }}
            css={[
              style.item,
              !showAccountings ? style.selected : style.notSelected,
            ]}
          >
            <span>
              Visualizar
              <span css={style.bold}> Blocos</span>
            </span>
            <GridView css={style.icon} />
          </Button>
        </div>
      )}
    </OverlapPanel>
  );
}

export default ChangeAccountingPanel;
