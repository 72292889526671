/** @jsxImportSource @emotion/react */
import { AccountCircleOutlined, Add, Close, Delete } from "@mui/icons-material";
import { useEffect, useRef, useState } from "react";
import DisplayList from "../../../../Components/DisplayList/View";
import { useFrameDrawerStyle } from "../../../../Components/Frame/Components/FrameDrawer/Style";
import ButtonSquared from "../../../../Components/ButtonSquared/View";
import TextEntry from "../../../../Components/TextEntry/View";
import FrameDrawer from "../FrameDrawer/View";
import OpacityTwoTimesTransition from "../../../OpacityTwoTimesTransition/View";
import { useDrawerAccountingPlanStyle } from "../../../../Pages/Accounting/Accounting/Components/DrawerAccountingPlan/Style";
import { useOperatorDrawerStyle } from "./Style";
import OperatorItem from "./OperatorItem/View";
import LoadingTransition from "../../../LoadingTransition/View";
import {
  OperatorAccounting,
  OperatorClient,
} from "../../../../Models/OperatorModel";
import { getOperatorListAccoutning, getOperatorsListClient } from "./Presenter";
import useLoadingTransitionExpanded from "../../../../Hooks/LoadingTransitionExpanded";

function OperatorDrawer({
  operatorDrawerRef,
  client = false,
}: {
  operatorDrawerRef: React.MutableRefObject<(operatorType?: number) => void>;
  client?: boolean;
}) {
  const style = useOperatorDrawerStyle();
  const drawerAccountingPlanStyle = useDrawerAccountingPlanStyle();
  const frameDrawerStyle = useFrameDrawerStyle();

  const [operatorType, setOperatorType] = useState<number>();
  const [selected, setSelected] = useState(-1);

  const [operatorsList, setOperatorsList] = useState<
    OperatorClient[] | OperatorAccounting[]
  >();

  const changeChildRef = useRef(() => {});
  const setDisabledRef = useRef<React.Dispatch<React.SetStateAction<boolean>>>(
    () => {}
  );

  useEffect(() => {
    operatorDrawerRef.current = (operatorType) => {
      expandedState[1](true);
      setOperatorType(operatorType ?? 2);
    };
    // eslint-disable-next-line
  }, []);

  const [transitionOff, expandedState, changeLoading] =
    useLoadingTransitionExpanded(
      operatorsList !== undefined,
      client
        ? () => getOperatorsListClient(setOperatorsList)
        : () => getOperatorListAccoutning(setOperatorsList)
    );

  return (
    <FrameDrawer
      drawerOpened={expandedState[0]}
      onClose={() => expandedState[1](false)}
      searchTitle="Buscar operador"
      setDisabledRef={setDisabledRef}
    >
      <LoadingTransition window transitionOff={transitionOff}>
        {(_changeLoading) => {
          changeLoading.current = _changeLoading;
          return (
            <OpacityTwoTimesTransition
              setDisabledSearchRef={setDisabledRef}
              changeChildRef={changeChildRef}
              firstChild={
                <>
                  <div
                    css={[
                      frameDrawerStyle.title,
                      drawerAccountingPlanStyle.title,
                    ]}
                  >
                    Adicionar operador
                    <ButtonSquared
                      onClick={() => changeChildRef.current()}
                      color="warning"
                    >
                      <Close />
                    </ButtonSquared>
                  </div>
                  <div css={[frameDrawerStyle.subtitle, style.subtitle]}>
                    E-mail
                  </div>
                  <TextEntry
                    ccss={drawerAccountingPlanStyle.textEntry}
                    placeholder="Digite aqui"
                    endIcon={
                      <ButtonSquared minor color="success">
                        <Add />
                      </ButtonSquared>
                    }
                    searchIcon
                  />
                  <div
                    css={[
                      frameDrawerStyle.subtitle,
                      drawerAccountingPlanStyle.subtitle,
                    ]}
                  >
                    Adicionados recentemente (aguardando cadastro)
                  </div>
                  <DisplayList
                    paddingTopHeight={2}
                    heightOfFixContent="234px"
                    itens={(operatorsList ?? []).map((operator) => (
                      <div
                        key={"OperatorRemoveEmail-" + operator.id}
                        css={style.item}
                      >
                        <div css={style.dipslay}>
                          <AccountCircleOutlined />
                          <span css={style.email}>{operator.email}</span>
                        </div>
                        <ButtonSquared
                          minor
                          transparentIcon
                          color="secondaryWhite20ErrorHover"
                        >
                          <Delete />
                        </ButtonSquared>
                      </div>
                    ))}
                  />
                </>
              }
              secondChild={
                <>
                  <div
                    css={[
                      frameDrawerStyle.title,
                      drawerAccountingPlanStyle.title,
                    ]}
                  >
                    Operadores
                    {operatorType ? (
                      <ButtonSquared
                        onClick={() => changeChildRef.current()}
                        color="primary"
                      >
                        <Add />
                      </ButtonSquared>
                    ) : (
                      <div css={style.placeholder} />
                    )}
                  </div>
                  <DisplayList
                    paddingTopHeight={2}
                    heightOfFixContent="72px"
                    itens={(operatorsList ?? [])
                      .sort((a, b) => {
                        const result = a.type - b.type;
                        if (result === 0) {
                          return a.name.localeCompare(b.name);
                        } else {
                          return result;
                        }
                      })
                      .map((operator, index) => (
                        <OperatorItem
                          onClick={() =>
                            selected === index
                              ? setSelected(-1)
                              : setSelected(index)
                          }
                          operator={operator}
                          setOperatorsList={setOperatorsList}
                          selected={selected === index}
                          key={"OperatorItem-" + index.toString()}
                          client={client}
                          operatorType={operatorType}
                        />
                      ))}
                  />
                </>
              }
            />
          );
        }}
      </LoadingTransition>
    </FrameDrawer>
  );
}

export default OperatorDrawer;
