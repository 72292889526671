/** @jsxImportSource @emotion/react */
import React from "react";
import { Badge, SvgIcon } from "@mui/material";
import { useSideBarHomeStyle } from "../Style";
import { SerializedStyles } from "@emotion/react";

function Icon({
  iconSpan,
  icon,
  style,
}: {
  iconSpan: boolean;
  icon: React.ReactChild;
  style: (SerializedStyles | undefined)[];
}) {
  return iconSpan ? (
    <div css={style}>{icon}</div>
  ) : (
    <SvgIcon css={style}>{icon}</SvgIcon>
  );
}

function SideBarHomeIcon({
  text,
  selected = false,
  icon,
  disabled = false,
  isExpanded,
  iconSpan = false,
  iconMirror = false,
  badgeNumber,
  onClick,
}: {
  text: string;
  selected?: boolean;
  icon: React.ReactChild;
  disabled?: boolean;
  isExpanded: boolean;
  iconSpan?: boolean;
  iconMirror?: boolean;
  badgeNumber?: number;
  onClick?: () => void;
}) {
  const style = useSideBarHomeStyle();

  return (
    <div
      onClick={() => {
        onClick?.apply([]);
      }}
      css={[
        style.item,
        disabled ? style.noInteractions : undefined,
        selected ? style.itemSelected : style.itemNotSelected,
      ]}
    >
      {badgeNumber ? (
        <Badge
          css={style.badge}
          badgeContent={badgeNumber}
          onClick={(e) => {
            onClick?.apply([]);
            e.stopPropagation();
          }}
          overlap="circular"
          color="warning"
          showZero
        >
          <Icon
            iconSpan={iconSpan}
            icon={icon}
            style={[
              style.iconSize,
              disabled ? style.iconDisabled : undefined,
              iconMirror ? style.iconMirror : undefined,
            ]}
          />
        </Badge>
      ) : (
        <Icon
          iconSpan={iconSpan}
          icon={icon}
          style={[
            style.iconSize,
            disabled ? style.iconDisabled : undefined,
            iconMirror ? style.iconMirror : undefined,
          ]}
        />
      )}
      <div
        css={[
          style.textItem,
          isExpanded ? style.textItemExpanded : style.textItemNotExpanded,
        ]}
      >
        <span css={style.text}>{text}</span>
      </div>
    </div>
  );
}

export default SideBarHomeIcon;
