/** @jsxImportSource @emotion/react */

import { useRef, useState } from "react";
import TurquesaAvatar from "../../../TurquesaAvatar/View";
import { useOperatorActionDisplayStyle } from "./Style";

function OperatorActionDisplay({
  action,
  by,
  photoUrl,
}: {
  action: string;
  by: string;
  photoUrl?: string;
}) {
  const nameRef = useRef<HTMLDivElement>(null);
  const titleRef = useRef<HTMLDivElement>(null);
  const elementSize = Math.max(
    nameRef.current?.clientWidth ?? 0,
    titleRef.current?.clientWidth ?? 0
  );
  const style = useOperatorActionDisplayStyle();

  const [expanded, setExpanded] = useState(false);

  return (
    <div
      css={[style.wrapper, expanded ? style.highlight : undefined]}
      onMouseEnter={
        expanded
          ? undefined
          : () => {
              setExpanded(true);
            }
      }
      onMouseLeave={expanded ? () => setExpanded(false) : undefined}
    >
      <div
        css={[
          style.display,
          expanded ? style.expanded(elementSize) : style.notExpanded,
        ]}
      >
        <div css={style.innerDisplay(elementSize)}>
          <div ref={titleRef} css={style.title}>
            {action} por
          </div>
          <div ref={nameRef} css={style.name}>
            {by}
          </div>
        </div>
      </div>
      <TurquesaAvatar size={4} src={photoUrl} nameToInitials={by} />
    </div>
  );
}

export default OperatorActionDisplay;
