/** @jsxImportSource @emotion/react */
import { BlockModel } from "../../../../../../Models/BlockModel";
import { mapMonth } from "../../../../../../Utils/Dates";
import { stringToValue } from "../../../../../../Utils/String";
import { useFrameDrawerStyle } from "../../../../../Frame/Components/FrameDrawer/Style";
import { getAccountInBlockContext } from "../ViewBlockAutocomplete/Presenter";
import { useEditBlockEntriesStyle } from "../EditBlockEntries/Style";
import { useContabilizedBlockEntriesStyle } from "./Style";
import { useContext, useEffect, useRef, useState } from "react";
import { EditHomeContext } from "../../../../../../Pages/Client/Home/Model";
import { setBlockViewed } from "../../../../Presenter";
import { useBlockCreateStyle } from "../../../../../../Pages/Client/BlockCreate/Style";
import TextEntry from "../../../../../TextEntry/View";

function ViewBlockEntries({
  block,
  accountInLine = false,
  show = true,
  onBack = false,
}: {
  block?: BlockModel;
  accountInLine?: boolean;
  show?: boolean;
  onBack?: boolean;
}) {
  const style = useContabilizedBlockEntriesStyle();
  const editBlockEntriesStyle = useEditBlockEntriesStyle();
  const frameDrawerStyle = useFrameDrawerStyle();
  const blockCreateStyle = useBlockCreateStyle();

  const [observationHover, setObservationHover] = useState(false);
  const [hiddenTransition, setHiddenTransition] = useState(onBack);

  const { fullAccountList } = getAccountInBlockContext();
  const account = fullAccountList.find((e) => e.id === block?.accountId);

  const observationsRef = useRef<HTMLElement>(null);

  const editContext = useContext(EditHomeContext);

  useEffect(() => {
    if (!show && !onBack) {
      setHiddenTransition(true);
    }
    if (onBack && show) {
      const observer = new IntersectionObserver(() =>
        setHiddenTransition(false)
      );
      if (observationsRef.current) {
        observer.observe(observationsRef.current);
      }
    }
  }, [observationsRef, onBack, show]);

  useEffect(() => {
    setTimeout(() => {
      if (!block?.viewed) {
        editContext((value) => {
          for (const _block of value!) {
            if (!_block.viewed && _block.id === block!.id) {
              _block.viewed = true;
              setBlockViewed(_block.id);
              break;
            }
          }
          return [...value!];
        });
      }
    }, style.time * 2);
    // eslint-disable-next-line
  }, []);

  const currentDate = new Date();

  return (
    <>
      <div css={frameDrawerStyle.subtitle}>Data de pagamento</div>
      <div css={editBlockEntriesStyle.dateRow}>
        <TextEntry
          ccss={editBlockEntriesStyle.dayTextEntry}
          initialValue={block?.date.getDate().toString() ?? ""}
          placeholder="dd"
          hiddenClear
          params={{
            inputProps: {
              readOnly: true,
            },
          }}
        />
        <div
          css={[
            editBlockEntriesStyle.monthSelect,
            style.entry,
            style.leftSpacing,
          ]}
        >
          {mapMonth[(block?.date ?? currentDate).getMonth().toString()]}
        </div>
        <div
          css={[
            editBlockEntriesStyle.yearSelect,
            style.entry,
            style.leftSpacing,
          ]}
        >
          {(block?.date ?? currentDate).getFullYear().toString()}
        </div>
      </div>
      {accountInLine ? (
        <div
          css={[
            blockCreateStyle.pendencyDetails,
            blockCreateStyle.floatingTitle,
            frameDrawerStyle.subtitle,
          ]}
        >
          Conta
        </div>
      ) : (
        <div css={frameDrawerStyle.subtitle}>Conta</div>
      )}
      {accountInLine ? (
        <div
          css={[
            blockCreateStyle.pendencyBox,
            blockCreateStyle.floatingBox,
            style.accountInLine,
            block !== undefined && observationHover && !hiddenTransition
              ? blockCreateStyle.expanded(
                  observationsRef.current!.getBoundingClientRect().height
                )
              : undefined,
          ]}
          onMouseEnter={() => setObservationHover(true)}
          onMouseLeave={() => setObservationHover(false)}
        >
          <span
            css={[
              blockCreateStyle.textAlign,
              blockCreateStyle.mask,
              style.mask,
              observationHover ? style.hiddenMask : undefined,
              style.opacityTransition,
              hiddenTransition ? style.hidden : undefined,
              block ? undefined : style.placeholder,
              block ? undefined : style.accountingPaddingLeft,
            ]}
            ref={observationsRef}
          >
            {account
              ? account.code + " - " + account.description
              : "Selecionar conta"}
          </span>
          <div css={blockCreateStyle.hidder} />
        </div>
      ) : (
        <div css={[style.entry, style.column, editBlockEntriesStyle.dateRow]}>
          <span>{account?.code}</span>
          <span>{account?.description}</span>
        </div>
      )}
      <div
        css={[
          frameDrawerStyle.subtitle,
          accountInLine ? style.accountHeight : undefined,
        ]}
      >
        Valor
      </div>
      <div
        css={[
          style.entry,
          editBlockEntriesStyle.dateRow,
          style.noMarginBottom,
          block ? undefined : style.placeholder,
        ]}
      >
        {block ? stringToValue(block.value) : "R$"}
      </div>
    </>
  );
}

export default ViewBlockEntries;
