/** @jsxImportSource @emotion/react */
import ContabilizationBlock from "../../../Components/ContabilizationBlock/View";
import ButtonMain from "../../../Components/ButtonMain/View";
import { useFrameDrawerStyle } from "../../../Components/Frame/Components/FrameDrawer/Style";
import TurquesaAvatar from "../../../Components/TurquesaAvatar/View";
import { useMainContabilizationStyle } from "../Contabilization/Components/MainContabilization/Style";
import { useContabilizationMobileStyle } from "./Style";
import { useEffect } from "react";
import { mobileSettings, monthSort } from "../../../Utils/Utils";
import FrameSelect from "../../../Components/FrameSelect/View";
import LoadingTransition from "Components/LoadingTransition/View";
import { useContabilizationMobilePresenter } from "./Presenter";
import { useHistory } from "react-router-dom";
import { dateToString, stringToValue } from "Utils/String";
import ContabilizedItem from "../Contabilization/Components/ContabilizedItem/View";
import { contabilizeBlock } from "../Contabilization/Presenter";

function ContabilizationMobile() {
  const style = useContabilizationMobileStyle();
  const frameDrawerStyle = useFrameDrawerStyle();
  const mainContabilizationStyle = useMainContabilizationStyle();
  const history = useHistory();

  const presenter = useContabilizationMobilePresenter();

  useEffect(mobileSettings, []);

  const toList = () =>
    history.push({
      pathname: "/home/client/contabilization/mobile/list",
      state: {
        accounts: presenter.sortedAccounts.map((e) => ({
          ...e,
          uncontabilizedNumber: presenter.blocksNumberByAccount(e),
        })),
        operatorName: presenter.operatorName,
        photoUrl: presenter.operatorPhotoUrl,
      },
    });

  return (
    <LoadingTransition>
      {(_changeLoading) => {
        presenter.changeLoading.current = _changeLoading;
        return (
          <div css={style.wrapper}>
            <div css={style.innerWrapper}>
              <div css={style.headRow}>
                <div css={style.placeholder} />
                <div css={style.avatarInfo}>
                  {presenter.operatorName}
                  <TurquesaAvatar
                    nameToInitials={presenter.operatorName}
                    src={presenter.operatorPhotoUrl}
                    size={4}
                  />
                </div>
              </div>
              <div css={style.separator} />
              <div css={style.dateRow}>
                <div css={style.contabilizationTitle}>Contabilizar</div>
                <div css={style.selectDate}>
                  <FrameSelect
                    ccss={style.month}
                    options={presenter.datesSelect[presenter.yearSelected]}
                    onSelect={presenter.setMonthSelected}
                    value={presenter.monthSelected}
                    showLenghtOptions={2}
                    color="dark"
                  />
                  <FrameSelect
                    ccss={style.year}
                    options={Object.keys(presenter.datesSelect).sort(monthSort)}
                    onSelect={presenter.setYearSelected}
                    value={presenter.yearSelected}
                    color="dark"
                    showLenghtOptions={
                      Object.keys(presenter.datesSelect).sort(monthSort)
                        .length < 3
                        ? Object.keys(presenter.datesSelect).sort(monthSort)
                            .length
                        : 3
                    }
                  />
                </div>
              </div>
              <div css={[frameDrawerStyle.subtitle, style.subtitleRow]}>
                <span>Conta</span>{" "}
                <span css={style.normalWeight}>
                  Toque para selecionar outra conta
                </span>
              </div>
              {presenter.sortedAccounts.length === 0 ? (
                <ButtonMain
                  ccss={style.noBlocksButton}
                  color="backgroundWhite10"
                  disabled={true}
                >
                  Sem blocos a serem contabilizados para a data selecionada
                </ButtonMain>
              ) : !presenter.accountSelected ? (
                <ButtonMain
                  ccss={style.selectABlockButton}
                  color="backgroundWhite10"
                  onClick={toList}
                >
                  Selecione uma conta
                </ButtonMain>
              ) : (
                <ContabilizationBlock
                  code={presenter.accountSelected.code}
                  name={presenter.accountSelected.description}
                  number={presenter.blocksNumberByAccount(
                    presenter.accountSelected
                  )}
                  onClick={toList}
                />
              )}
              <div css={[frameDrawerStyle.subtitle, style.blockInfoMessage]}>
                Informações de bloco
              </div>
              <div css={mainContabilizationStyle.mainContabilization}>
                {presenter.uncontabilizedBlocks.length > 0 ? (
                  <div css={style.blockInfos}>
                    <div css={style.halfWidth}>
                      <div css={frameDrawerStyle.subtitle}>Data</div>
                      <div
                        css={[
                          frameDrawerStyle.title,
                          mainContabilizationStyle.title,
                        ]}
                      >
                        {dateToString(presenter.uncontabilizedBlocks[0].date)}
                      </div>
                    </div>
                    <div css={style.halfWidth}>
                      <div css={frameDrawerStyle.subtitle}>Valor</div>
                      <div
                        css={[
                          frameDrawerStyle.title,
                          mainContabilizationStyle.title,
                        ]}
                      >
                        {stringToValue(presenter.uncontabilizedBlocks[0].value)}
                      </div>
                    </div>
                  </div>
                ) : undefined}
                <ButtonMain
                  ccss={[mainContabilizationStyle.button, style.button]}
                  color="success"
                  disabled={presenter.uncontabilizedBlocks.length === 0}
                  onClick={async () => {
                    const currentUncontabilizedBlock =
                      presenter.uncontabilizedBlocks[0];
                    await contabilizeBlock(
                      currentUncontabilizedBlock.id,
                      presenter.link
                    );
                    presenter.setAllBlocks((list) =>
                      list?.map((e) =>
                        e.id === currentUncontabilizedBlock.id
                          ? { ...e, contabilizedDatetime: new Date() }
                          : e
                      )
                    );
                  }}
                >
                  {presenter.uncontabilizedBlocks.length > 0
                    ? "Próximo"
                    : "Sem blocos!"}
                </ButtonMain>
              </div>
              <div
                css={
                  presenter.accountSelected
                    ? undefined
                    : mainContabilizationStyle.hideHistoryButton
                }
              >
                <div
                  css={[
                    frameDrawerStyle.subtitle,
                    mainContabilizationStyle.subtitle,
                  ]}
                >
                  Últimos blocos contabilizados
                </div>
                {presenter.contabilizedBlocks.length > 0 ? (
                  presenter.contabilizedBlocks.map((block, index) => (
                    <ContabilizedItem
                      link={presenter.link}
                      block={block}
                      setAllBlocks={presenter.setAllBlocks}
                      key={"HistoryContabilizationBlock-" + index.toString()}
                    />
                  ))
                ) : (
                  <div css={mainContabilizationStyle.noItemMessage}>
                    Sem itens nesta lista
                  </div>
                )}
                <ButtonMain
                  onClick={() =>
                    history.push({
                      pathname: "/home/client/contabilization/mobile/history",
                      state: {
                        contabilizedBlocks: presenter.contabilizedBlocks,
                        link: presenter.link,
                        operatorName: presenter.operatorName,
                        photoUrl: presenter.operatorPhotoUrl,
                      },
                    })
                  }
                  color="secondaryWhite10PrimaryHover"
                  ccss={mainContabilizationStyle.historyButton}
                  disabled={false}
                >
                  Visualizar Histórico
                </ButtonMain>
              </div>
            </div>
          </div>
        );
      }}
    </LoadingTransition>
  );
}

export default ContabilizationMobile;
