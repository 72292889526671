import { css } from "@emotion/react";
import CacheTheme from "Theme/CacheTheme";
import useCustomTheme from "Theme/CustomTheme";

var styleCached = false;
export function useProfileTextEntryStyle() {
  const name = "ProfileTextEntryCSS";
  const theme = useCustomTheme();
  if (!styleCached) {
    const label = theme.extras.label(name);
    var _style = {
      textEntry: css({
        label: label("textEntry"),
        borderRadius: theme.extras.borderRadius,
        transition: theme.extras.transition.fast("background-color"),
        marginTop: theme.spacing(0.5),
        marginBottom: theme.spacing(1.5),
        "& .MuiInputBase-root": {
          transition: theme.extras.transition.fast("padding-left"),
          paddindLeft: theme.spacing(0),
        },
        "& .MuiInputBase-input": {
          transition: theme.extras.transition.fast("color"),
          paddingLeft: theme.spacing(0),
          color: "white",
        },
        "& .MuiOutlinedInput-notchedOutline": {
          border: "none",
        },
        "& .MuiOutlinedInput-input.Mui-disabled": {
          WebkitTextFillColor: "unset",
        },
      }),
      subtitle: css({
        label: label("subtitle"),
        fontSize: "14px",
        fontWeight: "bold",
        color: theme.palette.secondary.white(50),
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
      }),
      editPadding: css({
        label: label("editPadding"),
        "& .MuiInputBase-root": {
          paddingLeft: theme.spacing(2),
          paddingRight: theme.spacing(2),
        },
      }),
      editBackground: css({
        label: label("editBackground"),
        backgroundColor: theme.palette.background.main,
      }),
      error: css({
        label: label("error"),
        backgroundColor: "#372829",
      }),
    };
    CacheTheme(name, theme.palette.type, _style);
    styleCached = true;
  }
  var style = CacheTheme(name, theme.palette.type);
  return style as typeof _style;
}
