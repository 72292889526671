import { Client, HomeClient } from "../../Models/ClientModel";
import { callPromiseWrapper } from "../../Utils/Login";
import { IClientServices } from "../Interfaces";

class ClientServicesImpl implements IClientServices {
  async getClient() {
    return (await callPromiseWrapper("/api/client")).data;
  }

  async postClient(client: Client) {
    await callPromiseWrapper("/api/client", client);
  }

  async postClientLogo(base64url: string) {
    return callPromiseWrapper("/api/client/logo", { base64url: base64url });
  }

  async getClients() {
    const result = (await callPromiseWrapper("/api/clients"))
      .data as HomeClient[];
    result.forEach((e) => (e.modifiedDatetime = new Date(e.modifiedDatetime)));
    return [...result];
  }

  async getClientById(clientId: string) {
    return (await callPromiseWrapper("/api/clients", { clientId: clientId }))
      .data;
  }

  async getClientBusy(clientId: string) {
    const result = (
      await callPromiseWrapper("/api/client/busy", {
        clientId: clientId,
      })
    ).data;
    if (result === null) {
      return undefined;
    }
    return result;
  }
}

export default ClientServicesImpl;
