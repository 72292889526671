import useGetAccoutingInfo from "Hooks/GetAccountingInfo";
import { OperatorAvatar } from "Models/OperatorModel";
import { useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import useGetFrameOperator from "../../../Hooks/GetFrameOperator";
import useLoadingTransition from "../../../Hooks/LoadingTransition";
import { KeyedDates } from "../../../Hooks/ShowAccountingsBlocks/Model";
import useShowAccountingsBlocks from "../../../Hooks/ShowAccountingsBlocks/View";
import { ClientAccountModel } from "../../../Models/AccountModel";
import { BlockModel } from "../../../Models/BlockModel";
import { Client, emptyClient } from "../../../Models/ClientModel";
import { PendencyModel } from "../../../Models/PendencyModel";
import {
  AccountServices,
  BlockServices,
  ClientServices,
  PendencyServices,
} from "../../../Services/Interfaces";
import { extractDates, mapMonth, organizeDates } from "../../../Utils/Dates";
import { SYNC_DELAY } from "../../../Utils/Utils";
import { AccountingDrawerOptions } from "./Components/AccountingDrawer/Model";
import {
  AccountingInfos,
  KeyedDatesPendencyModel,
  KeyedPendencyCount,
  ShowAccountingsPendencyState,
} from "./Model";

export function useAccountingPresenter() {
  const history = useHistory();
  const { state: clientId } = useLocation<string>();
  const [clientDialog, setClientDialog] = useState(false);
  const [removeDialog, setRemoveDialog] = useState(false);
  const [showAccountings, setShowAccountings] = useState(true);
  const [expandSideBar, setExpandSidebar] = useState(false);

  const [operatorInAccounting, setIsbusy] = useState<OperatorAvatar>();
  const [client, setClient] = useState<Client>(emptyClient);
  const operatorState = useGetFrameOperator();
  const accountingState = useGetAccoutingInfo();

  const [uncontabilizedBlocks, setUncontabilizedBlocks] = useState(-1);
  const [uncategorizedBlocks, setUncategorizedBlocks] = useState(0);
  const [allBlocks, setAllBlocks] = useState<BlockModel[]>();
  const [clientAccounts, setClientAccounts] = useState<ClientAccountModel[]>();
  const [pendencies, setPendencies] = useState<PendencyModel[]>();

  const [keyedPendency, setKeyedPendecy] = useState<KeyedDatesPendencyModel>(
    {}
  );
  const [pendencyDatesSelect, setPendencyDatesSelect] = useState<KeyedDates>(
    {}
  );
  const [keyedPendencyCount, setKeyedPendencyCount] =
    useState<KeyedPendencyCount>({});
  const [pendencyYearSelected, setPendencyYearSelected] = useState("");
  const [pendencyMonthSelected, setPendencyMonthSelected] = useState("");

  const showAccountingsBlocksState = useShowAccountingsBlocks(
    allBlocks,
    clientAccounts
  );

  const [changeLoading] = useLoadingTransition(
    showAccountingsBlocksState[3] !== "" &&
      showAccountingsBlocksState[5] !== "" &&
      pendencyYearSelected !== "" &&
      pendencyMonthSelected !== "" &&
      client.person.address?.cep !== "" &&
      operatorState[0].id !== "" &&
      accountingState[0]?.address?.cep !== "" &&
      uncontabilizedBlocks >= 0
  );

  const changeAccountingPanelRef = useRef<() => void>(() => null);
  const choosedDrawerRef = useRef<(choice: AccountingDrawerOptions) => void>(
    () => null
  );

  const showAccountingsPendencyState = [
    keyedPendency,
    pendencyDatesSelect,
    keyedPendencyCount,
    pendencyYearSelected,
    setPendencyYearSelected,
    pendencyMonthSelected,
    setPendencyMonthSelected,
  ] as ShowAccountingsPendencyState;

  useEffect(() => {
    if (!clientId) {
      history.push("/home");
    }
    if (!operatorInAccounting) {
      getClientById(clientId, setClient);
      getClientBlocks(clientId, setAllBlocks, setUncategorizedBlocks);
      getClientAccountings(clientId, setClientAccounts);
      getPendencyList(clientId, setPendencies);
    } else {
      setUncontabilizedBlocks(0);
    }
    //eslint-disable-next-line
  }, [operatorInAccounting]);

  useEffect(() => {
    if (allBlocks) {
      setUncontabilizedBlocks(
        allBlocks.reduce(
          (a, e) => a + (e.contabilizedDatetime === undefined ? 1 : 0),
          0
        )
      );
    }
  }, [allBlocks]);

  useEffect(() => {
    verifyClientJob(clientId, setIsbusy);
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (pendencies) {
      const [pendencyDates, keyedPendencyCount] =
        organizePendencies(pendencies);
      setKeyedPendecy(pendencyDates);
      setKeyedPendencyCount(keyedPendencyCount);
      extractDates(
        pendencyDates,
        setPendencyYearSelected,
        setPendencyMonthSelected,
        setPendencyDatesSelect
      );
    }
  }, [pendencies]);

  const chooseDrawer = (choose: AccountingDrawerOptions) =>
    choosedDrawerRef.current(choose);

  return {
    changeLoading,
    client,
    operatorState,
    setClientAccounts,
    setAllBlocks,
    operatorInAccounting,
    setClientDialog,
    showAccountings,
    showAccountingsBlocksState,
    expandSideBar,
    showAccountingsPendencyState,
    chooseDrawer,
    changeAccountingPanelRef,
    setRemoveDialog,
    uncategorizedBlocks,
    uncontabilizedBlocks,
    setExpandSidebar,
    removeDialog,
    clientDialog,
    setShowAccountings,
    choosedDrawerRef,
    accountingState,
  };
}

const getClientById = async (
  clientId: string,
  setClients: React.Dispatch<React.SetStateAction<Client>>
) => setClients(await ClientServices.getClientById(clientId));

const getClientBlocks = async (
  clientId: string,
  setAllBlocks: React.Dispatch<React.SetStateAction<BlockModel[] | undefined>>,
  setUncategorizedBlocks: React.Dispatch<React.SetStateAction<number>>
) => {
  const result = (await BlockServices.getClientBlocks(
    clientId
  )) as AccountingInfos;
  setAllBlocks(result.blocks);
  setUncategorizedBlocks(result.uncategorizedCount);
};

const getPendencyList = async (
  clientId: string,
  setPendencies: React.Dispatch<
    React.SetStateAction<PendencyModel[] | undefined>
  >
) => setPendencies(await PendencyServices.getPendencyList(clientId));

function organizePendencies(pendencies: PendencyModel[]) {
  const pendencyDates = organizeDates(
    pendencies.map((e) => e.date),
    false
  ) as KeyedDatesPendencyModel;
  for (const _pendency of pendencies) {
    const month = mapMonth[_pendency.date.getMonth().toString()];
    const year = _pendency.date.getFullYear().toString();
    const day = _pendency.date.getDate().toString();
    if (!Object.keys(pendencyDates[year][month]).includes(day)) {
      pendencyDates[year][month][day] = [];
    }
    pendencyDates[year][month][day].push(_pendency);
  }
  const keyedPendencyCount = {} as KeyedPendencyCount;
  for (const year in pendencyDates) {
    if (!Object.keys(keyedPendencyCount).includes(year)) {
      keyedPendencyCount[year] = {};
    }
    for (const month in pendencyDates[year]) {
      var count = 0;
      for (const day in pendencyDates[year][month]) {
        count += pendencyDates[year][month][day].length;
        pendencyDates[year][month][day].sort(
          (a, b) => b.date.valueOf() - a.date.valueOf()
        );
        keyedPendencyCount[year][month] = count;
      }
    }
  }
  return [pendencyDates, keyedPendencyCount] as [
    KeyedDatesPendencyModel,
    KeyedPendencyCount
  ];
}

const verifyClientJob = async (
  clientId: string,
  setIsbusy: React.Dispatch<React.SetStateAction<OperatorAvatar | undefined>>
) => {
  do {
    const result = await ClientServices.getClientBusy(clientId);
    setIsbusy(result);
    await new Promise((resolve) => setTimeout(resolve, SYNC_DELAY));
  } while (true);
};

export const getClientAccountings = async (
  clientId: string,
  setClientAccounts: React.Dispatch<
    React.SetStateAction<ClientAccountModel[] | undefined>
  >
) => setClientAccounts(await AccountServices.getClientAccounts(clientId));
