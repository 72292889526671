import { ClientPlataform } from "Models/LoginModel";
import { ILoginServices } from "Services/Interfaces";
import { callPromiseWrapper } from "Utils/Login";

const ClientPlataformKey = "clientPlataform";
const AllowChangePlataform = "allowChangeClientPlataform";

class LoginServicesImpl implements ILoginServices {
  set allowChangePlataform(value: boolean) {
    localStorage.setItem(AllowChangePlataform, value.toString());
  }

  get allowChangePlataform() {
    return localStorage.getItem(AllowChangePlataform) === "true" ?? false;
  }

  set clientPlataform(clientPlataform: ClientPlataform) {
    localStorage.setItem(ClientPlataformKey, clientPlataform);
    this.setPlataformTitle(clientPlataform);
  }

  get clientPlataform() {
    const _clientPlataform = localStorage.getItem(ClientPlataformKey);
    if (!_clientPlataform) {
      return "not-choosed";
    }
    this.setPlataformTitle(_clientPlataform as ClientPlataform);
    return _clientPlataform as ClientPlataform;
  }

  changePlataform() {
    const _clientPlataform = localStorage.getItem(
      ClientPlataformKey
    ) as ClientPlataform | null;
    if (_clientPlataform) {
      if (_clientPlataform === "accounting") {
        this.clientPlataform = "bussiness";
      } else {
        this.clientPlataform = "accounting";
      }
    }
  }

  async login(username: string, password: string) {
    return await callPromiseWrapper("/api/token/", {
      username: username,
      password: password,
    });
  }

  private setPlataformTitle(clientPlataform: ClientPlataform) {
    if (clientPlataform === "accounting") {
      document.title = "Blintes | Accounting";
    } else {
      document.title = "Blintes | Business";
    }
  }
}

export default LoginServicesImpl;
