import { useRef, useState } from "react";
import { useAuthActions } from "use-eazy-auth";
import { initTextEntryWithErrorState } from "../../Hooks/TextEntryWithError/Model";
import {
  passwordValidation,
  Validations,
} from "../../Hooks/TextEntryWithError/Presenter";
import { LoginServices } from "Services/Interfaces";
import useLoginSelectPlataform from "Hooks/LoginSelectPlataform";
import { ClientPlataform } from "Models/LoginModel";

let accessToken = "";
let refreshToken = "";

export function useLoginPresenter() {
  const { setTokens } = useAuthActions();

  const [disabled, setDisabled] = useState(true);
  const [error, setError] = useState("");
  const [showError, setShowError] = useState(false);
  const [recovery, setRecovery] = useState(false);
  const [hideLoginButtons, showSelectPlataform, startSelectPlataform] =
    useLoginSelectPlataform();

  const usernameRef = useRef(initTextEntryWithErrorState);
  const passwordRef = useRef(initTextEntryWithErrorState);
  const emailRef = useRef(initTextEntryWithErrorState);

  const setUsernameDisabledRef = useRef<
    React.Dispatch<React.SetStateAction<boolean>>
  >(() => {});
  const setPasswordDisabledRef = useRef<
    React.Dispatch<React.SetStateAction<boolean>>
  >(() => {});

  const changeRecovery = () => setRecovery(!recovery);

  const preLogin = async () => {
    let error = passwordValidation(passwordRef);
    if (error) {
      setError(error);
      setShowError(true);
    } else {
      try {
        const response = await callLoginService(
          usernameRef.current.state.text!.current,
          passwordRef.current.state.text!.current
        );
        accessToken = response.access;
        refreshToken = response.refresh;
        if (response.isAccouting && response.isBussiness) {
          setUsernameDisabledRef.current(true);
          setPasswordDisabledRef.current(true);
          LoginServices.allowChangePlataform = true;
          startSelectPlataform();
        } else {
          LoginServices.allowChangePlataform = false;
          if (response.isAccouting) {
            login("accounting");
          } else {
            login("bussiness");
          }
        }
      } catch (error) {
        const loginError = error as { status: number };
        if (loginError) {
          if (loginError.status === 400) {
            setError(Validations.loginBlockedError);
          } else if (loginError.status === 401) {
            setError(Validations.loginError);
          } else {
            setError("Erro de conexão");
          }
          usernameRef.current.setState({ error: "Erro de conexão" });
          passwordRef.current.setState({ error: "Erro de conexão" });
          setShowError(true);
        }
      }
    }
  };

  const login = (clientPlataform: ClientPlataform) => {
    if (clientPlataform === "accounting") {
      LoginServices.clientPlataform = "accounting";
    } else {
      LoginServices.clientPlataform = "bussiness";
    }
    setTokens({
      accessToken: accessToken,
      refreshToken: refreshToken,
    });
  };

  const onChangeInputs = () => {
    setShowError(false);
    setDisabled(
      usernameRef.current.state.text?.current === "" ||
        passwordRef.current.state.text?.current === ""
    );
  };

  return {
    hideLoginButtons,
    showSelectPlataform,
    recovery,
    changeRecovery,
    preLogin,
    login,
    usernameRef,
    passwordRef,
    emailRef,
    onChangeInputs,
    error,
    showError,
    disabled,
    setUsernameDisabledRef,
    setPasswordDisabledRef,
  };
}

const callLoginService = async (username: string, password: string) =>
  await LoginServices.login(username, password);
