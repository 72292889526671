/** @jsxImportSource @emotion/react */
import { ContentCopy } from "@mui/icons-material";
import ButtonMain from "../../../../../Components/ButtonMain/View";
import { usePendencyBlockStyle } from "./Style";

function PendencyBlock({
  onSelect,
  date,
}: {
  onSelect: () => void;
  date: Date;
}) {
  const style = usePendencyBlockStyle();

  return (
    <ButtonMain
      onClick={onSelect}
      color={"secondaryWhite10"}
      ccss={[style.wrapper]}
    >
      <>
        <ContentCopy css={style.iconMirror} fontSize="small" />
        <div css={[style.displayTimer]}>
          {date.toLocaleTimeString("pt-BR").substring(0, 5)}
        </div>
      </>
    </ButtonMain>
  );
}

export default PendencyBlock;
