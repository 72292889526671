import { TextEntryWithErrorState } from "../../../../../../Hooks/TextEntryWithError/Model";
import {
  cpfValidation,
  emailValidation,
  emptyValidation,
  phoneValidation,
} from "../../../../../../Hooks/TextEntryWithError/Presenter";
import { Client } from "../../../../../../Models/ClientModel";
import { OperatorProfile } from "../../../../../../Models/OperatorModel";
import { updatePicture, updateProfile } from "../../../../Presenter";

export const onSaveProfile = (
  nameRef: React.MutableRefObject<TextEntryWithErrorState>,
  cpfRef: React.MutableRefObject<TextEntryWithErrorState>,
  emailRef: React.MutableRefObject<TextEntryWithErrorState>,
  phoneRef: React.MutableRefObject<TextEntryWithErrorState>,
  cancelRef: React.MutableRefObject<boolean>,
  photo: string | undefined,
  setOperator: React.Dispatch<React.SetStateAction<OperatorProfile>>,
  clientState?: [Client, React.Dispatch<React.SetStateAction<Client>>]
) => {
  var error = undefined;
  error = emailValidation(emailRef) || error;
  error = cpfValidation(cpfRef) || error;
  error = emptyValidation(nameRef) || error;
  error = phoneValidation(phoneRef) || error;

  if (!error) {
    const newState = {
      name: nameRef.current.state.text!.current,
      code: cpfRef.current.state.text!.current,
      email: emailRef.current.state.text!.current,
      phone: phoneRef.current.state.text!.current,
    };
    setOperator((operator) => ({
      ...operator!,
      ...newState,
      pictureUrl: photo,
    }));
    if (photo) {
      updatePicture(photo, "client");
    }
    updateProfile(newState);
    if (clientState && clientState[0].type === "owner") {
      clientState[1]((value) => ({
        ...value,
        person: { ...value.person, ...{ ...newState, pictureUrl: photo } },
      }));
    }
  }
  cancelRef.current = error !== undefined;
  return !error;
};
