/** @jsxImportSource @emotion/react */
import { useMainColumnStyle } from "../MainColumn/Style";
import AccountingRow from "../AccountingRow/View";
import BlockGrid from "../BlockGrid/View";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { BlockModel } from "../../../../../Models/BlockModel";
import { ClientAccountModel } from "../../../../../Models/AccountModel";
import DialogViewBlock from "../DialogViewBlock/View";
import { EditShowAccountingsContext } from "./Model";
import { useShowAccountingBlocksPresenter } from "./Presenter";

function ShowAccountingsBlocks({
  accounts,
  blocksByAccount,
  client = false,
  showAccountings,
  notifyRef,
}: {
  accounts: ClientAccountModel[];
  blocksByAccount: { [accountId: string]: BlockModel[] };
  client?: boolean;
  showAccountings: boolean;
  notifyRef?: React.MutableRefObject<
    React.Dispatch<React.SetStateAction<string>>
  >;
}) {
  const accountingStyle = useMainColumnStyle();
  const presenter = useShowAccountingBlocksPresenter(
    accounts,
    showAccountings,
    blocksByAccount,
    notifyRef
  );

  return (
    <ClickAwayListener onClickAway={() => presenter.setSelected(-1)}>
      <div>
        {/* Necessário para o ClickAwayListener funcionar */}
        <EditShowAccountingsContext.Provider
          value={[presenter.setDetailsBlock, presenter.setOpenDetails]}
        >
          {showAccountings ? (
            presenter.filtedAccountings.length > 0 ? (
              presenter.filtedAccountings.map((e, index) => (
                <AccountingRow
                  key={"AccountingRow-" + index.toString()}
                  selected={presenter.isSelected(index)}
                  setSelected={() => presenter.onSelect(index)}
                  blockList={blocksByAccount[e.id]}
                  code={e.code}
                  description={e.description}
                  blockNumber={blocksByAccount[e.id]?.length ?? 0} //(index * index * (index + 7)) % 60
                  activated={e.activated}
                />
              ))
            ) : (
              <div css={accountingStyle.noItemMessage}>
                {accounts.length === 0
                  ? "Nenhuma conta adicionada"
                  : "Nenhuma conta encontrada"}
              </div>
            )
          ) : presenter.blockList.length > 0 ? (
            <BlockGrid list={presenter.blockList} notifyRef={notifyRef} />
          ) : (
            <div css={accountingStyle.noItemMessage}>
              Nenhum bloco categorizado
            </div>
          )}
          <DialogViewBlock
            openDetails={presenter.openDetails}
            setOpenDetails={presenter.setOpenDetails}
            block={presenter.detailsBlock}
            view
            edit={!client}
            devolveBlock={presenter.onDevolve}
          />
        </EditShowAccountingsContext.Provider>
      </div>
    </ClickAwayListener>
  );
}

export default ShowAccountingsBlocks;
