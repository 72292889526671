import { css } from "@emotion/react";
import CacheTheme from "Theme/CacheTheme";
import useCustomTheme from "Theme/CustomTheme";

var styleCached = false;
export function useContabilizationStyle() {
  const name = "ContabilizationCSS";
  const theme = useCustomTheme();
  if (!styleCached) {
    const label = theme.extras.label(name);
    var _style = {
      wrapper: css({
        label: label("wrapper"),
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        minHeight: theme.spacing(100),
        height: "100vh",
      }),
      lockScreen: css({
        label: label("lockScreen"),
        position: "absolute",
        left: 0,
        right: 0,
        bottom: 0,
        top: 0,
        padding: theme.spacing(4),
        backgroundColor: theme.palette.secondary.main + "E6", //80%
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
        minHeight: theme.spacing(100),
      }),
      lockScreenHead: css({
        label: label("lockScreenHead"),
        display: "flex",
        justifyContent: "space-between",
        paddingLeft: theme.spacing(2),
        width: "100%",
      }),
      lockMessage: css({
        label: label("lockMessage"),
        backgroundColor: theme.palette.background.main,
        borderRadius: theme.extras.borderRadius,
        width: theme.spacing(44),
        paddingRight: theme.spacing(4),
        alignItems: "center",
        display: "flex",
        color: "white",
        fontSize: "14px",
      }),
      lockIcon: css({
        label: label("lockIcon"),
        margin: theme.spacing(4),
        fontSize: theme.spacing(8),
      }),
      placeholder: css({
        label: label("placeholder"),
        height: theme.spacing(7),
      }),
      blocksColumn: css({
        label: label("blocksColumn"),
        padding: theme.spacing(2, 4, 4, 4),
        backgroundColor: theme.palette.secondary.main,
        color: "white",
        height: `calc(100% - ${theme.spacing(4)})`,
        width: theme.spacing(56),
        margin: theme.spacing(2),
        borderRadius: theme.extras.borderRadius,
      }),
      blocksHead: css({
        label: label("blocksHead"),
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }),
      accountingInfos: css({
        label: label("accountingInfos"),
        display: "flex",
        alignItems: "center",
        fontSize: "14px",
      }),
      icon: css({
        label: label("icon"),
        marginLeft: theme.spacing(1),
      }),
      separator: css({
        label: label("separator"),
        minHeight: "1px",
        backgroundColor: theme.palette.secondary.black(10),
        margin: theme.spacing(2, 0, 4, 0),
      }),
      title: css({
        label: label("title"),
        marginBottom: theme.spacing(2),
      }),
      subtitle: css({
        label: label("subtitle"),
        marginBottom: theme.spacing(1),
      }),
      noItemMessage: css({
        label: label("noItemMessage"),
        color: theme.palette.secondary.white(40),
        display: "flex",
        alignItems: "center",
        fontSize: "18px",
      }),
      selectDate: css({
        label: label("selectDate"),
        display: "flex",
        marginBottom: theme.spacing(3),
      }),
      order: css({
        label: label("order"),
        width: theme.spacing(20),
      }),
      year: css({
        label: label("year"),
        width: theme.spacing(16),
        marginLeft: theme.spacing(2),
      }),
      historyDetails: css({
        label: label("historyDetails"),
        color: theme.palette.transparency.white(50),
        fontSize: "12px",
      }),
      avatar: css({
        label: label("avatar"),
        marginLeft: theme.spacing(2),
      }),
    };
    CacheTheme(name, theme.palette.type, _style);
    styleCached = true;
  }
  var style = CacheTheme(name, theme.palette.type);
  return style as typeof _style;
}
