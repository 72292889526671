/** @jsxImportSource @emotion/react */
import { useEffect, useState } from "react";
import {
  hour24InMilliSeconds,
  hourAndMinutesDateFormat,
} from "../../../../Utils/Dates";
import { useDateSpanStyle } from "./Style";

function DateSpan({ date, devolve }: { date: Date; devolve: () => void }) {
  const style = useDateSpanStyle();
  const [timer, setTimer] = useState("");

  const activeTimer = async () => {
    var currentDiff = date.valueOf() - new Date().valueOf();
    if (currentDiff > hour24InMilliSeconds) {
      setTimer("1 dia");
    } else {
      setTimer(hourAndMinutesDateFormat(currentDiff / 1000));
    }

    while (currentDiff > 0) {
      await new Promise((resolve) => setTimeout(resolve, 1000));
      currentDiff = date.valueOf() - new Date().valueOf();
      if (currentDiff < hour24InMilliSeconds) {
        setTimer(hourAndMinutesDateFormat(currentDiff / 1000));
      }
    }

    setTimer("00:00");
    devolve();
  };

  useEffect(() => {
    const difDate = date.valueOf() - new Date().valueOf();
    const daysNumberMili = difDate;
    const daysNumber = daysNumberMili / hour24InMilliSeconds;
    if (Math.ceil(daysNumber) > 2) {
      setTimer(daysNumber.toFixed(0) + " dias");
    } else {
      activeTimer();
    }
    // eslint-disable-next-line
  }, [date]);

  return <span css={style.wrapper}>{timer}</span>;
}

export default DateSpan;
