/** @jsxImportSource @emotion/react */
import { useCallback, useEffect, useState } from "react";
import {
  min15InMilliSeconds,
  minuteAndSecondFormat,
} from "../../../../../../Utils/Dates";

function BlockTimer({
  date,
  setTimerRunning,
}: {
  date: Date;
  setTimerRunning: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const [timer, setTimer] = useState(-1);

  const activeTimer = useCallback(async () => {
    var currentDiff = new Date().valueOf() - date.valueOf();
    if (currentDiff < min15InMilliSeconds) {
      setTimerRunning(true);
    }

    while (currentDiff < min15InMilliSeconds) {
      setTimer(currentDiff);
      await new Promise((resolve) => setTimeout(resolve, 1000));
      currentDiff = new Date().valueOf() - date.valueOf();
    }

    setTimer(-1);
    setTimerRunning(false);
  }, [setTimerRunning, date]);

  useEffect(() => {
    activeTimer();
  }, [activeTimer]);

  return (
    <>
      {timer > 0
        ? minuteAndSecondFormat((min15InMilliSeconds - timer) / 1000)
        : date.toLocaleTimeString("pt-BR").substring(0, 5)}
    </>
  );
}

export default BlockTimer;
