/** @jsxImportSource @emotion/react */
import { ContentCopy } from "@mui/icons-material";
import { AvatarGroup } from "@mui/material";
import { OperatorAvatar } from "../../../../../../Models/OperatorModel";
import { useFrameDrawerStyle } from "../../../../../Frame/Components/FrameDrawer/Style";
import TurquesaAvatar from "../../../../../TurquesaAvatar/View";
import { useCategorizationInfosStyle } from "./Style";

function CategorizationInfos({
  operators,
  uncategorizedCount,
}: {
  operators: OperatorAvatar[];
  uncategorizedCount: number;
}) {
  const avatarSize = 4;
  const style = useCategorizationInfosStyle();
  const frameDrawerStyle = useFrameDrawerStyle();

  return (
    <>
      <div css={style.row}>
        <AvatarGroup max={7} css={style.groupAvatar(avatarSize)}>
          {operators.length > 0 ? (
            operators.map((op, index) => (
              <TurquesaAvatar
                key={"Avatars-" + index.toString()}
                src={op.pictureUrl}
                nameToInitials={op.name}
                tooltip={index === 0 ? "Você" : op.name}
                size={avatarSize}
              />
            ))
          ) : (
            <TurquesaAvatar
              key={"Avatars"}
              nameToInitials={""}
              tooltip={"Você"}
              size={avatarSize}
            />
          )}
        </AvatarGroup>
        <div css={style.infoNumber}>
          {uncategorizedCount}
          <ContentCopy css={style.icon} />
        </div>
      </div>
      <div css={[frameDrawerStyle.title, style.title]}>Categorizar blocos</div>
    </>
  );
}

export default CategorizationInfos;
