/** @jsxImportSource @emotion/react */
import { Switch } from "@mui/material";
import { useState } from "react";
import { useFrameDrawerStyle } from "../../../../../../Components/Frame/Components/FrameDrawer/Style";
import TurquesaAvatar from "../../../../../../Components/TurquesaAvatar/View";
import { SharedOperatorAvatar } from "../../../../../../Models/OperatorModel";
import { changeSharedOperator } from "../Presenter";
import { useDrawerSharedStyle } from "../Style";

function DrawerSharedItem({
  clientId,
  operator,
}: {
  clientId: string;
  operator: SharedOperatorAvatar;
}) {
  const frameDrawerStyle = useFrameDrawerStyle();
  const drawerSharedStyle = useDrawerSharedStyle();

  const [state, setState] = useState(operator.isShared);
  return (
    <div css={[drawerSharedStyle.item]}>
      <Switch
        value={state}
        defaultChecked={state}
        onChange={async (_) => {
          await changeSharedOperator(clientId, operator.id);
          setState(!state);
        }}
        css={drawerSharedStyle.switch}
        color="success"
      />
      <TurquesaAvatar
        size={7}
        nameToInitials={operator.name}
        src={operator.pictureUrl}
        ccss={drawerSharedStyle.avatar}
      />
      <span css={frameDrawerStyle.name}>{operator.name}</span>
      <div
        css={[
          drawerSharedStyle.status,
          state ? drawerSharedStyle.activated : drawerSharedStyle.deactivated,
        ]}
      />
    </div>
  );
}

export default DrawerSharedItem;
