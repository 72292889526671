/** @jsxImportSource @emotion/react */
import { Close } from "@mui/icons-material";
import { Button } from "@mui/material";
import DisplayList from "../../../../../Components/DisplayList/View";
import { useFrameDrawerStyle } from "../../../../../Components/Frame/Components/FrameDrawer/Style";
import ButtonSquared from "../../../../../Components/ButtonSquared/View";
import TurquesaAvatar from "../../../../../Components/TurquesaAvatar/View";
import { useDrawerTransferStyle } from "./Style";
import ButtonMain from "../../../../../Components/ButtonMain/View";
import { useDrawerTransferPresenter } from "./Presenter";
import LoadingTransition from "../../../../../Components/LoadingTransition/View";
import { normalizeString } from "Utils/String";

function DrawerTransfer({
  notifyRef,
}: {
  notifyRef: React.MutableRefObject<
    React.Dispatch<React.SetStateAction<string>>
  >;
}) {
  const style = useDrawerTransferStyle();
  const frameDrawerStyle = useFrameDrawerStyle();
  const presenter = useDrawerTransferPresenter(style.fastTime, notifyRef);

  return (
    <LoadingTransition window>
      {(_changeLoading) => {
        presenter.changeLoading.current = _changeLoading;
        return (
          <>
            <div css={frameDrawerStyle.title}>
              Transferir cliente para outro operador
            </div>
            <div css={style.description}>
              Transferir o controle de um cliente para outro operador significa
              que você só poderá ter acesso a este cliente se ele for
              compartilhado ou se for transferido para você.
            </div>
            <DisplayList
              heightOfFixContent={presenter.expanded ? "422px" : "224px"}
              paddingTopHeight={3}
              resize
              ccss={
                presenter.transferConfirm ? style.noInteractions : undefined
              }
              itens={(
                presenter.operators?.filter((e) =>
                  normalizeString(e.name).includes(
                    normalizeString(presenter.searchText)
                  )
                ) ?? []
              ).map((e, index) => (
                <div css={style.item} key={"DrawerTransfer-" + e.id}>
                  <TurquesaAvatar
                    size={7}
                    nameToInitials={e.name}
                    src={e.pictureUrl}
                  />
                  <Button
                    onClick={() => presenter.onSelect(index)}
                    css={[
                      style.innerItem,
                      presenter.transferConfirm
                        ? style.transferTransition
                        : undefined,
                      presenter.isSelected(index) && presenter.expanded
                        ? style.trasnferSelected
                        : presenter.isSelected(index)
                        ? style.selected
                        : undefined,
                    ]}
                  >
                    <span css={[frameDrawerStyle.name, style.name]}>
                      {e.name}
                    </span>
                  </Button>
                </div>
              ))}
            />
            <div
              css={[
                style.wrapper,
                presenter.noSelection ? style.noColor : undefined,
                presenter.expanded ? style.expanded : undefined,
              ]}
            >
              {/* trans, expan, showc
              false, false, false -> show
              true, false, false -> hidden
              true, true, true -> show
              true, false, true -> hiden
              false, false, false -> show */}
              <div
                css={[
                  style.fadeContent,
                  (presenter.transferConfirm &&
                    !presenter.expanded &&
                    !presenter.showContent) ||
                  (presenter.transferConfirm &&
                    !presenter.expanded &&
                    presenter.showContent)
                    ? style.hidden
                    : undefined,
                ]}
              >
                {presenter.showContent || presenter.expanded ? (
                  <>
                    <div css={style.transferRow}>
                      <div css={style.placeholder} />
                      <div css={style.transferMessage}>
                        Transferir cliente para
                        <br />
                        <span css={style.nameLine}>
                          {presenter.operatorSelectedFullname}?
                        </span>
                      </div>
                      <ButtonSquared
                        color="primaryWhite10"
                        onClick={presenter.onCancelConfirm}
                      >
                        <Close />
                      </ButtonSquared>
                    </div>
                    <div css={style.transferAvatar}>
                      <TurquesaAvatar size={7} nameToInitials="NC" />
                    </div>
                    <ButtonMain
                      onClick={presenter.onConfirm}
                      ccss={style.transferButton}
                      color="success"
                    >
                      Sim, transferir cliente
                    </ButtonMain>
                  </>
                ) : (
                  <Button
                    disabled={!presenter.activated}
                    fullWidth
                    css={[
                      style.button,
                      presenter.animationTransition
                        ? style.animationTransition
                        : undefined,
                    ]}
                    onClick={presenter.onExpand}
                  >
                    Transferir clientes
                  </Button>
                )}
              </div>
            </div>
          </>
        );
      }}
    </LoadingTransition>
  );
}

export default DrawerTransfer;
