import { css } from "@emotion/react";
import CacheTheme from "Theme/CacheTheme";
import useCustomTheme from "Theme/CustomTheme";

var styleCached = false;
export function useClientDialogStyle() {
  const name = "ClientDialogCSS";
  const theme = useCustomTheme();
  if (!styleCached) {
    const label = theme.extras.label(name);
    var _style = {
      row: css({
        label: label("row"),
        display: "flex",
        marginTop: theme.spacing(4),
        width: theme.spacing(73),
      }),
      column: css({
        label: label("column"),
        display: "flex",
        flexDirection: "column",
        lineHeight: "20px",
      }),
      logo: css({
        label: label("logo"),
        marginRight: theme.spacing(4),
      }),
      title: css({
        label: label("title"),
        fontSize: "14px",
        color: theme.palette.secondary.white(30),
        fontWeight: "bold",
        marginBottom: theme.spacing(2),
      }),
      description: css({
        label: label("description"),
        color: "white",
        marginBottom: theme.spacing(3),
      }),
    };
    CacheTheme(name, theme.palette.type, _style);
    styleCached = true;
  }
  var style = CacheTheme(name, theme.palette.type);
  return style as typeof _style;
}
