/** @jsxImportSource @emotion/react */

import AliceCarousel from "react-alice-carousel";
import { useRegisterCarouselStyle } from "./Style";
import StepCarousel from "../StepCarousel/View";
import { useRef } from "react";
import SiteTextEntry from "Components/SiteTextEntry/View";
import CardSignature from "../CardSignature/View";

function RegisterCarousel() {
  const style = useRegisterCarouselStyle();
  const carouselRef = useRef<AliceCarousel>(null);

  const renderDotsItem = ({ isActive }: { isActive: boolean }) =>
    isActive ? (
      <div css={[style.dot, style.active]} />
    ) : (
      <div css={[style.dot, style.notActive]} />
    );

  const items = [
    <StepCarousel
      step={1}
      title={"Dados do perfil"}
      onContinue={() => carouselRef.current?.slideNext()}
    >
      <SiteTextEntry
        ccss={[
          style.textEntry,
          style.textEntryDefaultSize,
          style.marginTopStep1,
        ]}
        placeholder="Nome"
      />
      <SiteTextEntry
        ccss={[style.textEntry, style.textEntryDefaultSize]}
        placeholder="Sobrenome"
      />
      <SiteTextEntry
        ccss={[style.textEntry, style.textEntryDefaultSize]}
        placeholder="CPF"
      />
      <SiteTextEntry
        ccss={[
          style.textEntry,
          style.textEntryDefaultSize,
          style.marginBottomStep1,
        ]}
        placeholder="Telefone"
      />
    </StepCarousel>,
    <StepCarousel
      step={2}
      title={"Dados da empresa"}
      onContinue={() => carouselRef.current?.slideNext()}
      onBack={() => carouselRef.current?.slidePrev()}
    >
      <div css={[style.row, style.marginTopStep1, style.marginBottomStep1]}>
        <div css={style.column}>
          <SiteTextEntry
            ccss={[style.textEntry, style.textEntryDefaultSize]}
            placeholder="Nome da empresa"
          />
          <SiteTextEntry
            ccss={[style.textEntry, style.textEntryDefaultSize]}
            placeholder="CNPJ"
          />
          <SiteTextEntry
            ccss={[style.textEntry, style.textEntryDefaultSize]}
            placeholder="E-mail para contato"
          />
          <SiteTextEntry
            ccss={style.textEntryDefaultSize}
            placeholder="Telefone"
          />
        </div>
        <div css={style.separator} />
        <div css={style.column}>
          <SiteTextEntry
            ccss={[style.textEntry, style.textEntryBigColumnDefaultSize]}
            placeholder="CEP"
          />
          <div>
            <SiteTextEntry
              ccss={[
                style.textEntry,
                style.TextEntryBigColumnBigSize,
                style.marginRightStep,
              ]}
              placeholder="Rua"
            />
            <SiteTextEntry
              ccss={[style.textEntry, style.TextEntryBigColumnSmallSize]}
              placeholder="Número"
            />
          </div>
          <SiteTextEntry
            ccss={[style.textEntry, style.textEntryBigColumnDefaultSize]}
            placeholder="Bairro"
          />
          <div>
            <SiteTextEntry
              ccss={[style.TextEntryBigColumnHalfSize, style.marginRightStep]}
              placeholder="Cidade"
            />
            <SiteTextEntry
              ccss={style.TextEntryBigColumnHalfSize}
              placeholder="Estado"
            />
          </div>
        </div>
      </div>
    </StepCarousel>,
    <StepCarousel
      step={3}
      title={"Informações de pagamento"}
      onContinue={() => carouselRef.current?.slideNext()}
      onBack={() => carouselRef.current?.slidePrev()}
    >
      <CardSignature />
      <SiteTextEntry
        ccss={[style.textEntry, style.textEntryDefaultSize]}
        placeholder="Número do cartão"
      />
      <div css={style.row}>
        <SiteTextEntry
          ccss={[
            style.textEntry,
            style.TextEntryDefaultHalfSize,
            style.marginRightStep,
          ]}
          placeholder="Validade"
        />
        <SiteTextEntry
          ccss={[style.textEntry, style.TextEntryDefaultHalfSize]}
          placeholder="CVV"
        />
      </div>
      <SiteTextEntry
        ccss={[style.textEntry, style.textEntryDefaultSize]}
        placeholder="Nome como escrito no cartão"
      />
      <div css={style.info}>
        Clicando em <strong>continuar</strong> você poderá revisar as
        informações antes de finalizar.
      </div>
    </StepCarousel>,
  ];

  return (
    <div css={style.carousel}>
      <AliceCarousel
        ref={carouselRef}
        disableButtonsControls
        renderDotsItem={renderDotsItem}
        items={items}
      />
    </div>
  );
}

export default RegisterCarousel;
