import { KeyedDates } from "./ShowAccountingsBlocks/Model";

function useCheckFrameDate(
  datesSelect: KeyedDates,
  yearSelected: string,
  monthSelected: string,
  setMonthSelected: React.Dispatch<React.SetStateAction<string>>
) {
  if (Object.keys(datesSelect).includes(yearSelected)) {
    const yearMonths = datesSelect[yearSelected];
    if (!yearMonths.includes(monthSelected)) {
      setMonthSelected(yearMonths[0]);
    }
  }
}

export default useCheckFrameDate;
