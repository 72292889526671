/** @jsxImportSource @emotion/react */
import { ArrowForward } from "@mui/icons-material";
import { Button } from "@mui/material";
import OpacityTransition from "../../../../../Components/OpacityTransition/View";
import { AccountModel } from "../../../../../Models/AccountModel";
import { useDrawerAccountingItemStyle } from "../../../../Accounting/Accounting/Components/DrawerAccountingPlan/DrawerAccountingItem/Style";
import { useContabilizationBlockItemStyle } from "./Style";

function ContabilizationBlockItem({
  selected,
  account,
  onClick,
  number,
}: {
  selected: boolean;
  account: AccountModel;
  onClick: () => void;
  number: number;
}) {
  const style = useContabilizationBlockItemStyle();
  const drawerAccountingItemStyle = useDrawerAccountingItemStyle();

  return (
    <Button
      onClick={onClick}
      css={[
        drawerAccountingItemStyle.item,
        style.item,
        selected ? style.itemSelected : style.itemNotSelected,
      ]}
    >
      <div css={drawerAccountingItemStyle.column}>
        <span css={[style.startContent, drawerAccountingItemStyle.number]}>
          {account.code}
        </span>
        <span css={[style.startContent, drawerAccountingItemStyle.name]}>
          {account.description}
        </span>
      </div>

      <div
        css={[
          style.info,
          selected ? style.infoSelected : style.infoNotSelected,
          drawerAccountingItemStyle.icon,
        ]}
      >
        <OpacityTransition
          value={!selected}
          childFalse={(ccss) => <ArrowForward css={[style.iconSize, ccss]} />}
          childTrue={(ccss) => <span css={ccss}>{number}</span>}
        />
      </div>
    </Button>
  );
}

export default ContabilizationBlockItem;
