/** @jsxImportSource @emotion/react */
import { SerializedStyles } from "@emotion/react";
import LogoBlintesAccounting from "../../Assets/blintes_accounting.svg";
import LogoBlintesBusiness from "../../Assets/blintes_business.svg";
import { getCurrentClientPlataform } from "./Presenter";

function BlintesLogo({
  ccss,
}: {
  ccss?: SerializedStyles | (SerializedStyles | undefined)[];
}) {
  const clientPlataform = getCurrentClientPlataform();
  return (
    <img
      css={ccss}
      src={
        clientPlataform === "accounting"
          ? LogoBlintesAccounting
          : LogoBlintesBusiness
      }
      alt="blintes_logomarca"
    />
  );
}

export default BlintesLogo;
