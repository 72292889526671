import { css } from "@emotion/react";
import CacheTheme from "Theme/CacheTheme";
import useCustomTheme from "Theme/CustomTheme";

var styleCached = false;
export function useRegisterCarouselStyle() {
  const name = "RegisterCarouselCSS";
  const theme = useCustomTheme();
  if (!styleCached) {
    const label = theme.extras.label(name);
    var _style = {
      carousel: css({
        label: label("carousel"),
        width: theme.spacing(150),
        padding: theme.spacing(2, 0),
        "> div > ul > li": {
          pointerEvents: "none",
        },
      }),
      dot: css({
        label: label("dot"),
        transition: "background-color 300ms",
        width: theme.spacing(1.5),
        height: theme.spacing(1.5),
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        borderRadius: theme.spacing(0.75),
      }),
      active: css({
        label: label("active"),
        backgroundColor: theme.palette.primary.main,
      }),
      notActive: css({
        label: label("notActive"),
        backgroundColor: theme.palette.primary.white(80),
        cursor: "pointer",
        ":hover": {
          backgroundColor: theme.palette.primary.white(50),
        },
      }),
      column: css({
        label: label("column"),
        display: "flex",
        flexDirection: "column",
      }),
      row: css({
        label: label("row"),
        display: "flex",
        flexDirection: "row",
      }),
      separator: css({
        label: label("separator"),
        height: theme.spacing(37),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        width: "2px",
        backgroundColor: theme.palette.transparency.black(5),
        margin: theme.spacing(0, 8),
      }),
      marginTopStep1: css({
        label: label("marginTopStep1"),
        marginTop: theme.spacing(18),
      }),
      marginBottomStep1: css({
        label: label("marginBottomStep1"),
        marginBottom: theme.spacing(16),
      }),
      marginRightStep: css({
        label: label("marginRightStep"),
        marginRight: theme.spacing(3),
      }),
      textEntry: css({
        label: label("textEntry"),
        marginBottom: theme.spacing(3),
      }),
      textEntryDefaultSize: css({
        label: label("textEntryDefaultSize"),
        width: theme.spacing(47),
      }),
      textEntryBigColumnDefaultSize: css({
        label: label("textEntryBigColumnDefaultSize"),
        width: theme.spacing(61),
      }),
      TextEntryBigColumnBigSize: css({
        label: label("TextEntryBigColumnBigSize"),
        width: theme.spacing(42),
      }),
      TextEntryBigColumnSmallSize: css({
        label: label("TextEntryBigColumnSmallSize"),
        width: theme.spacing(16),
      }),
      TextEntryBigColumnHalfSize: css({
        label: label("TextEntryBigColumnHalfSize"),
        width: theme.spacing(29),
      }),
      TextEntryDefaultHalfSize: css({
        label: label("TextEntryDefaultHalfSize"),
        width: theme.spacing(22.5),
      }),
      info: css({
        label: label("info"),
        backgroundColor: "#006aff1a",
        borderRadius: "12px",
        padding: theme.spacing(2, 8),
        fontSize: "12px",
        marginBottom: theme.spacing(3),
        marginTop: theme.spacing(8),
        lineHeight: theme.spacing(2),
      }),
    };
    CacheTheme(name, theme.palette.type, _style);
    styleCached = true;
  }
  var style = CacheTheme(name, theme.palette.type);
  return style as typeof _style;
}
