import { FrameSelectItem } from "../Components/FrameSelect/Model";
import { AccountModel, ClientAccountModel } from "../Models/AccountModel";
import { BlockModel } from "../Models/BlockModel";
import {
  OperatorAccounting,
  OperatorAvatar,
  OperatorClient,
  OperatorProfile,
  ProfileArgs,
  SharedOperatorAvatar,
} from "../Models/OperatorModel";
import {
  CreatePendencyModel as CreatePendencyArgs,
  PendencyModel,
} from "../Models/PendencyModel";
import { DocumentModel, DocumentsBlockArgs } from "../Models/DocumentModel";
import { SelectedDocumentModel } from "../Pages/Client/BlockCreate/Model";
import AccountServicesImpl from "./Implementations/AccountServices";
import BlockServicesImpl from "./Implementations/BlockServices";
import OperatorServicesImpl from "./Implementations/OperatorServices";
import PendencyServicesImpl from "./Implementations/PendencyServices";
import DocumentServicesImpl from "./Implementations/DocumentServices";
import ClientServicesImpl from "./Implementations/ClientServices";
import AccountingServicesImpl from "./Implementations/AccountingServices";
import LoginServicesImpl from "./Implementations/LoginServices";
import ContabilizationServicesImpl from "./Implementations/ContabilizationServices";
import { Client, HomeClient } from "../Models/ClientModel";
import {
  AccountingContact,
  AccountingInvited,
  Person,
} from "../Models/AccountingModel";
import { KeyedDatesAccountModel } from "../Hooks/ShowAccountingsBlocks/Model";
import { HomeInfos } from "../Pages/Client/Home/Model";
import { AccountingInfos } from "../Pages/Accounting/Accounting/Model";
import { ClientPlataform } from "Models/LoginModel";

export interface ILoginServices {
  clientPlataform: ClientPlataform;
  allowChangePlataform: boolean;
  login: (
    email: string,
    password: string
  ) => Promise<{
    refresh: string;
    access: string;
    isAccouting: boolean;
    isBussiness: boolean;
  }>;
  changePlataform: () => void;
}

export interface IAccountingServices {
  postAccountingLogo: (base64url: string) => Promise<void>;
  postAccountingInfo: (accounting: Person) => Promise<void>;
  getAccountingInfo: () => Promise<Person>;
  getAccountingContact: () => Promise<AccountingContact>;
  listInvited: () => Promise<AccountingInvited[]>;
  addInvited: (email: string) => Promise<void>;
  removeInvited: (inviteId: string) => Promise<void>;
}

export interface IAccountServices {
  initializeAllAccounts: () => Promise<AccountModel[]>;
  changeAccountState: (changeArgs: {
    clientId: string;
    accountId: string;
  }) => Promise<void>;
  setAccountInBlockContext: (
    organizedAccountList: KeyedDatesAccountModel
  ) => void;
  getAccountInBlockContext: () => {
    organizedAccountList: KeyedDatesAccountModel;
    fullAccountList: AccountModel[];
  };
  getClientAccounts: (clientId?: string) => Promise<ClientAccountModel[]>;
}

export interface IBlockServices {
  getBlockToSolve: (clientId: string) => Promise<BlockModel | null>;
  getClientBlocks: (clientId?: string) => Promise<HomeInfos | AccountingInfos>;
  getSentBlocks: (operatorId: string) => Promise<BlockModel[]>;
  setViewdBlocks: (blockId: string) => Promise<void>;
  createBlock: (documents: SelectedDocumentModel[]) => Promise<void>;
  devolveBlock: (blockId: string) => Promise<void>;
  saveBlock: (block: BlockModel) => Promise<void>;
  sentUndo: (blockId: string) => Promise<void>;
}

export interface IClientServices {
  getClient: () => Promise<Client>;
  postClient: (person: Client) => Promise<void>;
  postClientLogo: (base64url: string) => Promise<void>;
  getClients: () => Promise<HomeClient[]>;
  getClientById: (clientId: string) => Promise<Client>;
  getClientBusy: (clientId: string) => Promise<OperatorAvatar | undefined>;
}

export interface IContabilizationServices {
  contabilizationLinkState: {
    yearSelected: string;
    monthSelected: string;
    accountSelected: string;
  };
  contabilizeSync: (clientId: string) => Promise<void>;
  contabilizeBlock: (blockId: string, link: string) => Promise<void>;
  getContabilizationLink: (clientId: string) => Promise<string>;
  initContabilizationLink: (link: string) => Promise<{
    categorizedBlocks: BlockModel[];
    clientAccounts: ClientAccountModel[];
    name: string;
    id: string;
    pictureUrl?: string;
  }>;
}

export interface IOperatorServices {
  changeAdmin: (operatorId: string) => Promise<void>;
  changePassword: (
    currentPassword: string,
    newPassword: string
  ) => Promise<boolean>;
  operatorChangeBlock: (operatorId: string) => Promise<void>;
  removeOperatorAccounting: (operatorId: string) => Promise<void>;
  removeOperatorClient: (operatorId: string) => Promise<void>;
  operatorsSent: () => Promise<OperatorAvatar[]>;
  pendencySync: () => Promise<{
    pendencyNumber: number;
    operators: OperatorAvatar[];
  }>;
  pendencyUnsync: () => Promise<void>;
  blockSync: (clientId: string) => Promise<{
    uncategorizedCount: number;
    operators: OperatorAvatar[];
  }>;
  blockUnsync: () => Promise<void>;
  getOperatorProfile: () => Promise<OperatorProfile>;
  getOperatorClientList: () => Promise<OperatorClient[]>;
  getOperatorAccountingList: () => Promise<OperatorAccounting[]>;
  changeSharedTo: (operatorId: string) => Promise<void>;
  updateProfile: (profileArgs: ProfileArgs) => Promise<void>;
  updatePicture: (
    base64url: string,
    type: "accounting" | "client"
  ) => Promise<void>;
  getSharedOperators: (clientId: string) => Promise<SharedOperatorAvatar[]>;
  changeSharedOperator: (clientId: string, operatorId: string) => Promise<void>;
  getTransferOperators: (clientId: string) => Promise<OperatorAvatar[]>;
  changeTransferOperator: (
    clientId: string,
    operatorId: string
  ) => Promise<void>;
}

export interface IPendencyServices {
  createPendency: (createPendencyArgs: CreatePendencyArgs) => Promise<void>;
  getPendencyList: (clientId: string) => Promise<PendencyModel[]>;
  getToSolve: (next: boolean) => Promise<PendencyModel>;
  solvePendency: (
    documents: SelectedDocumentModel[],
    removedDocuments: string[]
  ) => Promise<void>;
}

export interface IDocumentServices {
  getBlockDocuments: (blockId: string) => Promise<DocumentsBlockArgs>;
  getDevolveds: () => Promise<DocumentModel[]>;
  getLibraries: () => Promise<FrameSelectItem[]>;
  getDocuments: (operatorId: string) => Promise<DocumentModel[]>;
  removeDocument: (documentId: string) => Promise<void>;
  devolvedRestore: (documentId: string) => Promise<void>;
}

export const LoginServices: ILoginServices = new LoginServicesImpl();
export const AccountServices: IAccountServices = new AccountServicesImpl();
export const AccountingServices: IAccountingServices =
  new AccountingServicesImpl();
export const BlockServices: IBlockServices = new BlockServicesImpl();
export const ClientServices: IClientServices = new ClientServicesImpl();
export const OperatorServices: IOperatorServices = new OperatorServicesImpl();
export const PendencyServices: IPendencyServices = new PendencyServicesImpl();
export const DocumentServices: IDocumentServices = new DocumentServicesImpl();
export const ContabilizationServices: IContabilizationServices =
  new ContabilizationServicesImpl();
