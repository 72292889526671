import { Person } from "Models/AccountingModel";
import { TextEntryWithErrorState } from "../../../../../../Hooks/TextEntryWithError/Model";
import {
  cepValidation,
  cnpjValidation,
  emptyValidation,
  phoneValidation,
} from "../../../../../../Hooks/TextEntryWithError/Presenter";
import { Client } from "../../../../../../Models/ClientModel";
import {
  postAccountingInfo,
  postAccountingLogo,
  postClient,
  postClientLogo,
} from "../../../../Presenter";

export const onSaveOffice = (
  type: "juridic" | "individual" | "accounting",
  fantasyNameRef: React.MutableRefObject<TextEntryWithErrorState>,
  emailRef: React.MutableRefObject<TextEntryWithErrorState>,
  cepRef: React.MutableRefObject<TextEntryWithErrorState>,
  numberRef: React.MutableRefObject<TextEntryWithErrorState>,
  cityRef: React.MutableRefObject<TextEntryWithErrorState>,
  cnpjRef: React.MutableRefObject<TextEntryWithErrorState>,
  phoneRef: React.MutableRefObject<TextEntryWithErrorState>,
  streetRef: React.MutableRefObject<TextEntryWithErrorState>,
  neighboorRef: React.MutableRefObject<TextEntryWithErrorState>,
  stateRef: React.MutableRefObject<TextEntryWithErrorState>,
  cancelRef: React.MutableRefObject<boolean>,
  photoRef: React.MutableRefObject<string | undefined>,
  setClient?: React.Dispatch<React.SetStateAction<Client>>,
  setAccounting?: React.Dispatch<React.SetStateAction<Person>>
) => {
  var error = undefined;
  error = cepValidation(cepRef) || error;
  error = emptyValidation(numberRef) || error;
  error = emptyValidation(cityRef) || error;
  error = emptyValidation(streetRef) || error;
  error = emptyValidation(neighboorRef) || error;
  error = emptyValidation(stateRef) || error;
  if (type === "juridic") {
    error = cnpjValidation(cnpjRef) || error;
    error = emptyValidation(fantasyNameRef) || error;
    error = emptyValidation(emailRef) || error;
    error = phoneValidation(phoneRef) || error;
  }

  if (!error) {
    // A alteração do "individualPerson" acontece quando o operador que é super-admin troca suas informações.
    // Isto prq no caso de empresa individual, as informações da empresa são recolhidas do perfil do operador.
    const person = {
      name: fantasyNameRef.current!.state.text!.current,
      code: cnpjRef.current!.state.text!.current,
      email: emailRef.current!.state.text!.current,
      phone: phoneRef.current!.state.text!.current,
      pictureUrl: photoRef.current,
      address: {
        cep: cepRef.current!.state.text!.current,
        city: cityRef.current!.state.text!.current,
        neighboor: neighboorRef.current!.state.text!.current,
        number: numberRef.current!.state.text!.current,
        state: stateRef.current!.state.text!.current,
        street: streetRef.current!.state.text!.current,
      },
    } as Person;
    if (setClient) {
      setClient((client) => {
        const newClient = {
          ...client,
          person: {
            ...(type === "juridic" ? person : client.person),
            address: person.address,
          },
        };
        postClient(newClient);
        return newClient;
      });
      if (photoRef.current) {
        postClientLogo(photoRef.current);
      }
    }
    if (setAccounting) {
      postAccountingInfo(person);
      setAccounting(person);
      if (photoRef.current) {
        postAccountingLogo(photoRef.current);
      }
    }
  }
  cancelRef.current = false;
  return !error;
};
