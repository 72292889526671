import { Person } from "./AccountingModel";

export type OperatorAvatar = {
  id: string;
  pictureUrl?: string;
  name: string;
};

export type SharedOperatorAvatar = OperatorAvatar & {
  isShared: boolean;
};

export type Operator = Person & {
  type: number; // 0 - superAdmin, 1 - admin, 2 - operator
  id: string;
};

export type OperatorProfile = Operator & {
  hasPaymentAccess: boolean;
};

export type OperatorAccounting = Operator & {
  blocked: boolean;
};

export type OperatorClient = OperatorAccounting & {
  sharedTo: boolean;
};

export type ProfileArgs = {
  name: string;
  code: string;
  email: string;
  phone: string;
};

export const emptyProfileOperator = {
  id: "",
  pictureUrl: "",
  name: "",
  code: "",
  email: "",
  phone: "",
  type: 0,
  hasPaymentAccess: false,
};
