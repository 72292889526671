import { css } from "@emotion/react";
import CacheTheme from "Theme/CacheTheme";
import useCustomTheme from "Theme/CustomTheme";

var styleCached = false;
export function useDrawerAccountingItemStyle() {
  const name = "DrawerAccountingItemCSS";
  const theme = useCustomTheme();
  if (!styleCached) {
    const label = theme.extras.label(name);
    var _style = {
      item: css({
        label: label("item"),
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        borderRadius: theme.extras.borderRadius,
        marginBottom: theme.spacing(1),
        backgroundColor: theme.palette.secondary.white(10),
        padding: theme.spacing(1.5, 1.5, 1.5, 2),
      }),
      column: css({
        label: label("column"),
        display: "flex",
        flexDirection: "column",
      }),
      number: css({
        label: label("number"),
        fontSize: "14px",
        color: theme.palette.transparency.white(40),
        lineHeight: "18px",
      }),
      name: css({
        label: label("name"),
        color: "white",
        fontWeight: "bold",
        lineHeight: "18px",
        textAlign: "justify",
      }),
      icon: css({
        label: label("icon"),
        marginLeft: theme.spacing(3),
      }),
    };
    CacheTheme(name, theme.palette.type, _style);
    styleCached = true;
  }
  var style = CacheTheme(name, theme.palette.type);
  return style as typeof _style;
}
