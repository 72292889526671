/** @jsxImportSource @emotion/react */

import { SerializedStyles } from "@emotion/react";
import { CameraAlt } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { fromFileGetBase64Image } from "../../../../../../Utils/Images";
import TurquesaAvatar from "../../../../../TurquesaAvatar/View";
import RepositionDialog from "./RepositionDialog/View";
import { useProfilePhotoButtonStyle } from "./Style";

function ProfilePhotoButton({
  photoUrl,
  name,
  edit,
  photoRef,
  cancelRef,
  setExpandedAvatarRef,
  profileEditPanel = false,
}: {
  ccss?: SerializedStyles | (SerializedStyles | undefined)[];
  photoUrl?: string;
  name: string;
  edit: boolean;
  photoRef: React.MutableRefObject<string | undefined>;
  cancelRef: React.MutableRefObject<boolean>;
  setExpandedAvatarRef?: React.MutableRefObject<
    React.Dispatch<React.SetStateAction<boolean>>
  >;
  profileEditPanel?: boolean;
}) {
  const style = useProfilePhotoButtonStyle();

  const [avatarExpanded, setAvatarExpanded] = useState(
    setExpandedAvatarRef === undefined
  );
  const [photo, setPhoto] = useState(photoUrl);
  const [showAvatarEditButton, setShowAvatarEditButton] = useState(false);

  const inputImageRef = useRef<HTMLInputElement>(null);
  const openRepositionDialogRef = useRef<() => void>(() => null);
  const setPhotoRef = useRef<React.Dispatch<React.SetStateAction<string>>>(
    () => null
  );

  photoRef.current = photo;
  if (setExpandedAvatarRef) {
    setExpandedAvatarRef.current = setAvatarExpanded;
  }

  useEffect(() => {
    if (edit) {
      setTimeout(() => {
        setShowAvatarEditButton(true);
      }, style.time);
    } else {
      setShowAvatarEditButton(false);
      if (cancelRef.current) {
        setPhoto(photoUrl);
      }
    }
    // eslint-disable-next-line
  }, [edit]);

  return (
    <div
      css={[
        style.avatarDiv,
        profileEditPanel ? style.profileEditPanelDiv : undefined,
      ]}
    >
      <IconButton
        //ids importantes para evitar que o ClickAwayLister do ProfileExpansivePanel detecte o avatar
        id="ProfileAvatarEditButton"
        onClick={(e) => inputImageRef.current?.click()}
        css={[
          style.editButton,
          avatarExpanded ? undefined : style.editButtonContracted,
          showAvatarEditButton ? style.showAvatarEditButton : undefined,
          photo !== photoUrl ? style.hiddenAvatarEditButton : undefined,
        ]}
      >
        <CameraAlt id="ProfileAvatarEditIcon" css={style.editIcon} />
      </IconButton>
      <TurquesaAvatar
        id="ProfileAvatar"
        size={!avatarExpanded ? 11 : 16}
        src={photo}
        nameToInitials={name}
        ccss={style.avatar}
      />
      <input
        type="file"
        accept="image/*"
        //garantir que o valor atual sempre será diferente do valor escolhido para chamar a função "onChange"
        value={""}
        onChange={(e) =>
          fromFileGetBase64Image(e.target.files![0], (newPhoto) => {
            setPhotoRef.current!(newPhoto);
            openRepositionDialogRef.current!();
          })
        }
        ref={inputImageRef}
        css={style.inputImageHidden}
      />
      {edit ? (
        <RepositionDialog
          openRepositionDialogRef={openRepositionDialogRef}
          setPhotoRef={setPhotoRef}
          setCurrentPhoto={setPhoto}
        />
      ) : undefined}
    </div>
  );
}

export default ProfilePhotoButton;
