/** @jsxImportSource @emotion/react */
import { ContentCopy } from "@mui/icons-material";
import ButtonMain from "../../../../../Components/ButtonMain/View";
import { useFrameDrawerStyle } from "../../../../../Components/Frame/Components/FrameDrawer/Style";
import { BlockModel } from "../../../../../Models/BlockModel";
import { dateToString, stringToValue } from "../../../../../Utils/String";
import { contabilizeBlock } from "../../Presenter";
import ContabilizedItem from "../ContabilizedItem/View";
import { useMainContabilizationStyle } from "./Style";

function MainContabilization({
  contabilizedBlocks,
  uncontabilizedBlocks,
  setAllBlocks,
  openHistory,
  link,
}: {
  contabilizedBlocks: BlockModel[];
  uncontabilizedBlocks: BlockModel[];
  setAllBlocks: React.Dispatch<React.SetStateAction<BlockModel[] | undefined>>;
  openHistory: React.Dispatch<React.SetStateAction<BlockModel[] | undefined>>;
  link: string;
}) {
  const style = useMainContabilizationStyle();
  const frameDrawerStyle = useFrameDrawerStyle();

  const number = uncontabilizedBlocks.length;

  return (
    <div css={style.center}>
      <div css={style.mainContabilization}>
        <div css={[style.blockDisplay, number > 0 ? undefined : style.noBlock]}>
          <ContentCopy css={style.icon} /> {number}
        </div>
        {number > 0 ? (
          <>
            <div css={frameDrawerStyle.subtitle}>Data</div>
            <div css={[frameDrawerStyle.title, style.title]}>
              {dateToString(uncontabilizedBlocks[0].date)}
            </div>
            <div css={frameDrawerStyle.subtitle}>Valor</div>
            <div css={[frameDrawerStyle.title, style.title]}>
              {stringToValue(uncontabilizedBlocks[0].value)}
            </div>
          </>
        ) : undefined}
        <ButtonMain
          onClick={
            number > 0
              ? async () => {
                  const currentUncontabilizedBlock = uncontabilizedBlocks[0];
                  await contabilizeBlock(currentUncontabilizedBlock.id, link);
                  setAllBlocks((list) =>
                    list?.map((e) =>
                      e.id === currentUncontabilizedBlock.id
                        ? { ...e, contabilizedDatetime: new Date() }
                        : e
                    )
                  );
                }
              : undefined
          }
          ccss={[style.button, number > 0 ? undefined : style.buttonNoBlock]}
          color="success"
          disabled={number === 0}
        >
          {number > 0 ? "Próximo" : "Sem blocos!"}
        </ButtonMain>
      </div>
      <div css={[frameDrawerStyle.subtitle, style.subtitle]}>
        Últimos blocos contabilizados
      </div>
      {contabilizedBlocks.length > 0 ? (
        contabilizedBlocks
          .slice(0, 3)
          .map((e) => (
            <ContabilizedItem
              key={"contabilizedBlocks-" + e.id}
              block={e}
              setAllBlocks={setAllBlocks}
              link={link}
            />
          ))
      ) : (
        <div css={style.noItemMessage}>Sem itens nesta lista</div>
      )}
      <ButtonMain
        onClick={() => openHistory(contabilizedBlocks)}
        color="secondaryWhite10PrimaryHover"
        ccss={style.historyButton}
        disabled={false}
      >
        Visualizar Histórico
      </ButtonMain>
    </div>
  );
}

export default MainContabilization;
