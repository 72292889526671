// Third party libs
import Auth from "use-eazy-auth";
import { AuthRoute, GuestRoute, MaybeAuthRoute } from "use-eazy-auth/routes";
import { BrowserRouter as Router, Switch } from "react-router-dom";

// Internal imports
import Accounting from "./Pages/Accounting/Accounting/View";
import Clients from "./Pages/Accounting/Clients/View";
import Login from "./Pages/Login/View";
import ColorLab from "./Pages/ColorLab/View";
import Categorization from "./Pages/Accounting/Categorization/View";
import Contabilization from "./Pages/Accounting/Contabilization/View";
import ContabilizationMobile from "./Pages/Accounting/ContabilizationMobile/View";
import ContabilizationListMobile from "./Pages/Accounting/ContabilizationListMobile/View";
import { login, me, refresh } from "./Utils/Login";
import ContabilizationHistoryMobile from "./Pages/Accounting/ContabilizationHistoryMobile/View";
import Home from "./Pages/Client/Home/View";
import BlockCreate from "./Pages/Client/BlockCreate/View";
import BlockSend from "./Pages/Client/BlockSend/View";
import DocumentDevolved from "./Pages/Client/DocumentDevolved/View";
import Site from "Pages/Site/Main/View";
import Register from "Pages/Site/Register/View";
import { LoginServices } from "Services/Interfaces";
import { EmotionJSX } from "@emotion/react/types/jsx-namespace";
import RedirectToLogin from "Components/RedirectToLogin/View";

export function createList(quantity: number) {
  var list = [] as string[];
  for (let i = 0; i < quantity; i++) {
    list.push(i.toString());
  }
  return list;
}

export var callAuthApiPromise: any;

export var isDiferentUser: (stateUser: string) => boolean;

export var getUser: () => string;

const verifyPlataform =
  (condiction: boolean, child: EmotionJSX.Element) => () =>
    condiction ? child : <RedirectToLogin />;

function App() {
  return (
    <Auth
      loginCall={login}
      meCall={me}
      refreshTokenCall={refresh}
      render={(authActions, _, userState) => {
        callAuthApiPromise = authActions.callAuthApiPromise;
        isDiferentUser = (stateUser: string) =>
          stateUser !== userState.user?.id;
        getUser = () => userState.user?.id ?? "not-logged";
        const clientPlataform = LoginServices.clientPlataform;
        const isLogged = userState.user !== null;

        const showAccountingRoutes = clientPlataform === "accounting";
        const showBusinessRoutes = clientPlataform === "bussiness";

        if (!isLogged || clientPlataform === "not-choosed") {
          document.title = "Blintes";
          if (isLogged) {
            authActions.logout();
          }
        }

        return (
          <Router>
            <Switch>
              <GuestRoute
                path="/"
                exact
                redirectTo="/home"
                children={<Site />}
              />
              <GuestRoute
                path="/register"
                exact
                redirectTo="/home"
                children={<Register />}
              />
              <GuestRoute
                path="/login"
                exact
                redirectTo="/home"
                children={<Login />}
              />
              <AuthRoute
                path="/home"
                exact
                redirectTo="/login"
                children={
                  clientPlataform === "accounting" ? <Clients /> : <Home />
                }
              />
              <GuestRoute
                path="/colorlab"
                exact
                redirectTo="/login"
                children={<ColorLab />}
              />
              <MaybeAuthRoute
                path={"/home/client/contabilization/mobile/list"}
                exact
                children={<ContabilizationListMobile />}
              />
              <MaybeAuthRoute
                path={"/home/client/contabilization/mobile/history"}
                exact
                children={<ContabilizationHistoryMobile />}
              />
              <MaybeAuthRoute
                path={"/home/client/contabilization/mobile"}
                children={<ContabilizationMobile />}
              />
              <AuthRoute
                path={"/home/client"}
                exact
                redirectTo="/login"
                render={verifyPlataform(showAccountingRoutes, <Accounting />)}
              />
              <AuthRoute
                path={"/home/client/categorization"}
                exact
                redirectTo="/login"
                render={verifyPlataform(
                  showAccountingRoutes,
                  <Categorization />
                )}
              />
              <AuthRoute
                path={"/home/client/contabilization"}
                exact
                redirectTo="/login"
                render={verifyPlataform(
                  showAccountingRoutes,
                  <Contabilization />
                )}
              />
              <AuthRoute
                path={"/createblock"}
                exact
                redirectTo="/login"
                render={verifyPlataform(showBusinessRoutes, <BlockCreate />)}
              />
              <AuthRoute
                path={"/pendencyblock"}
                exact
                redirectTo="/login"
                render={verifyPlataform(
                  showBusinessRoutes,
                  <BlockCreate pendency />
                )}
              />
              <AuthRoute
                path={"/sentblock"}
                exact
                redirectTo="/login"
                render={verifyPlataform(showBusinessRoutes, <BlockSend />)}
              />
              <AuthRoute
                path={"/devolveds"}
                exact
                redirectTo="/login"
                render={verifyPlataform(
                  showBusinessRoutes,
                  <DocumentDevolved />
                )}
              />
              <AuthRoute path="/" redirectTo="/login" />
            </Switch>
          </Router>
        );
      }}
    />
  );
}

export default App;
