import { css } from "@emotion/react";
import CacheTheme from "Theme/CacheTheme";
import useCustomTheme from "Theme/CustomTheme";

var styleCached = false;
export function useDisplayListStyle() {
  const name = "DisplayListCSS";
  const theme = useCustomTheme();
  const maskBearing = theme.spacing(6);
  if (!styleCached) {
    const label = theme.extras.label(name);
    var _style = {
      display: (
        isTopDisplay: boolean,
        isBottomDisplay: boolean,
        heightOfFixContent: string,
        paddingTopHeight: number
      ) => {
        const maskHeight = theme.spacing(paddingTopHeight);
        const calc = `calc(${maskHeight} + ${maskBearing})`;
        return css({
          label: label("display"),
          display: "flex",
          flexDirection: "column",
          height: "calc(100% - " + heightOfFixContent + ")",
          overflow: "scroll",
          flexShrink: 0,
          transition: theme.extras.transition.fast([
            "mask-size",
            "mask-position",
            "height",
          ]),
          maskImage: `linear-gradient(to bottom, transparent, black ${calc}, black calc(100% - ${calc}), transparent)`,
          maskSize: `100% ${
            isTopDisplay || isBottomDisplay
              ? `calc(100% + ${maskBearing})`
              : "100%"
          }`,
          maskPosition: `0 ${isTopDisplay ? `-${maskBearing}` : "0"}`,
          maskRepeat: "no-repeat",
          " > div:nth-last-of-type(2)": {
            marginBottom: 0,
          },
        });
      },
      fadeSpacer: (paddingTopHeight: number) =>
        css({
          label: label("fadeSpacer"),
          height: theme.spacing(paddingTopHeight),
          flexShrink: 0,
        }),
      fadeSpacerBottom: (paddingBottomHeight: number) =>
        css({
          label: label("fadeSpacerBottom"),
          height: theme.spacing(paddingBottomHeight),
          flexShrink: 0,
        }),
      noItemMessage: css({
        label: label("noItemMessage"),
        color: theme.palette.secondary.white(40),
        textAlign: "center",
        margin: "auto",
        paddingBottom: theme.spacing(20),
      }),
      time: theme.extras.transitionTime.fast,
    };
    CacheTheme(name, theme.palette.type, _style);
    styleCached = true;
  }
  var style = CacheTheme(name, theme.palette.type);
  return style as typeof _style;
}
