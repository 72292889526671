import { css } from "@emotion/react";
import CacheTheme from "Theme/CacheTheme";
import useCustomTheme from "Theme/CustomTheme";

var styleCached = false;
export function useContabilizationListMobileStyle() {
  const name = "ContabilizationListMobileCSS";
  const theme = useCustomTheme();
  if (!styleCached) {
    const label = theme.extras.label(name);
    var _style = {
      wrapper: css({
        label: label("wrapper"),
        height: "100vh",
      }),
      textEntry: css({
        label: label("textEntry"),
        backgroundColor: theme.palette.secondary.main,
        margin: theme.spacing(1, 0, 2, 0),
      }),
    };
    CacheTheme(name, theme.palette.type, _style);
    styleCached = true;
  }
  var style = CacheTheme(name, theme.palette.type);
  return style as typeof _style;
}
