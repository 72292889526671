/** @jsxImportSource @emotion/react */
import ButtonSquared from "../../../Components/ButtonSquared/View";
import DisplayList from "../../../Components/DisplayList/View";
import FramePanel from "../../../Components/FramePanel/View";
import { useDocumentDevolvedStyle } from "./Style";
import AutoDeleteOutlinedIcon from "@mui/icons-material/AutoDeleteOutlined";
import {
  DeleteOutlineOutlined,
  RestorePageOutlined,
} from "@mui/icons-material";
import DateSpan from "./DateSpan/View";
import ButtonMain from "../../../Components/ButtonMain/View";
import { useDocumentDevolvedPresenter } from "./Presenter";
import { urlToDownload } from "../../../Utils/Images";
import LoadingTransition from "../../../Components/LoadingTransition/View";
import FrameDialog from "../../../Components/FrameDialog/View";

function DocumentDevolved() {
  const style = useDocumentDevolvedStyle();
  const presenter = useDocumentDevolvedPresenter();

  return (
    <LoadingTransition>
      {(_changeLoading) => {
        presenter.changeLoading.current = _changeLoading;
        return (
          <FramePanel
            title={"Documentos devolvidos"}
            icon={<AutoDeleteOutlinedIcon htmlColor="white" />}
            operatorAvatar={presenter.operatorAvatar}
            documents={presenter.imageToShow}
            emptyMessage={
              presenter.documents.length === 0 ? "" : "Selecione um documento"
            }
            hiddenList
          >
            <>
              <div css={style.separator} />
              <div css={style.description}>
                Quando um bloco não é utilizado pela contabilidade, os
                documentos são disponibilizados aqui para que sejam salvos em
                seu computador ou, após 7 dias, sejam excluídos automaticamente.
              </div>
              {presenter.documents.length > 0 ? (
                <DisplayList
                  ccss={style.displayWrapper}
                  paddingBottomHeight={2}
                  heightOfFixContent={"169px"}
                  paddingTopHeight={0}
                  itens={presenter.documents.map((e, _) => (
                    <div key={e.id} css={style.row}>
                      <div
                        onClick={() => presenter.onSelect(e.id)}
                        css={[
                          style.item,
                          presenter.isSelected(e.id)
                            ? style.selected
                            : undefined,
                        ]}
                      >
                        {e.name}
                      </div>
                      <div
                        css={[
                          style.item,
                          style.buttonBox,
                          presenter.isSelected(e.id)
                            ? style.buttonBoxSelected
                            : style.noButtonInteractions,
                        ]}
                      >
                        <ButtonSquared
                          minor
                          onClick={() => presenter.restoureDocument(e.id)}
                          ccss={[
                            style.donwloadIcon,
                            presenter.isSelected(e.id)
                              ? undefined
                              : style.notSelected,
                          ]}
                          color="transparentWhite10SuccessHover"
                        >
                          <RestorePageOutlined />
                        </ButtonSquared>
                        <ButtonSquared
                          minor
                          onClick={() => urlToDownload(e.url, e.name!)}
                          ccss={[
                            style.donwloadIcon,
                            presenter.isSelected(e.id)
                              ? undefined
                              : style.notSelected,
                          ]}
                          color="transparentWhite10PrimaryHover"
                        >
                          <span className="material-symbols-outlined">
                            download
                          </span>
                        </ButtonSquared>
                        <ButtonMain
                          onClick={() => presenter.setConfirmDialog(true)}
                          ccss={[
                            style.deleteButton,
                            presenter.isSelected(e.id)
                              ? undefined
                              : style.notSelected,
                          ]}
                          color="transparentWhite10ErrorHover"
                        >
                          <>
                            <DeleteOutlineOutlined />
                            <DateSpan
                              date={e.date}
                              devolve={() => presenter.onRemoveDocument(e.id)}
                            />
                          </>
                        </ButtonMain>
                      </div>
                    </div>
                  ))}
                />
              ) : (
                <div css={style.noItems}>Sem documentos</div>
              )}
              <FrameDialog
                color="neutralWarning"
                content={
                  "Esta ação removerá o documento permanentemente. Deseja continuar?"
                }
                open={presenter.confirmDialog}
                onConfirm={presenter.onRemoveDocument}
                onClose={() => presenter.setConfirmDialog(false)}
              />
            </>
          </FramePanel>
        );
      }}
    </LoadingTransition>
  );
}

export default DocumentDevolved;
