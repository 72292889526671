/** @jsxImportSource @emotion/react */
import { Button } from "@mui/material";
import { useDrawerAccountingItemStyle } from "../../Pages/Accounting/Accounting/Components/DrawerAccountingPlan/DrawerAccountingItem/Style";
import { useContabilizationBlockItemStyle } from "../../Pages/Accounting/Contabilization/Components/ContabilizationBlockItem/Style";
import { useContabilizationBlockStyle } from "./Style";

function ContabilizationBlock({
  code,
  name,
  number,
  onClick,
}: {
  code: string;
  name: string;
  number: number;
  onClick: () => void;
}) {
  const style = useContabilizationBlockStyle();
  const contabilizationBlockItemstyle = useContabilizationBlockItemStyle();
  const drawerAccountingItemStyle = useDrawerAccountingItemStyle();
  return (
    <Button
      fullWidth
      onClick={onClick}
      css={[
        drawerAccountingItemStyle.item,
        contabilizationBlockItemstyle.item,
        style.accountingItem,
      ]}
    >
      <div css={[contabilizationBlockItemstyle.info, style.icon]}>{number}</div>
      <div css={drawerAccountingItemStyle.column}>
        <span
          css={[
            contabilizationBlockItemstyle.startContent,
            drawerAccountingItemStyle.number,
          ]}
        >
          {code}
        </span>
        <span
          css={[
            contabilizationBlockItemstyle.startContent,
            drawerAccountingItemStyle.name,
          ]}
        >
          {name}
        </span>
      </div>
    </Button>
  );
}

export default ContabilizationBlock;
