/** @jsxImportSource @emotion/react */
import { Grade, PhotoLibraryOutlined, RemoveCircle } from "@mui/icons-material";
import { Badge, Button, Switch, Tooltip } from "@mui/material";
import { MouseEvent, useContext, useEffect, useState } from "react";
import useContentExpansion from "../../../../../Hooks/ContentExpansion";
import {
  OperatorAccounting,
  OperatorClient,
} from "../../../../../Models/OperatorModel";
import { RefreshAccountingHomeContext } from "../../../../../Pages/Accounting/Clients/Model";
import ButtonMain from "../../../../ButtonMain/View";
import FrameDialog from "../../../../FrameDialog/View";
import TurquesaAvatar from "../../../../TurquesaAvatar/View";
import { changeSharedTo } from "../../../Presenter";
import {
  changeAdmin,
  operatorChangeBlock,
  removeOperatorAccounting,
  removeOperatorClient,
} from "../Presenter";
import { useOperatorItemStyle } from "./Style";

function OperatorItem({
  operator,
  selected,
  onClick,
  setOperatorsList,
  client = false,
  operatorType = 2,
}: {
  operator: OperatorAccounting | OperatorClient;
  selected: boolean;
  onClick: () => void;
  setOperatorsList: React.Dispatch<
    React.SetStateAction<OperatorClient[] | OperatorAccounting[] | undefined>
  >;
  blocked?: boolean;
  client?: boolean;
  operatorType?: number;
}) {
  const style = useOperatorItemStyle();
  const [showContent, expanded, setExpanded] = useContentExpansion();
  const [shared, setShared] = useState((operator as OperatorClient).sharedTo);
  const [confirmDialog, setConfirmDialog] = useState(false);

  const refreshAccountingHomeContext = useContext(RefreshAccountingHomeContext);

  useEffect(() => {
    setExpanded(selected);
  }, [selected, setExpanded]);

  const allowActions = operatorType <= operator.type;
  const _changeAdmin = async (
    e: MouseEvent<SVGSVGElement, globalThis.MouseEvent>
  ) => {
    e.stopPropagation();
    await changeAdmin(operator.id);
    setOperatorsList((operators) =>
      operators?.map((e) =>
        e.id !== operator.id ? e : { ...e, type: e.type === 1 ? 2 : 1 }
      )
    );
  };

  return (
    <div css={style.operatorItem}>
      {operator.blocked ? (
        <div>
          <Badge
            badgeContent={<RemoveCircle color="warning" fontSize="small" />}
            overlap="circular"
          >
            <TurquesaAvatar
              ccss={style.lessOpacity}
              nameToInitials={operator.name}
              size={7}
            />
          </Badge>
        </div>
      ) : (
        <TurquesaAvatar nameToInitials={operator.name} size={7} />
      )}
      <div
        css={[
          style.display,
          expanded ? style.expanded(client, allowActions) : undefined,
        ]}
      >
        <div css={style.separator} />
        <span>{operator.email}</span>
        <span>{operator.phone}</span>
        {allowActions ? (
          <div css={style.buttonRow}>
            <ButtonMain
              ccss={style.buttonMargins}
              color={
                operator.blocked ? "warning" : "secondaryWhite20WarningHover"
              }
              onClick={() => {
                operatorChangeBlock(operator.id);
                setOperatorsList((operators) =>
                  operators?.map((e) =>
                    e.id === operator.id
                      ? { ...operator, blocked: !operator.blocked }
                      : e
                  )
                );
              }}
            >
              {operator.blocked ? "Desbloquear" : "Bloquear"}
            </ButtonMain>
            <ButtonMain
              ccss={style.buttonMargins}
              color="secondaryWhite20ErrorHover"
              onClick={() => setConfirmDialog(true)}
            >
              Excluir
            </ButtonMain>
          </div>
        ) : undefined}
        {client ? (
          <div css={style.galleryDiv}>
            <PhotoLibraryOutlined />
            <span css={style.galleryText}>
              Compartilhar galeria e blocos enviados
            </span>
            <Switch
              checked={shared}
              onChange={() => {
                setShared(!shared);
                changeSharedTo(operator.id);
              }}
              css={style.gallerySwitch}
              color="success"
            />
          </div>
        ) : undefined}
      </div>
      <Button
        onClick={onClick}
        css={[style.content, showContent ? style.selected : undefined]}
      >
        <span css={style.lineHeight}>{operator.name}</span>
        {operator.type < 2 ? (
          <Tooltip
            placement="left"
            title={allowActions ? "Remover administração" : "Administrador"}
          >
            <Grade
              onClick={allowActions ? _changeAdmin : undefined}
              css={style.adminIcon}
            />
          </Tooltip>
        ) : operatorType < 2 ? (
          <Tooltip placement="left" title="Tornar administrador">
            <Grade onClick={_changeAdmin} css={style.becomeAdminIcon} />
          </Tooltip>
        ) : (
          <div />
        )}
      </Button>
      <FrameDialog
        open={confirmDialog}
        onClose={() => setConfirmDialog(false)}
        onConfirm={async () => {
          if (client) {
            await removeOperatorClient(operator.id);
          } else {
            await removeOperatorAccounting(operator.id);
          }
          setOperatorsList((operators) =>
            operators?.filter((e) => e.id !== operator.id)
          );
          refreshAccountingHomeContext();
        }}
        color="neutralWarning"
        content={
          "Você tem certeza que deseja prosseguir com a remoção do(a) operador(a) " +
          operator.name +
          "?"
        }
      />
    </div>
  );
}

export default OperatorItem;
