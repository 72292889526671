import { css } from "@emotion/react";
import CacheTheme from "Theme/CacheTheme";
import useCustomTheme from "Theme/CustomTheme";

var styleCached = false;
export function useSideBarHomeStyle() {
  const name = "SideBarHomeCSS";
  const theme = useCustomTheme();
  if (!styleCached) {
    const label = theme.extras.label(name);
    var _style = {
      wrapper: css({
        label: label("wrapper"),
        padding: theme.spacing(2),
        borderRadius: theme.extras.borderRadius,
        height: "min-content",
        backgroundColor: theme.palette.primary.main,
        marginRight: theme.spacing(3),
        marginBottom: theme.spacing(2),
      }),
      item: css({
        label: label("item"),
        display: "flex",
        borderRadius: theme.extras.borderRadius,
        cursor: "pointer",
        transition: "background-color 75ms",
        userSelect: "none",
        backgroundColor: theme.palette.primary.main,
      }),
      itemSelected: css({
        label: label("itemSelected"),
        backgroundColor: theme.palette.primary.white(10),
      }),
      itemNotSelected: css({
        label: label("itemNotSelected"),
        ":hover": {
          backgroundColor: theme.palette.transparency.white(10),
        },
      }),
      iconMirror: css({
        label: label("iconMirror"),
        transform: "scaleX(-1)",
      }),
      iconSize: css({
        label: label("iconSize"),
        width: theme.spacing(7),
        height: theme.spacing(7),
        padding: theme.spacing(2),
        color: "white",
      }),
      iconDisabled: css({
        label: label("iconDisabled"),
        color: theme.palette.transparency.white(50),
      }),
      noInteractions: css({
        label: label("noInteractions"),
        pointerEvents: "none",
      }),
      text: css({
        label: label("text"),
        color: "white",
        padding: theme.spacing(0, 3, 0, 1),
        whiteSpace: "nowrap",
        height: "100%",
      }),
      textItem: css({
        label: label("textItem"),
        margin: "auto 0px",
        transition: "width 500ms",
        overflow: "hidden",
      }),
      textItemNotExpanded: css({
        label: label("textItemNotExpanded"),
        width: "0px",
      }),
      textItemExpanded: css({
        label: label("textItemExpanded"),
        width: theme.spacing(21),
      }),
      arrowIcon: css({
        label: label("arrowIcon"),
        borderRadius: theme.extras.borderRadius,
        cursor: "pointer",
        color: "white",
        backgroundColor: theme.palette.transparency.white(10),
      }),
      arrowRotateBase: css({
        label: label("arrowRotateBase"),
        transition: "transform 500ms",
      }),
      arrowRotate: css({
        label: label("arrowRotate"),
        transform: "rotate(-0.5turn)",
      }),
      separator: css({
        label: label("separator"),
        height: theme.spacing(1),
      }),
      expandButton: css({
        label: label("expandButton"),
        width: theme.spacing(11),
        height: theme.spacing(6),
      }),
      sidebarWrapper: css({
        label: label("sidebarWrapper"),
        flexDirection: "column",
        display: "flex",
      }),
      badge: css({
        "& .MuiBadge-badge": {
          label: label("badge"),
          width: theme.spacing(4),
          height: theme.spacing(4),
          borderRadius: theme.spacing(4),
          userSelect: "none",
          cursor: "pointer",
          fontWeight: "bold",
          textShadow: "none",
          transform: "scale(1) translate(60%, -60%)",
        },
      }),
    };
    CacheTheme(name, theme.palette.type, _style);
    styleCached = true;
  }
  var style = CacheTheme(name, theme.palette.type);
  return style as typeof _style;
}
