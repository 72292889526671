import { css } from "@emotion/react";
import CacheTheme from "Theme/CacheTheme";
import useCustomTheme from "Theme/CustomTheme";

var styleCached = false;
export function useDrawerTransferStyle() {
  const name = "DrawerTransferCSS";
  const theme = useCustomTheme();
  if (!styleCached) {
    const label = theme.extras.label(name);
    var _style = {
      noInteractions: css({
        label: label("noInteractions"),
        userSelect: "none",
        pointerEvents: "none",
      }),
      description: css({
        label: label("description"),
        color: "white",
        margin: theme.spacing(2, 0),
        lineHeight: "18px",
      }),
      name: css({
        label: label("name"),
        display: "flex",
      }),
      item: css({
        label: label("item"),
        display: "flex",
        marginBottom: theme.spacing(2),
      }),
      innerItem: css({
        label: label("innerItem"),
        display: "flex",
        width: "100%",
        alignItems: "center",
        fontSize: "16px",
        marginLeft: theme.spacing(2),
        transition: theme.extras.transition.faster("background-color"),
        ":hover": {
          backgroundColor: theme.palette.secondary.white(10),
        },
      }),
      transferTransition: css({
        label: label("transferTransition"),
        transition: theme.extras.transition.fast("background-color"),
      }),
      selected: css({
        label: label("selected"),
        backgroundColor: theme.palette.primary.main,
        ":hover": {
          backgroundColor: theme.palette.primary.white(10),
        },
      }),
      trasnferSelected: css({
        label: label("trasnferSelected"),
        backgroundColor: theme.palette.success.main,
        pointer: "default",
        ":hover": {
          backgroundColor: theme.palette.success.main,
        },
      }),
      button: css({
        label: label("button"),
        display: "flex",
        flexDirection: "column",
        fontSize: "16px",
        fontWeight: "bold",
        backgroundColor: theme.palette.primary.main,
        transition: theme.extras.transition.faster([
          "background-color",
          "color",
        ]),
        ":hover": {
          backgroundColor: theme.palette.primary.white(10),
        },
        "&.Mui-disabled": {
          backgroundColor: theme.palette.secondary.black(10),
          color: theme.palette.secondary.white(10),
        },
      }),
      animationTransition: css({
        label: label("animationTransition"),
        transition: theme.extras.transition.fast(["background-color", "color"]),
      }),
      wrapper: css({
        label: label("wrapper"),
        display: "flex",
        width: "100%",
        color: "white",
        height: theme.spacing(7),
        marginTop: theme.spacing(2),
        overflow: "clip",
        backgroundColor: theme.palette.primary.main,
        borderRadius: theme.extras.borderRadius,
        transition: theme.extras.transition.fast([
          "height",
          "background-color",
        ]),
      }),
      // A sobreposição de elementos faz com que, quando desativo, o botão tenha alguns pixels transparentes que vazam o fundo.
      // Por isso a necessidade de deixar o elemento do fundo transparente e colorindo-o junto ao botão tornando-se ativo.
      noColor: css({
        label: label("noColor"),
        backgroundColor: "unset",
      }),
      expanded: css({
        label: label("expanded"),
        height: theme.spacing(32),
      }),
      fadeContent: css({
        label: label("centerContent"),
        transition: theme.extras.transition.fast("opacity"),
        alignSelf: "center",
        width: "100%",
      }),
      transferMessage: css({
        label: label("transferMessage"),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }),
      nameLine: css({
        label: label("nameLine"),
        fontWeight: "bold",
        lineHeight: "18px",
      }),
      placeholder: css({
        label: label("placeholder"),
        height: theme.spacing(7),
        width: theme.spacing(7),
      }),
      transferRow: css({
        label: label("transferRow"),
        display: "flex",
        width: "100%",
        justifyContent: "space-between",
        alignItems: "end",
        padding: theme.spacing(2),
      }),
      transferAvatar: css({
        label: label("transferAvatar"),
        display: "flex",
        justifyContent: "center",
      }),
      transferButton: css({
        label: label("transferButton"),
        width: `calc(100% - ${theme.spacing(4)})`,
        margin: theme.spacing(3, 2, 4, 2),
      }),
      hidden: css({
        label: label("hidden"),
        opacity: 0,
      }),
      fastTime: theme.extras.transitionTime.fast,
    };
    CacheTheme(name, theme.palette.type, _style);
    styleCached = true;
  }
  var style = CacheTheme(name, theme.palette.type);
  return style as typeof _style;
}
