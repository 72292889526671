import { css } from "@emotion/react";
import CacheTheme from "Theme/CacheTheme";
import useCustomTheme from "Theme/CustomTheme";

var styleCached = false;
export function useSentBlockStyle() {
  const name = "SentBlockCSS";
  const theme = useCustomTheme();
  if (!styleCached) {
    const label = theme.extras.label(name);
    var _style = {
      wrapper: css({
        label: label("wrapper"),
        display: "flex",
        color: "white",
        alignItems: "center",
        justifyContent: "space-between",
        padding: theme.spacing(1, 1, 1, 2),
        borderRadius: theme.extras.borderRadius,
      }),
      iconMirror: css({
        label: label("iconMirror"),
        transform: "scaleX(-1)",
      }),
      displayTimer: css({
        label: label("displayTimer"),
        display: "flex",
        fontSize: "12px",
        padding: theme.spacing(1, 1, 1, 1.5),
        height: theme.spacing(5),
        alignItems: "center",
        fontWeight: "normal",
        pointerEvents: "all",
      }),
      displayTimerSelected: css({
        label: label("displayTimerSelected"),
        borderRadius: theme.extras.borderRadius,
        backgroundColor: theme.palette.transparency.white(10),
      }),
      displayTimerAction: css({
        label: label("displayTimerAction"),
        transition: theme.extras.transition.faster("background-color"),
        backgroundColor: theme.palette.success.main,
        ":hover": {
          backgroundColor: theme.palette.success.white(10),
        },
      }),
      smallIcon: css({
        label: label("smallIcon"),
        fontSize: "24px",
        marginLeft: theme.spacing(0.5),
        transition: theme.extras.transition.fast(["opacity", "width"]),
        opacity: 1,
        width: theme.spacing(3),
      }),
      hiddenIcon: css({
        label: label("hiddenIcon"),
        opacity: 0,
      }),
      contractIcon: css({
        label: label("contractIcon"),
        width: 0,
      }),
      noInteractions: css({
        label: label("noInteractions"),
        pointerEvents: "none",
        ":hover": {
          backgroundColor: theme.palette.primary.main,
        },
      }),
    };
    CacheTheme(name, theme.palette.type, _style);
    styleCached = true;
  }
  var style = CacheTheme(name, theme.palette.type);
  return style as typeof _style;
}
