/** @jsxImportSource @emotion/react */
import { AccountCircle, Add, Delete } from "@mui/icons-material";
import { useRef, useState } from "react";
import DisplayList from "../../../../Components/DisplayList/View";
import { useFrameDrawerStyle } from "../../../../Components/Frame/Components/FrameDrawer/Style";
import FrameDrawer from "../../../../Components/Frame/Components/FrameDrawer/View";
import ButtonSquared from "../../../../Components/ButtonSquared/View";
import TextEntry from "../../../../Components/TextEntry/View";
import { useAddClientDrawerStyle } from "./Style";
import { AccountingInvited } from "../../../../Models/AccountingModel";
import useLoadingTransitionExpanded from "../../../../Hooks/LoadingTransitionExpanded";
import { invitedAdd, invitedList, invitedRemove } from "../Presenter";
import LoadingTransition from "../../../../Components/LoadingTransition/View";
import useTextEntryWithError from "../../../../Hooks/TextEntryWithError/View";
import { initTextEntryWithErrorState } from "../../../../Hooks/TextEntryWithError/Model";

function AddClientDrawer({
  drawerRef,
}: {
  drawerRef: React.MutableRefObject<() => void>;
}) {
  const frameDrawerStyle = useFrameDrawerStyle();
  const style = useAddClientDrawerStyle();

  const [inviteds, setInviteds] = useState<AccountingInvited[]>();

  const [transitionOff, expandedState, changeLoading] =
    useLoadingTransitionExpanded(inviteds !== undefined, () =>
      invitedList(setInviteds)
    );

  const emailState = useRef(initTextEntryWithErrorState);
  const [showError, textRef, setTextRef, error] =
    useTextEntryWithError(emailState);

  drawerRef.current = () => expandedState[1](true);

  return (
    <FrameDrawer
      searchTitle="Pesquisar e-mail"
      drawerOpened={expandedState[0]}
      onClose={() => expandedState[1](false)}
    >
      <LoadingTransition transitionOff={transitionOff}>
        {(_changeLoading) => {
          changeLoading.current = _changeLoading;
          return (
            <>
              <div css={frameDrawerStyle.title}>Adicionar cliente</div>
              <div css={[frameDrawerStyle.subtitle, style.subtitle]}>
                E-mail
                <span
                  css={[
                    frameDrawerStyle.subtitleErrorTransition,
                    showError ? frameDrawerStyle.subtitleError : undefined,
                  ]}
                >
                  {error}
                </span>
              </div>
              <TextEntry
                textRef={textRef}
                setTextRef={setTextRef}
                error={showError}
                placeholder="Digite aqui"
                onChange={() => emailState.current.setState({ error: "" })}
                endIcon={
                  <ButtonSquared
                    onClick={() =>
                      invitedAdd(emailState, inviteds, setInviteds)
                    }
                    minor
                    color="success"
                  >
                    <Add />
                  </ButtonSquared>
                }
              />
              <div css={[frameDrawerStyle.subtitle, style.subtitle2]}>
                Adicionados recentemente (aguardando cadastro)
              </div>
              <DisplayList
                paddingTopHeight={2}
                heightOfFixContent={"202px"}
                itens={(inviteds ?? ([] as AccountingInvited[])).map(
                  (invited) => (
                    <div key={"AddClient-" + invited.id} css={style.item}>
                      <AccountCircle />
                      <span css={style.name}>{invited.email}</span>
                      <ButtonSquared
                        minor
                        transparentIcon
                        color="secondaryWhite20ErrorHover"
                        onClick={() => invitedRemove(invited.id, setInviteds)}
                      >
                        <Delete />
                      </ButtonSquared>
                    </div>
                  )
                )}
              />
            </>
          );
        }}
      </LoadingTransition>
    </FrameDrawer>
  );
}

export default AddClientDrawer;
