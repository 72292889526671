import { css } from "@emotion/react";
import CacheTheme from "Theme/CacheTheme";
import useCustomTheme from "Theme/CustomTheme";

var styleCached = false;
export function useButtonImageStyle() {
  const name = "ButtonImageCSS";
  const theme = useCustomTheme();
  if (!styleCached) {
    const label = theme.extras.label(name);
    var _style = {
      wrapper: css({
        label: label("wrapper"),
        position: "relative",
        display: "flex",
        userSelect: "none",
      }),
      item: css({
        label: label("item"),
        width: theme.spacing(12),
        height: theme.spacing(12),
        borderRadius: theme.extras.borderRadius,
        opacity: 0.5,
        transition: theme.extras.transition.fast("opacity"),
        cursor: "pointer",
        objectFit: "cover",
        ":hover": {
          opacity: 1,
        },
      }),
      itemSolid: css({
        label: label("itemSolid"),
        opacity: 1,
      }),
      itemDisplayed: css({
        label: label("itemDisplayed"),
        opacity: 1,
      }),
      position: css({
        label: label("position"),
        position: "absolute",
        opacity: 0,
        bottom: 0,
        margin: theme.spacing(1),
        transition: theme.extras.transition.fast("opacity"),
      }),
      hiddenOptions: css({
        label: label("hiddenOptions"),
        height: 0,
        overflow: "clip",
      }),
      showOptions: css({
        label: label("showOptions"),
        opacity: 1,
      }),
      iconMirror: css({
        label: label("iconMirror"),
        transform: "scaleX(-1)",
        color: "white",
      }),
      options: css({
        label: label("options"),
        borderRadius: theme.extras.borderRadius,
        display: "flex",
        justifyContent: "center",
        width: "calc(" + theme.spacing(10) + " + 1px)",
        overflow: "clip",
      }),
      endJustify: css({
        label: label("endJustify"),
        justifyContent: "end",
      }),
      noInteractions: css({
        label: label("noInteractions"),
        pointerEvents: "none",
      }),
      buttons: css({
        label: label("buttons"),
        display: "flex",
        height: theme.spacing(5),
        color: "white",
        cursor: "pointer",
        width: theme.spacing(5),
        backgroundColor: theme.palette.background.main,
        justifyContent: "center",
        alignItems: "center",
        overflow: "clip",
        flexShrink: 0,
        transition:
          theme.extras.transition.faster("background-color") +
          " ," +
          theme.extras.transition.fast("width"),
        ":hover": {
          backgroundColor: theme.palette.background.white(10),
        },
        ":first-of-type": {
          borderTopLeftRadius: theme.extras.borderRadius,
          borderBottomLeftRadius: theme.extras.borderRadius,
        },
        ":last-of-type": {
          borderTopRightRadius: theme.extras.borderRadius,
          borderBottomRightRadius: theme.extras.borderRadius,
        },
      }),
      onSelection: css({
        label: label("onSelection"),
        width: "100%",
      }),
      separator: css({
        label: label("separator"),
        minWidth: "1px",
        backgroundColor: theme.palette.background.white(10),
        transition: theme.extras.transition.fast("opacity"),
        opacity: 1,
      }),
      buttonExapnded: css({
        label: label("buttonExapnded"),
        width: "calc(" + theme.spacing(10) + " + 1px)",
      }),
      buttonContracted: css({
        label: label("buttonContracted"),
        width: 0,
      }),
      iconOptions: css({
        label: label("iconOptions"),
        transition: theme.extras.transition.fast("opacity"),
        opacity: 1,
      }),
      iconHidden: css({
        label: label("iconHidden"),
        opacity: 0,
      }),
      iconDisabled: css({
        label: label("iconDisabled"),
        color: theme.palette.transparency.white(30),
      }),
      selectedTag: css({
        label: label("selectedTag"),
        transition: theme.extras.transition.fast(["background-color", "width"]),
        backgroundColor: theme.palette.success.main,
        ":hover": {
          backgroundColor: theme.palette.success.main,
        },
      }),
      removeTag: css({
        label: label("removeTag"),
        transition: theme.extras.transition.fast(["background-color", "width"]),
        backgroundColor: theme.palette.warning.main,
        ":hover": {
          backgroundColor: theme.palette.warning.white(10),
        },
      }),
      removeSpan: css({
        label: label("removeSpan"),
        fontSize: "14px",
        fontWeight: "bold",
        userSelect: "none",
      }),
      time: theme.extras.transitionTime.fast,
    };
    CacheTheme(name, theme.palette.type, _style);
    styleCached = true;
  }
  var style = CacheTheme(name, theme.palette.type);
  return style as typeof _style;
}
