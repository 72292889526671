/** @jsxImportSource @emotion/react */
import AliceCarousel from "react-alice-carousel";
import SiteSlide01 from "./../../../../../Assets/site_slide_01.png";
import "react-alice-carousel/lib/alice-carousel.css";
import { useCarouselStyle } from "./Style";

function Carousel() {
  const style = useCarouselStyle();

  const renderDotsItem = ({ isActive }: { isActive: boolean }) =>
    isActive ? (
      <div css={[style.dot, style.active]} />
    ) : (
      <div css={[style.dot, style.notActive]} />
    );

  const items = [
    <div css={style.slideItem}>
      <div css={style.slideText}>
        Otimizando a gestão e controle de documentos contábeis
      </div>
      <img
        alt="slide: Otimizando a gestão e controle de documentos contábeis"
        css={[style.slideImage, style.imagem1]}
        src={SiteSlide01}
      />
    </div>,
    <div css={style.slideItem}>
      <div css={style.slideText}>
        Otimizando a gestão e controle de documentos contábeis
      </div>
      <img
        alt="slide: Otimizando a gestão e controle de documentos contábeis"
        css={[style.slideImage, style.imagem1]}
        src={SiteSlide01}
      />
    </div>,
    <div>oi3</div>,
    <div>oi4</div>,
    // <img src="path-to-img" onDragStart={handleDragStart} role="presentation" />,
    // <img src="path-to-img" onDragStart={handleDragStart} role="presentation" />,
    // <img src="path-to-img" onDragStart={handleDragStart} role="presentation" />,
  ];
  return (
    <div css={style.carousel}>
      <AliceCarousel
        mouseTracking
        touchTracking
        disableButtonsControls
        renderDotsItem={renderDotsItem}
        items={items}
      />
    </div>
  );
}

export default Carousel;
