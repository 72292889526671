import { AccountModel, ClientAccountModel } from "../Models/AccountModel";
import { BlockModel } from "../Models/BlockModel";
import { normalizeString, onlyDigits } from "./String";

export const SYNC_DELAY = 1000;

// Converte tipos de pendência para String.
export function issueToString(issue: number) {
  if (issue === 0) {
    return "Baixa qualidade das fotos/documentos";
  } else if (issue === 1) {
    return "Documentos não compatíveis";
  } else if (issue === 2) {
    return "Bloco incompleto";
  } else {
    return "Desconhecido";
  }
}

export function stringToIssue(str: string) {
  if (str === "Baixa qualidade das fotos/documentos") {
    return 0;
  } else if (str === "Documentos não compatíveis") {
    return 1;
  } else if (str === "Bloco incompleto") {
    return 2;
  } else {
    return -1;
  }
}

export const mobileSettings = () => {
  document.body.style.minWidth = "unset";
  document
    .getElementById("viewport")
    ?.setAttribute(
      "content",
      "height=device-height, width=device-width, initial-scale=1"
    );
};

export const blockSort = (a: BlockModel, b: BlockModel) =>
  b.date.valueOf() - a.date.valueOf();

export const monthSort = (a: string, b: string) => Number(b) - Number(a);

export const accountSort = (a: ClientAccountModel, b: ClientAccountModel) =>
  a.activated === b.activated
    ? a.code.localeCompare(b.code)
    : a.activated
    ? 1
    : -1;

export const accountFilter = (e: AccountModel, searchText: string) => {
  const lowerCaseSearchText = searchText.toLocaleLowerCase();
  return (
    lowerCaseSearchText === "" ||
    onlyDigits(e.code).includes(lowerCaseSearchText) ||
    e.code.includes(lowerCaseSearchText) ||
    e.description.toLowerCase().includes(lowerCaseSearchText) ||
    normalizeString(e.description).includes(lowerCaseSearchText)
  );
};
