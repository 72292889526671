/** @jsxImportSource @emotion/react */

import RegisterCarousel from "./RegisterCarousel/View";
import { useRegisterStyle } from "./Style";
import LogoBlintesBottom from "./../../../Assets/logo_blintes_bottom.png";

function Register() {
  const style = useRegisterStyle();

  return (
    <div css={style.container}>
      <RegisterCarousel />
      <img css={style.logo} alt="logo" src={LogoBlintesBottom} />
    </div>
  );
}

export default Register;
