const TARGET = 16777216;

function base64Resize(
  sourceBase64: string,
  callback: (newSourceBase64: string) => void
) {
  if (sourceBase64.length >= TARGET) {
    const _scale = TARGET / sourceBase64.length;
    var img = document.createElement("img");

    img.onload = () => {
      var canvas = document.createElement("canvas");
      canvas.width = Math.floor(img.width * _scale);
      canvas.height = Math.floor(img.height * _scale);

      var ctx = canvas.getContext("2d");
      var maxW = Math.floor(img.width * _scale);
      var maxH = Math.floor(img.height * _scale);

      var iw = Math.floor(img.width);
      var ih = Math.floor(img.height);
      var scl = Math.min(maxW / iw, maxH / ih);

      var iwScaled = Math.floor(iw * scl);
      var ihScaled = Math.floor(ih * scl);
      canvas.width = iwScaled;
      canvas.height = ihScaled;

      ctx!.drawImage(img, 0, 0, iwScaled, ihScaled);
      const newBase64 = canvas.toDataURL("image/jpeg", scl);

      base64Resize(newBase64, callback);
    };

    img.setAttribute("src", sourceBase64);
  } else {
    callback(sourceBase64);
  }
}

export function fromFileGetBase64Image(
  file: File,
  callback: (newSourceBase64: string) => void
) {
  var reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = function () {
    base64Resize(reader.result as string, callback);
  };
}

export const fromUrlGetBase64Image = async (url: string) =>
  new Promise<string>(async (resolve) => {
    const data = await fetch(url);
    const blob = await data.blob();
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onload = () => {
      base64Resize(reader.result as string, resolve);
    };
  });

export const urlToDownload = (url: string, name: string) => {
  const link = document.createElement("a");
  link.href = url;
  link.download = name;
  link.click();
};
