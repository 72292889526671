/** @jsxImportSource @emotion/react */

import TextEntry from "Components/TextEntry/View";
import { useSiteTextEntryStyle } from "./Style";
import { CcssType } from "Components/TextEntry/Model";

function SiteTextEntry({
  ccss,
  placeholder,
  notifyRef,
}: {
  ccss?: CcssType;
  placeholder: string;
  notifyRef?: React.MutableRefObject<
    React.Dispatch<React.SetStateAction<string>>
  >;
}) {
  const style = useSiteTextEntryStyle();

  return (
    <TextEntry
      placeholder={placeholder}
      ccss={[style.siteTextEntry, ccss]}
      notifyRef={notifyRef}
      white
    />
  );
}

export default SiteTextEntry;
