/** @jsxImportSource @emotion/react */
import TurquesaAvatar from "../../../../../../TurquesaAvatar/View";
import { useContactCardStyle } from "./Style";

function ContactCard({
  name,
  phone,
  email,
  photoUrl,
}: {
  name: string;
  phone: string;
  email: string;
  photoUrl?: string;
}) {
  const style = useContactCardStyle();

  return (
    <div css={style.wrapper}>
      <TurquesaAvatar nameToInitials={name} src={photoUrl} size={10} />
      <div css={style.infoColumn}>
        <span css={style.title}>{name}</span>
        <span>{email}</span>
        <span>{phone}</span>
      </div>
    </div>
  );
}

export default ContactCard;
