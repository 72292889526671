import { css } from "@emotion/react";
import CacheTheme from "Theme/CacheTheme";
import useCustomTheme from "Theme/CustomTheme";

var styleCached = false;
export function useProfilePhotoButtonStyle() {
  const name = "ProfilePhotoButtonCSS";
  const theme = useCustomTheme();
  if (!styleCached) {
    const label = theme.extras.label(name);
    var _style = {
      avatarDiv: css({
        label: label("avatarDiv"),
        display: "flex",
        width: theme.spacing(16),
        height: theme.spacing(16),
      }),
      profileEditPanelDiv: css({
        label: label("profileEditPanelDiv"),
        justifyContent: "end",
        position: "relative",
        height: theme.spacing(7),
        width: "unset",
      }),
      editButton: css({
        label: label("editButton"),
        position: "absolute",
        zIndex: 2,
        display: "flex",
        transition: theme.extras.transition.fast([
          "opacity",
          "background-color",
          "width",
          "height",
        ]),
        opacity: 0,
        color: "black",
        pointerEvents: "none",
        width: theme.spacing(16),
        height: theme.spacing(16),
        marginRight: theme.spacing(7),
        backgroundColor: theme.palette.transparency.black(30),
        ":hover": {
          backgroundColor: theme.palette.transparency.black(20),
        },
      }),
      editButtonContracted: css({
        label: label("editButtonContracted"),
        width: theme.spacing(11),
        height: theme.spacing(11),
      }),
      showAvatarEditButton: css({
        label: label("showAvatarEditButton"),
        pointerEvents: "unset",
        opacity: 1,
      }),
      hiddenAvatarEditButton: css({
        label: label("hiddenAvatarEditButton"),
        opacity: 0,
        ":hover": {
          opacity: 1,
        },
      }),
      editIcon: css({
        label: label("editIcon"),
        width: theme.spacing(4),
        height: theme.spacing(4),
        color: "white",
        zIndex: 3,
      }),
      avatar: css({
        label: label("avatar"),
        position: "absolute",
        zIndex: 1,
        marginRight: theme.spacing(7),
      }),
      inputImageHidden: css({
        label: label("inputImageHidden"),
        display: "none",
      }),
      time: theme.extras.transitionTime.fast,
    };
    CacheTheme(name, theme.palette.type, _style);
    styleCached = true;
  }
  var style = CacheTheme(name, theme.palette.type);
  return style as typeof _style;
}
