/** @jsxImportSource @emotion/react */

import { SerializedStyles } from "@emotion/react";
import { useRef } from "react";
import { useIMask } from "use-imask";
import TextEntry from "../TextEntry/View";

function TextEntryMasked({
  ccss,
  textRef,
  placeholder,
  initialValue,
  patternMask,
  onChange,
  error,
  setTextRef,
  setDisableRef,
  password,
  profileTextEntry,
  hiddenClear,
}: {
  placeholder: string;
  ccss?: SerializedStyles | (undefined | SerializedStyles)[];
  initialValue?: string;
  patternMask?: string;
  onChange?: (value: string) => void;
  textRef?: React.MutableRefObject<string>;
  setTextRef?: React.MutableRefObject<
    React.Dispatch<React.SetStateAction<string>>
  >;
  setDisableRef?: React.MutableRefObject<
    React.Dispatch<React.SetStateAction<boolean>>
  >;
  password?: boolean;
  profileTextEntry?: boolean;
  hiddenClear?: boolean;
  error?: boolean;
}) {
  const maskRef = useRef({ mask: patternMask ?? "" });
  const [inputRef] = useIMask(maskRef.current, {
    onAccept: (e) => setTextRef?.current(e.target.value),
    onComplete: (e) => setTextRef?.current(e.target.value),
  });

  return (
    <TextEntry
      ccss={ccss}
      placeholder={placeholder}
      initialValue={initialValue}
      inputRef={inputRef}
      onChange={onChange}
      textRef={textRef}
      setTextRef={setTextRef}
      setDisableRef={setDisableRef}
      password={password}
      error={error}
      profileTextEntry={profileTextEntry}
      hiddenClear={hiddenClear}
    />
  );
}

export default TextEntryMasked;
