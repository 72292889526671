/** @jsxImportSource @emotion/react */
import { Close } from "@mui/icons-material";
import { Dialog } from "@mui/material";
import { useFrameStyle } from "../../../../../Components/Frame/Style";
import ButtonSquared from "../../../../../Components/ButtonSquared/View";
import TurquesaAvatar from "../../../../../Components/TurquesaAvatar/View";
import { useClientDialogStyle } from "./Style";
import { Client } from "../../../../../Models/ClientModel";

function ClientDialog({
  open,
  onClose,
  client,
}: {
  open: boolean;
  onClose: () => void;
  client: Client;
}) {
  const style = useClientDialogStyle();
  const frameStyle = useFrameStyle();

  const isJuridicPerson = client.type === "juridicPerson";

  return (
    <Dialog css={frameStyle.dialog} onClose={onClose} open={open}>
      <>
        <ButtonSquared onClick={onClose}>
          <Close />
        </ButtonSquared>
        <div css={frameStyle.panelSeparator} />
        <div css={style.row}>
          <TurquesaAvatar
            size={16}
            nameToInitials={client.person.name}
            ccss={style.logo}
            src={client.person.pictureUrl}
          />
          <div css={style.column}>
            <div css={style.title}>
              {isJuridicPerson ? "Razão Social" : "Nome Completo"}
            </div>
            <div css={style.description}>{client.person.name}</div>
            <div css={style.title}>{isJuridicPerson ? "CNPJ" : "CPF"}</div>
            <div css={style.description}>{client.person.code}</div>
            <div css={style.title}>E-mail</div>
            <div css={style.description}>{client.person.email}</div>
            <div css={style.title}>Telefone</div>
            <div css={style.description}>{client.person.phone}</div>
            <div css={style.title}>Endereço</div>
            <div css={style.description}>
              {client?.person?.address?.street +
                ", " +
                client?.person?.address?.number}
              <br />
              {client?.person?.address?.neighboor}
              <br />
              {client?.person?.address?.cep}
              <br />
              {client?.person?.address?.city}, {client?.person?.address?.state}
              <br />
            </div>
          </div>
        </div>
      </>
    </Dialog>
  );
}

export default ClientDialog;
