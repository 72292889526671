/** @jsxImportSource @emotion/react */
import { useEffect, useRef } from "react";
import { useFrameStyle } from "../../../../../../../Components/Frame/Style";
import { ClientAccountModel } from "../../../../../../../Models/AccountModel";
import { BlockModel } from "../../../../../../../Models/BlockModel";
import ShowAccountingsBlocks from "../../../ShowAccountingsBlocks/View";
import { useMainColumnStyle } from "../../Style";

function AccountingBody({
  accountingExpanded,
  showAccountings,
  blocksByAccount,
  acccounts,
  expandSideBar,
  notifyRef,
}: {
  accountingExpanded: boolean;
  showAccountings: boolean;
  blocksByAccount: { [accountId: string]: BlockModel[] };
  acccounts: ClientAccountModel[];
  expandSideBar: boolean;
  notifyRef: React.MutableRefObject<
    React.Dispatch<React.SetStateAction<string>>
  >;
}) {
  const mainColumnStyle = useMainColumnStyle();
  const frameStyle = useFrameStyle();

  const scrollRef = useRef<HTMLDivElement>(null);
  const timeoutRef = useRef<NodeJS.Timeout>();

  useEffect(() => {
    if (!accountingExpanded) {
      timeoutRef.current = setTimeout(
        () => scrollRef.current?.scrollTo({ top: 0 }),
        mainColumnStyle.time
      );
    } else {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    }
    // eslint-disable-next-line
  }, [accountingExpanded]);

  return (
    <div
      ref={scrollRef}
      css={[
        frameStyle.panelContent,
        mainColumnStyle.panelContent(accountingExpanded),
      ]}
    >
      <span
        css={mainColumnStyle.placeholder(accountingExpanded, expandSideBar)}
      />
      <ShowAccountingsBlocks
        showAccountings={showAccountings}
        blocksByAccount={blocksByAccount}
        accounts={acccounts}
        notifyRef={notifyRef}
      />
    </div>
  );
}

export default AccountingBody;
