import { ClientAccountModel } from "../../Models/AccountModel";
import { BlockModel } from "../../Models/BlockModel";
import { AccountServices } from "../../Services/Interfaces";
import {
  dateFromMonthAndYear,
  extractDates,
  mapMonth,
  organizeDates,
} from "../../Utils/Dates";
import { accountSort, blockSort } from "../../Utils/Utils";
import {
  KeydedDatesBlockModel,
  KeyedDates,
  KeyedDatesAccountModel,
} from "./Model";

export const getKeyedState = (
  clientAccounts: ClientAccountModel[],
  allBlocks: BlockModel[],
  setKeyedAccounts: (
    value: React.SetStateAction<KeyedDatesAccountModel>
  ) => void,
  setKeyedBlocks: (value: React.SetStateAction<KeydedDatesBlockModel>) => void,
  setYear: React.Dispatch<React.SetStateAction<string>>,
  setMonth: React.Dispatch<React.SetStateAction<string>>,
  setDates: React.Dispatch<React.SetStateAction<KeyedDates>>
) => {
  const organizedAccounts = organizeAccounts(clientAccounts);
  const organizedBlocks = organizeBlocks(allBlocks);
  const { fullAccountList } = AccountServices.getAccountInBlockContext();
  AccountServices.setAccountInBlockContext(organizedAccounts);

  for (const year in organizedBlocks) {
    for (const month in organizedBlocks[year]) {
      for (const accountId in organizedBlocks[year][month]) {
        if (!Object.keys(organizedAccounts).includes(year)) {
          organizedAccounts[year] = {};
        }
        if (!Object.keys(organizedAccounts[year]).includes(month)) {
          organizedAccounts[year][month] = [];
        }
        if (!organizedAccounts[year][month].find((e) => e.id === accountId)) {
          const account = fullAccountList.find((e) => e.id === accountId)!;
          organizedAccounts[year][month].push({
            ...account,
            activated: false,
            date: dateFromMonthAndYear(month, year),
          });
        }
      }
    }
  }

  extractDates(organizedAccounts, setYear, setMonth, setDates);
  setKeyedBlocks(organizedBlocks);
  setKeyedAccounts(organizedAccounts);
};

export function organizeAccounts(accountList: ClientAccountModel[]) {
  const accountDates = organizeDates(
    accountList.map((e) => e.date),
    true
  ) as KeyedDatesAccountModel;

  for (const account of accountList) {
    const accountMonth = mapMonth[account.date.getMonth().toString()];
    const accountYear = account.date.getFullYear().toString();
    accountDates[accountYear][accountMonth].push(account);
  }

  for (const year in accountDates) {
    for (const month in accountDates[year]) {
      accountDates[year][month].sort(accountSort);
    }
  }

  return accountDates;
}

export function organizeBlocks(blocks: BlockModel[]) {
  const keyedBlocks = organizeDates(
    blocks.map((e) => e.date),
    false
  ) as KeydedDatesBlockModel;

  for (const block of blocks) {
    const month = mapMonth[block.date.getMonth().toString()];
    const year = block.date.getFullYear().toString();
    if (!Object.keys(keyedBlocks[year][month]).includes(block.accountId)) {
      keyedBlocks[year][month][block.accountId] = [block];
    } else {
      keyedBlocks[year][month][block.accountId].push(block);
    }
  }

  for (const year in keyedBlocks) {
    for (const month in keyedBlocks[year]) {
      for (const accountId of Object.keys(keyedBlocks[year][month])) {
        keyedBlocks[year][month][accountId].sort(blockSort);
      }
    }
  }

  return keyedBlocks;
}

export const selectItemByDate = (
  yearSelected: string,
  monthSelected: string,
  object: any
) => {
  try {
    return object[yearSelected][monthSelected] ?? [];
  } catch {
    return [];
  }
};
