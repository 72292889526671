/** @jsxImportSource @emotion/react */
import React, { useState } from "react";
import { useSideBarHomeStyle } from "./Style";
import {
  Add,
  KeyboardDoubleArrowRight,
  ErrorOutline,
  HomeOutlined,
  AutoDeleteOutlined,
} from "@mui/icons-material";
import SideBarHomeIcon from "./SideBarHomeIcon/View";
import { Button } from "@mui/material";
import { useButtonSquaredStyle } from "../../../../Components/ButtonSquared/Style";
import { useButtonColorsPick } from "../../../../Hooks/ButtonColorsPick/View";
import { useHistory } from "react-router-dom";
import { OperatorAvatar } from "../../../../Models/OperatorModel";

function SideBarHome({
  pendencyNumber,
  operatorAvatar,
}: {
  pendencyNumber: number;
  operatorAvatar: OperatorAvatar;
}) {
  const style = useSideBarHomeStyle();
  const history = useHistory();

  const [isExpanded, setIsExpanded] = useState(false);

  const squaredButtonStyle = useButtonSquaredStyle();
  const backgroundWhite10Color = useButtonColorsPick("backgroundWhite10");

  return (
    <div css={style.sidebarWrapper}>
      <div css={style.wrapper}>
        <SideBarHomeIcon
          text="Início"
          icon={<HomeOutlined />}
          selected
          isExpanded={isExpanded}
          onClick={() =>
            history.push({
              pathname: "/home",
              state: operatorAvatar,
            })
          }
        />
        <div css={style.separator} />
        <SideBarHomeIcon
          text="Criar bloco"
          icon={<Add />}
          isExpanded={isExpanded}
          onClick={() =>
            history.push({
              pathname: "/createblock",
              state: operatorAvatar,
            })
          }
        />
        <div css={style.separator} />
        <SideBarHomeIcon
          text="Pendências"
          icon={<ErrorOutline />}
          iconMirror
          isExpanded={isExpanded}
          badgeNumber={pendencyNumber}
          onClick={() =>
            history.push({
              pathname: "/pendencyblock",
              state: operatorAvatar,
            })
          }
        />
        <div css={style.separator} />
        <SideBarHomeIcon
          text="Blocos enviados"
          icon={
            <span className="material-symbols-outlined">
              drive_file_move_rtl
            </span>
          }
          iconSpan
          isExpanded={isExpanded}
          onClick={() =>
            history.push({
              pathname: "/sentblock",
              state: operatorAvatar,
            })
          }
        />
        <div css={style.separator} />
        <SideBarHomeIcon
          text="Devolvidos"
          icon={<AutoDeleteOutlined />}
          isExpanded={isExpanded}
          onClick={() =>
            history.push({
              pathname: "/devolveds",
              state: operatorAvatar,
            })
          }
        />
      </div>
      <Button
        onClick={(e) => {
          e.stopPropagation();
          setIsExpanded(!isExpanded);
        }}
        css={[
          squaredButtonStyle.iconSettings,
          backgroundWhite10Color,
          style.expandButton,
        ]}
      >
        <KeyboardDoubleArrowRight
          css={[
            style.arrowRotateBase,
            isExpanded ? style.arrowRotate : undefined,
          ]}
        />
      </Button>
    </div>
  );
}

export default SideBarHome;
