import { css } from "@emotion/react";
import CacheTheme from "Theme/CacheTheme";
import useCustomTheme from "Theme/CustomTheme";

var styleCached = false;
export function useContabilizedItemStyle() {
  const name = "ContabilizedItemCSS";
  const theme = useCustomTheme();
  if (!styleCached) {
    const label = theme.extras.label(name);
    var _style = {
      contabilizedBlock: css({
        label: label("contabilizedBlock"),
        display: "flex",
        backgroundColor: theme.palette.secondary.main,
        borderRadius: theme.extras.borderRadius,
        color: "white",
        padding: theme.spacing(1, 1, 1, 2),
        marginBottom: theme.spacing(1),
        justifyContent: "space-between",
        fontSize: "14px",
      }),
      brighter: css({
        label: label("brighter"),
        backgroundColor: theme.palette.secondary.white(10),
      }),
      margin: css({
        label: label("margin"),
        margin: theme.spacing(0, 4),
      }),
      empashis: css({
        label: label("empashis"),
        fontWeight: "bold",
      }),
      contabilizedIcon: css({
        label: label("contabilizedIcon"),
        color: theme.palette.success.main,
        transform: "scaleX(-1)",
        fontSize: "20px",
      }),
      content: css({
        label: label("content"),
        display: "flex",
        alignItems: "center",
      }),
      button: css({
        label: label("button"),
        width: "unset",
      }),
      icon: css({
        label: label("icon"),
        width: theme.spacing(1),
        justifyContent: "center",
        display: "flex",
      }),
      undo: css({
        label: label("undo"),
        fontSize: "12px",
        overflow: "Hidden",
        transition: theme.extras.transition.fast([
          "width",
          "opacity",
          "margin-right",
        ]),
        marginRight: theme.spacing(0),
        width: theme.spacing(0),
        opacity: 0,
      }),
      undoExpanded: css({
        label: label("buttonExpanded"),
        marginRight: theme.spacing(1.5),
        width: theme.spacing(7),
      }),
      undoShow: css({
        label: label("undoShow"),
        opacity: 1,
      }),
      fast: theme.extras.transitionTime.fast,
    };
    CacheTheme(name, theme.palette.type, _style);
    styleCached = true;
  }
  var style = CacheTheme(name, theme.palette.type);
  return style as typeof _style;
}
