import { css } from "@emotion/react";
import CacheTheme from "Theme/CacheTheme";
import useCustomTheme from "Theme/CustomTheme";

var styleCached = false;
export function useButtonMainStyle() {
  const name = "ButtonMainCSS";
  const theme = useCustomTheme();
  if (!styleCached) {
    const label = theme.extras.label(name);
    var _style = {
      bold: css({
        label: label("bold"),
        fontWeight: "bold",
      }),
      disabled: css({
        label: label("disabled"),
        "&.Mui-disabled": {
          backgroundColor: theme.palette.background.white(10),
          transition: theme.extras.transition.faster("color"),
          color: theme.palette.transparency.white(40),
        },
      }),
    };
    CacheTheme(name, theme.palette.type, _style);
    styleCached = true;
  }
  var style = CacheTheme(name, theme.palette.type);
  return style as typeof _style;
}
