import { css } from "@emotion/react";
import CacheTheme from "Theme/CacheTheme";
import useCustomTheme from "Theme/CustomTheme";

var styleCached = false;
export function useButtonSquaredStyle() {
  const name = "ButtonSquaredCSS";
  const theme = useCustomTheme();
  if (!styleCached) {
    const label = theme.extras.label(name);
    var _style = {
      iconSettings: css({
        label: label("iconSettings"),
        transition: theme.extras.transition.faster([
          "background-color",
          "color",
        ]),
        flexShrink: 0,
        "&.Mui-disabled": {
          color: theme.palette.transparency.white(30),
        },
      }),
      normalSize: css({
        label: label("normalSize"),
        width: theme.spacing(7),
      }),
      minorSize: css({
        label: label("minorSize"),
        width: theme.spacing(5),
        height: theme.spacing(5),
      }),
      transparentIcon: css({
        label: label("transparentIcon"),
        color: theme.palette.secondary.white(50),
      }),
    };
    CacheTheme(name, theme.palette.type, _style);
    styleCached = true;
  }
  var style = CacheTheme(name, theme.palette.type);
  return style as typeof _style;
}
