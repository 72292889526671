import { useCallback, useEffect, useRef, useState } from "react";

export function useDocumentViewerPresenter(documents?: string[]) {
  const [documentSelected, setDocumentSelected] = useState(0);
  const [documentScale, setDocumentScale] = useState(1);
  const [currentZoom, setCurrentZoom] = useState(1);
  const [pos, setPos] = useState({ x: 0, y: 0 });
  const [isDragging, setIsDragging] = useState(false);
  const [allowDragging, setAllowDragging] = useState(true);
  const [devolveDialog, setDevolveDialog] = useState(false);

  const documentRef = useRef<HTMLImageElement>(null);
  const viewRef = useRef<HTMLDivElement>(null);
  const dragPosition = useRef({ x: 0, y: 0 });

  const handleZoom = useCallback(
    (addZoom?: boolean) => {
      let current: any = documentRef.current;
      if (current) {
        let imgSize = [current.clientWidth, current.clientHeight];
        current = viewRef.current;
        let viewSize = [current.clientWidth, current.clientHeight];

        let initZoom;
        let finalZoom;
        if (
          imgSize[0] > imgSize[1] &&
          imgSize[0] / imgSize[1] >= viewSize[0] / viewSize[1]
        ) {
          initZoom = viewSize[0] / imgSize[0];
          finalZoom = (viewSize[1] - 32) / imgSize[1];
        } else {
          initZoom = (viewSize[1] - 32) / imgSize[1];
          finalZoom = viewSize[0] / imgSize[0];
        }

        let _currentZoom: number;
        if (addZoom !== undefined) {
          if (addZoom && currentZoom < 3) {
            setCurrentZoom(currentZoom + 1);
            _currentZoom = currentZoom + 1;
          } else if (!addZoom && currentZoom > 1) {
            setCurrentZoom(currentZoom - 1);
            _currentZoom = currentZoom - 1;
          } else {
            _currentZoom = currentZoom;
          }
        } else {
          _currentZoom = currentZoom;
        }
        setDocumentScale(
          initZoom + ((_currentZoom - 1) * (finalZoom - initZoom)) / 2
        );
      }
    },
    [currentZoom, documentRef, viewRef]
  );

  useEffect(() => {
    // do not check limits if the image is being draged
    if (isDragging) {
      return;
    }

    let imgcur = documentRef.current!;
    let viewcur = viewRef.current!;
    let stopPoint = {
      x: undefined as undefined | number,
      y: undefined as undefined | number,
    };

    // check height limit
    let img = imgcur?.clientHeight;
    let view = viewcur?.clientHeight;
    let total = img * documentScale;
    let limit = 0;
    let allowDraggingCount = 0;

    if (total > view) {
      limit = (total - view) / 2 + 16;
      if (pos.y > limit) {
        stopPoint.y = limit;
      } else if (pos.y < -limit) {
        stopPoint.y = -limit;
      }
    } else {
      if (pos.y !== 0) {
        stopPoint.y = 0;
      }
      allowDraggingCount++;
    }

    // check width limit
    img = imgcur?.clientWidth;
    view = viewcur?.clientWidth;
    total = img * documentScale;
    if (total > view) {
      limit = (total - view) / 2;
      if (pos.x > limit) {
        stopPoint.x = limit;
      } else if (pos.x < -limit) {
        stopPoint.x = -limit;
      }
    } else {
      if (pos.x !== 0) {
        stopPoint.x = 0;
      }
      allowDraggingCount++;
    }
    if (allowDraggingCount === 2) {
      //The children is fully on view. Don't need drag.
      setAllowDragging(false);
    } else {
      //The children isn't fully on the view. Need drag.
      setAllowDragging(true);
    }

    // update
    if (stopPoint.x !== undefined || stopPoint.y !== undefined) {
      setPos({
        x: stopPoint.x ?? pos.x,
        y: stopPoint.y ?? pos.y,
      });
    }
  }, [documentScale, pos, isDragging]);

  useEffect(() => {
    const event = () => handleZoom();
    window.addEventListener("resize", event);
    return () => window.removeEventListener("resize", event);
  }, [handleZoom]);

  useEffect(() => {
    setPos({ x: 0, y: 0 });
    setCurrentZoom(1);
  }, [documentSelected]);

  useEffect(() => {
    setDocumentSelected(0);
  }, [documents]);

  const handleWheel = (event: React.WheelEvent<HTMLDivElement>) => {
    if (event.deltaY > 0 || event.deltaX > 0) {
      handleZoom(false);
    } else {
      handleZoom(true);
    }
    event.stopPropagation();
  };

  const handleDragDocument = (
    event: React.MouseEvent<HTMLImageElement, MouseEvent>
  ) => {
    if (allowDragging) {
      if (isDragging === false && event.type === "mousedown") {
        setIsDragging(true);
        dragPosition.current.y = event.clientY;
        dragPosition.current.x = event.clientX;
      } else if (isDragging === true && event.type === "mousemove") {
        setPos({
          x: pos.x + event.clientX - dragPosition.current.x,
          y: pos.y + event.clientY - dragPosition.current.y,
        });
        dragPosition.current.y = event.clientY;
        dragPosition.current.x = event.clientX;
      } else if (event.type === "mouseup" || event.type === "mouseleave") {
        setIsDragging(false);
      }
    }
  };

  return {
    documentSelected,
    setDocumentSelected,
    viewRef,
    documentRef,
    handleWheel,
    handleDragDocument,
    handleZoom,
    documentScale,
    pos,
    allowDragging,
    isDragging,
    devolveDialog,
    setDevolveDialog,
  };
}
