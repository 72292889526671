/** @jsxImportSource @emotion/react */
import {
  Add,
  ArrowForward,
  ContentCopy,
  NearMeOutlined,
  PhotoLibraryOutlined,
} from "@mui/icons-material";
import { AvatarGroup, Button, Checkbox, Switch } from "@mui/material";
import ButtonMain from "../../../Components/ButtonMain/View";
import DisplayList from "../../../Components/DisplayList/View";
import FrameSelect from "../../../Components/FrameSelect/View";
import ButtonImage from "./ButtonImage/View";
import { useBlockCreateStyle } from "./Style";
import ImageUploading from "react-images-uploading";
import { selfLib } from "./Model";
import { issueToString } from "../../../Utils/Utils";
import FramePanel from "../../../Components/FramePanel/View";
import TurquesaAvatar from "../../../Components/TurquesaAvatar/View";
import FrameDialog from "../../../Components/FrameDialog/View";
import { timeToString } from "../../../Utils/String";
import { useBlockCreatePresenter } from "./Presenter";
import LoadingTransition from "../../../Components/LoadingTransition/View";
import { dateToString } from "../../../Utils/String";

function BlockCreate({ pendency = false }: { pendency?: boolean }) {
  const style = useBlockCreateStyle();
  const presenter = useBlockCreatePresenter(pendency);

  return (
    <LoadingTransition>
      {(_changeLoading) => {
        presenter.changeLoading.current = _changeLoading;
        return (
          <FramePanel
            hiddenList
            operatorAvatar={presenter.operatorAvatar}
            title={pendency ? "Blocos com pendência" : "Criar bloco"}
            icon={
              pendency ? (
                <div css={style.pendencyRow}>
                  <AvatarGroup css={style.mirror}>
                    {presenter.operators.map((op, index) => (
                      <TurquesaAvatar
                        ccss={style.mirror}
                        key={"Avatars-" + index.toString()}
                        src={op.pictureUrl}
                        nameToInitials={op.name}
                        tooltip={op.name}
                        size={4}
                      />
                    ))}
                  </AvatarGroup>
                  <div css={style.pendencyDisplay}>
                    <span>{presenter.pendencyNumber}</span>
                    <ContentCopy css={style.iconMirror} />
                  </div>
                </div>
              ) : (
                <ContentCopy css={style.iconMirror} />
              )
            }
            documents={presenter.imageToShowOrUndefined}
            emptyMessage={
              presenter.emptyPendency ? "" : "Selecione um documento"
            }
          >
            <>
              {pendency && !presenter.emptyPendency ? (
                <>
                  <div css={style.separator} />
                  <div css={style.pendencyShow}>
                    <div>
                      <div css={style.pendencyDetails}>O que aconteceu?</div>
                      <div css={[style.pendencyBox, style.issueType]}>
                        {issueToString(presenter.issue)}
                      </div>
                    </div>
                    <div>
                      <div
                        css={[
                          style.pendencyDetails,
                          style.observation,
                          style.floatingTitle,
                          style.spaceBetween,
                        ]}
                      >
                        <span>Observação</span>
                        <span css={style.dateSpan}>
                          Pendência criada em{" "}
                          <strong>{dateToString(presenter.date)}</strong> às{" "}
                          <strong>{timeToString(presenter.date)}</strong>
                        </span>
                      </div>
                      <div
                        css={[
                          style.pendencyBox,
                          style.observation,
                          presenter.observation
                            ? undefined
                            : style.noObservation,
                          style.floatingBox,
                          presenter.observationHover
                            ? style.expanded(
                                presenter.observationsRef.current!.getBoundingClientRect()
                                  .height
                              )
                            : undefined,
                        ]}
                        onMouseEnter={() => presenter.setObservationHover(true)}
                        onMouseLeave={() =>
                          presenter.setObservationHover(false)
                        }
                      >
                        <span
                          css={[
                            style.textAlign,
                            style.mask,
                            presenter.observationHover
                              ? style.hiddenMask
                              : undefined,
                          ]}
                          ref={presenter.observationsRef}
                        >
                          {presenter.observation ?? "Nenhuma observação"}
                        </span>
                        <div css={style.hidder} />
                      </div>
                    </div>
                  </div>
                </>
              ) : undefined}
              {presenter.emptyPendency ? (
                <>
                  <div css={style.separator} />
                  <div css={[style.galleryEmpty, style.emptyMargin]}>
                    Você não tem pendências
                  </div>
                </>
              ) : (
                <>
                  <div css={style.separator} />
                  <div css={style.row}>
                    {pendency ? (
                      <ButtonMain
                        color="secondaryWhite10"
                        ccss={style.nextButton}
                        onClick={presenter.onNext}
                      >
                        <>
                          Próximo <ArrowForward css={style.sendIcon} />
                        </>
                      </ButtonMain>
                    ) : undefined}
                    <span css={style.description}>
                      Selecione imagens da galeria ou adicione do seu
                      computador.
                    </span>
                    <ButtonMain
                      onClick={presenter.onSend}
                      color="success"
                      ccss={style.sendButton}
                      disabled={presenter.selecteds.length === 0}
                    >
                      <>
                        Enviar bloco
                        <NearMeOutlined css={style.sendIcon} />
                      </>
                    </ButtonMain>
                  </div>
                  <div
                    css={style.selectedAreas(
                      presenter.selecteds.length + 1 > 7
                        ? 7
                        : presenter.selecteds.length + 1
                    )}
                  >
                    <>
                      {presenter.selecteds.map((e, _) => (
                        <ButtonImage
                          key={"ButtonImage" + e.id}
                          id={e.id}
                          url={e.url ?? e.base64url!}
                          displayed={presenter.displayedId}
                          setDisplayed={presenter.updateDisplayId}
                          noInteractions={presenter.noInteractions}
                          setNoInteractions={presenter.setNoInteractions}
                          selectionRemove={() => presenter.onRemove(e.id)}
                        />
                      ))}
                      <ImageUploading
                        maxNumber={13 - presenter.selecteds.length}
                        value={[]}
                        onChange={presenter.onChange}
                      >
                        {({ onImageUpload, dragProps }) => {
                          return (
                            <Button
                              onClick={onImageUpload}
                              {...dragProps}
                              css={style.addButton}
                              disabled={presenter.selecteds.length === 13}
                            >
                              <Add />
                            </Button>
                          );
                        }}
                      </ImageUploading>
                    </>
                  </div>
                  <div css={style.galleryRow}>
                    <div css={style.galleryName}>
                      <PhotoLibraryOutlined />
                      <span css={style.gallerySpan}>Galeria</span>
                    </div>
                    <div css={style.switchDiv}>
                      Visualizar documentos resgatados{" "}
                      <Switch
                        onChange={() =>
                          presenter.setRescued(!presenter.rescued)
                        }
                        value={presenter.rescued}
                      />
                    </div>
                    <FrameSelect
                      options={presenter.libraries}
                      value={presenter.currentLib ?? { value: "", key: "" }}
                      onSelect={(key) =>
                        presenter.setCurrentLibAndGetDocuments(
                          presenter.libraries.find((e) => e.key === key)!
                        )
                      }
                      ccss={style.galleryLibs}
                      heightOfFixContent={448}
                    />
                  </div>
                  {presenter.documentsToShow.length > 0 ? (
                    <DisplayList
                      heightOfFixContent={
                        presenter.selecteds.length > 6
                          ? pendency
                            ? "652px"
                            : "518px"
                          : pendency
                          ? "540px"
                          : "406px"
                      }
                      paddingTopHeight={2}
                      paddingBottomHeight={0}
                      ccss={style.galleryArea}
                      itens={[
                        <div
                          key={"gridGallery"}
                          css={style.grid(
                            Math.floor(presenter.documentsToShow.length / 8) +
                              (presenter.documentsToShow.length % 8 > 0 ? 1 : 0)
                          )}
                        >
                          {presenter.documentsToShow.map((e, _) => (
                            <ButtonImage
                              key={
                                "ButtonImageSelected(" +
                                presenter.currentLib.key +
                                ")-" +
                                e.id
                              }
                              id={e.id}
                              url={e.url}
                              disableRemove={
                                presenter.pendencyDocumentsRef.current.includes(
                                  e
                                ) || presenter.currentLib.key !== selfLib.key
                              }
                              displayed={presenter.displayedId}
                              setDisplayed={presenter.updateDisplayId}
                              noInteractions={presenter.noInteractions}
                              setNoInteractions={presenter.setNoInteractions}
                              removeDocument={() =>
                                presenter.onRemoveDocument(e.id)
                              }
                              maxSelecteds={presenter.selecteds.length === 13}
                              selected={
                                presenter.selecteds.find(
                                  (_e) => _e.id === e.id
                                ) !== undefined
                              }
                              setSelected={() => presenter.onSelectDocument(e)}
                            />
                          ))}
                        </div>,
                      ]}
                    />
                  ) : (
                    <div css={style.galleryEmpty}> Sem documentos </div>
                  )}
                </>
              )}
              <FrameDialog
                color="primary"
                content={
                  <>
                    <span>
                      Ao clicar em próxima você pula a pendência atual. Isso faz
                      com que ela seja direcionada para o final da fila de
                      pendências.
                    </span>
                    <div css={style.nextDialogCheckboxRow}>
                      <Checkbox
                        css={style.checkbox}
                        onClick={presenter.onNotShowAgainNextDialog}
                      />
                      <span>Não mostrar novamente</span>
                    </div>
                  </>
                }
                open={presenter.nextDialog}
                onConfirm={() => presenter.getToSolve(true)}
                onClose={() => presenter.setNextDialog(false)}
              />
              <FrameDialog
                color="neutralWarning"
                content={
                  "Você está resolvendo uma pendência sem fazer alterações. Deseja continuar?"
                }
                open={presenter.equalsDialog}
                onConfirm={presenter.toSolve}
                onClose={() => presenter.setEqualsDialog(false)}
              />
            </>
          </FramePanel>
        );
      }}
    </LoadingTransition>
  );
}

export default BlockCreate;
