import { useRef, useState } from "react";

function useLoadingTransitionExpanded(
  checkLoadDone: boolean,
  onOpen?: () => void
) {
  const expandedState = useState(false);
  const [transitionOff, setTransitionOff] = useState<boolean>(
    onOpen === undefined
  );

  const loadingDone = useRef(false);
  const changeLoading = useRef<
    React.MutableRefObject<() => null> | undefined
  >();

  if (!loadingDone.current && checkLoadDone) {
    changeLoading.current?.current();
    loadingDone.current = true;
  }

  if (!expandedState[0] && loadingDone.current && !transitionOff) {
    setTimeout(() => setTransitionOff(true), 500);
  }

  if (expandedState[0] && !loadingDone.current) {
    onOpen?.apply(undefined);
  }

  return [transitionOff, expandedState, changeLoading] as [
    boolean,
    [boolean, React.Dispatch<React.SetStateAction<boolean>>],
    React.MutableRefObject<React.MutableRefObject<() => null> | undefined>
  ];
}

export default useLoadingTransitionExpanded;
