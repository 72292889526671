import { KeyedDatesAccountModel } from "../../Hooks/ShowAccountingsBlocks/Model";
import { AccountModel, ClientAccountModel } from "../../Models/AccountModel";
import { waitOnFailureInMilliSeconds } from "../../Utils/Dates";
import { callPromiseWrapper } from "../../Utils/Login";
import { IAccountServices } from "../Interfaces";

class AccountServicesImpl implements IAccountServices {
  _fullAccountList: AccountModel[] | undefined;
  _organizedAccountList: KeyedDatesAccountModel | undefined;

  async _getAllAccounts() {
    this._fullAccountList = (await callPromiseWrapper("/api/account/all")).data;
    this._fullAccountList?.sort((a, b) => a.code.localeCompare(b.code));
  }

  async initializeAllAccounts() {
    while (this._fullAccountList === undefined) {
      try {
        await this._getAllAccounts();
      } catch {
        await new Promise((resolve) =>
          setTimeout(resolve, waitOnFailureInMilliSeconds)
        );
      }
    }
    return this._fullAccountList;
  }

  async getClientAccounts(clientId?: string) {
    await this.initializeAllAccounts();

    var clientAccountIds = (
      await callPromiseWrapper(
        "/api/account/client",
        clientId ? { clientId: clientId } : undefined
      )
    ).data as {
      id: string;
      date: Date;
    }[];

    var data = [] as ClientAccountModel[];

    clientAccountIds.forEach((clientAccountIds) => {
      let clientAccount = (this._fullAccountList as AccountModel[]).find(
        (elem: any) => elem.id === clientAccountIds.id
      );
      data.push({
        id: clientAccount?.id as string,
        description: clientAccount?.description as string,
        code: clientAccount?.code as string,
        yearOfExercise: clientAccount?.yearOfExercise as string,
        date: new Date(clientAccountIds.date),
        activated: true,
      });
    });

    return data;
  }

  getFullAccountList = () => this._fullAccountList!;

  setAccountInBlockContext(organizedAccountList: KeyedDatesAccountModel) {
    this._organizedAccountList = organizedAccountList;
  }

  getAccountInBlockContext = () => ({
    organizedAccountList: this._organizedAccountList!,
    fullAccountList: this._fullAccountList!,
  });

  changeAccountState(changeArgs: { clientId: string; accountId: string }) {
    return callPromiseWrapper("/api/account/change", changeArgs);
  }
}

export default AccountServicesImpl;
