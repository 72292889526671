import { css } from "@emotion/react";
import CacheTheme from "Theme/CacheTheme";
import useCustomTheme from "Theme/CustomTheme";

var styleCached = false;
export function useDocumentDevolvedStyle() {
  const name = "DocumentDevolvedCSS";
  const theme = useCustomTheme();
  if (!styleCached) {
    const label = theme.extras.label(name);
    var _style = {
      row: css({
        label: label("row"),
        display: "flex",
        marginBottom: theme.spacing(1),
      }),
      separator: css({
        label: label("separator"),
        backgroundColor: theme.palette.secondary.black(10),
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(3),
        minHeight: "1px",
        width: "100%",
      }),
      description: css({
        label: label("description"),
        color: theme.palette.secondary.white(40),
        marginBottom: theme.spacing(3),
      }),
      displayWrapper: css({
        label: label("displayWrapper"),
        width: "100%",
      }),
      item: css({
        label: label("item"),
        backgroundColor: theme.palette.secondary.white(10),
        borderRadius: theme.extras.borderRadius,
        padding: theme.spacing(1, 1, 1, 3),
        color: "white",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        cursor: "pointer",
        userSelect: "none",
        width: "100%",
        transition: theme.extras.transition.faster("background-color"),
        ":hover": {
          backgroundColor: theme.palette.secondary.white(20),
        },
      }),
      buttonBox: css({
        label: label("buttonBox"),
        display: "flex",
        width: "unset",
        padding: theme.spacing(1),
        marginLeft: theme.spacing(1),
        ":hover": {
          cursor: "unset",
          backgroundColor: theme.palette.secondary.white(10),
        },
      }),
      buttonBoxSelected: css({
        label: label("buttonBoxSelected"),
        backgroundColor: theme.palette.secondary.white(20),
        ":hover": {
          cursor: "unset",
          backgroundColor: theme.palette.secondary.white(20),
        },
      }),
      noButtonInteractions: css({
        label: label("noInteractions"),
        " > button": {
          pointerEvents: "none",
        },
      }),
      selected: css({
        label: label("selected"),
        backgroundColor: theme.palette.secondary.white(20),
      }),
      notSelected: css({
        label: label("notSelected"),
        color: theme.palette.transparency.white(40),
      }),
      donwloadIcon: css({
        label: label("donwloadIcon"),
        marginRight: theme.spacing(1),
        opacity: theme.extras.transition.faster("color"),
      }),
      deleteButton: css({
        label: label("deleteButton"),
        padding: theme.spacing(0, 1),
        height: theme.spacing(5),
        width: theme.spacing(12),
        justifyContent: "start",
        opacity: theme.extras.transition.faster("color"),
      }),
      noItems: css({
        label: label("noItems"),
        color: theme.palette.secondary.white(40),
        margin: "auto",
        paddingBottom: "169px",
      }),
    };
    CacheTheme(name, theme.palette.type, _style);
    styleCached = true;
  }
  var style = CacheTheme(name, theme.palette.type);
  return style as typeof _style;
}
