import { css } from "@emotion/react";
import CacheTheme from "Theme/CacheTheme";
import useCustomTheme from "Theme/CustomTheme";

var styleCached = false;
export function useColorLabStyle() {
  const name = "ColorLabCss";
  const theme = useCustomTheme();
  if (!styleCached) {
    const label = theme.extras.label(name);
    var _style = {
      background: css({
        label: label("backgroundGray"),
        backgroundColor: theme.palette.background.main,
      }),
      primary: css({
        label: label("primary"),
        backgroundColor: theme.palette.primary.main,
      }),
      secondary: css({
        label: label("secondary"),
        backgroundColor: theme.palette.secondary.main,
      }),
      error: css({
        label: label("error"),
        backgroundColor: theme.palette.error.main,
      }),
      warning: css({
        label: label("warning"),
        backgroundColor: theme.palette.warning.main,
      }),
      info: css({
        label: label("info"),
        backgroundColor: theme.palette.info.main,
      }),
      success: css({
        label: label("success"),
        backgroundColor: theme.palette.success.main,
      }),
      backgroundWhite: css({
        label: label("backgroundWhite"),
        backgroundColor: "white",
      }),
      div: css({
        label: label("div"),
        display: "flex",
        height: "100vh",
        justifyContent: "center",
        alignItems: "center",
      }),
      divTransparency: (number: number, isWhite: boolean) =>
        css({
          label: label("divTransparency"),
          padding: theme.spacing(20),
          display: "flex",
          backgroundColor: isWhite
            ? theme.palette.transparency.white(number)
            : theme.palette.transparency.black(number),
          borderRadius: theme.extras.borderRadius,
        }),
    };
    CacheTheme(name, theme.palette.type, _style);
    styleCached = true;
  }
  var style = CacheTheme(name, theme.palette.type);
  return style as typeof _style;
}
