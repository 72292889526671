import { css } from "@emotion/react";
import CacheTheme from "Theme/CacheTheme";
import useCustomTheme from "Theme/CustomTheme";

var styleCached = false;
export function useContabilizedBlockEntriesStyle() {
  const name = "ContabilizationBlockEntriesCSS";
  const theme = useCustomTheme();
  if (!styleCached) {
    const label = theme.extras.label(name);
    var _style = {
      entry: css({
        label: label("entry"),
        color: "white",
        borderRadius: theme.extras.borderRadius,
        backgroundColor: theme.palette.background.main,
        padding: theme.spacing(2),
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        cursor: "text",
        "> span:first-of-type": {
          color: theme.palette.transparency.white(75),
          fontSize: "12px",
        },
      }),
      column: css({
        label: label("column"),
        flexDirection: "column",
      }),
      accountInLine: css({
        label: label("accountInLine"),
        color: "white",
        width: theme.spacing(48),
      }),
      leftSpacing: css({
        label: label("leftSpacing"),
        paddingLeft: theme.spacing(3),
      }),
      accountName: css({
        label: label("accountName"),
        width: theme.spacing(48),
      }),
      accountHeight: css({
        label: label("accountHeight"),
        marginTop: theme.spacing(13),
      }),
      mask: css({
        label: label("mask"),
        maskSize: theme.spacing(46),
      }),
      hiddenMask: css({
        label: label("hiddenMask"),
        maskSize: theme.spacing(50),
      }),
      noMarginBottom: css({
        label: label("noMarginBottom"),
        marginBottom: theme.spacing(0),
      }),
      opacityTransition: css({
        label: label("opacityTransition"),
        transition: theme.extras.transition.fast("opacity"),
        opacity: 1,
      }),
      hidden: css({
        label: label("hidden"),
        opacity: 0,
      }),
      button: css({
        label: label("button"),
        color: theme.palette.secondary.white(30),
      }),
      placeholder: css({
        label: label("placeholder"),
        color: theme.palette.background.white(30),
      }),
      accountingPaddingLeft: css({
        label: label("accountingPaddingLeft"),
        paddingLeft: theme.spacing(0.75),
      }),
      time: theme.extras.transitionTime.fast,
    };
    CacheTheme(name, theme.palette.type, _style);
    styleCached = true;
  }
  var style = CacheTheme(name, theme.palette.type);
  return style as typeof _style;
}
