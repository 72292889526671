/** @jsxImportSource @emotion/react */

import { useCardSignatureStyle } from "./Style";

function CardSignature() {
  const style = useCardSignatureStyle();
  return (
    <div css={style.signatureCard}>
      <div css={style.column}>
        <span css={style.signature}>Assinatura</span>
        <span css={style.blintes}>Blintes</span>
      </div>
      <div css={style.bold}>
        R$ 49,99<span css={style.normal}>/mês</span>
      </div>
    </div>
  );
}

export default CardSignature;
