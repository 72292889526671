import { css } from "@emotion/react";
import CacheTheme from "Theme/CacheTheme";
import useCustomTheme from "Theme/CustomTheme";

var styleCached = false;
export function useLoginStyle() {
  const name = "LoginCSS";
  const theme = useCustomTheme();
  const selectPlataformAnimationTime = 500;
  if (!styleCached) {
    const label = theme.extras.label(name);
    var _style = {
      wrapper: css({
        label: label("wrapper"),
        backgroundColor: "white",
        background: "linear-gradient(90deg, #006aff 50%, white 50%)",
        display: "flex",
        width: "100vw",
        height: "100vh",
        flexDirection: "column",
        alignItems: "center",
        minHeight: theme.spacing(80),
        // marginLeft: "75%",
        // marginRight: "25%"
      }),
      panelWrapper: css({
        label: label("panelWrapper"),
        display: "flex",
        flexDirection: "column",
        width: theme.spacing(50),
        flexShrink: 0,
        position: "relative",
        transition: theme.extras.transition.fast("right"),
        right: 0,
      }),
      panel: css({
        label: label("panel"),
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        backgroundColor: "white",
        padding: theme.spacing(8),
        borderRadius: theme.extras.borderRadius,
        width: theme.spacing(66),
        overflow: "clip",
        margin: "auto 25% auto 75%",
      }),
      plataform: css({
        label: label("plataform"),
        display: "flex",
        flexDirection: "column",
        width: "100%",
      }),
      translate: css({
        label: label("translate"),
        right: theme.spacing(66),
      }),
      leftMargin: css({
        label: label("leftMargin"),
        marginLeft: theme.spacing(16),
      }),
      bottomMargin: css({
        label: label("bottomMargin"),
        marginBottom: theme.spacing(2),
      }),
      panelHead: css({
        label: label("panelHead"),
        display: "flex",
        width: "100%",
        justifyContent: "space-between",
        alignItems: "center",
      }),
      logoMargin: css({
        label: label("logoMargin"),
        marginBottom: theme.spacing(8),
      }),
      forgetTitle: css({
        label: label("forgetTitle"),
        fontWeight: "bolder",
        fontSize: "24px",
        lineHeight: "40px",
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(3),
        userSelect: "none",
      }),
      forgetDescription: css({
        label: label("forgetDescription"),
        fontSize: "12px",
        lineHeight: "16px",
        marginBottom: theme.spacing(4),
        userSelect: "none",
      }),
      forgetRecoveryButton: css({
        label: label("forgetRecoveryButton"),
        marginTop: theme.spacing(3),
      }),
      selectPlataformButton: css({
        display: "flex",
        flexDirection: "column",
        label: label("selectPlataformButton"),
        marginBottom: theme.spacing(3),
        backgroundColor: "white",
        color: "gray",
      }),
      backButton: css({
        label: label("backButton"),
        backgroundColor: theme.palette.secondary.main + "1A",
        color: "black",
        ":hover": {
          backgroundColor: theme.palette.secondary.main + "33",
        },
      }),
      disabled: css({
        label: label("disabled"),
        "&.Mui-disabled": {
          backgroundColor: theme.palette.transparency.black(20),
          color: "white",
        },
      }),
      placeholder: css({
        label: label("placeholder"),
        width: theme.spacing(7),
        height: theme.spacing(7),
      }),
      logo: css({
        label: label("logo"),
        width: theme.spacing(25),
      }),
      icon: css({
        label: label("icon"),
        color: theme.palette.primary.main,
      }),
      iconlogo: css({
        label: label("iconlogo"),
        width: "50px",
        height: "50px",
        transform: "rotate(45deg)",
        // color: theme.palette.primary.main,
      }),
      iconButton: css({
        label: label("iconButton"),
        display: "flex",
        flexDirection: "row",
      }),
      whiteButton: css({
        label: label("whiteButton"),
        backgroundColor: "white",
        color: "black",
        fontWeight: "normal",
        ":hover": {
          backgroundColor: theme.palette.transparency.black(10),
        },
      }),
      buttonRow: css({
        label: label("buttonRow"),
        display: "flex",
        flexDirection: "row",
        transition: "opacity " + selectPlataformAnimationTime.toString() + "ms",
        width: "100%",
        " > .MuiButton-root:first-of-type": {
          marginRight: theme.spacing(2),
        },
      }),
      hidden: css({
        label: label("hidden"),
        opacity: 0,
        userSelect: "none",
        pointerEvents: "none",
      }),
      errorDisplay: css({
        label: label("errorDisplay"),
        textAlign: "center",
        marginBottom: theme.spacing(3),
        height: "16px",
        lineHeight: "16px",
        fontSize: "14px",
        color: theme.palette.warning.main,
        transition: theme.extras.transition.fast("opacity"),
        userSelect: "none",
        opacity: 0,
      }),
      showError: css({
        label: label("showError"),
        opacity: 1,
      }),
      allRights: css({
        label: label("allRights"),
        marginBottom: theme.spacing(4),
        textAlign: "center",
        color: "black",
        fontSize: "12px",
        userSelect: "none",
        marginLeft: "75%",
        marginRight: "25%",
        width: "140px",
      }),
      loginOptions: css({
        label: label("loginOptions"),
        transition: "height " + selectPlataformAnimationTime.toString() + "ms",
        height: "56px",
        position: "relative",
      }),
      selectPlataformOptions: css({
        label: label("selectPlataformOptions"),
        transition: "opacity " + selectPlataformAnimationTime.toString() + "ms",
        opacity: 1,
        position: "absolute",
        top: "0px",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing(1),
      }),
      choosePlataform: css({
        label: label("choosePlataform"),
        fontWeight: "400",
        marginBottom: theme.spacing(3),
      }),
      showSelectPlataformOptions: css({
        label: label("showSelectPlataformOptions"),
        height: "232px",
      }),
    };
    CacheTheme(name, theme.palette.type, _style);
    styleCached = true;
  }
  var style = CacheTheme(name, theme.palette.type);
  return style as typeof _style;
}
