/** @jsxImportSource @emotion/react */

import { getAccountingInfo } from "Components/Frame/Presenter";
import { Person } from "Models/AccountingModel";
import { empytPerson } from "Models/ClientModel";
import { useEffect, useState } from "react";

function useGetAccoutingInfo() {
  const accountingState = useState<Person>(empytPerson);

  useEffect(() => {
    getAccountingInfo(accountingState[1]);
    //eslint-disable-next-line
  }, []);

  return accountingState;
}

export default useGetAccoutingInfo;
