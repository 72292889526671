/** @jsxImportSource @emotion/react */
import { Add, Close, HelpOutline } from "@mui/icons-material";
import { useContext, useEffect, useRef, useState } from "react";
import DisplayList from "../../../../../Components/DisplayList/View";
import { useFrameDrawerStyle } from "../../../../../Components/Frame/Components/FrameDrawer/Style";
import ButtonSquared from "../../../../../Components/ButtonSquared/View";
import TextEntry from "../../../../../Components/TextEntry/View";
import DrawerAccountingItem from "./DrawerAccountingItem/View";
import { useDrawerAccountingPlanStyle } from "./Style";
import OpacityTwoTimesTransition from "../../../../../Components/OpacityTwoTimesTransition/View";
import { getAccountInBlockContext } from "../../../../../Components/FrameViewBlock/Components/ViewBlockPanel/Components/ViewBlockAutocomplete/Presenter";
import { mapMonth } from "../../../../../Utils/Dates";
import { selectItemByDate } from "../../../../../Hooks/ShowAccountingsBlocks/Presenter";
import {
  AccountModel,
  ClientAccountModel,
} from "../../../../../Models/AccountModel";
import { accountFilter } from "../../../../../Utils/Utils";
import { AccountingListContext } from "../../Model";
import { addAccount, removeAccount } from "./Presenter";
import { Tooltip } from "@mui/material";

function DrawerAccountingPlan({
  setDisabledSearchRef,
  notifyRef,
}: {
  setDisabledSearchRef: React.MutableRefObject<
    React.Dispatch<React.SetStateAction<boolean>>
  >;
  notifyRef: React.MutableRefObject<
    React.Dispatch<React.SetStateAction<string>>
  >;
}) {
  const style = useDrawerAccountingPlanStyle();
  const frameDrawerStyle = useFrameDrawerStyle();

  const [activatedAccounts, setActivatedAccounts] = useState<AccountModel[]>(
    []
  );
  const [toActiveAccountList, setToActiveAccountList] = useState<
    AccountModel[]
  >([]);

  const [searchText, setSearchText] = useState("");
  const [addSearchText, setAddSearchText] = useState("");
  const changeChildRef = useRef(() => null);

  const { setClientAccounts, clientId } = useContext(AccountingListContext);
  const { organizedAccountList, fullAccountList } = getAccountInBlockContext();

  const addNotifyRef =
    useRef<React.Dispatch<React.SetStateAction<string>>>(setAddSearchText);

  notifyRef.current = setSearchText;

  useEffect(() => {
    const currentDate = new Date();
    const currentMonth = mapMonth[currentDate.getMonth().toString()];
    const currentYear = currentDate.getFullYear().toString();
    const currentAccountList: ClientAccountModel[] = selectItemByDate(
      currentYear,
      currentMonth,
      organizedAccountList
    );
    setToActiveAccountList(
      fullAccountList.filter(
        (e) =>
          currentAccountList.find(
            (_e) => _e.code === e.code && _e.activated
          ) === undefined
      )
    );
    setActivatedAccounts(currentAccountList.filter((e) => e.activated));
  }, [organizedAccountList, fullAccountList]);

  const tooltipText = (
    <>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;As contas ativas ficam disponíveis a partir
      do momento da ativação e continuam sendo mostradas nos meses seguintes. É
      recomendado escolher contas que fazem parte do dia a dia da
      empresa/cliente.
      <p />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Adicionar uma conta não tem efeito em meses
      passados. Contas desativadas continuam disponíveis se houver pelo menos um
      bloco categorizado.
    </>
  );
  return (
    <OpacityTwoTimesTransition
      setDisabledSearchRef={setDisabledSearchRef}
      firstChild={
        <>
          <div css={[frameDrawerStyle.title, style.title]}>
            Adicionar contas
            <span css={[style.title, style.tooltip]}>
              <Tooltip
                componentsProps={{
                  tooltip: { style: style.tooltipCss },
                }}
                placement="bottom-start"
                title={tooltipText}
              >
                <HelpOutline />
              </Tooltip>
              <ButtonSquared
                onClick={() => changeChildRef.current()}
                color="warning"
              >
                <Close />
              </ButtonSquared>
            </span>
          </div>
          <TextEntry
            ccss={style.textEntry}
            placeholder="Buscar conta"
            notifyRef={addNotifyRef}
            searchIcon
          />
          <div css={[frameDrawerStyle.subtitle, style.subtitle]}>
            Resultados da busca
          </div>
          <DisplayList
            paddingTopHeight={2}
            heightOfFixContent="205px"
            itens={toActiveAccountList
              .filter((e) => accountFilter(e, addSearchText))
              .map((e, index) => (
                <DrawerAccountingItem
                  addIcon
                  onClick={() => addAccount(clientId, e, setClientAccounts)}
                  code={e.code}
                  description={e.description}
                  key={"DrawerAccountingAddItem-" + index}
                />
              ))}
          />
        </>
      }
      secondChild={
        <>
          <div css={[frameDrawerStyle.title, style.title]}>
            Gerenciar plano de Contas
            <span css={[style.title, style.tooltip]}>
              <Tooltip
                componentsProps={{
                  tooltip: { style: style.tooltipCss },
                }}
                placement="bottom-start"
                title={tooltipText}
              >
                <HelpOutline />
              </Tooltip>
              <ButtonSquared
                onClick={() => changeChildRef.current()}
                color="primary"
              >
                <Add />
              </ButtonSquared>
            </span>
          </div>
          <div css={[frameDrawerStyle.subtitle, style.subtitle]}>
            Contas ativas
          </div>
          <DisplayList
            paddingTopHeight={2}
            heightOfFixContent="101px"
            itens={activatedAccounts
              .filter((e) => accountFilter(e, searchText))
              .map((e, index) => (
                <DrawerAccountingItem
                  code={e.code}
                  description={e.description}
                  onClick={() => removeAccount(clientId, e, setClientAccounts)}
                  key={"DrawerAccountingRemoveItem-" + index}
                />
              ))}
          />
        </>
      }
      changeChildRef={changeChildRef}
    />
  );
}

export default DrawerAccountingPlan;
