import { useEffect, useRef, useState } from "react";
import useGetFrameOperator from "../../../Hooks/GetFrameOperator";
import useLoadingTransition from "../../../Hooks/LoadingTransition";
import useShowAccountingsBlocks from "../../../Hooks/ShowAccountingsBlocks/View";
import { ClientAccountModel } from "../../../Models/AccountModel";
import { BlockModel } from "../../../Models/BlockModel";
import { Client, emptyClient } from "../../../Models/ClientModel";
import {
  AccountServices,
  BlockServices,
  ClientServices,
} from "../../../Services/Interfaces";
import { HomeInfos } from "./Model";

export function useHomePresenter() {
  const [showAccountings, setShowAccountings] = useState(true);
  const [clientDialog, setClientDialog] = useState(false);

  const [clientAccounts, setClientAccounts] = useState<ClientAccountModel[]>();
  const [allBlocks, setAllBlocks] = useState<BlockModel[]>();
  const clientState = useState<Client>(emptyClient);
  const operatorState = useGetFrameOperator();

  const [pendencyNumber, setPendencyNumber] = useState(0);

  const changeAccountingPanelRef = useRef<() => void>(() => null);
  const notifyRef = useRef<React.Dispatch<React.SetStateAction<string>>>(
    () => null
  );

  const [
    keyedBlocks,
    keyedAccounts,
    datesSelect,
    yearSelected,
    setYearSelected,
    monthSelected,
    setMonthSelected,
  ] = useShowAccountingsBlocks(allBlocks, clientAccounts);

  const [changeLoading] = useLoadingTransition(
    yearSelected !== "" &&
      monthSelected !== "" &&
      operatorState[0].id !== "" &&
      clientState[0].person.address?.cep !== ""
  );

  useEffect(() => {
    getClientAccountings(setClientAccounts);
    getClientBlocks(setAllBlocks, setPendencyNumber);
    getAccountingClient(clientState[1]);
    //eslint-disable-next-line
  }, []);

  return {
    changeLoading,
    clientState,
    operatorState,
    pendencyNumber,
    clientDialog,
    setClientDialog,
    monthSelected,
    setMonthSelected,
    yearSelected,
    setYearSelected,
    datesSelect,
    notifyRef,
    changeAccountingPanelRef,
    showAccountings,
    setShowAccountings,
    setAllBlocks,
    keyedBlocks,
    keyedAccounts,
  };
}

const getClientAccountings = async (
  setClientAccounts: React.Dispatch<
    React.SetStateAction<ClientAccountModel[] | undefined>
  >
) => setClientAccounts(await AccountServices.getClientAccounts());

const getClientBlocks = async (
  setAllBlocks: React.Dispatch<React.SetStateAction<BlockModel[] | undefined>>,
  setPendencyNumber: React.Dispatch<React.SetStateAction<number>>
) => {
  const response = (await BlockServices.getClientBlocks()) as HomeInfos;
  setAllBlocks(response.blocks);
  setPendencyNumber(response.pendencyNumber);
};

const getAccountingClient = async (
  setPerson: React.Dispatch<React.SetStateAction<Client>>
) => setPerson(await ClientServices.getClient());
