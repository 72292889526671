import { css } from "@emotion/react";
import CacheTheme from "Theme/CacheTheme";
import useCustomTheme from "Theme/CustomTheme";

var styleCached = false;
export function useMainContabilizationStyle() {
  const name = "MainContabilizationCSS";
  const theme = useCustomTheme();
  if (!styleCached) {
    const label = theme.extras.label(name);
    var _style = {
      mainContabilization: css({
        label: label("mainContabilization"),
        padding: theme.spacing(4),
        borderRadius: theme.extras.borderRadius,
        backgroundColor: theme.palette.secondary.main,
        width: theme.spacing(52),
        color: "white",
      }),
      blockDisplay: css({
        label: label("blockDisplay"),
        width: "min-content",
        display: "flex",
        padding: theme.spacing(1, 1.5),
        borderRadius: theme.extras.borderRadius,
        backgroundColor: theme.palette.warning.main,
        marginBottom: theme.spacing(4),
        fontWeight: "bold",
      }),
      center: css({
        label: label("center"),
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }),
      noBlock: css({
        label: label("noBlock"),
        backgroundColor: theme.palette.secondary.white(10),
      }),
      icon: css({
        label: label("icon"),
        marginRight: theme.spacing(2),
        transform: "scaleX(-1)",
      }),
      title: css({
        label: label("title"),
        fontSize: "20px",
        marginBottom: theme.spacing(3),
        lineHeight: "20px",
      }),
      button: css({
        label: label("button"),
        marginTop: theme.spacing(2),
      }),
      buttonNoBlock: css({
        label: label("buttonNoBlock"),
        marginTop: theme.spacing(0),
      }),
      subtitle: css({
        label: label("subtitle"),
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(2),
      }),
      noItemMessage: css({
        label: label("noItemMessage"),
        color: theme.palette.secondary.white(40),
        marginBottom: theme.spacing(3),
      }),
      historyButton: css({
        label: label("historyButton"),
        transition: "opacity 500ms",
        opacity: 1,
        "&.Mui-disabled": {
          backgroundColor: theme.palette.secondary.black(10),
        },
      }),
      hideHistoryButton: css({
        label: label("hideHistoryButton"),
        opacity: 0,
        pointerEvents: "none",
      }),
    };
    CacheTheme(name, theme.palette.type, _style);
    styleCached = true;
  }
  var style = CacheTheme(name, theme.palette.type);
  return style as typeof _style;
}
