/** @jsxImportSource @emotion/react */
import Frame from "../../../Components/Frame/View";
import TextEntry from "../../../Components/TextEntry/View";
import ButtonSquared from "../../../Components/ButtonSquared/View";
import {
  Add,
  ContentCopy,
  ReportProblemOutlined,
  ShareOutlined,
} from "@mui/icons-material";
import { useClientsStyle } from "./Style";
import { useFrameStyle } from "../../../Components/Frame/Style";
import { Button } from "@mui/material";
import AddClientDrawer from "./AddClientDrawer/View";
import { useHistory } from "react-router-dom";
import TurquesaAvatar from "../../../Components/TurquesaAvatar/View";
import LoadingTransition from "../../../Components/LoadingTransition/View";
import { HomeClient } from "../../../Models/ClientModel";
import { useClientsPresenter } from "./Presenter";
import { dateToString, timeToString } from "../../../Utils/String";
import { RefreshAccountingHomeContext } from "./Model";

function Clients() {
  const history = useHistory();

  const style = useClientsStyle();
  const frameStyle = useFrameStyle();
  const presenter = useClientsPresenter();

  return (
    <LoadingTransition>
      {(_changeLoading) => {
        presenter.changeLoading.current = _changeLoading;
        return (
          <RefreshAccountingHomeContext.Provider value={presenter.refresh}>
            <Frame
              operatorState={presenter.operatorState}
              accountingState={presenter.accountingState}
            >
              <div css={frameStyle.panel}>
                <div css={frameStyle.panelHead}>
                  <div css={frameStyle.panelHeadRow}>
                    <span css={frameStyle.panelHeadTitle}>Clientes</span>
                    <div css={frameStyle.panelHeadItem}>
                      <TextEntry
                        notifyRef={presenter.searchRef}
                        placeholder="Buscar conta"
                        ccss={frameStyle.textEntry}
                        searchIcon
                      />
                      <div css={frameStyle.panelHeadSpacer} />
                      <ButtonSquared
                        ccss={style.addClientButton}
                        onClick={() => presenter.addDrawerRef.current()}
                        color="primary"
                      >
                        <>
                          <span css={style.margin}>Adicionar Cliente</span>
                          <Add />
                        </>
                      </ButtonSquared>
                    </div>
                  </div>
                  <div css={frameStyle.panelSeparator} />
                </div>
                <div css={frameStyle.panelShower}>
                  <div css={[frameStyle.panelContent, style.grid]}>
                    {(presenter.clients ?? ([] as HomeClient[])).map(
                      (client, index) => (
                        <Button
                          onClick={() =>
                            history.push({
                              pathname: "/home/client",
                              state: client.id,
                            })
                          }
                          key={"ClientCard-" + client.id}
                          css={[
                            style.card,
                            presenter.clientActivated(index)
                              ? style.cardActive
                              : style.cardInactive,
                          ]}
                        >
                          <div css={style.infoRow}>
                            <div css={style.centerIcons}>
                              <ShareOutlined
                                css={[
                                  style.icon,
                                  client.isShared
                                    ? style.iconColorGreen
                                    : undefined,
                                ]}
                              />
                              <ContentCopy
                                css={[
                                  style.icon,
                                  style.iconMiddle,
                                  client.hasUncategorizedBlocks
                                    ? style.iconColorPurple
                                    : undefined,
                                ]}
                              />
                              <ReportProblemOutlined
                                css={[
                                  style.icon,
                                  client.hasPendencies
                                    ? style.iconColorOrange
                                    : undefined,
                                ]}
                              />
                            </div>
                            {client.isOwner ? undefined : (
                              <TurquesaAvatar
                                nameToInitials={client.operatorName}
                                src={client.photoOperatorUrl}
                                size={3}
                                tooltip={client.operatorName}
                                tooltipCss={style.tooltipMargins}
                                placement={"left"}
                              />
                            )}
                          </div>
                          <div css={style.clientName}>{client.name}</div>
                          <div css={style.cardDate}>
                            Modificado em{" "}
                            <span css={style.cardDateBold}>
                              {dateToString(client.modifiedDatetime)}
                            </span>{" "}
                            às{" "}
                            <span css={style.cardDateBold}>
                              {timeToString(client.modifiedDatetime)}
                            </span>
                          </div>
                        </Button>
                      )
                    )}
                  </div>
                </div>
                <AddClientDrawer drawerRef={presenter.addDrawerRef} />
              </div>
            </Frame>
          </RefreshAccountingHomeContext.Provider>
        );
      }}
    </LoadingTransition>
  );
}

export default Clients;
