import { css } from "@emotion/react";
import CacheTheme from "Theme/CacheTheme";
import useCustomTheme from "Theme/CustomTheme";

var styleCached = false;
export function useBlockCreateStyle() {
  const name = "BlockCreateCSS";
  const theme = useCustomTheme();
  if (!styleCached) {
    const label = theme.extras.label(name);
    var _style = {
      iconMirror: css({
        label: label("iconMirror"),
        transform: "scaleX(-1)",
        color: "white",
      }),
      sendButton: css({
        label: label("sendButton"),
        width: theme.spacing(22),
      }),
      nextButton: css({
        label: label("nextButton"),
        width: theme.spacing(20),
      }),
      sendIcon: css({
        label: label("sendIcon"),
        marginLeft: theme.spacing(2),
        transition: theme.extras.transition.faster("color"),
        color: "inherit",
      }),
      grid: (rowNumber: number) =>
        css({
          label: label("grid"),
          display: "grid",
          gridTemplateColumns: "repeat(8, 96px)",
          gridTemplateRows: "repeat(" + rowNumber.toString() + ", 96px)",
          gridGap: theme.spacing(1.75),
        }),
      row: css({
        label: label("row"),
        display: "flex",
        width: "100%",
        alignItems: "center",
        justifyContent: "space-between",
      }),
      separator: css({
        label: label("separator"),
        backgroundColor: theme.palette.secondary.black(10),
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(3),
        minHeight: "1px",
        width: "100%",
      }),
      description: css({
        label: label("description"),
        fontSize: "13px",
        color: theme.palette.transparency.white(40),
      }),
      addButton: css({
        label: label("addButton"),
        width: theme.spacing(12),
        height: theme.spacing(12),
        backgroundColor: theme.palette.transparency.white(20),
        transition: theme.extras.transition.fast("background-color"),
        ":hover": {
          backgroundColor: theme.palette.transparency.white(30),
        },
        "&.Mui-disabled": {
          backgroundColor: theme.palette.transparency.white(5),
          color: theme.palette.transparency.white(20),
        },
      }),
      selectedAreas: (itemsNumber: number) =>
        css({
          label: label("selectedAreas"),
          display: "grid",
          gridTemplateColumns: "repeat(" + itemsNumber.toString() + ", 96px)",
          gridGap: theme.spacing(2),
          borderRadius: theme.extras.borderRadius,
          padding: theme.spacing(2),
          margin: theme.spacing(2),
          border: theme.palette.transparency.white(50) + " dashed 2px",
        }),
      galleryRow: css({
        label: label("galleryRow"),
        color: "white",
        display: "flex",
        fontWeight: "bold",
        width: "100%",
        justifyContent: "space-between",
        alignItems: "center",
      }),
      galleryLibs: css({
        label: label("galleryLibs"),
        width: theme.spacing(30),
      }),
      galleryName: css({
        label: label("galleryName"),
        display: "flex",
      }),
      gallerySpan: css({
        label: label("gallerySpan"),
        marginLeft: theme.spacing(2),
        alignItems: "center",
      }),
      galleryArea: css({
        label: label("galleryArea"),
        marginTop: theme.spacing(2),
      }),
      galleryEmpty: css({
        label: label("galleryEmpty"),
        height: "100%",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: theme.palette.secondary.white(40),
        fontSize: "20px",
        userSelect: "none",
      }),
      emptyMargin: css({
        label: label("emptyMargin"),
        marginBottom: theme.spacing(16),
      }),
      switchDiv: css({
        label: label("switchDiv"),
        marginLeft: theme.spacing(15),
        fontSize: "14px",
        fontWeight: "normal",
      }),
      spaceBetween: css({
        label: label("spaceBetween"),
        justifyContent: "space-between",
      }),
      dateSpan: css({
        label: label("dateSpan"),
        fontSize: "12px",
        fontWeight: "normal",
      }),
      pendencyDisplay: css({
        label: label("pendencyDisplay"),
        display: "flex",
        color: "white",
        alignItems: "center",
        backgroundColor: theme.palette.warning.main,
        padding: theme.spacing(2),
        borderRadius: theme.spacing(2),
        marginLeft: theme.spacing(4),
        "> span": {
          marginRight: theme.spacing(1),
        },
      }),
      pendencyRow: css({
        label: label("pendencyRow"),
        display: "flex",
        alignItems: "center",
      }),
      pendencyShow: css({
        label: label("pendencyShow"),
        display: "flex",
        alignItems: "start",
        justifyContent: "space-between",
        width: "100%",
        color: "white",
      }),
      pendencyDetails: css({
        label: label("pendencyDetails"),
        color: theme.palette.transparency.white(40),
        fontSize: "14px",
        fontWeight: "bold",
        marginBottom: theme.spacing(2),
      }),
      pendencyBox: css({
        label: label("pendencyBox"),
        borderRadius: theme.extras.borderRadius,
        backgroundColor: theme.palette.background.main,
        padding: theme.spacing(2),
      }),
      issueType: css({
        label: label("issueType"),
        width: theme.spacing(42),
      }),
      observation: css({
        label: label("observation"),
        width: theme.spacing(62),
      }),
      floatingTitle: css({
        label: label("floatingTitle"),
        display: "flex",
        position: "relative",
        top: 0,
      }),
      floatingBox: css({
        label: label("floatingBox"),
        position: "absolute",
        zIndex: "1",
        height: theme.spacing(7),
        overflow: "hidden",
        textOverflow: "ellipsis",
        transition: theme.extras.transition.fast("height"),
      }),
      expanded: (height: number) =>
        css({
          label: label("expanded"),
          height: "calc(" + height.toString() + "px + 32px)",
        }),
      hidder: css({
        label: label("hidder"),
        width: theme.spacing(62),
        height: theme.spacing(2),
        backgroundColor: theme.palette.background.main,
        position: "absolute",
        bottom: 0,
      }),
      noObservation: css({
        label: label("noObservation"),
        color: theme.palette.transparency.white(50),
      }),
      nextDialogCheckboxRow: css({
        label: label("nextDialogCheckboxRow"),
        display: "flex",
        alignItems: "center",
        marginTop: theme.spacing(2),
        justifyContent: "center",
        "> span:last-of-type": {
          marginLeft: theme.spacing(1),
          marginRight: theme.spacing(1),
        },
      }),
      textAlign: css({
        label: label("textAlign"),
        textAlign: "justify",
      }),
      checkbox: css({
        label: label("checkbox"),
        color: "white",
        "&.Mui-checked": {
          color: "white",
        },
      }),
      mask: css({
        label: label("mask"),
        display: "flex",
        maskImage:
          "linear-gradient(to left, transparent, black 64px, transparent, black 0px)",
        maskSize: theme.spacing(58),
        transition: theme.extras.transition.fast("mask-size"),
      }),
      hiddenMask: css({
        label: label("hiddenMask"),
        maskSize: theme.spacing(64),
      }),
      mirror: css({
        label: label("mirror"),
        transform: "scaleX(-1)",
      }),
    };
    CacheTheme(name, theme.palette.type, _style);
    styleCached = true;
  }
  var style = CacheTheme(name, theme.palette.type);
  return style as typeof _style;
}
