/** @jsxImportSource @emotion/react */
import { ArrowBack, LockOutlined, QrCode } from "@mui/icons-material";
import ButtonSquared from "../../../Components/ButtonSquared/View";
import DisplayList from "../../../Components/DisplayList/View";
import { useFrameDrawerStyle } from "../../../Components/Frame/Components/FrameDrawer/Style";
import { useContabilizationStyle } from "./Style";
import ContabilizationBlockItem from "./Components/ContabilizationBlockItem/View";
import { useLocation } from "react-router-dom";
import QrPanel from "./Components/QrPanel/View";
import MainContabilization from "./Components/MainContabilization/View";
import FrameSelect from "../../../Components/FrameSelect/View";
import { useRef } from "react";
import FrameDrawer from "../../../Components/Frame/Components/FrameDrawer/View";
import ContabilizedItem from "./Components/ContabilizedItem/View";
import TurquesaAvatar from "../../../Components/TurquesaAvatar/View";
import { CategorizationStateArgs } from "../Categorization/Model";
import { useContabilizationPresenter } from "./Presenter";
import { monthSort } from "../../../Utils/Utils";
import LoadingTransition from "Components/LoadingTransition/View";

function Contabilization() {
  const {
    state,
  }: {
    state: CategorizationStateArgs;
  } = useLocation<CategorizationStateArgs>();

  const style = useContabilizationStyle();
  const frameDrawerStyle = useFrameDrawerStyle();

  const presenter = useContabilizationPresenter(state);

  const closePanelRef = useRef<() => void>(() => {});

  const lockScreen = false as false | true;
  const heightOfFixContent = 282;

  return (
    <LoadingTransition>
      {(_changeLoading) => {
        presenter.changeLoading.current = _changeLoading;
        return (
          <>
            <div ref={presenter.divRef} css={style.wrapper}>
              <div css={style.blocksColumn}>
                <div css={style.blocksHead}>
                  <ButtonSquared onClick={() => presenter.history.goBack()}>
                    <ArrowBack />
                  </ButtonSquared>

                  <div css={style.accountingInfos}>
                    {presenter.clientName}
                    <TurquesaAvatar
                      ccss={style.avatar}
                      size={4}
                      src={presenter.clientPictureUrl}
                      nameToInitials={presenter.clientName}
                    />
                  </div>
                </div>
                <div css={style.separator} />
                <div css={[frameDrawerStyle.title, style.title]}>
                  Contabilizar blocos
                </div>
                <div css={style.selectDate}>
                  <FrameSelect
                    ccss={style.order}
                    options={presenter.datesSelect[presenter.yearSelected]}
                    value={presenter.monthSelected}
                    onSelect={presenter.setMonthSelected}
                    showLenghtOptions={2}
                    heightOfFixContent={heightOfFixContent}
                  />
                  <FrameSelect
                    ccss={style.year}
                    value={presenter.yearSelected}
                    onSelect={presenter.setYearSelected}
                    options={Object.keys(presenter.datesSelect).sort(monthSort)}
                    heightOfFixContent={heightOfFixContent}
                  />
                </div>
                <div css={[frameDrawerStyle.subtitle, style.subtitle]}>
                  Contas
                </div>
                <DisplayList
                  itens={presenter.sortedAccounts.map((e) => (
                    <ContabilizationBlockItem
                      selected={e.id === presenter.accountSelected}
                      onClick={() =>
                        presenter.setAccountSelected(
                          e.id === presenter.accountSelected ? "" : e.id
                        )
                      }
                      account={e}
                      number={presenter.blocksNumberByAccount(e)}
                      key={"ContabilizationBlock-" + e.id}
                    />
                  ))}
                  paddingTopHeight={2}
                  heightOfFixContent="252px"
                />
              </div>
              {presenter.accountSelected !== "" ? (
                <MainContabilization
                  link={presenter.link}
                  setAllBlocks={presenter.setAllBlocks}
                  uncontabilizedBlocks={presenter.currentBlocks.filter(
                    (e) => e.contabilizedDatetime === undefined
                  )}
                  contabilizedBlocks={presenter.currentBlocks
                    .filter((e) => e.contabilizedDatetime !== undefined)
                    .sort(
                      (a, b) =>
                        b.contabilizedDatetime!.valueOf() -
                        a.contabilizedDatetime!.valueOf()
                    )}
                  openHistory={(contabilizedBlocks) => {
                    presenter.setShowHistory(true);
                    presenter.setHistoryBlocks(contabilizedBlocks);
                    closePanelRef.current();
                  }}
                />
              ) : (
                <div css={style.noItemMessage}>
                  Selecione uma conta para começar
                </div>
              )}
              <QrPanel link={presenter.link} closePanelRef={closePanelRef} />
            </div>
            {lockScreen ? (
              <div css={style.lockScreen}>
                <div css={style.lockScreenHead}>
                  <ButtonSquared onClick={() => presenter.history.goBack()}>
                    <ArrowBack />
                  </ButtonSquared>
                  <ButtonSquared color="success">
                    <QrCode />
                  </ButtonSquared>
                </div>
                <div css={style.lockMessage}>
                  <LockOutlined css={style.lockIcon} />
                  Feche a página em seu smartphone para continuar usando o
                  computador.
                </div>
                <div css={style.placeholder} />
              </div>
            ) : undefined}
            <FrameDrawer
              drawerOpened={presenter.showHistory}
              onClose={() => presenter.setShowHistory(false)}
              title="Visualizar histórico"
            >
              <>
                <span css={style.historyDetails}>
                  Histórico de blocos contabilizados desta conta, no mês e ano
                  selecioandos.
                </span>
                <DisplayList
                  itens={(presenter.historyBlocks ?? []).map((e, index) => (
                    <ContabilizedItem
                      block={e}
                      setAllBlocks={presenter.setAllBlocks}
                      brighter
                      link={presenter.link}
                      key={"ContabilizedItem-" + index.toString()}
                    />
                  ))}
                  paddingTopHeight={2}
                  heightOfFixContent="24px"
                />
              </>
            </FrameDrawer>
          </>
        );
      }}
    </LoadingTransition>
  );
}

export default Contabilization;
