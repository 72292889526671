import useCustomTheme from "Theme/CustomTheme";
import { css } from "@emotion/react";
import CacheTheme from "Theme/CacheTheme";

var styleCached = false;
export function useTurquesaAvatarStyle() {
  const name = "TurquesaAvatarCSS";
  const theme = useCustomTheme();
  if (!styleCached) {
    const label = theme.extras.label(name);
    var _style = {
      avatar: (size: number) =>
        css({
          label: label("avatar"),
          width: theme.spacing(size),
          height: theme.spacing(size),
          color: "white",
          fontSize: `calc(${theme.spacing(size)} * 0.4)`,
          backgroundColor: theme.palette.info.main,
          transition: theme.extras.transition.fast([
            "width",
            "height",
            "font-size",
          ]),
        }),
      margin: css({
        label: label("margin"),
        marginLeft: theme.spacing(4),
        marginRight: theme.spacing(4),
      }),
    };
    CacheTheme(name, theme.palette.type, _style);
    styleCached = true;
  }
  var style = CacheTheme(name, theme.palette.type);
  return style as typeof _style;
}
