import { css } from "@emotion/react";
import CacheTheme from "Theme/CacheTheme";
import useCustomTheme from "Theme/CustomTheme";

var styleCached = false;
export function useResourcesItemStyle() {
  const name = "ResourcesItemCSS";
  const theme = useCustomTheme();
  if (!styleCached) {
    const label = theme.extras.label(name);
    var _style = {
      item: css({
        label: label("item"),
        padding: theme.spacing(4, 6),
        backgroundColor: "#e6e6e6",
        borderRadius: "24px",
        display: "flex",
        flexDirection: "column",
      }),
      icon: css({
        label: label("icon"),
        marginBottom: theme.spacing(3),
        width: theme.spacing(4),
        height: theme.spacing(4),
      }),
      title: css({
        label: label("title"),
        fontWeight: 700,
        fontSize: "20px",
        lineHeight: "25px",
        marginBottom: theme.spacing(3),
      }),
      description: css({
        label: label("description"),
        lineHeight: "20px",
      }),
    };
    CacheTheme(name, theme.palette.type, _style);
    styleCached = true;
  }
  var style = CacheTheme(name, theme.palette.type);
  return style as typeof _style;
}
