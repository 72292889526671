import { SharedOperatorAvatar } from "../../../../../Models/OperatorModel";
import { OperatorServices } from "../../../../../Services/Interfaces";

export const getSharedOperators = async (
  clientId: string,
  setSharedOperators: React.Dispatch<
    React.SetStateAction<SharedOperatorAvatar[] | undefined>
  >
) => setSharedOperators(await OperatorServices.getSharedOperators(clientId));

export const changeSharedOperator = OperatorServices.changeSharedOperator;
