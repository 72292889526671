import { css } from "@emotion/react";
import CacheTheme from "Theme/CacheTheme";
import useCustomTheme from "Theme/CustomTheme";

var styleCached = false;
export function useViewBlockPanelStyle() {
  const name = "ViewBlockPanelCSS";
  const theme = useCustomTheme();
  if (!styleCached) {
    const label = theme.extras.label(name);
    var _style = {
      panel: (fullScreen: boolean) =>
        css({
          label: label("panel"),
          width: theme.spacing(56),
          padding: theme.spacing(2, 4, 4, 4),
          borderRadius: theme.extras.borderRadius,
          backgroundColor: theme.palette.secondary.main,
          flexShrink: 0,
          marginRight: theme.spacing(2),
          height: fullScreen ? `calc(100% - ${theme.spacing(4)})` : undefined,
          margin: fullScreen ? theme.spacing(2) : undefined,
        }),
      dipslayIcon: css({
        label: label("dipslayIcon"),
        display: "flex",
      }),
      contabilizedTag: css({
        label: label("contabilizedTag"),
        backgroundColor: theme.palette.success.main,
        color: "white",
        fontWeight: "bold",
        borderRadius: theme.extras.borderRadius,
        fontSize: "14px",
        padding: theme.spacing(1, 3),
      }),
      iconMirror: css({
        label: label("iconMirror"),
        transform: "scaleX(-1)",
        color: "white",
      }),
      iconWarning: css({
        label: label("iconWarning"),
        color: theme.palette.warning.main,
      }),
      accountingName: css({
        label: label("accountingName"),
        color: "white",
        fontSize: "14px",
        marginRight: theme.spacing(2),
        alignItems: "center",
        display: "flex",
      }),
      headRow: css({
        label: label("headRow"),
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }),
      separator: css({
        label: label("separator"),
        backgroundColor: theme.palette.secondary.black(10),
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(4),
        minHeight: "1px",
        width: "100%",
      }),
      button: css({
        label: label("button"),
        marginTop: theme.spacing(6),
      }),
      time: theme.extras.transitionTime.fast,
    };
    CacheTheme(name, theme.palette.type, _style);
    styleCached = true;
  }
  var style = CacheTheme(name, theme.palette.type);
  return style as typeof _style;
}
