import { css } from "@emotion/react";
import CacheTheme from "Theme/CacheTheme";
import useCustomTheme from "Theme/CustomTheme";

var styleCached = false;
export function useRegisterStyle() {
  const name = "RegisterCSS";
  const theme = useCustomTheme();
  if (!styleCached) {
    const label = theme.extras.label(name);
    var _style = {
      container: css({
        label: label("container"),
        width: "100%",
        height: "100%",
        position: "absolute",
        backgroundColor: "white",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        overflow: "auto",
        scrollbarWidth: "thin",
      }),
      logo: css({
        label: label("logo"),
        position: "absolute",
        width: theme.spacing(16),
        right: theme.spacing(12),
        bottom: theme.spacing(6),
      }),
    };
    CacheTheme(name, theme.palette.type, _style);
    styleCached = true;
  }
  var style = CacheTheme(name, theme.palette.type);
  return style as typeof _style;
}
