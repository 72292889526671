/** @jsxImportSource @emotion/react */
import React, { useRef } from "react";
import OpacityTwoTimesTransition from "../OpacityTwoTimesTransition/View";
import { useLoadingTransitionStyle } from "./Style";

function LoadingTransition({
  children,
  transitionOff,
  window = false,
}: {
  children: (
    changeLoading: React.MutableRefObject<() => null>
  ) => React.ReactElement;
  transitionOff?: boolean;
  window?: boolean;
}) {
  const style = useLoadingTransitionStyle();

  const changeChildRef = useRef(() => null);

  return transitionOff ? (
    children(changeChildRef)
  ) : (
    <OpacityTwoTimesTransition
      firstChild={children(changeChildRef)}
      secondChild={
        <div css={style.bouncingLoader(window)}>
          <div></div>
          <div></div>
          <div></div>
        </div>
      }
      changeChildRef={changeChildRef}
    />
  );
}

export default LoadingTransition;
