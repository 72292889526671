/** @jsxImportSource @emotion/react */
import {
  ArrowBack,
  Close,
  ContentCopy,
  WarningAmber,
} from "@mui/icons-material";
import { useRef, useState } from "react";
import { BlockModel } from "../../../../Models/BlockModel";
import { PendencyModel } from "../../../../Models/PendencyModel";
import ButtonSquared from "../../../ButtonSquared/View";
import { CategorizationArgs } from "../../Model";
import CategorizationInfos from "./Components/CategorizationInfos/View";
import ViewBlockEntries from "./Components/ViewBlockEntries/View";
import EditBlockEntries from "./Components/EditBlockEntries/View";
import ShowBlockReport from "./Components/ShowBlockReport/View";
import { useViewBlockPanelStyle } from "./Style";
import ProfileEditButtons from "../../../Frame/Components/ProfileDrawer/Components/ProfileEditButtons/View";
import ButtonMain from "../../../ButtonMain/View";
import TurquesaAvatar from "../../../TurquesaAvatar/View";

function ViewBlockPanel({
  onBack,
  fullScreen = false,
  pendency,
  block,
  edit = false,
  view = false,
  categorizationArgs,
  isBusy = false,
}: {
  onBack: () => void;
  fullScreen?: boolean;
  pendency?: PendencyModel;
  block?: BlockModel | null;
  edit?: boolean;
  view?: boolean;
  categorizationArgs?: CategorizationArgs;
  isBusy?: boolean;
}) {
  const style = useViewBlockPanelStyle();

  const [disabledButton, setDisabledButton] = useState(false);
  const [onBackView, setOnBackView] = useState(false);
  const [showView, setShowView] = useState(true);
  const [showEdit, setShowEdit] = useState(false);

  const onSaveRef = useRef(() => new Promise<void>(() => null));

  const frameType = pendency
    ? "pendency"
    : block && block.accountId !== ""
    ? block.contabilizedDatetime
      ? "contabilized"
      : edit
      ? view
        ? "edit-view"
        : "edit"
      : "view"
    : "categorization";

  // Editar
  // > hiddenView, hiddenEdit, offBack -> Escondendo tudo a partir do estado inicial
  // hiddenView, showEdit, offBack -> Mostrando painel de edição sem permitir interação nos frameSelect
  // hiddenView, showEdit, onBack -> Permitindo interações de cancelar ou salvar
  // Cancelar/Salvar
  // > hiddenView, hiddenEdit, onBack ->  Voltando para esconder tudo
  // showView, hiddenEdit, onBack -> Mostrando botão de editar
  // showView, hiddenEdit, offBack -> Estado inicial

  return (
    <div css={style.panel(fullScreen)}>
      <div css={style.headRow}>
        <ButtonSquared onClick={onBack}>
          {frameType === "pendency" ||
          frameType === "edit" ||
          frameType === "contabilized" ? (
            <Close />
          ) : (
            <ArrowBack />
          )}
        </ButtonSquared>
        <div css={style.dipslayIcon}>
          {categorizationArgs ? (
            <div css={style.accountingName}>
              {categorizationArgs.accountingName}
            </div>
          ) : undefined}
          {frameType === "pendency" ? (
            <WarningAmber css={style.iconWarning} />
          ) : frameType === "edit" || frameType === "edit-view" ? (
            <ContentCopy css={style.iconMirror} />
          ) : frameType === "contabilized" ? (
            <div css={style.contabilizedTag}>Contabilizado</div>
          ) : (
            <TurquesaAvatar
              nameToInitials={categorizationArgs?.accountingName ?? ""}
              src={categorizationArgs?.logoUrl}
              size={4}
            />
          )}
        </div>
      </div>
      <div css={style.separator} />
      {frameType === "pendency" ? (
        <ShowBlockReport
          issue={pendency!.issue}
          observations={pendency?.observations}
        />
      ) : (
        <>
          {frameType === "categorization" ? (
            <CategorizationInfos
              operators={categorizationArgs!.operators}
              uncategorizedCount={categorizationArgs!.uncategorizedCount}
            />
          ) : undefined}
          {block !== null && block !== undefined ? (
            frameType === "edit" || frameType === "edit-view" ? (
              <>
                {showEdit || (!showEdit && !showView && onBackView) ? (
                  <EditBlockEntries
                    editBlock={block}
                    modal
                    setDisabledButton={setDisabledButton}
                    onSaveRef={onSaveRef}
                    show={showEdit}
                    noInteraction={!onBackView || !showEdit}
                  />
                ) : (
                  <ViewBlockEntries
                    block={block}
                    accountInLine
                    show={showView}
                    onBack={onBackView}
                  />
                )}
                <ProfileEditButtons
                  ccss={style.button}
                  onSave={() => {
                    onSaveRef.current();
                    return true;
                  }}
                  saveOrEditDisabled={(showEdit && disabledButton) || isBusy}
                  setEdit={() => {
                    if (showView) {
                      setShowView(false);
                      setTimeout(() => {
                        setShowEdit(true);
                        setTimeout(() => setOnBackView(true), style.time);
                      }, style.time);
                    } else {
                      setShowEdit(false);
                      setTimeout(() => {
                        setShowView(true);
                        setTimeout(() => setOnBackView(false), style.time / 2);
                      }, style.time);
                    }
                  }}
                />
              </>
            ) : frameType === "categorization" ? (
              <>
                <EditBlockEntries
                  editBlock={block}
                  setDisabledButton={setDisabledButton}
                  onSaveRef={onSaveRef}
                  categorize
                />
                <ButtonMain
                  disabled={disabledButton}
                  onClick={async () => {
                    await onSaveRef.current();
                    await categorizationArgs?.nextBlock();
                    return true;
                  }}
                  ccss={style.button}
                  color="primary"
                >
                  Salvar Bloco
                </ButtonMain>
              </>
            ) : (
              <ViewBlockEntries block={block} />
            )
          ) : (
            <>
              <ViewBlockEntries accountInLine />
              <ButtonMain disabled={true} ccss={style.button} color="primary">
                Salvar Bloco
              </ButtonMain>
            </>
          )}
        </>
      )}
    </div>
  );
}

export default ViewBlockPanel;
