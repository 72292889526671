/** @jsxImportSource @emotion/react */
import { Button } from "@mui/material";
import { useFrameStyle } from "../../../Components/Frame/Style";
import Frame from "../../../Components/Frame/View";
import FrameSelect from "../../../Components/FrameSelect/View";
import TextEntry from "../../../Components/TextEntry/View";
import TurquesaAvatar from "../../../Components/TurquesaAvatar/View";
import ClientDialog from "../../Accounting/Accounting/Components/ClientDialog/View";
import ShowAccountingsBlocks from "../../Accounting/Accounting/Components/ShowAccountingsBlocks/View";
import { useMainColumnStyle } from "../../Accounting/Accounting/Components/MainColumn/Style";
import SideBarHome from "./SideBarHome/View";
import LoadingTransition from "../../../Components/LoadingTransition/View";
import { EditHomeContext } from "./Model";
import ChangeAccountingPanel from "../../Accounting/Accounting/Components/ChangeAccountingPanel/View";
import ButtonSquared from "../../../Components/ButtonSquared/View";
import { GridView, ViewStreamOutlined } from "@mui/icons-material";
import { monthSort } from "../../../Utils/Utils";
import { selectItemByDate } from "../../../Hooks/ShowAccountingsBlocks/Presenter";
import { useHomePresenter } from "./Presenter";

function Home() {
  const accountingStyle = useMainColumnStyle();
  const frameStyle = useFrameStyle();
  const presenter = useHomePresenter();

  return (
    <LoadingTransition>
      {(_changeLoading) => {
        presenter.changeLoading.current = _changeLoading;
        return (
          <Frame
            clientState={presenter.clientState}
            operatorState={presenter.operatorState}
          >
            <>
              <SideBarHome
                pendencyNumber={presenter.pendencyNumber}
                operatorAvatar={presenter.operatorState[0]}
              />
              <div css={frameStyle.panel}>
                <div css={frameStyle.panelHead}>
                  <div css={frameStyle.panelHeadRow}>
                    <div css={accountingStyle.panelOptions(true)}>
                      <TurquesaAvatar
                        nameToInitials={presenter.clientState[0].person.name}
                        src={presenter.clientState[0].person.pictureUrl}
                        size={7}
                      />
                      <Button
                        onClick={() => presenter.setClientDialog(true)}
                        css={accountingStyle.clientTextButton}
                      >
                        <span css={frameStyle.panelHeadTitle}>
                          {presenter.clientState[0].person.name}
                        </span>
                      </Button>
                    </div>
                    <div css={accountingStyle.panelOptions(true)}>
                      <FrameSelect
                        ccss={accountingStyle.selectMonth}
                        value={presenter.monthSelected}
                        onSelect={presenter.setMonthSelected}
                        heightOfFixContent={112}
                        options={presenter.datesSelect[presenter.yearSelected]}
                      />
                      <div css={frameStyle.panelHeadSpacer} />
                      <FrameSelect
                        ccss={accountingStyle.selectYear}
                        value={presenter.yearSelected}
                        heightOfFixContent={112}
                        onSelect={presenter.setYearSelected}
                        options={Object.keys(presenter.datesSelect).sort(
                          monthSort
                        )}
                      />
                      <div css={frameStyle.panelHeadSpacer} />
                      <TextEntry
                        placeholder="Buscar conta"
                        ccss={frameStyle.textEntry}
                        searchIcon
                        notifyRef={presenter.notifyRef}
                      />
                      <div css={frameStyle.panelHeadSpacer} />
                      <ButtonSquared
                        onClick={() =>
                          presenter.changeAccountingPanelRef.current()
                        }
                      >
                        {presenter.showAccountings ? (
                          <ViewStreamOutlined />
                        ) : (
                          <GridView />
                        )}
                      </ButtonSquared>
                    </div>
                  </div>
                  <div css={frameStyle.panelSeparator} />
                </div>
                <EditHomeContext.Provider value={presenter.setAllBlocks}>
                  <div css={frameStyle.panelShower}>
                    <div css={frameStyle.panelContent}>
                      <ShowAccountingsBlocks
                        blocksByAccount={selectItemByDate(
                          presenter.yearSelected,
                          presenter.monthSelected,
                          presenter.keyedBlocks
                        )}
                        accounts={selectItemByDate(
                          presenter.yearSelected,
                          presenter.monthSelected,
                          presenter.keyedAccounts
                        )}
                        showAccountings={presenter.showAccountings}
                        notifyRef={presenter.notifyRef}
                        client
                      />
                    </div>
                  </div>
                </EditHomeContext.Provider>
              </div>
              <ClientDialog
                open={presenter.clientDialog}
                onClose={() => presenter.setClientDialog(false)}
                client={presenter.clientState[0]}
              />
              <ChangeAccountingPanel
                showAccountings={presenter.showAccountings}
                setShowAccountings={presenter.setShowAccountings}
                changeAccountingPanelRef={presenter.changeAccountingPanelRef}
                client
              />
            </>
          </Frame>
        );
      }}
    </LoadingTransition>
  );
}

export default Home;
