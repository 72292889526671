import { css } from "@emotion/react";
import CacheTheme from "Theme/CacheTheme";
import useCustomTheme from "Theme/CustomTheme";

var styleCached = false;
export function useFramePendencyItemStyle() {
  const name = "framePendencyItemCSS";
  const theme = useCustomTheme();
  if (!styleCached) {
    const label = theme.extras.label(name);
    var _style = {
      wrapper: css({
        label: label("wrapper"),
        display: "flex",
        alignItems: "center",
        marginTop: theme.spacing(-1),
        marginBottom: theme.spacing(-1),
        marginRight: theme.spacing(1),
        marginLeft: theme.spacing(-2),
        "> div": {
          marginRight: theme.spacing(2),
        },
      }),
      box: css({
        label: label("box"),
        borderRadius: theme.extras.borderRadius,
        backgroundColor: theme.palette.warning.main,
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        height: theme.spacing(5),
        width: theme.spacing(5),
        fontWeight: "bold",
      }),
    };
    CacheTheme(name, theme.palette.type, _style);
    styleCached = true;
  }
  var style = CacheTheme(name, theme.palette.type);
  return style as typeof _style;
}
