/** @jsxImportSource @emotion/react */

import { useFramePanelStyle } from "./Style";
import LogoBlintes from "../../Assets/logo_blintes.svg";
import TurquesaAvatar from "../TurquesaAvatar/View";
import React from "react";
import ButtonSquared from "../ButtonSquared/View";
import { ArrowBack } from "@mui/icons-material";
import DocumentViewer from "../FrameViewBlock/Components/DocumentViewer/View";
import { useHistory } from "react-router-dom";
import { OperatorAvatar } from "../../Models/OperatorModel";

function FramePanel({
  children,
  title,
  icon,
  documents,
  hiddenList,
  emptyMessage,
  operatorAvatar,
}: {
  children: React.ReactChild;
  title: string;
  icon: React.ReactChild;
  documents?: string[];
  hiddenList?: boolean;
  emptyMessage: string;
  operatorAvatar: OperatorAvatar;
}) {
  const style = useFramePanelStyle();
  const history = useHistory();

  return (
    <div css={style.wrapper}>
      <div>
        <div css={style.logoBar}>
          <img css={style.logo} src={LogoBlintes} alt="blints_logomarca" />
          <TurquesaAvatar
            nameToInitials={operatorAvatar.name}
            tooltip="Você"
            src={operatorAvatar?.pictureUrl}
            size={4}
          />
        </div>
        <div css={style.panel}>
          <div css={style.row}>
            <div>
              <ButtonSquared onClick={() => history.goBack()}>
                <ArrowBack />
              </ButtonSquared>
              <span css={style.title}>{title}</span>
            </div>
            {icon}
          </div>
          {children}
        </div>
      </div>
      <DocumentViewer
        minor
        hiddenList={hiddenList}
        documents={documents}
        emptyMessage={emptyMessage}
      />
    </div>
  );
}

export default FramePanel;
