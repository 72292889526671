/** @jsxImportSource @emotion/react */
import { useEffect, useRef } from "react";
import { TextEntryWithErrorState } from "../../../../../../Hooks/TextEntryWithError/Model";
import useTextEntryWithError from "../../../../../../Hooks/TextEntryWithError/View";
import TextEntry from "../../../../../TextEntry/View";
import TextEntryMasked from "../../../../../TextEntryMasked/View";
import { useFrameDrawerStyle } from "../../../FrameDrawer/Style";
import { useProfileTextEntryStyle } from "./Style";

function ProfileTextEntry({
  subtitle,
  initialValue,
  stateRef,
  password,
  editMode,
  padding,
  backgroundColor,
  cancelRef,
  patternMask,
}: {
  subtitle?: string;
  initialValue?: string;
  stateRef: React.MutableRefObject<TextEntryWithErrorState>;
  password?: boolean;
  editMode: boolean;
  padding?: boolean;
  backgroundColor?: boolean;
  cancelRef?: React.MutableRefObject<boolean>;
  patternMask?: string;
}) {
  const style = useProfileTextEntryStyle();
  const frameDrawerStyle = useFrameDrawerStyle();
  const [showError, textRef, setTextRef, error] = useTextEntryWithError(
    stateRef!
  );

  const setDisabledRef = useRef<React.Dispatch<React.SetStateAction<boolean>>>(
    () => null
  );

  useEffect(() => {
    setDisabledRef.current(!editMode);
    if (!editMode && cancelRef?.current) {
      stateRef.current.setState({
        text: initialValue ?? "",
        error: "",
      });
    }
    // eslint-disable-next-line
  }, [editMode]);

  const ccss = [
    style.textEntry,
    padding ? style.editPadding : undefined,
    backgroundColor ? style.editBackground : undefined,
    showError ? style.error : undefined,
  ];

  return (
    <>
      <div css={style.subtitle}>
        {subtitle}
        <span
          css={[
            frameDrawerStyle.subtitleErrorTransition,
            showError ? frameDrawerStyle.subtitleError : undefined,
          ]}
        >
          {error}
        </span>
      </div>
      {patternMask ? (
        <TextEntryMasked
          ccss={ccss}
          patternMask={patternMask}
          initialValue={initialValue}
          error={showError}
          textRef={textRef}
          setTextRef={setTextRef}
          setDisableRef={setDisabledRef}
          placeholder={"Digite aqui"}
          onChange={() => stateRef.current.setState({ error: "" })}
          password={password}
          profileTextEntry
        />
      ) : (
        <TextEntry
          ccss={ccss}
          initialValue={initialValue}
          textRef={textRef}
          setTextRef={setTextRef}
          error={showError}
          setDisableRef={setDisabledRef}
          placeholder={"Digite aqui"}
          onChange={() => stateRef.current.setState({ error: "" })}
          password={password}
          profileTextEntry
        />
      )}
    </>
  );
}

export default ProfileTextEntry;
