import { css } from "@emotion/react";
import CacheTheme from "Theme/CacheTheme";
import useCustomTheme from "Theme/CustomTheme";

var styleCached = false;
export function useOperatorItemStyle() {
  const name = "OperatorItemCSS";
  const theme = useCustomTheme();

  if (!styleCached) {
    const label = theme.extras.label(name);
    var _style = {
      operatorItem: css({
        label: label("operatorItem"),
        marginBottom: theme.spacing(2),
        display: "flex",
        color: "white",
        position: "relative",
      }),
      content: css({
        label: label("content"),
        position: "absolute",
        padding: theme.spacing(2),
        marginLeft: theme.spacing(9),
        width: theme.spacing(43),
        fontSize: "16px",
        height: "unset",
        justifyContent: "space-between",
        backgroundColor: theme.palette.secondary.main,
        ":hover": {
          backgroundColor: theme.palette.secondary.white(10),
        },
      }),
      selected: css({
        label: label("selected"),
        backgroundColor: theme.palette.secondary.white(10),
      }),
      display: css({
        label: label("display"),
        transition: theme.extras.transition.fast("height"),
        backgroundColor: theme.palette.secondary.white(10),
        borderRadius: theme.extras.borderRadius,
        padding: theme.spacing(2),
        marginLeft: theme.spacing(2),
        height: theme.spacing(7),
        display: "flex",
        alignItems: "start",
        lineHeight: "22px",
        fontSize: "14px",
        flexDirection: "column",
        overflow: "clip",
        width: "100%",
      }),
      buttonRow: css({
        label: label("buttonRow"),
        display: "flex",
        width: "100%",
        paddingTop: theme.spacing(3),
      }),
      buttonMargins: css({
        label: label("buttonMargins"),
        ":first-of-type": {
          marginRight: theme.spacing(1),
        },
        ":last-of-type": {
          marginLeft: theme.spacing(1),
        },
      }),
      lessOpacity: css({
        label: label("lessOpacity"),
        opacity: 0.25,
      }),
      becomeAdminIcon: css({
        label: label("becomeAdminIcon"),
        color: theme.palette.transparency.white(20),
      }),
      adminIcon: css({
        label: label("adminIcon"),
        color: theme.palette.alert.main,
      }),
      expanded: (client: boolean, isAdmin: boolean) =>
        css({
          label: label("expanded"),
          height:
            `calc(${theme.spacing(4)} + ` +
            (client
              ? isAdmin
                ? ` 225px)`
                : ` 148px)`
              : isAdmin
              ? ` 181px)`
              : ` 105px)`),
        }),
      separator: css({
        label: label("separator"),
        minHeight: "1px",
        width: "100%",
        marginTop: `calc(${theme.spacing(2)} + 24px)`,
        marginBottom: theme.spacing(2),
        backgroundColor: theme.palette.transparency.black(10),
      }),
      lineHeight: css({
        label: label("lineHeight"),
        lineHeight: "24px",
        textAlign: "start",
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
      }),
      galleryText: css({
        label: label("galleryText"),
        fontSize: "12px",
        margin: theme.spacing(0, -1, 0, 1),
      }),
      galleryDiv: css({
        label: label("galleryDiv"),
        display: "flex",
        alignItems: "center",
        marginTop: theme.spacing(1),
      }),
      gallerySwitch: css({
        label: label("gallerySwitch"),
        right: theme.spacing(-2),
      }),
    };
    CacheTheme(name, theme.palette.type, _style);
    styleCached = true;
  }
  var style = CacheTheme(name, theme.palette.type);
  return style as typeof _style;
}
