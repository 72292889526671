import { AccountingContact, Person } from "../../Models/AccountingModel";
import { OperatorProfile } from "../../Models/OperatorModel";
import {
  AccountingServices,
  ClientServices,
  OperatorServices,
} from "../../Services/Interfaces";

export const getOperatorProfile = async (
  operatorProfileRef: React.Dispatch<React.SetStateAction<OperatorProfile>>
) => operatorProfileRef(await OperatorServices.getOperatorProfile());

export const changeSharedTo = OperatorServices.changeSharedTo;
export const updateProfile = OperatorServices.updateProfile;
export const updatePicture = OperatorServices.updatePicture;
export const postClient = ClientServices.postClient;
export const postClientLogo = ClientServices.postClientLogo;
export const getAccountingContact = async (
  setAccountingContact: React.Dispatch<React.SetStateAction<AccountingContact>>
) => setAccountingContact(await AccountingServices.getAccountingContact());
export const getAccountingInfo = async (
  setAccountingInfo: React.Dispatch<React.SetStateAction<Person>>
) => setAccountingInfo(await AccountingServices.getAccountingInfo());
export const postAccountingInfo = AccountingServices.postAccountingInfo;
export const postAccountingLogo = AccountingServices.postAccountingLogo;
