/** @jsxImportSource @emotion/react */
import { useFramePendencyItemStyle } from "./Style";

function FramePendencyItem({
  number,
  month,
}: {
  number: number | [];
  month: string;
}) {
  const style = useFramePendencyItemStyle();
  return (
    <div css={style.wrapper}>
      <div css={style.box}>{Number(number)}</div>
      {month}
    </div>
  );
}

export default FramePendencyItem;
