/** @jsxImportSource @emotion/react */

import { ArrowBack } from "@mui/icons-material";
import ButtonSquared from "../../../Components/ButtonSquared/View";
import DisplayList from "../../../Components/DisplayList/View";
import { useFrameDrawerStyle } from "../../../Components/Frame/Components/FrameDrawer/Style";
import TurquesaAvatar from "../../../Components/TurquesaAvatar/View";
import { useContabilizationMobileStyle } from "../ContabilizationMobile/Style";
import { useEffect, useState } from "react";
import { mobileSettings } from "../../../Utils/Utils";
import { useContabilizationHistoryMobileStyle } from "./Style";
import { useHistory, useLocation } from "react-router-dom";
import ContabilizedItem from "../Contabilization/Components/ContabilizedItem/View";
import { BlockModel } from "Models/BlockModel";

function ContabilizationHistoryMobile() {
  const {
    state,
  }: {
    state: {
      contabilizedBlocks: BlockModel[];
      link: string;
      operatorName: string;
      photoUrl: string;
    };
  } = useLocation();

  const history = useHistory();
  const style = useContabilizationHistoryMobileStyle();
  const contabilizationMobileStyle = useContabilizationMobileStyle();
  const frameDrawerStyle = useFrameDrawerStyle();

  const [contabilizedBlocks, useContabilizedBlocks] = useState<
    BlockModel[] | undefined
  >(state.contabilizedBlocks);

  useEffect(mobileSettings, []);

  return (
    <div css={[contabilizationMobileStyle.wrapper, style.wrapper]}>
      <div css={contabilizationMobileStyle.innerWrapper}>
        <div css={contabilizationMobileStyle.headRow}>
          <ButtonSquared onClick={history.goBack}>
            <ArrowBack />
          </ButtonSquared>
          <div css={contabilizationMobileStyle.avatarInfo}>
            {state.operatorName}
            <TurquesaAvatar
              nameToInitials={state.operatorName}
              src={state.photoUrl}
              size={4}
            />
          </div>
        </div>
        <div css={contabilizationMobileStyle.separator} />
        <div css={[frameDrawerStyle.title, contabilizationMobileStyle.title]}>
          Visualizar histórico
        </div>
        <div
          css={[
            frameDrawerStyle.subtitle,
            contabilizationMobileStyle.normalWeight,
          ]}
        >
          Histórico de blocos contabilizados desta conta, no mês e ano
          selecionados.
        </div>
        <DisplayList
          itens={contabilizedBlocks!
            .filter((e) => e.contabilizedDatetime !== undefined)
            .map((block, index) => (
              <ContabilizedItem
                block={block}
                link={state.link}
                setAllBlocks={useContabilizedBlocks}
                key={"HistoryContabilizationBlock-" + index.toString()}
              />
            ))}
          heightOfFixContent={"203px"}
          paddingTopHeight={2}
        />
      </div>
    </div>
  );
}

export default ContabilizationHistoryMobile;
