/** @jsxImportSource @emotion/react */
import { Button } from "@mui/material";
import { useSelectPlataformButtonStyle } from "./Style";
import { ReactComponent as Logo } from "../../../../Assets/only_logo_blue.svg";
import { ArrowForward } from "@mui/icons-material";

function SelectPlataformButton({
  title,
  onClick,
}: {
  title: string;
  onClick: () => void;
}) {
  const style = useSelectPlataformButtonStyle();
  return (
    <Button onClick={onClick} css={style.selectPlataformButton}>
      <Logo />
      <div css={style.informations}>
        <span css={style.blintes}>Blintes</span>
        <span css={style.plataform}>{title}</span>
      </div>
      <ArrowForward css={style.forwardIcon} />
    </Button>
  );
}

export default SelectPlataformButton;
