import { css } from "@emotion/react";
import CacheTheme from "Theme/CacheTheme";
import useCustomTheme from "Theme/CustomTheme";

var styleCached = false;
export function useSideBarContabilizeIconStyle() {
  const name = "SidebarContabilizeIconCSS";
  const theme = useCustomTheme();
  if (!styleCached) {
    const label = theme.extras.label(name);
    var _style = {
      wrapper: (expanded: boolean, active: boolean) =>
        css({
          label: label("wrapper"),
          padding: theme.spacing(1.5),
          paddingRight: theme.spacing(0),
          borderRadius: theme.extras.borderRadius,
          backgroundColor: active
            ? theme.palette.success.main
            : theme.palette.background.white(20),
          transition: theme.extras.transition.fast([
            "margin-left",
            "background-color",
          ]),
          marginTop: theme.spacing(-7),
          marginLeft:
            expanded || !active ? theme.spacing(0) : theme.spacing(-4),
        }),
      avatarOperator: css({
        label: label("avatarOperator"),
        transition: theme.extras.transition.fast("opacity"),
        opacity: 1,
      }),
      hiddenAvatar: css({
        label: label("hiddenAvatar"),
        opacity: 0,
      }),
      noInteractions: css({
        label: label("noInteractions"),
        pointerEvents: "none",
      }),
    };
    CacheTheme(name, theme.palette.type, _style);
    styleCached = true;
  }
  var style = CacheTheme(name, theme.palette.type);
  return style as typeof _style;
}
