import { css } from "@emotion/react";
import CacheTheme from "Theme/CacheTheme";
import useCustomTheme from "Theme/CustomTheme";

var styleCached = false;
export function useBlockGridStyle() {
  const name = "BlockGridCSS";
  const theme = useCustomTheme();
  if (!styleCached) {
    const label = theme.extras.label(name);
    var _style = {
      block: css({
        label: label("block"),
        display: "flex",
        userSelect: "none",
        transition:
          theme.extras.transition.faster("background-color") +
          ", " +
          theme.extras.transition.fast("color"),
        padding: theme.spacing(2),
        borderRadius: theme.extras.borderRadius,
        width: "100%",
      }),
      activated: css({
        label: label("activated"),
        cursor: "pointer",
        ":hover": {
          backgroundColor: theme.palette.secondary.white(20),
        },
        color: "white",
      }),
      deactivated: css({
        label: label("deactivated"),
        color: theme.palette.secondary.white(20),
      }),
      icon: css({
        label: label("icon"),
        transition: theme.extras.transition.fast("color"),
        margin: theme.spacing(1, 2, 1, 0),
        transform: "scaleX(-1)",
      }),
      contabilizedIcon: css({
        label: label("contabilizedIcon"),
        color: theme.palette.success.main,
      }),
      deactivatedContabilizedIcon: css({
        label: label("deactivatedContabilizedIcon"),
        color: theme.palette.success.main + "33",
      }),
      column: css({
        label: label("column"),
        display: "flex",
        flexDirection: "column",
      }),
      date: css({
        label: label("date"),
        fontSize: "12px",
        transition: theme.extras.transition.fast("color"),
      }),
      dateActivated: css({
        label: label("dateActivated"),
        color: theme.palette.secondary.white(50),
      }),
      dateDeactivated: css({
        label: label("dateDeactivated"),
        color: theme.palette.secondary.white(20),
      }),
      value: css({
        label: label("value"),
        fontWeight: "bold",
        lineHeight: "22px",
      }),
      badge: css({
        label: label("badge"),
        display: "flex",
        "> .MuiBadge-badge": {
          left: theme.spacing(1.5),
          top: theme.spacing(1.5),
          opacity: 1,
          transition: theme.extras.transition.fast("opacity"),
          pointerEvents: "none",
        },
      }),
    };
    CacheTheme(name, theme.palette.type, _style);
    styleCached = true;
  }
  var style = CacheTheme(name, theme.palette.type);
  return style as typeof _style;
}
