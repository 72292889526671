import useLoadingTransition from "Hooks/LoadingTransition";
import { selectItemByDate } from "Hooks/ShowAccountingsBlocks/Presenter";
import useShowAccountingsBlocks from "Hooks/ShowAccountingsBlocks/View";
import { AccountModel, ClientAccountModel } from "Models/AccountModel";
import { BlockModel } from "Models/BlockModel";
import { ContabilizationServices } from "Services/Interfaces";
import { useEffect, useMemo, useState } from "react";
import { ContabilizedAccountSort } from "../Contabilization/Presenter";

export const useContabilizationMobilePresenter = () => {
  const [accountSelectedId, setAccountSelectedId] = useState(
    ContabilizationServices.contabilizationLinkState.accountSelected
  );
  const [accountSelected, setAccountSelected] = useState<ClientAccountModel>();

  const [allBlocks, setAllBlocks] = useState<BlockModel[]>();
  const [clientAccounts, setClientAccounts] = useState<ClientAccountModel[]>();

  const [clientName, setClientName] = useState("");
  const [clientPictureUrl, setClientPictureUrl] = useState<string>();
  const [clientId, setClientId] = useState("not-initialized");
  const [link, setLink] = useState("not-initialized");

  const [contabilizedBlocks, setContabilizedBlocks] = useState<BlockModel[]>(
    []
  );

  const [uncontabilizedBlocks, setUncontabilizedBlocks] = useState<
    BlockModel[]
  >([]);

  const [
    keyedBlocks,
    keyedAccounts,
    datesSelect,
    yearSelected,
    setYearSelected,
    monthSelected,
    setMonthSelected,
  ] = useShowAccountingsBlocks(
    allBlocks,
    clientAccounts,
    ContabilizationServices.contabilizationLinkState.monthSelected,
    ContabilizationServices.contabilizationLinkState.yearSelected
  );

  const [changeLoading] = useLoadingTransition(
    yearSelected !== "" && clientName !== ""
  );

  const blocksNumberByAccount = (e: AccountModel) => {
    const keyedList = selectItemByDate(
      yearSelected,
      monthSelected,
      keyedBlocks
    );
    try {
      return (keyedList[e.id] as BlockModel[]).filter(
        (e) => e.contabilizedDatetime === undefined
      ).length;
    } catch {
      return 0;
    }
  };

  const sortedAccounts = (
    selectItemByDate(
      yearSelected,
      monthSelected,
      keyedAccounts
    ) as ClientAccountModel[]
  ).sort((a, b) => ContabilizedAccountSort(a, b, blocksNumberByAccount));

  const currentBlocks = useMemo(
    () =>
      (selectItemByDate(yearSelected, monthSelected, keyedBlocks)[
        accountSelectedId
      ] ?? []) as BlockModel[],
    [yearSelected, monthSelected, keyedBlocks, accountSelectedId]
  );

  useEffect(() => {
    const _selectedAccount = sortedAccounts.filter(
      (e) => e.id === accountSelectedId
    );
    if (_selectedAccount) {
      setAccountSelected(
        sortedAccounts.filter((e) => e.id === accountSelectedId)[0]
      );
    } else {
      setAccountSelected(undefined);
    }
  }, [sortedAccounts, accountSelectedId]);

  useEffect(() => {
    if (clientId !== "not-initialized") {
      // contabilizeSyncJob(clientId, divRef);
    }
    //eslint-disable-next-line
  }, [clientId]);

  useEffect(() => {
    const _link = window.location.href.split("mobile/")[1];
    setLink(_link);
    ContabilizationServices.initContabilizationLink(_link).then((response) => {
      setClientId(response.id);
      setClientName(response.name);
      setClientPictureUrl(response.pictureUrl);
      setAllBlocks(response.categorizedBlocks);
      setClientAccounts(response.clientAccounts);
    });
  }, []);

  useEffect(() => {
    setContabilizedBlocks(
      currentBlocks
        .filter((e) => e.contabilizedDatetime !== undefined)
        .sort(
          (a, b) =>
            b.contabilizedDatetime!.valueOf() -
            a.contabilizedDatetime!.valueOf()
        )
    );
    setUncontabilizedBlocks(
      currentBlocks
        .filter((e) => e.contabilizedDatetime === undefined)
        .sort(
          (a, b) =>
            b.contabilizedDatetime!.valueOf() -
            a.contabilizedDatetime!.valueOf()
        )
    );
  }, [currentBlocks]);

  useEffect(() => {
    if (
      ContabilizationServices.contabilizationLinkState.yearSelected !==
        yearSelected ||
      monthSelected !==
        ContabilizationServices.contabilizationLinkState.monthSelected
    ) {
      setAccountSelectedId("");
      ContabilizationServices.contabilizationLinkState.accountSelected = "";
    }
    if (monthSelected !== "") {
      ContabilizationServices.contabilizationLinkState.monthSelected =
        monthSelected;
    }
    if (yearSelected !== "") {
      ContabilizationServices.contabilizationLinkState.yearSelected =
        yearSelected;
    }
  }, [yearSelected, monthSelected]);

  return {
    changeLoading,
    datesSelect,
    yearSelected,
    monthSelected,
    setMonthSelected,
    setYearSelected,
    sortedAccounts,
    accountSelected,
    blocksNumberByAccount,
    setAllBlocks,
    operatorName: clientName,
    operatorPhotoUrl: clientPictureUrl,
    contabilizedBlocks,
    uncontabilizedBlocks,
    link,
  };
};
