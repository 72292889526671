import { callPromiseWrapper } from "../../Utils/Login";
import { IDocumentServices } from "../Interfaces";

class DocumentServicesImpl implements IDocumentServices {
  async getBlockDocuments(blockId: string) {
    const result = (
      await callPromiseWrapper("/api/document/block", {
        blockId: blockId,
      })
    ).data;
    result.documentsUrl = JSON.parse(result["documentsUrl"]);
    return result;
  }

  async getLibraries() {
    return (await callPromiseWrapper("/api/library/all")).data;
  }

  async getDocuments(operatorId: string) {
    return (
      await callPromiseWrapper("/api/library/documents", {
        operatorId: operatorId,
      })
    ).data;
  }

  async getDevolveds() {
    return (await callPromiseWrapper("/api/document/devolved")).data;
  }

  async removeDocument(documentId: string) {
    return await callPromiseWrapper("/api/document/remove", {
      documentId: documentId,
    });
  }

  async devolvedRestore(documentId: string) {
    return await callPromiseWrapper("/api/document/devolved/restore", {
      documentId: documentId,
    });
  }
}

export default DocumentServicesImpl;
