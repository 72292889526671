import { css } from "@emotion/react";
import CacheTheme from "Theme/CacheTheme";
import useCustomTheme from "Theme/CustomTheme";

var styleCached = false;
export function useDocumentViewerStyle() {
  const name = "DocumentViewerCSS";
  const theme = useCustomTheme();
  const documentBorderWidth = 3;
  if (!styleCached) {
    const label = theme.extras.label(name);
    var _style = {
      viewLeft: (documentNumber: number) =>
        css({
          label: label("viewLeft"),
          display: "flex",
          flexDirection: "column",
          flexShrink: 0,
          margin: theme.spacing(2, 2),
          overflow: "scroll",
          justifyContent: documentNumber >= 5 ? "start" : "center",
        }),
      document: (image: string) =>
        css({
          label: label("document"),
          backgroundPosition: "center",
          margin: theme.spacing(1, 0),
          width: `calc(${theme.spacing(11)} - ${documentBorderWidth * 2}px)`,
          height: theme.spacing(16),
          transition: theme.extras.transition.faster("border"),
          border: `3px solid ${theme.palette.background.main}`,
          borderRadius: theme.extras.borderRadius,
          backgroundImage: `url(data:image/jpeg;base64,${image})`,
          // backgroundImage: `url(${image})`,
          backgroundSize: "cover",
          flexShrink: 0,
          cursor: "pointer",
          ":hover": {
            border: `${documentBorderWidth}px solid ${theme.palette.background.white(
              30
            )}`,
          },
          ":first-of-type": {
            marginTop: theme.spacing(0),
          },
          ":last-of-type": {
            marginBottom: theme.spacing(0),
          },
        }),
      documentSelected: css({
        label: label("documentSelected"),
        border: `${documentBorderWidth}px solid ${theme.palette.primary.main}`,
        ":hover": {
          border: `${documentBorderWidth}px solid ${theme.palette.primary.main}`,
        },
      }),
      viewer: (hiddenList: boolean, minor: boolean) =>
        css({
          label: label("viewer"),
          display: "flex",
          marginTop: minor ? undefined : theme.spacing(2),
          height: minor ? undefined : `calc(100% - ${theme.spacing(4)})`,
          width:
            "calc(100% - " +
            (minor ? (hiddenList ? "944px" : "970px") : "522px") +
            ")",
          flexShrink: 0,
        }),
      container: (hiddenList: boolean, minor: boolean) =>
        css({
          label: label("container"),
          overflow: "hidden",
          flexShrink: 0,
          height: "100%",
          width:
            "calc(100% - 120px" + (minor && hiddenList ? "" : " - 88px") + ")",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          margin: theme.spacing(0, 2),
        }),
      containerWihtoutShrink: css({
        label: label("containerWihtoutShrink"),
        height: "100%",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: theme.palette.secondary.white(40),
        fontSize: "18px",
      }),
      relativeContainer: (
        imgScale: number,
        posX: number,
        posY: number,
        allowDragging: boolean,
        isDragging: boolean
      ) =>
        css({
          label: label("relativeContainer"),
          display: "flex",
          position: "relative",
          height: "max-content",
          width: "max-content",
          transition: `${
            isDragging
              ? ""
              : "transform 0.20s ease, top 0.20s ease, left 0.20s ease"
          }`,
          transform: `scale(${imgScale})`,
          top: `${posY}px`,
          left: `${posX}px`,
          cursor: `${
            allowDragging ? (isDragging ? "grabbing" : "grab") : "unset"
          }`,
        }),
      moveDocument: css({
        label: label("moveDocument"),
        position: "relative",
        left: "0",
        drag: "none",
        height: "100%",
        display: "flex",
        flexGrow: 1,
        webkitUserDrag: "none",
        khtmlUserDrag: "none",
        mozUserDrag: "none",
        OUserDrag: "none",
        userDrag: "none",
        pointerEvents: "none",
        flexBasis: "content",
        justifyContent: "center",
        overflow: "hidden",
        webkitTouchCallout: "none",
        webkitUserSelect: "none",
        khtmlUserSelect: "none",
        mozUserSelect: "none",
        msUserSelect: "none",
        userSelect: "none",
      }),
      viewRight: css({
        label: label("viewRight"),
        display: "flex",
        flexDirection: "column",
        height: "100%",
        justifyContent: "space-between",
        padding: theme.spacing(1, 2),
      }),
      groupButtons: css({
        label: label("groupButtons"),
        display: "flex",
        flexDirection: "column",
        height: "144px",
        flexShrink: 0,
      }),
      placeholder: css({
        label: label("placeholder"),
        height: theme.spacing(7),
      }),
      separator: css({
        label: label("separator"),
        height: theme.spacing(1),
      }),
      emphasis: css({
        label: label("emphasis"),
        fontWeight: "bold",
      }),
      margins: css({
        label: label("margins"),
        margin: theme.spacing(2, 0),
      }),
    };
    CacheTheme(name, theme.palette.type, _style);
    styleCached = true;
  }
  var style = CacheTheme(name, theme.palette.type);
  return style as typeof _style;
}
