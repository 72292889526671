/** @jsxImportSource @emotion/react */

import { ErrorOutline } from "@mui/icons-material";
import { Dialog } from "@mui/material";
import ButtonMain from "../ButtonMain/View";
import { useFrameDialogStyle } from "./Style";

function FrameDialog({
  open,
  onClose,
  content,
  title = "Atenção",
  color = "warning",
  onConfirm,
  onCancel,
}: {
  open: boolean;
  onClose: () => void;
  content: React.ReactChild;
  title?: string;
  color?: "primary" | "secondary" | "warning" | "neutralWarning";
  onConfirm?: () => void;
  onCancel?: () => void;
}) {
  const style = useFrameDialogStyle();

  const colorStyle =
    color === "primary"
      ? style.primaryColor
      : color === "secondary" || color === "neutralWarning"
      ? style.secondaryColor
      : style.warningColor;

  const colorButtonStyle =
    color === "primary"
      ? "primaryWhite10"
      : color === "secondary"
      ? "secondaryWhite10"
      : color === "neutralWarning"
      ? "warning"
      : "warningWhite10";

  return (
    <Dialog css={[style.wrapper, colorStyle]} onClose={onClose} open={open}>
      <div css={style.head}>
        <span css={style.emphasis}>{title}</span>
        <ErrorOutline />
      </div>
      <div css={style.separator} />
      {content}
      <div css={style.buttonRow}>
        <ButtonMain
          onClick={() => {
            onCancel?.apply(undefined, []);
            onClose();
          }}
          color={colorButtonStyle}
        >
          Cancelar
        </ButtonMain>
        <div css={style.buttonSeparator} />
        <ButtonMain
          onClick={() => {
            onConfirm?.apply(undefined, []);
            onClose();
          }}
          color={
            color === "neutralWarning"
              ? "secondarySuccessHover"
              : colorButtonStyle
          }
        >
          Continuar
        </ButtonMain>
      </div>
    </Dialog>
  );
}

export default FrameDialog;
