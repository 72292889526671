import { css } from "@emotion/react";
import CacheTheme from "Theme/CacheTheme";
import useCustomTheme from "Theme/CustomTheme";

var styleCached = false;
export function useAddClientDrawerStyle() {
  const name = "AddClientDrawerCSS";
  const theme = useCustomTheme();
  if (!styleCached) {
    const label = theme.extras.label(name);
    var _style = {
      subtitle: css({
        label: label("subtitle"),
        marginTop: theme.spacing(3),
      }),
      subtitle2: css({
        label: label("subtitle2"),
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(1),
      }),
      item: css({
        label: label("item"),
        color: "white",
        display: "flex",
        alignItems: "center",
        backgroundColor: theme.palette.secondary.white(10),
        borderRadius: theme.extras.borderRadius,
        padding: theme.spacing(1),
        paddingLeft: theme.spacing(2),
        marginBottom: theme.spacing(1),
      }),
      name: css({
        label: label("name"),
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(3),
        width: "100%",
      }),
    };
    CacheTheme(name, theme.palette.type, _style);
    styleCached = true;
  }
  var style = CacheTheme(name, theme.palette.type);
  return style as typeof _style;
}
