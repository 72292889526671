/** @jsxImportSource @emotion/react */

import { Close, HelpOutline } from "@mui/icons-material";
import { Button } from "@mui/material";
import { useFrameDrawerStyle } from "../../../../Components/Frame/Components/FrameDrawer/Style";
import FrameSelect from "../../../../Components/FrameSelect/View";
import OverlapPanel from "../../../../Components/OverlapPanel/View";
import ButtonSquared from "../../../../Components/ButtonSquared/View";
import TextEntry from "../../../../Components/TextEntry/View";
import { useReportDialogStyle } from "./Style";
import { useRef, useState } from "react";
import { createPendency } from "../Presenter";
import { stringToIssue } from "../../../../Utils/Utils";

function ReportDialog({
  showReportDialogRef,
  nextBlock,
}: {
  showReportDialogRef: React.MutableRefObject<() => void>;
  nextBlock: () => Promise<void>;
}) {
  const style = useReportDialogStyle();
  const frameDrawerStyle = useFrameDrawerStyle();

  const [value, setValue] = useState("Baixa qualidade das fotos/documentos");

  const textRef = useRef<string>("");
  const setTextRef = useRef<React.Dispatch<React.SetStateAction<string>>>(
    () => null
  );

  return (
    <OverlapPanel showPanelRef={showReportDialogRef}>
      {(openedShowPanel, setOpenedShowPanel) => (
        <div css={style.panel}>
          <div css={style.headRow}>
            <ButtonSquared
              onClick={
                openedShowPanel ? () => setOpenedShowPanel(false) : undefined
              }
            >
              <Close />
            </ButtonSquared>
            <HelpOutline css={style.icon} />
          </div>
          <div css={style.separator} />
          <div css={frameDrawerStyle.title}>Reportar problema</div>
          <div css={[frameDrawerStyle.subtitle, style.subtitle]}>
            O que aconteceu?
          </div>
          <FrameSelect
            color="darker"
            value={value}
            onSelect={setValue}
            options={[
              "Baixa qualidade das fotos/documentos",
              "Documentos não compatíveis",
              "Bloco incompleto",
            ]}
          />
          <div css={[frameDrawerStyle.subtitle, style.subtitle]}>
            Observação
          </div>
          <TextEntry
            textRef={textRef}
            setTextRef={setTextRef}
            placeholder="Nenhuma observação"
            lineNumber={4}
          />
          <Button
            onClick={async () => {
              await createPendency({
                issue: stringToIssue(value),
                observations: textRef.current,
              });
              await nextBlock();
              showReportDialogRef.current();
              setTimeout(() => {
                setValue("Baixa qualidade das fotos/documentos");
                setTextRef.current("");
              }, style.time);
            }}
            css={style.reportButton}
            fullWidth
          >
            Enviar
          </Button>
        </div>
      )}
    </OverlapPanel>
  );
}

export default ReportDialog;
