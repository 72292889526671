import { css } from "@emotion/react";
import CacheTheme from "Theme/CacheTheme";
import useCustomTheme from "Theme/CustomTheme";

var styleCached = false;
export function useViewBlockAutocompleteStyle() {
  const name = "ViewBlockAutocompleteCSS";
  const theme = useCustomTheme();
  if (!styleCached) {
    const label = theme.extras.label(name);
    var _style = {
      autocomplete: css({
        label: label("autocomplete"),
        marginBottom: theme.spacing(4),
        " > div > .MuiAutocomplete-endAdornment": {
          marginRight: `calc(${theme.spacing(1)} - 1px)`,
        },
        " > div > .MuiAutocomplete-endAdornment > .MuiButtonBase-root": {
          color: theme.palette.background.white(30),
        },
      }),
      noOptions: css({
        label: label("noOptions"),
        color: "white",
        padding: theme.spacing(1, 3),
      }),
      optionCode: css({
        label: label("optionCode"),
        fontSize: "12px",
        color: theme.palette.transparency.white(75),
      }),
      papper: css({
        label: label("papper"),
        borderRadius: theme.extras.borderRadius,
        color: "white",
        boxShadow: "none",
        "> .MuiAutocomplete-listbox > li > ul > .MuiAutocomplete-option": {
          alignItems: "start",
          flexDirection: "column",
          backgroundColor: theme.palette.background.white(10),
          padding: theme.spacing(1, 3),
          "&.Mui-focused": {
            backgroundColor: theme.palette.background.white(20),
          },
        },
        '> .MuiAutocomplete-listbox > li > ul > .MuiAutocomplete-option[aria-selected="true"]':
          {
            backgroundColor: theme.palette.primary.main,
            "&.Mui-focused": {
              backgroundColor: theme.palette.primary.main,
            },
          },
        "> .MuiAutocomplete-listbox": {
          padding: "0px",
          scrollbarWidth: "thin",
          //Configurações do Chrome
          "&::-webkit-scrollbar": {
            display: "block",
            width: "8px",
          },
          "&::-webkit-scrollbar-track": {
            background: theme.palette.secondary.white(10),
          },
          "&::-webkit-scrollbar-thumb": {
            background: theme.palette.secondary.white(40),
            outline: "0px",
          },
          "&::-webkit-scrollbar-thumb:hover": {
            background: theme.palette.secondary.white(30),
            outline: "0px",
          },
          "&::-webkit-scrollbar-thumb:active": {
            background: theme.palette.secondary.white(20),
            outline: "0px",
          },
        },
      }),
    };
    CacheTheme(name, theme.palette.type, _style);
    styleCached = true;
  }
  var style = CacheTheme(name, theme.palette.type);
  return style as typeof _style;
}
