import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import useLoadingTransition from "../../../../../Hooks/LoadingTransition";
import { OperatorAvatar } from "../../../../../Models/OperatorModel";
import { OperatorServices } from "../../../../../Services/Interfaces";
import { AccountingListContext } from "../../Model";

export function useDrawerTransferPresenter(
  time: number,
  notifyRef: React.MutableRefObject<
    React.Dispatch<React.SetStateAction<string>>
  >
) {
  const history = useHistory();
  const [transferConfirm, setTransferConfirm] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [showContent, setShowContent] = useState(false);
  const [searchText, setSearchText] = useState("");

  const [selected, setSelected] = useState(-1);

  const [activated, setActivated] = useState(false);
  const [animationTransition, setAnimationTransition] = useState(false);

  const [operators, setOperators] = useState<OperatorAvatar[]>();

  const [changeLoading] = useLoadingTransition(operators !== undefined);
  const { clientId } = useContext(AccountingListContext);

  notifyRef.current = setSearchText;

  useEffect(() => {
    getTransferOperators(clientId, setOperators);
  }, [clientId]);

  const isSelected = (index: number) => selected === index;
  const noSelection = selected === -1;
  const onSelect = (index: number) => {
    const newSelect = isSelected(index) ? -1 : index;
    setSelected(newSelect);
    var fastTransition = false;
    if (activated && newSelect === -1) {
      setActivated(false);
      fastTransition = true;
    } else if (!activated && newSelect !== -1) {
      setActivated(true);
      fastTransition = true;
    }

    if (fastTransition) {
      setAnimationTransition(true);
      setTimeout(() => setAnimationTransition(false), time);
    }
  };

  const operatorSelectedFullname =
    operators && !noSelection ? operators[selected].name : "";

  const onCancelConfirm = () => {
    setExpanded(false);
    setTimeout(() => {
      setTransferConfirm(false);
      setShowContent(false);
    }, time);
  };

  const onConfirm = async () => {
    await OperatorServices.changeTransferOperator(
      clientId,
      operators && !noSelection ? operators[selected].id : ""
    );
    history.goBack();
  };

  const onExpand = !expanded
    ? () => {
        setTransferConfirm(true);
        setTimeout(() => {
          setExpanded(true);
          setShowContent(true);
        }, time);
      }
    : undefined;

  return {
    changeLoading,
    expanded,
    showContent,
    transferConfirm,
    operators,
    isSelected,
    onSelect,
    noSelection,
    selected,
    operatorSelectedFullname,
    onCancelConfirm,
    onConfirm,
    animationTransition,
    activated,
    onExpand,
    searchText,
  };
}

const getTransferOperators = async (
  clientId: string,
  setOperators: React.Dispatch<
    React.SetStateAction<OperatorAvatar[] | undefined>
  >
) => setOperators(await OperatorServices.getTransferOperators(clientId));
