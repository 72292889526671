import { css } from "@emotion/react";
import CacheTheme from "Theme/CacheTheme";
import useCustomTheme from "Theme/CustomTheme";

var styleCached = false;
export function useBlockSendStyle() {
  const name = "BlockSendCSS";
  const theme = useCustomTheme();
  if (!styleCached) {
    const label = theme.extras.label(name);
    var _style = {
      icon: css({
        label: label("icon"),
        color: "white",
        userSelect: "none",
      }),
      separator: css({
        label: label("separator"),
        backgroundColor: theme.palette.secondary.black(10),
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(3),
        minHeight: "1px",
        width: "100%",
      }),
      description: css({
        label: label("description"),
        color: theme.palette.secondary.white(40),
        marginBottom: theme.spacing(3),
      }),
      operationRow: css({
        label: label("operationRow"),
        display: "flex",
        width: "100%",
        justifyContent: "space-between",
      }),
      subtitle: css({
        label: label("subtitle"),
        color: theme.palette.secondary.white(40),
        fontWeight: "bold",
      }),
      selectMargin: css({
        label: label("selectMargin"),
        marginBottom: theme.spacing(2),
      }),
      dateSelection: css({
        label: label("dateSelection"),
        display: "flex",
        width: theme.spacing(38),
      }),
      order: css({
        label: label("order"),
        width: theme.spacing(20),
      }),
      year: css({
        label: label("year"),
        width: theme.spacing(16),
        marginLeft: theme.spacing(2),
      }),
      operatorItem: css({
        label: label("operatorItem"),
      }),
      fullWidth: css({
        label: label("fullWidth"),
        width: "100%",
        marginTop: theme.spacing(2),
      }),
      displaySentBlocks: css({
        label: label("displaySentBlocks"),
        display: "grid",
        gridTemplateColumns: "repeat(6, 1fr)",
        gridGap: theme.spacing(2),
        marginBottom: theme.spacing(4),
      }),
      noItems: css({
        label: label("noItems"),
        color: theme.palette.secondary.white(40),
        margin: "auto",
        paddingBottom: "256px",
      }),
    };
    CacheTheme(name, theme.palette.type, _style);
    styleCached = true;
  }
  var style = CacheTheme(name, theme.palette.type);
  return style as typeof _style;
}
