import { css } from "@emotion/react";
import CacheTheme from "Theme/CacheTheme";
import useCustomTheme from "Theme/CustomTheme";

var styleCached = false;
export function useHowWorksItemStyle() {
  const name = "HowWorksItemCSS";
  const theme = useCustomTheme();
  if (!styleCached) {
    const label = theme.extras.label(name);
    var _style = {
      imagem: css({
        label: label("imagem"),
        width: theme.spacing(20),
        height: theme.spacing(15),
        margin: "auto",
        marginBottom: theme.spacing(-6),
        display: "flex",
        position: "relative",
      }),
      item: css({
        label: label("item"),
        border: "3px solid transparent",
        background:
          "linear-gradient(white, white) padding-box, linear-gradient(-45deg, #B3D3FF, #E6E6E6) border-box",
        padding: theme.spacing(8, 6),
        borderRadius: "24px",
        textAlign: "center",
        lineHeight: "25px",
        fontSize: "20px",
      }),
    };
    CacheTheme(name, theme.palette.type, _style);
    styleCached = true;
  }
  var style = CacheTheme(name, theme.palette.type);
  return style as typeof _style;
}
