import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import useLoadingTransition from "../../../Hooks/LoadingTransition";
import { KeyedDates } from "../../../Hooks/ShowAccountingsBlocks/Model";
import { BlockModel } from "../../../Models/BlockModel";
import { OperatorAvatar } from "../../../Models/OperatorModel";
import { BlockServices, OperatorServices } from "../../../Services/Interfaces";
import { extractDates, organizeDates } from "../../../Utils/Dates";
import { blockSort } from "../../../Utils/Utils";
import FrameOperatorItem from "./Components/FrameOperatorItem/View";
import { KeyedDatesSentBlockModel } from "./Model";

export function useBlockSendPresenter() {
  const { state: operatorAvatar } = useLocation<OperatorAvatar>();

  const [sentItems, setSentItems] = useState<BlockModel[]>([]);
  const [organizedSentBlocks, setOrganizedSentBlocks] =
    useState<KeyedDatesSentBlockModel>({});
  const [selected, setSelected] = useState("");
  const [datesSelect, setDatesSelect] = useState<KeyedDates>({});
  const [documents, setDocuments] = useState<string[]>();
  const [yearSelected, setYearSelected] = useState("");
  const [monthSelected, setMonthSelected] = useState("");
  const [operators, setOperators] = useState<OperatorAvatar[]>([]);
  const [operatorSelectedId, setOperatorSelectedId] = useState("");

  const [changeLoading] = useLoadingTransition(
    yearSelected !== "" && monthSelected !== "" && operatorSelectedId !== ""
  );

  const operatorsOptions = operators.map((e, _) => ({
    value: <FrameOperatorItem operatorAvatar={e} />,
    key: e.id,
  }));

  useEffect(() => {
    getOperatorsSent(setOperators, setOperatorSelectedId, operatorAvatar.id);
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    const result = organizeSentBlocks(sentItems);
    extractDates(result, setYearSelected, setMonthSelected, setDatesSelect);
    setOrganizedSentBlocks(result);
  }, [sentItems]);

  useEffect(() => {
    getBlockSent(operatorSelectedId, setSentItems);
  }, [operatorSelectedId]);

  const itemNumber = (length?: number) =>
    length !== undefined ? (length < 3 ? length : 3) : 0;

  const emptyMessage =
    Object.keys(sentItems).length === 0 ? "" : "Selecione um bloco";

  const currentOperator = operatorsOptions.find(
    (e) => e.key === operatorSelectedId
  ) ?? { value: <div />, key: "" };

  const operatorShowLenghtOptions =
    operatorsOptions.length < 7 ? operatorsOptions.length : 7;

  const isSelected = (index: number, index2: number) =>
    selected === index.toString() + "_" + index2.toString();

  const onUndo = async (id: string) => {
    await BlockServices.sentUndo(id);
    setSentItems(sentItems.filter((e) => e.id !== id));
  };

  const onSelect = (
    index: number,
    index2: number,
    isSelected: boolean,
    block: BlockModel
  ) => {
    setSelected(isSelected ? "" : index.toString() + "_" + index2.toString());
    setDocuments(isSelected ? undefined : block.documentsUrl);
  };

  return {
    changeLoading,
    documents,
    operatorAvatar,
    emptyMessage,
    operatorsOptions,
    currentOperator,
    setOperatorSelectedId,
    operatorShowLenghtOptions,
    monthSelected,
    setMonthSelected,
    yearSelected,
    setYearSelected,
    datesSelect,
    itemNumber,
    organizedSentBlocks,
    isSelected,
    onUndo,
    onSelect,
  };
}

const getBlockSent = async (
  operatorId: string,
  setSentItems: React.Dispatch<React.SetStateAction<BlockModel[]>>
) => {
  const result = await BlockServices.getSentBlocks(operatorId);
  setSentItems(result);
};

function organizeSentBlocks(blocks: BlockModel[]) {
  const keyedBlocks = organizeDates(
    blocks.map((e) => e.date),
    false
  ) as KeyedDatesSentBlockModel;

  for (const year in keyedBlocks) {
    for (const month in keyedBlocks[year]) {
      for (const block of blocks) {
        const day = block.date.getDate().toString();
        if (!Object.keys(keyedBlocks[year][month]).includes(day)) {
          keyedBlocks[year][month][day] = [block];
        } else {
          keyedBlocks[year][month][day].push(block);
        }
      }

      for (const day in keyedBlocks[year][month]) {
        keyedBlocks[year][month][day].sort(blockSort);
      }
    }
  }

  return keyedBlocks;
}

const getOperatorsSent = async (
  setOperatorOptions: React.Dispatch<React.SetStateAction<OperatorAvatar[]>>,
  setOperatorSelectedId: React.Dispatch<React.SetStateAction<string>>,
  operatorId: string
) => {
  const result = await OperatorServices.operatorsSent();
  const operators = [] as OperatorAvatar[];
  for (const operator of result) {
    const name =
      operator.name.length > 25
        ? operator.name.slice(0, 25) + "..."
        : operator.name;
    operators.push({
      name: operator.id === operatorId ? "Você" : name,
      id: operator.id,
      pictureUrl: operator.pictureUrl,
    });
  }
  setOperatorSelectedId(operators[0].id);
  setOperatorOptions(operators);
};
