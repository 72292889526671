import { Person } from "Models/AccountingModel";
import { callPromiseWrapper } from "../../Utils/Login";
import { IAccountingServices } from "../Interfaces";

class AccountingServicesImpl implements IAccountingServices {
  async postAccountingLogo(base64url: string) {
    return callPromiseWrapper("/api/accounting/logo", { base64url: base64url });
  }

  async postAccountingInfo(accounting: Person) {
    return await callPromiseWrapper("/api/accounting/info", {
      accounting: accounting,
    });
  }

  async getAccountingInfo() {
    return (await callPromiseWrapper("/api/accounting/info")).data;
  }

  async getAccountingContact() {
    return (await callPromiseWrapper("/api/accounting/contact")).data;
  }

  async listInvited() {
    return (await callPromiseWrapper("/api/invited/list")).data;
  }

  async addInvited(email: string) {
    return callPromiseWrapper("/api/invited/add", { email: email });
  }

  async removeInvited(inviteId: string) {
    return callPromiseWrapper("/api/invited/remove", { inviteId: inviteId });
  }
}

export default AccountingServicesImpl;
