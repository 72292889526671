import { useContext, useEffect, useState } from "react";
import { devolveBlock } from "../../../../../Components/FrameViewBlock/Presenter";
import { ClientAccountModel } from "../../../../../Models/AccountModel";
import { BlockModel } from "../../../../../Models/BlockModel";
import { accountFilter } from "../../../../../Utils/Utils";
import { EditHomeContext } from "../../../../Client/Home/Model";

export function useShowAccountingBlocksPresenter(
  acccounts: ClientAccountModel[],
  showAccountings: boolean,
  blocksByAccount: { [accountId: string]: BlockModel[] },
  notifyRef?: React.MutableRefObject<
    React.Dispatch<React.SetStateAction<string>>
  >
) {
  const [detailsBlock, setDetailsBlock] = useState<BlockModel>();
  const [openDetails, setOpenDetails] = useState(false);
  const [selected, setSelected] = useState(-1);
  const [blockList, setBlockList] = useState<BlockModel[]>([]);
  const [searchText, setSearchText] = useState("");

  const editHomeContxt = useContext(EditHomeContext);

  useEffect(() => {
    // Necessário montar logo por causa do painel variável da contabilidade
    var blist = [] as BlockModel[];
    Object.keys(blocksByAccount).forEach(
      (accountId) => (blist = blist.concat(blocksByAccount[accountId]))
    );
    setBlockList(blist);
  }, [showAccountings, blocksByAccount]);

  if (notifyRef) {
    notifyRef.current = setSearchText;
  }

  const filtedAccountings = acccounts.filter((e) =>
    accountFilter(e, searchText)
  );

  const isSelected = (index: number) => selected === index;

  const onSelect = (index: number) =>
    selected === index ? setSelected(-1) : setSelected(index);

  const onDevolve =
    detailsBlock?.contabilizedDatetime === undefined
      ? async () => {
          await devolveBlock(detailsBlock!.id);
          editHomeContxt((list) =>
            list?.filter((e) => e.id !== detailsBlock!.id)
          );
          setOpenDetails(false);
        }
      : undefined;

  return {
    setSelected,
    setDetailsBlock,
    setOpenDetails,
    filtedAccountings,
    isSelected,
    onSelect,
    blockList,
    openDetails,
    detailsBlock,
    onDevolve,
  };
}
