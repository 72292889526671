import { css } from "@emotion/react";
import CacheTheme from "Theme/CacheTheme";
import useCustomTheme from "Theme/CustomTheme";

var styleCached = false;
export function useStepCarouselStyle() {
  const name = "StepCarouselCSS";
  const theme = useCustomTheme();
  if (!styleCached) {
    const label = theme.extras.label(name);
    var _style = {
      title: css({
        label: label("title"),
        marginTop: theme.spacing(1),
        fontSize: "24px",
        fontWeight: "700",
      }),
      wrapper: css({
        label: label("wrapper"),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }),
      button: css({
        label: label("button"),
        fontWeight: 700,
        ":hover": {
          transition: "background-color 300ms",
        },
      }),
      primary: css({
        label: label("primary"),
        backgroundColor: theme.palette.primary.main,
        color: "white",
        fontSize: "20px",
        padding: theme.spacing(4),
        width: theme.spacing(47),
        ":hover": {
          transition: "background-color 300ms",
          backgroundColor: theme.palette.primary.white(10),
        },
      }),
      white: css({
        label: label("white"),
        backgroundColor: "white",
        color: "black",
        fontSize: "14px",
        padding: theme.spacing(3, 8),
        marginTop: theme.spacing(2),
        ":hover": {
          transition: "background-color 300ms",
          backgroundColor: "#e6e6e6",
        },
      }),
      buttonPlaceholder: css({
        label: label("buttonPlaceholder"),
        marginTop: theme.spacing(2),
        height: theme.spacing(7),
      }),
    };
    CacheTheme(name, theme.palette.type, _style);
    styleCached = true;
  }
  var style = CacheTheme(name, theme.palette.type);
  return style as typeof _style;
}
