import { css } from "@emotion/react";
import CacheTheme from "Theme/CacheTheme";
import useCustomTheme from "Theme/CustomTheme";

var styleCached = false;
export function useFramePanelStyle() {
  const name = "FramePanelCSS";
  const theme = useCustomTheme();
  const minHeight = "900px";
  const width = "928px";
  if (!styleCached) {
    const label = theme.extras.label(name);
    var _style = {
      logo: css({
        label: label("logo"),
        width: theme.spacing(10),
      }),
      logoBar: css({
        label: label("logoBar"),
        display: "flex",
        justifyContent: "space-between",
        width: width,
        borderTopLeftRadius: theme.extras.borderRadius,
        borderTopRightRadius: theme.extras.borderRadius,
        backgroundColor: theme.palette.secondary.white(10),
        padding: theme.spacing(2, 4),
      }),
      wrapper: css({
        label: label("wrapper"),
        display: "flex",
        minHeight: minHeight,
        height: "100vh",
        padding: theme.spacing(2),
      }),
      panel: css({
        label: label("panel"),
        display: "flex",
        flexDirection: "column",
        backgroundColor: theme.palette.secondary.main,
        borderBottomLeftRadius: theme.extras.borderRadius,
        borderBottomRightRadius: theme.extras.borderRadius,
        padding: theme.spacing(2, 4),
        marginRight: theme.spacing(2),
        alignItems: "center",
        height:
          "max(calc(100vh - " +
          theme.spacing(4) +
          " - 64px), " +
          minHeight +
          " - 32px - 64px)",
        width: width,
        flexShrink: 0,
      }),
      row: css({
        label: label("row"),
        display: "flex",
        width: "100%",
        alignItems: "center",
        justifyContent: "space-between",
      }),
      title: css({
        label: label("title"),
        color: "white",
        fontSize: "18px",
        fontWeight: "bold",
        marginLeft: theme.spacing(4),
      }),
    };
    CacheTheme(name, theme.palette.type, _style);
    styleCached = true;
  }
  var style = CacheTheme(name, theme.palette.type);
  return style as typeof _style;
}
