import { css } from "@emotion/react";
import CacheTheme from "Theme/CacheTheme";
import useCustomTheme from "Theme/CustomTheme";

var styleCached = false;
export function useSelectPlataformButtonStyle() {
  const name = "SelectPlataformButtonCSS";
  const theme = useCustomTheme();
  if (!styleCached) {
    const label = theme.extras.label(name);
    var _style = {
      selectPlataformButton: css({
        label: label("selectPlataformButton"),
        display: "flex",
        justifyContent: "start",
        gap: "16px",
        width: "100%",
        height: "84px",
        padding: "24px",
        border: "2px solid " + theme.palette.transparency.black(5),
        transition: "border 250ms",
        ":hover": {
          border: "2px solid " + theme.palette.primary.main,
          backgroundColor: "white",
          "> svg": {
            right: "24px",
            opacity: 1,
          },
        },
      }),
      informations: css({
        label: label("informations"),
        display: "flex",
        flexDirection: "column",
        alignItems: "start",
      }),
      blintes: css({
        label: label("blintes"),
        color: theme.palette.transparency.black(20),
        fontWeight: "400",
        lineHeight: "13px",
        marginTop: "4px",
      }),
      plataform: css({
        label: label("plataform"),
        color: theme.palette.primary.main,
        fontWeight: "900",
        fontSize: "20px",
        lineHeight: "25px",
      }),
      forwardIcon: css({
        label: label("forwardIcon"),
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.transparency.black(5),
        borderRadius: "12px",
        height: "40px",
        width: "40px",
        padding: "8px",
        position: "absolute",
        right: "0px",
        opacity: 0,
        transition: "right 250ms, opacity 250ms",
      }),
    };
    CacheTheme(name, theme.palette.type, _style);
    styleCached = true;
  }
  var style = CacheTheme(name, theme.palette.type);
  return style as typeof _style;
}
