import { css } from "@emotion/react";
import CacheTheme from "Theme/CacheTheme";
import useCustomTheme from "Theme/CustomTheme";

var styleCached = false;
export function useContactCardStyle() {
  const name = "ContactCardCSS";
  const theme = useCustomTheme();
  if (!styleCached) {
    const label = theme.extras.label(name);
    var _style = {
      wrapper: css({
        label: label("wrapper"),
        borderRadius: theme.extras.borderRadius,
        backgroundColor: theme.palette.secondary.white(10),
        marginTop: theme.spacing(3),
        display: "flex",
        width: "100%",
      }),
      infoColumn: css({
        label: label("infoColumn"),
        display: "flex",
        flexDirection: "column",
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(2),
        marginBottom: theme.spacing(3),
      }),
      title: css({
        label: label("title"),
        fontWeight: "bold",
      }),
    };
    CacheTheme(name, theme.palette.type, _style);
    styleCached = true;
  }
  var style = CacheTheme(name, theme.palette.type);
  return style as typeof _style;
}
