/** @jsxImportSource @emotion/react */
import { Close, InfoOutlined, QrCode } from "@mui/icons-material";
import { QRCodeSVG } from "qrcode.react";
import ButtonSquared from "../../../../../Components/ButtonSquared/View";
import OpacityTransition from "../../../../../Components/OpacityTransition/View";
import useContentExpansion from "../../../../../Hooks/ContentExpansion";
import { useQrPanelStyle } from "./Style";

function QrPanel({
  closePanelRef,
  link,
}: {
  closePanelRef: React.MutableRefObject<() => void>;
  link: string;
}) {
  const style = useQrPanelStyle();

  const [showContent, expanded, setExpanded] = useContentExpansion();
  closePanelRef.current = () => setExpanded(false);

  return (
    <div css={[style.qrPanel, expanded ? style.expanded : undefined]}>
      <ButtonSquared
        color={expanded ? "primaryWhite10" : "primary"}
        onClick={() => setExpanded(!expanded)}
      >
        <OpacityTransition
          value={expanded}
          childFalse={(ccss) => <QrCode css={ccss} />}
          childTrue={(ccss) => <Close css={ccss} />}
        />
      </ButtonSquared>
      {showContent ? (
        <div css={[style.content, expanded ? undefined : style.hidden]}>
          <QRCodeSVG
            fgColor="white"
            bgColor="transparent"
            css={style.qrSymbol}
            value={window.location.href + "/mobile/" + link}
          />
          <div css={style.title}>
            Você pode usar esta função pelo seu smartphone!
          </div>
          <div css={style.description}>
            Escaneie o QrCode com a câmera do seu celular e continue usando esta
            função na palma da sua mão.
          </div>
          <div css={style.info}>
            <InfoOutlined />
            <div css={style.textInfo}>
              Para sua segurança, você só pode acessar esta função uma vez a
              cada escaneamento. Se você fechar ou atualizar a página no seu
              celular, será preciso voltar aqui e escanear este Qr Code
              novamente.
            </div>
          </div>
        </div>
      ) : undefined}
    </div>
  );
}

export default QrPanel;
