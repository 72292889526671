/** @jsxImportSource @emotion/react */
import { AccountingContact } from "../../../../../../Models/AccountingModel";
import { useFrameDrawerStyle } from "../../../FrameDrawer/Style";
import ProfileExpansivePanel from "../ProfileExpansivePanel/View";
import ContactCard from "./ContactCard/View";
import { useContactPanelStyle } from "./Style";

function ContactPanel({
  acocuntingContact,
}: {
  acocuntingContact: AccountingContact;
}) {
  const style = useContactPanelStyle();
  const frameDrawerStyle = useFrameDrawerStyle();

  return (
    <ProfileExpansivePanel title="Contato" height="304px">
      <>
        <div css={[frameDrawerStyle.subtitle, style.subtitle]}>Operador</div>
        <ContactCard
          name={acocuntingContact.operator.name}
          email={acocuntingContact.operator.email}
          phone={acocuntingContact.operator.phone}
          photoUrl={acocuntingContact.operator.photoUrl}
        />
        <div css={[frameDrawerStyle.subtitle, style.subtitle]}>Escritório</div>
        <ContactCard
          name={acocuntingContact.accounting.name}
          email={acocuntingContact.accounting.email}
          phone={acocuntingContact.accounting.phone}
          photoUrl={acocuntingContact.accounting.photoUrl}
        />
      </>
    </ProfileExpansivePanel>
  );
}

export default ContactPanel;
