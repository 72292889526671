import { useCallback, useEffect, useRef, useState } from "react";
import useContentExpansion from "../../../../../Hooks/ContentExpansion";
import { AccountingDrawerOptions } from "./Model";

export function useAccountingDrawerPresenter(
  choosedDrawerRef: React.MutableRefObject<
    (choice: AccountingDrawerOptions) => void
  >
) {
  const [choosed, setChoosed] = useState<AccountingDrawerOptions>("none");
  const [showDrawer, expanded, setShowerDrawer] = useContentExpansion();
  const [searchTitle, setSearchTitle] = useState("");
  const [drawerBigSize, setDrawerBigSize] = useState(false);

  const notifyRef = useRef<React.Dispatch<React.SetStateAction<string>>>(
    () => null
  );

  const setDisabledSearchRef = useRef<
    React.Dispatch<React.SetStateAction<boolean>>
  >(() => null);

  const chooseCallback = useCallback(
    (choice: AccountingDrawerOptions) => {
      setChoosed(choice);
      switch (choice) {
        case "shared":
          setSearchTitle("Buscar cliente");
          setDrawerBigSize(false);
          break;
        case "accountingPlan":
          setSearchTitle("Buscar conta ativa");
          setDrawerBigSize(true);
          break;
        case "transfer":
          setSearchTitle("Buscar cliente");
          setDrawerBigSize(false);
          break;
        default:
          break;
      }
      if (choice !== "none") {
        setShowerDrawer(true);
      }
    },
    [setShowerDrawer]
  );

  choosedDrawerRef.current = chooseCallback;

  useEffect(() => {
    if (!showDrawer) {
      setChoosed("none");
    }
  }, [showDrawer]);

  return {
    expanded,
    setShowerDrawer,
    notifyRef,
    searchTitle,
    setDisabledSearchRef,
    drawerBigSize,
    showDrawer,
    choosed,
  };
}
