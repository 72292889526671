import { CreatePendencyModel, PendencyModel } from "../../Models/PendencyModel";
import { SelectedDocumentModel } from "../../Pages/Client/BlockCreate/Model";
import { callPromiseWrapper } from "../../Utils/Login";
import { IPendencyServices } from "../Interfaces";

class PendencyServicesImpl implements IPendencyServices {
  async getPendencyList(clientId: string) {
    const result = (
      await callPromiseWrapper("/api/pendency/list", { clientId: clientId })
    ).data as PendencyModel[];
    result.forEach((e) => {
      e.date = new Date(e.date);
      e.documentsUrl = JSON.parse(e.documentsUrl as any);
    });
    return result;
  }

  async getToSolve(next: boolean) {
    return (await callPromiseWrapper("/api/pendency/tosolve", { next: next }))
      .data;
  }

  async solvePendency(
    documents: SelectedDocumentModel[],
    removedDocuments: string[]
  ) {
    const data = [] as any[];
    for (const i in documents) {
      data.push({
        id: documents[i].id,
        base64url: documents[i].base64url,
      });
    }
    return await callPromiseWrapper("/api/pendency/solve", {
      documents: data,
      removedDocuments: removedDocuments,
    });
  }

  async createPendency(createPendencyArgs: CreatePendencyModel) {
    return callPromiseWrapper("/api/pendency/create", {
      ...createPendencyArgs,
    });
  }
}

export default PendencyServicesImpl;
