import { css } from "@emotion/react";
import CacheTheme from "Theme/CacheTheme";
import useCustomTheme from "Theme/CustomTheme";

var styleCached = false;
export function useQrPanelStyle() {
  const name = "QrPanelCSS";
  const theme = useCustomTheme();
  if (!styleCached) {
    const label = theme.extras.label(name);
    var _style = {
      qrPanel: css({
        label: label("qrPanel"),
        display: "flex",
        flexDirection: "column",
        padding: theme.spacing(4), //2
        transition: theme.extras.transition.fast([
          "background-color",
          "width",
          "padding",
        ]),
        width: theme.spacing(15), //11
        height: "100vh",
        overflow: "clip",
      }),
      expanded: css({
        label: label("expanded"),
        width: theme.spacing(40),
        padding: theme.spacing(4), //2, 4
        backgroundColor: theme.palette.primary.main,
      }),
      content: css({
        label: label("content"),
        transition: theme.extras.transition.fast("opacity"),
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        flexShrink: 0,
        width: theme.spacing(32),
        overflow: "scroll",
        height: `calc(100vh - ${theme.spacing(9)})`,
      }),
      hidden: css({
        label: label("hidden"),
        opacity: 0,
      }),
      qrSymbol: css({
        label: label("qrSymbol"),
        color: "white",
        width: theme.spacing(18),
        height: theme.spacing(18),
        padding: theme.spacing(2),
        marginTop: theme.spacing(8),
        marginBottom: theme.spacing(4),
        border: "2px white solid",
        borderRadius: theme.extras.borderRadius,
        display: "flex",
        flexShrink: 0,
      }),
      title: css({
        label: label("title"),
        fontSize: "18px",
        lineHeight: "20px",
        fontWeight: "bold",
        color: "white",
      }),
      description: css({
        label: label("description"),
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4),
        lineHeight: "18px",
        color: "white",
      }),
      info: css({
        label: label("info"),
        borderRadius: theme.extras.borderRadius,
        backgroundColor: theme.palette.primary.white(10),
        color: "white",
        padding: theme.spacing(2, 2, 3, 2),
        marginBottom: theme.spacing(2),
      }),
      textInfo: css({
        label: label("textInfo"),
        fontSize: "12px",
      }),
    };
    CacheTheme(name, theme.palette.type, _style);
    styleCached = true;
  }
  var style = CacheTheme(name, theme.palette.type);
  return style as typeof _style;
}
