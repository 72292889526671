import { ProfileArgs } from "../../Models/OperatorModel";
import { callPromiseWrapper } from "../../Utils/Login";
import { IOperatorServices } from "../Interfaces";

class OperatorServicesImpl implements IOperatorServices {
  async changeAdmin(operatorId: string) {
    return await callPromiseWrapper("/api/operator/change/admin", {
      operatorId: operatorId,
    });
  }

  async changePassword(currentPassword: string, newPassword: string) {
    return (
      await callPromiseWrapper("/api/operator/changePassword", {
        currentPassword: currentPassword,
        newPassword: newPassword,
      })
    ).data;
  }

  async operatorChangeBlock(operatorId: string) {
    return callPromiseWrapper("/api/operator/block/change", {
      operatorId: operatorId,
    });
  }

  async removeOperatorAccounting(operatorId: string) {
    return callPromiseWrapper("/api/operator/remove/accounting", {
      operatorId: operatorId,
    });
  }

  async removeOperatorClient(operatorId: string) {
    return callPromiseWrapper("/api/operator/remove/client", {
      operatorId: operatorId,
    });
  }

  async pendencySync() {
    return (await callPromiseWrapper("/api/operator/sync/pendency")).data;
  }

  async pendencyUnsync() {
    return callPromiseWrapper("/api/operator/unsync/pendency");
  }

  async blockSync(clientId: string) {
    return (
      await callPromiseWrapper("/api/operator/sync/block", {
        clientId: clientId,
      })
    ).data;
  }

  async blockUnsync() {
    return callPromiseWrapper("/api/operator/unsync/block");
  }

  async operatorsSent() {
    return (await callPromiseWrapper("/api/operator/sent/get")).data;
  }

  async getOperatorProfile() {
    return (await callPromiseWrapper("/api/operator/profile")).data;
  }

  async getOperatorClientList() {
    return (await callPromiseWrapper("/api/operator/list/client")).data;
  }

  async getOperatorAccountingList() {
    return (await callPromiseWrapper("/api/operator/list/accounting")).data;
  }

  async changeSharedTo(operatorId: string) {
    return callPromiseWrapper("/api/operator/change/sharedto", {
      operatorId: operatorId,
    });
  }

  async updateProfile({ name, code, email, phone }: ProfileArgs) {
    return callPromiseWrapper("/api/operator/profile", {
      name: name,
      code: code,
      email: email,
      phone: phone,
    });
  }

  async updatePicture(base64url: string, type: "accounting" | "client") {
    return callPromiseWrapper("/api/operator/update/photo", {
      type: type,
      base64url: base64url,
    });
  }

  async getSharedOperators(clientId: string) {
    return (
      await callPromiseWrapper("/api/operator/shared", {
        clientId: clientId,
      })
    ).data;
  }

  async changeSharedOperator(clientId: string, accountingOperatorId: string) {
    return callPromiseWrapper("/api/operator/shared/change", {
      clientId: clientId,
      accountingOperatorId: accountingOperatorId,
    });
  }

  async getTransferOperators(clientId: string) {
    return (
      await callPromiseWrapper("/api/operator/transfer", {
        clientId: clientId,
      })
    ).data;
  }

  async changeTransferOperator(clientId: string, accountingOperatorId: string) {
    return callPromiseWrapper("/api/operator/transfer/change", {
      clientId: clientId,
      accountingOperatorId: accountingOperatorId,
    });
  }
}

export default OperatorServicesImpl;
