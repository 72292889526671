import { css } from "@emotion/react";
import CacheTheme from "Theme/CacheTheme";
import useCustomTheme from "Theme/CustomTheme";

var styleCached = false;
export function useOperatorDrawerStyle() {
  const name = "OperatorDrawerStyleCSS";
  const theme = useCustomTheme();
  if (!styleCached) {
    const label = theme.extras.label(name);
    var _style = {
      item: css({
        label: label("item"),
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        borderRadius: theme.extras.borderRadius,
        marginBottom: theme.spacing(1),
        backgroundColor: theme.palette.secondary.white(10),
        color: "white",
        padding: theme.spacing(1, 1, 1, 2),
      }),
      subtitle: css({
        label: label("subtitle"),
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(0),
      }),
      dipslay: css({
        label: label("dipslay"),
        display: "flex",
      }),
      email: css({
        label: label("email"),
        marginLeft: theme.spacing(2),
      }),
      placeholder: css({
        label: label("placeholder"),
        width: theme.spacing(7),
        height: theme.spacing(7),
      }),
      time: theme.extras.transitionTime.fast,
    };
    CacheTheme(name, theme.palette.type, _style);
    styleCached = true;
  }
  var style = CacheTheme(name, theme.palette.type);
  return style as typeof _style;
}
