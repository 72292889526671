/** @jsxImportSource @emotion/react */

import { Dialog } from "@mui/material";
import { useContext } from "react";
import FrameViewBlock from "../../../../../Components/FrameViewBlock/View";
import { BlockModel } from "../../../../../Models/BlockModel";
import { PendencyModel } from "../../../../../Models/PendencyModel";
import { IsBusyContext } from "../../Model";
import { useMainColumnStyle } from "../MainColumn/Style";

function DialogViewBlock({
  openDetails,
  setOpenDetails,
  devolveBlock,
  block,
  pendency,
  edit,
  view,
}: {
  openDetails: boolean;
  setOpenDetails: React.Dispatch<React.SetStateAction<boolean>>;
  block?: BlockModel;
  pendency?: PendencyModel;
  edit?: boolean;
  view?: boolean;
  devolveBlock?: () => void;
}) {
  const accountingStyle = useMainColumnStyle();
  const isBusy = useContext(IsBusyContext);

  return (
    <Dialog
      css={accountingStyle.paper}
      open={openDetails}
      onClose={() => setOpenDetails(false)}
    >
      <FrameViewBlock
        onBack={() => setOpenDetails(false)}
        block={block}
        pendency={pendency}
        edit={edit}
        view={view}
        isBusy={isBusy}
        devolveBlock={devolveBlock}
      />
    </Dialog>
  );
}

export default DialogViewBlock;
