/** @jsxImportSource @emotion/react */
import { SerializedStyles } from "@emotion/react";
import { ExpandMore } from "@mui/icons-material";
import ClickAwayListener from "react-advanced-click-away";
import useId from "@mui/material/utils/useId";
import { useEffect, useState } from "react";
import { useFrameSelectStyle } from "./Style";
import { FrameColorOptions, FrameSelectItem } from "./Model";

function FrameSelect({
  value,
  options,
  ccss,
  onSelect,
  heightOfFixContent,
  showLenghtOptions,
  hiddenSelected,
  color = "normal",
}: // brighter = false,
// darker = false,
// dark = false,
// primary = false,
{
  value: string | FrameSelectItem;
  onSelect?: (value: string) => void;
  options: string[] | FrameSelectItem[];
  ccss?: SerializedStyles | (SerializedStyles | undefined)[];
  heightOfFixContent?: number;
  showLenghtOptions?: number;
  hiddenSelected?: boolean;
  color?: FrameColorOptions;
  // brighter?: boolean;
  // darker?: boolean;
  // dark?: boolean;
  // primary?: boolean;
}) {
  const lenght = hiddenSelected ? options.length - 1 : options.length;
  const _showLenghtOptions = showLenghtOptions
    ? Math.min(showLenghtOptions ?? lenght, lenght)
    : undefined;
  const style = useFrameSelectStyle();
  const [expanded, setExpanded] = useState(false);
  const [currentScreenHeight, setCurrentScreenHeight] = useState(0);
  const id = useId();

  useEffect(() => {
    if (!showLenghtOptions) {
      const event = () => setCurrentScreenHeight(window.innerHeight);
      event();
      window.addEventListener("resize", event);
      return () => window.removeEventListener("resize", event);
    }
    // eslint-disable-next-line
  }, []);

  const isString = typeof value === "string";
  const showIcon = isString || lenght > 0;

  return (
    <ClickAwayListener
      onClickAway={() => (expanded ? setExpanded(false) : null)}
    >
      <div
        css={[
          ccss,
          style.positionFrame,
          showIcon ? undefined : style.noInteractions,
        ]}
      >
        <div
          onClick={() => setExpanded(!expanded)}
          css={[
            style.frameSelect(color),
            expanded
              ? style.frameSelectExpanded(
                  currentScreenHeight,
                  color,
                  lenght,
                  heightOfFixContent,
                  _showLenghtOptions
                )
              : style.frameSelectNotExpanded(color),
          ]}
        >
          <div css={[style.item, style.display]}>
            {isString ? value : value.value}
            {showIcon ? (
              <ExpandMore
                css={[style.icon(color), expanded ? style.iconRotated : null]}
              />
            ) : undefined}
          </div>
          <div
            css={style.options(
              currentScreenHeight,
              heightOfFixContent,
              _showLenghtOptions,
              lenght
            )}
          >
            <div css={style.separator} />
            {options.map((_value, index) => {
              var isSelected: boolean;
              var _keyValue = isString
                ? (_value as string)
                : (_value as FrameSelectItem).key;
              var keyValue = isString
                ? (value as string)
                : (value as FrameSelectItem).key;
              isSelected = _keyValue === keyValue;
              return hiddenSelected && isSelected ? undefined : (
                <div
                  onClick={() => onSelect?.call(null, _keyValue)}
                  key={"SelectOptions(" + id + ")-" + index.toString()}
                  css={[
                    style.item,
                    isSelected
                      ? style.itemSelected(color)
                      : style.itemNotSelected(color),
                  ]}
                >
                  {isString ? _value : (_value as FrameSelectItem).value}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </ClickAwayListener>
  );
}

export default FrameSelect;
