import { BlockServices, DocumentServices } from "../../Services/Interfaces";
import { OperatorActions } from "./Model";

export const getClientBlocks = async (
  blockId: string,
  setDocuments: React.Dispatch<React.SetStateAction<string[] | undefined>>,
  setOperatorActions: React.Dispatch<React.SetStateAction<OperatorActions>>
) => {
  const result = await DocumentServices.getBlockDocuments(blockId);
  setDocuments(result.documentsUrl);
  setOperatorActions({
    categorizedBy: result.categorizedBy,
    contabilizedBy: result.contabilizedBy,
    categorizedPhotoUrl: result.categorizedPhotoUrl,
    contabilziedPhotoUrl: result.contabilizedPhotoUrl,
    alsoContabilize: result.alsoContabilize,
  });
};

export const setBlockViewed = BlockServices.setViewdBlocks;
export const saveBlock = BlockServices.saveBlock;
export const devolveBlock = BlockServices.devolveBlock;
