import { css } from "@emotion/react";
import CacheTheme from "Theme/CacheTheme";
import useCustomTheme from "Theme/CustomTheme";

var styleCached = false;
export function useMainColumnStyle() {
  const name = "MainColumnCSS";
  const theme = useCustomTheme();
  if (!styleCached) {
    const label = theme.extras.label(name);
    var _style = {
      sidePanel: css({
        label: label("sidePanel"),
        display: "flex",
        flexDirection: "column",
        height: "min-content",
        padding: theme.spacing(2),
        backgroundColor: theme.palette.secondary.white(10),
        borderRadius: theme.extras.borderRadius,
        borderTopLeftRadius: "0px",
        borderBottomLeftRadius: "0px",
      }),
      clientTextButton: css({
        label: label("clientTextButton"),
        marginLeft: theme.spacing(2),
        ":hover": {
          backgroundColor: theme.palette.secondary.white(10),
        },
      }),
      backButton: css({
        label: label("backButton"),
        marginRight: theme.spacing(3),
      }),
      mainColumn: css({
        label: label("mainColumn"),
        width: "100%",
        height: "calc(100vh - 96px)",
      }),
      noPaddingTop: css({
        label: label("noPaddingTop"),
        paddingTop: theme.spacing(0),
      }),
      panelOptions: (expanded?: boolean) =>
        css({
          label: label("panelOptions"),
          display: "flex",
          transition: theme.extras.transition.fast("opacity"),
          opacity: expanded ? 1 : 0.5,
          pointerEvents: expanded ? "unset" : "none",
        }),
      selectYear: css({
        label: label("selectYear"),
        width: theme.spacing(16),
        zIndex: "1",
      }),
      selectMonth: css({
        label: label("selectMonth"),
        width: theme.spacing(20),
        zIndex: "1",
      }),
      pendencyText: css({
        label: label("pendencyText"),
        color: "white",
        fontWeight: "bold",
        fontSize: "14px",
        display: "flex",
        alignItems: "center",
      }),
      bold: css({
        label: label("bold"),
        fontWeight: "bold",
      }),
      pendencyIcon: css({
        label: label("pendencyIcon"),
        color: "white",
        marginRight: theme.spacing(2),
        fontSize: "30px",
      }),
      noItemMessage: css({
        label: label("noItemMessage"),
        color: theme.palette.secondary.white(40),
      }),
      displayPendencyBlocks: css({
        label: label("displayPendencyBlocks"),
        display: "grid",
        gridTemplateColumns: "repeat(auto-fill, minmax(100px, 1fr))",
        gridGap: theme.spacing(2),
        marginBottom: theme.spacing(4),
      }),
      paper: css({
        label: label("paper"),
        " > div > .MuiPaper-root": {
          backgroundColor: theme.palette.background.main,
          borderRadius: theme.extras.borderRadius,
          maxWidth: "unset",
          width: "85vw",
          height: "80vh",
        },
      }),
      placeholder: (expanded: boolean, expandedSidebar: boolean) =>
        css({
          label: label("placeholder"),
          backgroundColor: theme.palette.secondary.main,
          position: "absolute",
          transition: theme.extras.transition.fast(["height", "width"]),
          height: expanded ? "calc(100vh - 96px - 60px - 89px - 89px)" : "0px",
          width: expandedSidebar
            ? "calc(100vw - " + theme.spacing(43) + ")"
            : "calc(100vw - " + theme.spacing(22) + ")",
          marginTop: theme.spacing(-2),
          zIndex: -1,
        }),
      panelHead: (expanded: boolean) =>
        css({
          label: label("panelHead"),
          borderBottomRightRadius: theme.extras.borderRadius,
          borderBottomLeftRadius: theme.extras.borderRadius,
          zIndex: expanded ? 1 : 0,
          cursor: expanded ? "unset" : "pointer",
        }),
      panelContent: (expanded: boolean) =>
        css({
          label: label("panelContent"),
          transition: theme.extras.transition.fast([
            "height",
            "padding-bottom",
          ]),
          "> div": {
            padding: theme.spacing(4),
            paddingTop: theme.spacing(3),
            paddingBottom: theme.spacing(0),
            " > div:last-of-type > div:last-of-type": {
              marginBottom: theme.spacing(0),
            },
            " > div > span:last-of-type > div": {
              marginBottom: theme.spacing(0),
            },
          },
          padding: "0px",
          paddingBottom: theme.spacing(0),
          marginBottom: theme.spacing(3),
          borderRadius: theme.spacing(0),
          borderBottomRightRadius: theme.extras.borderRadius,
          borderBottomLeftRadius: theme.extras.borderRadius,
          overflow: "scroll",
          height: expanded ? "calc(100vh - 96px - 60px - 89px - 89px)" : "0px",
        }),
      time: theme.extras.transitionTime.fast,
    };
    CacheTheme(name, theme.palette.type, _style);
    styleCached = true;
  }
  var style = CacheTheme(name, theme.palette.type);
  return style as typeof _style;
}
