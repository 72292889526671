/** @jsxImportSource @emotion/react */

import { useHowWorksItemStyle } from "./Style";

function HowWorksItem({
  src,
  text,
  alt,
}: {
  src: string;
  text: string[];
  alt: string;
}) {
  const style = useHowWorksItemStyle();
  return (
    <div>
      <img css={style.imagem} alt={alt} src={src} />
      <div css={style.item}>
        {text.map((_text, index) => (
          <div key={"howWorksItem: " + index}>{_text}</div>
        ))}
      </div>
    </div>
  );
}

export default HowWorksItem;
