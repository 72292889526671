import { css } from "@emotion/react";
import CacheTheme from "Theme/CacheTheme";
import useCustomTheme from "Theme/CustomTheme";

var styleCached = false;
export function useCategorizationInfosStyle() {
  const name = "CategorizationInfosCSS";
  const theme = useCustomTheme();
  if (!styleCached) {
    const label = theme.extras.label(name);
    var _style = {
      title: css({
        label: label("title"),
        marginBottom: theme.spacing(2),
      }),
      row: css({
        label: label("row"),
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: theme.spacing(4),
      }),
      groupAvatar: (avatarSize: number) =>
        css({
          label: label("groupAvatar"),
          " > .MuiAvatarGroup-avatar": {
            width: theme.spacing(avatarSize),
            height: theme.spacing(avatarSize),
          },
        }),
      infoNumber: css({
        label: label("infoNumber"),
        display: "flex",
        fontWeight: "bold",
        color: "white",
        alignItems: "center",
      }),
      icon: css({
        label: label("icon"),
        transform: "scaleX(-1)",
        marginLeft: theme.spacing(1),
      }),
    };
    CacheTheme(name, theme.palette.type, _style);
    styleCached = true;
  }
  var style = CacheTheme(name, theme.palette.type);
  return style as typeof _style;
}
