import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { DocumentModel } from "../../../Models/DocumentModel";
import { OperatorAvatar } from "../../../Models/OperatorModel";
import { DocumentServices } from "../../../Services/Interfaces";
import { days7inMiliSeconds } from "../../../Utils/Dates";

export function useDocumentDevolvedPresenter() {
  const { state: operatorAvatar } = useLocation<OperatorAvatar>();

  const [selectedId, setSelectedId] = useState("");
  const [documents, setDocuments] = useState<DocumentModel[]>([]);
  const [confirmDialog, setConfirmDialog] = useState(false);

  const changeLoading = useRef<
    React.MutableRefObject<() => null> | undefined
  >();

  useEffect(() => {
    getDevolveds(setDocuments, changeLoading);
  }, []);

  const _imageToShow = documents.find((e) => e.id === selectedId)?.url;
  const imageToShow = _imageToShow ? [_imageToShow] : undefined;

  const onSelect = (id: string) => setSelectedId(selectedId !== id ? id : "");
  const isSelected = (id: string) => selectedId === id;

  const restoureDocument = async (id: string) => {
    await DocumentServices.devolvedRestore(id);
    setDocuments(documents.filter((e) => e.id !== id));
  };
  const onRemoveDocument = async (id?: string) => {
    const _id = id ?? selectedId;
    await DocumentServices.removeDocument(_id);
    setDocuments((_documents) => _documents.filter((_e) => _e.id !== _id));
  };

  return {
    changeLoading,
    operatorAvatar,
    imageToShow,
    documents,
    confirmDialog,
    setConfirmDialog,
    onSelect,
    isSelected,
    restoureDocument,
    onRemoveDocument,
  };
}

const getDevolveds = async (
  setDocuments: React.Dispatch<React.SetStateAction<DocumentModel[]>>,
  changeLoading: React.MutableRefObject<
    React.MutableRefObject<() => null> | undefined
  >
) => {
  const result = await DocumentServices.getDevolveds();
  for (const document of result) {
    document.name = document.url.split("/").pop();
    document.date = new Date(
      new Date(document.date).valueOf() + days7inMiliSeconds
    );
  }
  result.sort((a, b) => a.date.valueOf() - b.date.valueOf());
  setDocuments(result);
  changeLoading.current?.current();
};
