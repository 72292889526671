import { css, keyframes } from "@emotion/react";
import CacheTheme from "Theme/CacheTheme";
import useCustomTheme from "Theme/CustomTheme";

var styleCached = false;
export function useLoadingTransitionStyle() {
  const name = "LoadingTransitionCSS";
  const theme = useCustomTheme();
  const dotSize = theme.spacing(2);
  if (!styleCached) {
    const label = theme.extras.label(name);
    var _style = {
      //https://dev.to/kirteshbansal/bouncing-dots-loader-in-react-4jng
      bouncingLoader: (window: boolean) =>
        css({
          label: label("bouncingLoader"),
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: window ? "100%" : "100vh",
          "> div": {
            width: dotSize,
            height: dotSize,
            margin: "4px 4px",
            borderRadius: "50%",
            backgroundColor: theme.palette.primary.main,
            animation:
              keyframes(
                "25% { opacity: 0.25; transform: translateY(-" +
                  theme.spacing(1) +
                  "); } 0%, 50%, 100% { opacity: 1; transform: translateY(0px)}"
              ) + " 1.6s infinite",
          },
          "> div:nth-of-type(2)": {
            animationDelay: "0.2s",
          },
          "> div:nth-of-type(3)": {
            animationDelay: "0.4s",
          },
        }),
    };
    CacheTheme(name, theme.palette.type, _style);
    styleCached = true;
  }
  var style = CacheTheme(name, theme.palette.type);
  return style as typeof _style;
}
