import { useRef } from "react";

function useLoadingTransition(checkLoadDone: boolean) {
  const loadingDone = useRef(false);
  const changeLoading = useRef<
    React.MutableRefObject<() => null> | undefined
  >();

  if (!loadingDone.current && checkLoadDone) {
    setTimeout(() => {
      changeLoading.current?.current();
      loadingDone.current = true;
    }, 25);
  }

  return [changeLoading, loadingDone] as [
    React.MutableRefObject<React.MutableRefObject<() => null> | undefined>,
    React.MutableRefObject<boolean>
  ];
}

export default useLoadingTransition;
