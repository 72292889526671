/** @jsxImportSource @emotion/react */
import { useRef, useState } from "react";
import { useFrameStyle } from "../../../../../Components/Frame/Style";
import useCheckFrameDate from "../../../../../Hooks/CheckFrameDate";
import { ShowAccountingsBlocksState } from "../../../../../Hooks/ShowAccountingsBlocks/Model";
import { selectItemByDate } from "../../../../../Hooks/ShowAccountingsBlocks/Presenter";
import { ShowAccountingsPendencyState } from "../../Model";
import AccountingBody from "./Components/AccountingBody/View";
import AccountingHead from "./Components/AccountingHead/View";
import PendencyBody from "./Components/PendencyBody/View";
import PendencyHead from "./Components/PendencyHead/View";
import { useMainColumnStyle } from "./Style";

function MainColumn({
  setClientDialog,
  showAccountings,
  clientName,
  logoUrl,
  showAccountingsBlocksState,
  showAccountingsPendencyState,
  expandSideBar,
}: {
  setClientDialog: React.Dispatch<React.SetStateAction<boolean>>;
  showAccountings: boolean;
  clientName: string;
  logoUrl?: string;
  showAccountingsBlocksState: ShowAccountingsBlocksState;
  showAccountingsPendencyState: ShowAccountingsPendencyState;
  expandSideBar: boolean;
}) {
  const style = useMainColumnStyle();
  const frameStyle = useFrameStyle();
  const [accountingExpanded, setAccountingExpanded] = useState(true);
  const notifyRef = useRef<React.Dispatch<React.SetStateAction<string>>>(
    () => null
  );

  const [
    keyedBlocks,
    keyedAccounts,
    datesSelect,
    yearSelected,
    setYearSelected,
    monthSelected,
    setMonthSelected,
  ] = showAccountingsBlocksState;

  const [
    keyedPendency,
    pendencyDatesSelect,
    keyedPendencyCount,
    pendencyYearSelected,
    setPendencyYearSelected,
    pendencyMonthSelected,
    setPendencyMonthSelected,
  ] = showAccountingsPendencyState;

  useCheckFrameDate(
    datesSelect,
    pendencyYearSelected,
    pendencyMonthSelected,
    setPendencyMonthSelected
  );

  return (
    <div css={style.mainColumn}>
      <div css={frameStyle.panel}>
        <AccountingHead
          accountingExpanded={accountingExpanded}
          setAccountingExpanded={setAccountingExpanded}
          setClientDialog={setClientDialog}
          showAccountings={showAccountings}
          clientName={clientName}
          logoUrl={logoUrl}
          datesSelect={datesSelect}
          yearSelected={yearSelected}
          setYearSelected={setYearSelected}
          monthSelected={monthSelected}
          setMonthSelected={setMonthSelected}
          notifyRef={notifyRef}
        />
        <AccountingBody
          accountingExpanded={accountingExpanded}
          showAccountings={showAccountings}
          notifyRef={notifyRef}
          expandSideBar={expandSideBar}
          blocksByAccount={selectItemByDate(
            yearSelected,
            monthSelected,
            keyedBlocks
          )}
          acccounts={selectItemByDate(
            yearSelected,
            monthSelected,
            keyedAccounts
          )}
        />
      </div>
      <div css={frameStyle.panel}>
        <PendencyHead
          accountingExpanded={accountingExpanded}
          setAccountingExpanded={setAccountingExpanded}
          datesSelect={pendencyDatesSelect}
          yearSelected={pendencyYearSelected}
          setYearSelected={setPendencyYearSelected}
          monthSelected={pendencyMonthSelected}
          setMonthSelected={setPendencyMonthSelected}
          keyedPendencyCount={keyedPendencyCount}
        />
        <PendencyBody
          accountingExpanded={accountingExpanded}
          expandSideBar={expandSideBar}
          pendencyItens={selectItemByDate(
            pendencyYearSelected,
            pendencyMonthSelected,
            keyedPendency
          )}
        />
      </div>
    </div>
  );
}

export default MainColumn;
