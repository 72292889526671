/** @jsxImportSource @emotion/react */

import { Add, ContentCopy, Delete } from "@mui/icons-material";
import { useEffect, useState } from "react";
import OpacityTransition from "../../../../Components/OpacityTransition/View";
import useContentExpansion from "../../../../Hooks/ContentExpansion";
import { useButtonImageStyle } from "./Style";

function ButtonImage({
  id,
  url,
  selected = false,
  maxSelecteds = false,
  disableRemove = false,
  setSelected,
  noInteractions,
  setNoInteractions,
  displayed,
  setDisplayed,
  selectionRemove,
  removeDocument,
}: {
  id: string;
  url: string;
  selected?: boolean;
  maxSelecteds?: boolean;
  disableRemove?: boolean;
  setSelected?: (value: string) => void;
  noInteractions: boolean;
  setNoInteractions: React.Dispatch<React.SetStateAction<boolean>>;
  displayed: string;
  setDisplayed: (value: string) => void;
  selectionRemove?: () => void;
  removeDocument?: () => void;
}) {
  const style = useButtonImageStyle();

  const isDisplayed = id === displayed || selected;

  const [showContent, expanded, setExpanded] = useContentExpansion();
  const [phaseOne, setPhaseOne] = useState(isDisplayed);

  const [remove, setRemove] = useState(false);
  const [_selected, _setSelected] = useState(false);

  const [mouseOff, setMouseOff] = useState(false);

  const noInteractionsCallback = (callback: () => void) => {
    callback();
    setTimeout(() => setNoInteractions(false), style.time);
  };

  const onAction = (action: () => void) => {
    setNoInteractions(true);
    if (!phaseOne) {
      setPhaseOne(true);
      setTimeout(() => noInteractionsCallback(() => action()), style.time);
    } else {
      action();
      setTimeout(
        () => noInteractionsCallback(() => setPhaseOne(false)),
        style.time
      );
    }
  };

  const reset = () => {
    setRemove(false);
    setMouseOff(false);
    setPhaseOne(false);
  };

  useEffect(() => {
    if (selected) {
      _setSelected(true);
    } else {
      setTimeout(() => _setSelected(false), style.time);
    }
    // eslint-disable-next-line
  }, [selected]);

  useEffect(() => {
    if (isDisplayed) {
      setExpanded(true);
    } else if (expanded) {
      setExpanded(false);
      setTimeout(() => {
        reset();
      }, style.time);
    }
    // eslint-disable-next-line
  }, [isDisplayed, setExpanded, expanded]);

  useEffect(() => {
    if (mouseOff && remove && !noInteractions) {
      reset();
      setTimeout(() => setNoInteractions(false), style.time);
    }
    // eslint-disable-next-line
  }, [noInteractions, mouseOff, remove]);

  useEffect(() => {
    if (phaseOne && !isDisplayed) {
      setPhaseOne(false);
    }
  }, [phaseOne, isDisplayed]);

  return (
    <div
      css={[
        style.wrapper,
        _selected || noInteractions ? style.noInteractions : undefined,
      ]}
    >
      <img
        onClick={() => setDisplayed(isDisplayed ? "" : id)}
        css={[
          style.item,
          expanded ? style.itemDisplayed : undefined,
          selectionRemove ? style.itemSolid : undefined,
        ]}
        src={url}
        alt={id}
      />
      <div
        css={[
          style.position,
          showContent ? undefined : style.hiddenOptions,
          expanded ? style.showOptions : undefined,
        ]}
      >
        <div css={[style.options, remove ? style.endJustify : undefined]}>
          {selectionRemove ? (
            <div
              onClick={selectionRemove}
              css={[style.buttons, style.removeTag, style.onSelection]}
            >
              <span className="material-symbols-outlined">undo</span>
            </div>
          ) : (
            <>
              <div
                css={[
                  style.buttons,
                  _selected ? style.buttonExapnded : undefined,
                  _selected ? style.selectedTag : undefined,
                  remove ? style.buttonContracted : undefined,
                  maxSelecteds || remove ? style.noInteractions : undefined,
                ]}
                onClick={() =>
                  selectionRemove ? undefined : onAction(() => setSelected!(id))
                }
              >
                <OpacityTransition
                  childFalse={(ccss) => (
                    <Add
                      css={[
                        style.iconOptions,
                        phaseOne ? style.iconHidden : undefined,
                        maxSelecteds ? style.iconDisabled : undefined,
                        ccss,
                      ]}
                      fontSize="small"
                    />
                  )}
                  childTrue={(ccss) => (
                    <ContentCopy
                      fontSize="small"
                      css={[style.iconMirror, ccss]}
                    />
                  )}
                  value={_selected}
                />
              </div>
              <div css={style.separator} />
              <div
                css={[
                  style.buttons,
                  _selected ? style.buttonContracted : undefined,
                  remove ? style.buttonExapnded : undefined,
                  remove ? style.removeTag : undefined,
                  disableRemove ? style.noInteractions : undefined,
                ]}
                onMouseLeave={remove ? () => setMouseOff(true) : undefined}
                onClick={
                  remove
                    ? removeDocument!
                    : () => onAction(() => setRemove(!remove))
                }
              >
                <OpacityTransition
                  childFalse={(ccss) => (
                    <Delete
                      css={[
                        style.iconOptions,
                        phaseOne ? style.iconHidden : undefined,
                        disableRemove ? style.iconDisabled : undefined,
                        ccss,
                      ]}
                      fontSize="small"
                    />
                  )}
                  childTrue={(ccss) => (
                    <span css={[ccss, style.removeSpan]}>Excluir</span>
                  )}
                  value={remove}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default ButtonImage;
