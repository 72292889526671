/** @jsxImportSource @emotion/react */

import { Button } from "@mui/material";
import { useStepCarouselStyle } from "./Style";

function StepCarousel({
  step,
  title,
  children,
  onContinue,
  onBack,
}: {
  step: number;
  title: string;
  children: React.ReactNode[] | React.ReactNode;
  onContinue?: () => void;
  onBack?: () => void;
}) {
  const style = useStepCarouselStyle();
  return (
    <div css={style.wrapper}>
      <div>Etapa {step} de 4</div>
      <div css={style.title}>{title}</div>
      {children}
      <Button onClick={onContinue} css={[style.button, style.primary]}>
        Continuar
      </Button>
      {onBack ? (
        <Button onClick={onBack} css={[style.button, style.white]}>
          Voltar
        </Button>
      ) : (
        <div css={style.buttonPlaceholder} />
      )}
    </div>
  );
}

export default StepCarousel;
