import { css } from "@emotion/react";
import CacheTheme from "Theme/CacheTheme";
import useCustomTheme from "Theme/CustomTheme";

var styleCached = false;
export function useShowBlockReportStyle() {
  const name = "ShowBlockReportCSS";
  const theme = useCustomTheme();
  if (!styleCached) {
    const label = theme.extras.label(name);
    var _style = {
      subtitle: css({
        label: label("issueSubtitle"),
        marginBottom: theme.spacing(2),
      }),
      issueView: css({
        label: label("issueView"),
        backgroundColor: theme.palette.background.main,
        borderRadius: theme.extras.borderRadius,
        padding: theme.spacing(2),
        marginBottom: theme.spacing(4),
        color: "white",
        width: "100%",
      }),
      observation: css({
        label: label("observation"),
        backgroundColor: theme.palette.background.main,
        borderRadius: theme.extras.borderRadius,
        padding: theme.spacing(2),
        width: "100%",
        height: "125px",
      }),
      noObservation: css({
        label: label("noObservation"),
        color: theme.palette.background.white(30),
      }),
      withObservation: css({
        label: label("withObservation"),
        color: "white",
      }),
    };
    CacheTheme(name, theme.palette.type, _style);
    styleCached = true;
  }
  var style = CacheTheme(name, theme.palette.type);
  return style as typeof _style;
}
