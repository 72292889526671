import { css } from "@emotion/react";
import CacheTheme from "Theme/CacheTheme";
import useCustomTheme from "Theme/CustomTheme";

var styleCached = false;
export function useContabilizationBlockStyle() {
  const name = "ContabilizationBlockCSS";
  const theme = useCustomTheme();
  if (!styleCached) {
    const label = theme.extras.label(name);
    var _style = {
      icon: css({
        label: label("icon"),
        backgroundColor: theme.palette.warning.main,
        marginRight: theme.spacing(2),
      }),
      accountingItem: css({
        label: label("accountingItem"),
        justifyContent: "start",
        marginBottom: theme.spacing(2),
        backgroundColor: theme.palette.secondary.main,
        ":hover": {
          backgroundColor: theme.palette.secondary.white(10),
          " > div:first-of-type": {
            backgroundColor: theme.palette.warning.white(10),
          },
        },
      }),
    };
    CacheTheme(name, theme.palette.type, _style);
    styleCached = true;
  }
  var style = CacheTheme(name, theme.palette.type);
  return style as typeof _style;
}
