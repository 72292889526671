/** @jsxImportSource @emotion/react */
import Frame from "../../../Components/Frame/View";
import AccountingDrawer from "./Components/AccountingDrawer/View";
import ChangeAccountingPanel from "./Components/ChangeAccountingPanel/View";
import ClientDialog from "./Components/ClientDialog/View";
import FrameDialog from "../../../Components/FrameDialog/View";
import SideBar from "./Components/SideBar/View";
import MainColumn from "./Components/MainColumn/View";
import LoadingTransition from "../../../Components/LoadingTransition/View";
import { useAccountingPresenter } from "./Presenter";
import { EditHomeContext } from "../../Client/Home/Model";
import { AccountingListContext, IsBusyContext } from "./Model";

function Accounting() {
  const presenter = useAccountingPresenter();
  return (
    <LoadingTransition>
      {(_changeLoading) => {
        presenter.changeLoading.current = _changeLoading;
        return (
          <AccountingListContext.Provider
            value={{
              setClientAccounts: presenter.setClientAccounts,
              clientId: presenter.client.id,
            }}
          >
            <Frame
              operatorState={presenter.operatorState}
              accountingState={presenter.accountingState}
            >
              <>
                <EditHomeContext.Provider value={presenter.setAllBlocks}>
                  <IsBusyContext.Provider
                    value={presenter.operatorInAccounting !== undefined}
                  >
                    <MainColumn
                      setClientDialog={presenter.setClientDialog}
                      showAccountings={presenter.showAccountings}
                      clientName={presenter.client.person.name}
                      logoUrl={presenter.client.person.pictureUrl}
                      showAccountingsBlocksState={
                        presenter.showAccountingsBlocksState
                      }
                      expandSideBar={presenter.expandSideBar}
                      showAccountingsPendencyState={
                        presenter.showAccountingsPendencyState
                      }
                    />
                  </IsBusyContext.Provider>
                </EditHomeContext.Provider>
                <SideBar
                  isResponsable={presenter.client.isResponsable}
                  chooseDrawer={presenter.chooseDrawer}
                  showAccountings={presenter.showAccountings}
                  changeAccountingPanelRef={presenter.changeAccountingPanelRef}
                  setRemoveDialog={() => presenter.setRemoveDialog(true)}
                  uncategorizedBlocks={presenter.uncategorizedBlocks}
                  uncontabilizedBlocks={presenter.uncontabilizedBlocks}
                  operatorInAccounting={presenter.operatorInAccounting}
                  expandSideBar={presenter.expandSideBar}
                  setExpandSidebar={presenter.setExpandSidebar}
                  categorizationStateArgs={{
                    id: presenter.client.id,
                    name: presenter.client.person.name,
                    logoUrl: presenter.client.person.pictureUrl,
                  }}
                />
                <FrameDialog
                  content="Tem certeza que deseja remover este cliente?"
                  color="warning"
                  open={presenter.removeDialog}
                  onClose={() => presenter.setRemoveDialog(false)}
                />
                <ClientDialog
                  open={presenter.clientDialog}
                  onClose={() => presenter.setClientDialog(false)}
                  client={presenter.client}
                />
                <ChangeAccountingPanel
                  showAccountings={presenter.showAccountings}
                  setShowAccountings={presenter.setShowAccountings}
                  changeAccountingPanelRef={presenter.changeAccountingPanelRef}
                />
                <AccountingDrawer
                  choosedDrawerRef={presenter.choosedDrawerRef}
                />
              </>
            </Frame>
          </AccountingListContext.Provider>
        );
      }}
    </LoadingTransition>
  );
}

export default Accounting;
