/** @jsxImportSource @emotion/react */
import { SerializedStyles } from "@emotion/react";
import { Button } from "@mui/material";
import { useState } from "react";
import OpacityTransition from "../../../../../OpacityTransition/View";
import { useProfileEditButtonsStyle } from "./Style";

function ProfileEditButtons({
  setEdit,
  onSave,
  onCancel,
  resetEditButtonRef,
  saveOrEditDisabled,
  ccss,
}: {
  setEdit: React.Dispatch<React.SetStateAction<boolean>>;
  onSave: () => boolean;
  onCancel?: () => void;
  resetEditButtonRef?: React.MutableRefObject<() => void>;
  saveOrEditDisabled?: boolean;
  ccss?: SerializedStyles;
}) {
  const style = useProfileEditButtonsStyle();

  const [showSecondButton, setShowSecondButton] = useState(false);
  const [inEditMode, setInEditMode] = useState(false);

  const [animationTransition, setAnimationTransition] = useState(false);

  const _onCancel = () => {
    setAnimationTransition(true);
    setEdit(false);
    setShowSecondButton(false);
    setTimeout(() => {
      setInEditMode(false);
      setTimeout(() => setAnimationTransition(false), style.time);
    }, style.time);
    // eslint-disable-next-line
  };

  if (resetEditButtonRef) {
    resetEditButtonRef!.current = _onCancel;
  }

  return (
    <div
      css={[style.row, ccss, animationTransition ? style.noEvents : undefined]}
    >
      <Button
        css={[
          style.mainButton,
          animationTransition ? style.animationTransition : undefined,
          inEditMode ? style.saveButton : style.editButton,
        ]}
        disabled={saveOrEditDisabled}
        onClick={
          !inEditMode
            ? () => {
                setAnimationTransition(true);
                setInEditMode(true);
                setEdit(true);
                setTimeout(() => {
                  setShowSecondButton(true);
                  setTimeout(() => setAnimationTransition(false), style.time);
                }, style.time);
              }
            : () => {
                const done = onSave();
                if (done) {
                  _onCancel();
                }
              }
        }
      >
        <OpacityTransition
          value={inEditMode}
          childFalse={(ccss) => <span css={ccss}>Editar</span>}
          childTrue={(ccss) => <span css={ccss}>Salver</span>}
        />
      </Button>
      <Button
        css={[
          style.opacityTransition,
          style.cancelButton,
          inEditMode ? undefined : style.noButton,
          showSecondButton ? undefined : style.hidden,
        ]}
        onClick={() => {
          _onCancel();
          onCancel?.apply(undefined);
        }}
      >
        Cancelar
      </Button>
    </div>
  );
}

export default ProfileEditButtons;
