/** @jsxImportSource @emotion/react */

import { useHistory } from "react-router-dom";

function RedirectToLogin() {
  useHistory().push("/home");
  return <div></div>;
}

export default RedirectToLogin;
