/** @jsxImportSource @emotion/react */
import { OperatorAvatar } from "Models/OperatorModel";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import TurquesaAvatar from "../../../../../../Components/TurquesaAvatar/View";
import useContentExpansion from "../../../../../../Hooks/ContentExpansion";
import useEditButtonAnimation from "../../../../../../Hooks/EditButtonAnimation";
import { CategorizationStateArgs } from "../../../../Categorization/Model";
import SideBarIcon from "../SideBarIcon/View";
import { useSideBarStyle } from "../Style";
import { useSideBarContabilizeIconStyle } from "./Style";
import { ReactComponent as Priority } from "@material-symbols/svg-700/rounded/priority.svg";

function SideBarContabilizeIcon({
  expandSideBar,
  uncontabilizedBlocks,
  categorizationStateArgs,
  operatorInAccounting,
}: {
  expandSideBar: boolean;
  uncontabilizedBlocks: number;
  categorizationStateArgs: CategorizationStateArgs;
  operatorInAccounting: OperatorAvatar | undefined;
}) {
  const history = useHistory();
  const style = useSideBarContabilizeIconStyle();
  const siderBarStyle = useSideBarStyle();
  //eslint-disable-next-line
  const [_, padding, backgroundColor, setEdit] = useEditButtonAnimation();
  //eslint-disable-next-line
  const [showContent, __, setExpanded] = useContentExpansion();
  const [cacheAvatarUrl, setCacheAvatarUrl] = useState<string>();

  useEffect(() => {
    setEdit(operatorInAccounting !== undefined);
    setExpanded(operatorInAccounting !== undefined);
    if (operatorInAccounting) {
      setCacheAvatarUrl(operatorInAccounting?.pictureUrl);
    }
    //eslint-disable-next-line
  }, [operatorInAccounting]);

  return (
    <>
      <SideBarIcon
        text="Contabilizar"
        badgeNumber={operatorInAccounting ? undefined : uncontabilizedBlocks}
        disabled={showContent}
        icon={
          <Priority css={siderBarStyle.marginIcon}/>
        }
        contabilization
        onClick={() =>
          history.push({
            pathname: "/home/client/contabilization",
            state: categorizationStateArgs,
          })
        }
        isExpanded={expandSideBar}
      />
      <div css={[style.wrapper(expandSideBar, padding)]}>
        <div
          css={[
            style.avatarOperator,
            backgroundColor ? undefined : style.noInteractions,
            backgroundColor ? undefined : style.hiddenAvatar,
          ]}
        >
          <TurquesaAvatar
            size={4}
            nameToInitials={operatorInAccounting?.name ?? ""}
            tooltip={operatorInAccounting?.name ?? ""}
            src={cacheAvatarUrl}
          />
        </div>
      </div>
    </>
  );
}

export default SideBarContabilizeIcon;
