/** @jsxImportSource @emotion/react */
import { useEffect, useState } from "react";
import { BlockModel } from "../../Models/BlockModel";
import { PendencyModel } from "../../Models/PendencyModel";
import DocumentViewer from "./Components/DocumentViewer/View";
import ViewBlockPanel from "./Components/ViewBlockPanel/View";
import { CategorizationArgs, OperatorActions } from "./Model";
import { getClientBlocks } from "./Presenter";
import { useFrameViewBlockStyle } from "./Style";

function FrameViewBlock({
  onBack,
  pendency,
  block,
  devolveBlock,
  edit = false,
  view = false,
  categorizationArgs,
  fullScreen = false,
  isBusy = false,
}: {
  onBack: () => void;
  pendency?: PendencyModel;
  block?: BlockModel | null;
  edit?: boolean;
  view?: boolean;
  devolveBlock?: () => void;
  fullScreen?: boolean;
  categorizationArgs?: CategorizationArgs;
  isBusy?: boolean;
}) {
  const style = useFrameViewBlockStyle();

  const [operatorActions, setOperatorActions] = useState<OperatorActions>({});

  const [documents, setDocuments] = useState<string[]>();
  useEffect(() => {
    if (block && (edit || view)) {
      getClientBlocks(block.id, setDocuments, setOperatorActions);
    } else if (pendency) {
      setDocuments(pendency.documentsUrl);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div css={style.screen(pendency !== undefined || block !== undefined)}>
      <ViewBlockPanel
        onBack={onBack}
        pendency={pendency}
        block={block}
        categorizationArgs={categorizationArgs}
        edit={edit}
        view={view}
        fullScreen={fullScreen}
        isBusy={isBusy}
      />
      <DocumentViewer
        operatorActions={operatorActions}
        showReportDialogRef={categorizationArgs?.showReportDialogRef}
        documents={block?.documentsUrl ?? documents}
        isBusy={isBusy}
        devolveBlock={devolveBlock}
      />
    </div>
  );
}

export default FrameViewBlock;
