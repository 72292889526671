import { css } from "@emotion/react";
import CacheTheme from "Theme/CacheTheme";
import useCustomTheme from "Theme/CustomTheme";

var styleCached = false;
export function usePendencyBlockStyle() {
  const name = "PendencyBlockCSS";
  const theme = useCustomTheme();
  if (!styleCached) {
    const label = theme.extras.label(name);
    var _style = {
      wrapper: css({
        label: label("wrapper"),
        display: "flex",
        color: "white",
        alignItems: "center",
        justifyContent: "space-between",
        padding: theme.spacing(1, 1, 1, 2),
        borderRadius: theme.extras.borderRadius,
      }),
      iconMirror: css({
        label: label("iconMirror"),
        transform: "scaleX(-1)",
      }),
      displayTimer: css({
        label: label("displayTimer"),
        display: "flex",
        fontSize: "14px",
        padding: theme.spacing(1),
        height: theme.spacing(5),
        alignItems: "center",
        fontWeight: "normal",
        pointerEvents: "all",
      }),
    };
    CacheTheme(name, theme.palette.type, _style);
    styleCached = true;
  }
  var style = CacheTheme(name, theme.palette.type);
  return style as typeof _style;
}
