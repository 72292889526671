/** @jsxImportSource @emotion/react */
import { GroupsOutlined, HelpOutline } from "@mui/icons-material";
import { Button } from "@mui/material";
import { Person } from "Models/AccountingModel";
import React, { useRef } from "react";
import { Client } from "../../Models/ClientModel";
import { OperatorProfile } from "../../Models/OperatorModel";
import ButtonSquared from "../ButtonSquared/View";
import TurquesaAvatar from "../TurquesaAvatar/View";
import OperatorDrawer from "./Components/OperatorDrawer/View";
import ProfileOfficeDialog from "./Components/ProfileDrawer/Components/ProfileOfficeDialog/View";
import ProfileDrawer from "./Components/ProfileDrawer/View";
import { useFrameStyle } from "./Style";
import { ReactComponent as Logo } from "./../../Assets/only_logo_blue.svg";
import { LoginServices } from "Services/Interfaces";
import { useAuthActions } from "use-eazy-auth";
import { useHistory } from "react-router-dom";
import BlintesLogo from "Components/BlintesLogo/View";

function Frame({
  children,
  operatorState,
  clientState,
  accountingState,
}: {
  children: React.ReactChild;
  operatorState: [
    OperatorProfile,
    React.Dispatch<React.SetStateAction<OperatorProfile>>
  ];
  clientState?: [Client, React.Dispatch<React.SetStateAction<Client>>];
  accountingState?: [Person, React.Dispatch<React.SetStateAction<Person>>];
}) {
  const { patchUser } = useAuthActions();
  const style = useFrameStyle();

  const profileDrawerRef = useRef<() => void>(() => null);
  const operatorDrawerRef = useRef<(operatorType?: number) => void>(
    (_) => null
  );
  const openProfileOfficeDialog = useRef<() => void>(() => null);

  const isClient = clientState !== undefined;
  const history = useHistory();

  const changePlataform = () => {
    LoginServices.changePlataform();
    patchUser({});
    history.push("/home");
  };

  const allowChangePlataform = LoginServices.allowChangePlataform;

  return (
    <>
      <div css={style.title}>
        <BlintesLogo ccss={style.logo} />
        <div>
          <ButtonSquared
            ccss={style.marginRight}
            transparentIcon
            color="backgroundWhiteHover"
          >
            <HelpOutline />
          </ButtonSquared>
          <ButtonSquared
            onClick={() => operatorDrawerRef?.current(operatorState[0].type)}
            transparentIcon
            ccss={style.marginRight}
            color="backgroundWhiteHover"
          >
            <GroupsOutlined />
          </ButtonSquared>
          {allowChangePlataform ? (
            <ButtonSquared
              onClick={changePlataform}
              transparentIcon
              ccss={style.marginRight}
              color="backgroundWhiteHover"
            >
              <Logo />
            </ButtonSquared>
          ) : undefined}
          <Button
            onClick={() => profileDrawerRef.current!()}
            css={style.textButton}
          >
            <span>{operatorState[0].name}</span>
            <TurquesaAvatar
              src={operatorState[0].pictureUrl}
              nameToInitials={operatorState[0].name}
              size={4}
              ccss={style.operatorAvatar}
            />
          </Button>
        </div>
      </div>
      <div css={style.content}>{children}</div>
      <ProfileDrawer
        openProfileOfficeDialog={openProfileOfficeDialog}
        profileDrawerRef={profileDrawerRef}
        clientState={clientState}
        operatorState={operatorState}
      />
      <OperatorDrawer operatorDrawerRef={operatorDrawerRef} client={isClient} />
      {clientState || accountingState ? (
        <ProfileOfficeDialog
          clientState={clientState}
          accountingState={accountingState}
          openProfileOfficeDialog={openProfileOfficeDialog}
        />
      ) : undefined}
    </>
  );
}

export default Frame;
