/** @jsxImportSource @emotion/react */
import { ContentCopy, FolderOffOutlined } from "@mui/icons-material";
import { Badge, Tooltip } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import TextEntry from "../../../../../Components/TextEntry/View";
import useContentExpansion from "../../../../../Hooks/ContentExpansion";
import { BlockModel } from "../../../../../Models/BlockModel";
import BlockGrid from "../BlockGrid/View";
import { useAccountingRowStyle } from "./Style";

function AccountingRow({
  code,
  description,
  blockNumber,
  selected,
  setSelected,
  blockList,
  activated,
}: {
  code: string;
  description: string;
  blockNumber: number;
  selected: boolean;
  setSelected: () => void;
  blockList?: BlockModel[];
  activated: boolean;
}) {
  const style = useAccountingRowStyle();
  const [showGrid, expanded, setExpanded] = useContentExpansion();
  const [gridHeight, setGridHeight] = useState(0);
  const gridRef = useRef<HTMLDivElement>(null);
  const notifyRef = useRef<React.Dispatch<React.SetStateAction<string>>>(
    () => null
  );

  var viewed = true;
  if (blockList) {
    for (const block of blockList) {
      if (!block.viewed) {
        viewed = false;
        break;
      }
    }
  }

  useEffect(() => {
    if (showGrid) {
      setGridHeight(gridRef.current?.clientHeight ?? 0);
    }
  }, [showGrid, blockNumber]);

  useEffect(() => {
    if (selected) {
      setExpanded(true);
    } else {
      setExpanded(false);
    }
  }, [selected, setExpanded]);

  return (
    <Badge
      css={[style.badge, viewed ? style.badgeHidden : undefined]}
      variant="dot"
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      overlap="rectangular"
      color="warning"
    >
      <div
        css={[style.accountingRow, showGrid ? undefined : style.notExpanded]}
        onClick={setSelected}
      >
        <div
          css={[
            style.head,
            expanded ? style.headExpanded : style.headNotExpanded,
          ]}
        >
          <div css={style.display}>
            {activated ? undefined : (
              <Tooltip
                placement="bottom-start"
                title="Um ou mais blocos foram categorizados nesta conta, porém ela não faz parte das contas ativas da empresa."
              >
                <FolderOffOutlined css={[style.icon, style.noAccount]} />
              </Tooltip>
            )}
            <span css={style.number}>{code}</span>
            <span css={style.name}>{description}</span>
          </div>
          <div css={style.display}>
            <span css={style.photosNumber}>{blockNumber}</span>
            <ContentCopy
              css={[
                style.icon,
                style.mirrorIcon,
                expanded ? style.iconExpanded : undefined,
              ]}
            />
            <div
              onClick={(e) => e.stopPropagation()}
              css={[
                style.textEntryDisplay,
                expanded
                  ? style.textEntryDisplayExpanded
                  : style.textEntryDisplayNotExpanded,
              ]}
            >
              {showGrid ? (
                <TextEntry
                  ccss={style.textEntry}
                  placeholder="Buscar valor"
                  notifyRef={notifyRef}
                  searchIcon
                />
              ) : undefined}
            </div>
          </div>
        </div>
        <div
          css={[
            style.content,
            expanded
              ? style.contentExpanded(gridHeight)
              : style.contentNotExpanded,
          ]}
        >
          <div css={style.separator} />
          {showGrid ? (
            blockList?.length ?? -1 > 0 ? (
              <BlockGrid
                list={blockList!}
                gridRef={gridRef}
                notifyRef={notifyRef}
              />
            ) : (
              <div ref={gridRef} css={style.noItemMessage}>
                Sem blocos nesta conta
              </div>
            )
          ) : undefined}
        </div>
      </div>
    </Badge>
  );
}

export default AccountingRow;
