import {
  BlockServices,
  OperatorServices,
  PendencyServices,
  DocumentServices,
} from "../../../Services/Interfaces";
import {
  fromFileGetBase64Image,
  fromUrlGetBase64Image,
} from "../../../Utils/Images";
import { randomString } from "../../../Utils/String";
import { SelectedDocumentModel, selfLib } from "./Model";
import { useCookies } from "react-cookie";
import { useLocation } from "react-router-dom";
import { FrameSelectItem } from "../../../Components/FrameSelect/Model";
import { OperatorAvatar } from "../../../Models/OperatorModel";
import { useEffect, useRef, useState } from "react";
import { DocumentModel } from "../../../Models/DocumentModel";
import { ImageListType } from "react-images-uploading";
import { SYNC_DELAY } from "../../../Utils/Utils";

export function useBlockCreatePresenter(pendency: boolean) {
  const { state: operatorAvatar } = useLocation<OperatorAvatar>();

  const [cookie, setCookie] = useCookies(["notShowAgainNextDialog"]);
  const [nextDialog, setNextDialog] = useState(false);
  const [equalsDialog, setEqualsDialog] = useState(false);

  const [emptyPendency, setEmptyPendency] = useState(false);
  const [pendencyNumber, setPendencyNumber] = useState(0);
  const [observation, setObservation] = useState("");
  const [issue, setIssue] = useState(-1);
  const [date, setDate] = useState<Date>(new Date());
  const pendencyDocumentsRef = useRef<DocumentModel[]>([]);

  const [noInteractions, setNoInteractions] = useState(false);
  const [displayedId, setDisplayedId] = useState("");
  const [imageToShow, setImageToShow] = useState("");
  const [documents, setDocuments] = useState<DocumentModel[]>([]);
  const [selecteds, setSelecteds] = useState<SelectedDocumentModel[]>([]);
  const [currentLib, setCurrentLib] = useState<FrameSelectItem>(selfLib);
  const [libraries, setLibraries] = useState<FrameSelectItem[]>([]);
  const [operators, setOperators] = useState<OperatorAvatar[]>([]);
  const [rescued, setRescued] = useState(false);
  const [observationHover, setObservationHover] = useState(false);

  const observationsRef = useRef<HTMLElement>(null);
  const currentLibRef = useRef<FrameSelectItem>();
  const updateDocumentsRef = useRef<(documents: DocumentModel[]) => void>(
    (_) => {}
  );
  const changeLoading = useRef<
    React.MutableRefObject<() => null> | undefined
  >();

  const setCurrentLibAndGetDocuments = (currentLib: FrameSelectItem) => {
    currentLibRef.current = currentLib;
    setCurrentLib(currentLib);
    getDocuments(currentLib.key, pendencyDocumentsRef, updateDocumentsRef);
  };

  const updateDisplayId = (displayedId: string) => {
    // Em ambiente de testes acontece de dois documentos diferentes possuirem a mesma URL.
    setDisplayedId(displayedId);
    if (displayedId === "") {
      setImageToShow("");
      return;
    }
    for (const selected of selecteds) {
      if (selected.id === displayedId) {
        setImageToShow(selected.url ?? selected.base64url!);
        return;
      }
    }

    for (const document of documents) {
      if (document.id === displayedId) {
        setImageToShow(document.url);
        return;
      }
    }
  };

  const updateDocuments = (documents: DocumentModel[]) => {
    setDocuments(documents);
    if (displayedId) {
      var find = false;
      for (const document of documents) {
        if (document.id === displayedId) {
          find = true;
          break;
        }
      }
      if (!find) {
        for (const selected of selecteds) {
          if (selected.id === displayedId) {
            find = true;
            break;
          }
        }
      }
      if (!find) {
        updateDisplayId("");
      }
    }
  };
  updateDocumentsRef.current = updateDocuments;

  const getToSolve = (next: boolean) =>
    getPendencyToSolve(
      setEmptyPendency,
      setObservation,
      setIssue,
      setDate,
      pendencyDocumentsRef,
      setSelecteds,
      next
    );

  useEffect(() => {
    DocumentServices.getLibraries().then((data) => {
      setLibraries([selfLib, ...data]);
      setCurrentLibAndGetDocuments(selfLib);
      if (pendency) {
        getToSolve(false);
        pendencySync(currentLibRef, setPendencyNumber, setOperators);
      }
      syncGalleryJob(currentLibRef, pendencyDocumentsRef, updateDocumentsRef);
      changeLoading.current?.current();
    });
    // Para parar o syncJob
    return () => (currentLibRef.current = undefined);
    // eslint-disable-next-line
  }, []);

  const onChange = (imageList: ImageListType, _: number[] | undefined) => {
    imageList.forEach((img) =>
      fromFileGetBase64Image(img.file!, (base64Url) =>
        setSelecteds([
          ...selecteds,
          {
            id: randomString(16),
            base64url: base64Url,
          },
        ])
      )
    );
  };

  const toSolve = async () => {
    await PendencyServices.solvePendency(
      selecteds,
      pendencyDocumentsRef.current
        .filter((e) => selecteds.find((_e) => _e.id === e.id) === undefined)
        .map((e) => e.id)
    );
    getToSolve(false);
  };

  const documentsToShow = documents.filter(
    (document) => document.rescued === rescued
  );

  const imageToShowOrUndefined = imageToShow === "" ? undefined : [imageToShow];
  const onNext = () => {
    cookie["notShowAgainNextDialog"] === "true"
      ? getToSolve(true)
      : setNextDialog(true);
  };

  const onSend = async () => {
    if (pendency) {
      verifySolvePendency(
        selecteds,
        pendencyDocumentsRef,
        setEqualsDialog,
        toSolve
      );
    } else {
      setSelecteds([]);
      updateDisplayId("");
      setCurrentLibAndGetDocuments({ ...currentLib });
      await BlockServices.createBlock(
        selecteds.map((doc) => ({
          id: doc.id,
          base64url: doc.base64url,
        }))
      );
    }
  };

  const onRemove = (id: string) => {
    setSelecteds(selecteds.filter((_e) => _e.id !== id));
    updateDisplayId("");
  };

  const onRemoveDocument = async (id: string) => {
    await DocumentServices.removeDocument(id);
    updateDocuments(documents.filter((_e) => _e.id !== id));
  };

  const onSelectDocument = async (e: DocumentModel) => {
    setSelecteds([
      ...selecteds,
      {
        id: e.id,
        url: e.url,
        base64url: await fromUrlGetBase64Image(e.url),
      },
    ]);
    updateDisplayId(e.id);
  };

  const onNotShowAgainNextDialog = () => {
    if (cookie["notShowAgainNextDialog"] === "true") {
      setCookie("notShowAgainNextDialog", "false");
    } else {
      setCookie("notShowAgainNextDialog", "true");
    }
  };

  return {
    changeLoading,
    operatorAvatar,
    operators,
    pendencyNumber,
    imageToShowOrUndefined,
    emptyPendency,
    issue,
    date,
    observation,
    observationsRef,
    observationHover,
    setObservationHover,
    cookie,
    onNext,
    onSend,
    selecteds,
    setSelecteds,
    displayedId,
    updateDisplayId,
    noInteractions,
    setNoInteractions,
    onRemove,
    onChange,
    rescued,
    setRescued,
    libraries,
    currentLib,
    setCurrentLibAndGetDocuments,
    documentsToShow,
    pendencyDocumentsRef,
    onRemoveDocument,
    onSelectDocument,
    onNotShowAgainNextDialog,
    nextDialog,
    setNextDialog,
    getToSolve,
    toSolve,
    equalsDialog,
    setEqualsDialog,
  };
}

const getPendencyToSolve = async (
  setEmptyPendency: React.Dispatch<React.SetStateAction<boolean>>,
  setObservation: React.Dispatch<React.SetStateAction<string>>,
  setIssue: React.Dispatch<React.SetStateAction<number>>,
  setDate: React.Dispatch<React.SetStateAction<Date>>,
  pendencyDocumentsRef: React.MutableRefObject<DocumentModel[]>,
  setSelecteds: React.Dispatch<React.SetStateAction<SelectedDocumentModel[]>>,
  next: boolean
) => {
  const result = await PendencyServices.getToSolve(next);
  if (result === null) {
    setEmptyPendency(true);
    return;
  }
  setObservation(result.observations);
  setIssue(result.issue);
  setDate(new Date(result.date));
  const documents = [] as DocumentModel[];
  const selectedDocuments = [] as SelectedDocumentModel[];

  const datetimenow = new Date();

  for (const document of result.documents) {
    documents.push({
      id: document.id,
      date: datetimenow,
      url: document.url,
      rescued: false,
    });
    selectedDocuments.push({
      id: document.id,
      base64url: await fromUrlGetBase64Image(document.url),
      url: document.url,
    });
  }
  pendencyDocumentsRef.current = documents;
  setSelecteds(selectedDocuments);
};

const syncGalleryJob = async (
  currentLibRef: React.MutableRefObject<FrameSelectItem | undefined>,
  pendencyDocumentsRef: React.MutableRefObject<DocumentModel[]>,
  updateDocuments: React.MutableRefObject<(documents: DocumentModel[]) => void>
) => {
  do {
    if (currentLibRef.current) {
      getDocuments(
        currentLibRef.current.key,
        pendencyDocumentsRef,
        updateDocuments
      );
    }
    await new Promise((resolve) => setTimeout(resolve, SYNC_DELAY));
  } while (currentLibRef.current);
};

const getDocuments = (
  lib: string,
  pendencyDocumentsRef: React.MutableRefObject<DocumentModel[]>,
  updateDocuments: React.MutableRefObject<(documents: DocumentModel[]) => void>
) =>
  DocumentServices.getDocuments(lib).then((data) => {
    const unwrappedData = data
      .map((doc: DocumentModel) => ({
        id: doc.id,
        date: doc.date,
        url: doc.url,
        rescued: doc.rescued,
      }))
      .sort(
        (a: DocumentModel, b: DocumentModel) =>
          new Date(b.date).valueOf() - new Date(a.date).valueOf()
      );
    updateDocuments.current(
      lib === ""
        ? [...pendencyDocumentsRef.current, ...unwrappedData]
        : unwrappedData
    );
  });

const pendencySync = async (
  currentLibRef: React.MutableRefObject<FrameSelectItem | undefined>,
  setPendencyNumber: React.Dispatch<React.SetStateAction<number>>,
  setOperators: React.Dispatch<React.SetStateAction<OperatorAvatar[]>>
) => {
  do {
    if (currentLibRef.current) {
      const result = await OperatorServices.pendencySync();
      setPendencyNumber(result.pendencyNumber);
      setOperators(result.operators);
    }
    await new Promise((resolve) => setTimeout(resolve, SYNC_DELAY));
  } while (currentLibRef.current);
  OperatorServices.pendencyUnsync();
};

const verifySolvePendency = (
  selecteds: SelectedDocumentModel[],
  pendencyDocumentsRef: React.MutableRefObject<DocumentModel[]>,
  setEqualsDialog: (value: React.SetStateAction<boolean>) => void,
  toSolve: () => Promise<void>
) => {
  var isEqual = true;
  if (selecteds.length !== pendencyDocumentsRef.current.length) {
    isEqual = false;
  }
  if (isEqual) {
    for (var i = 0; i < selecteds.length && isEqual; i++) {
      if (selecteds[i].id !== pendencyDocumentsRef.current[i].id) {
        isEqual = false;
      }
    }
  }
  if (isEqual) {
    setEqualsDialog(true);
  } else {
    toSolve();
  }
};
