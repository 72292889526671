/** @jsxImportSource @emotion/react */
import { ContentCopy } from "@mui/icons-material";
import { useState } from "react";
import ClickAwayListener from "react-advanced-click-away";
import ButtonSquared from "../../../../../Components/ButtonSquared/View";
import { BlockModel } from "../../../../../Models/BlockModel";
import { dateToString, stringToValue } from "../../../../../Utils/String";
import { contabilizeBlock } from "../../Presenter";
import { useContabilizedItemStyle } from "./Style";

function ContabilizedItem({
  block,
  setAllBlocks,
  brighter = false,
  link,
}: {
  block: BlockModel;
  setAllBlocks: React.Dispatch<React.SetStateAction<BlockModel[] | undefined>>;
  brighter?: boolean;
  link: string;
}) {
  const [showUndo, setShowUndo] = useState(false);
  const [animation, setAnimation] = useState(false);

  const style = useContabilizedItemStyle();
  return (
    <div css={[style.contabilizedBlock, brighter ? style.brighter : undefined]}>
      <div css={style.content}>
        <ContentCopy css={style.contabilizedIcon} />
        <span css={style.margin}>{dateToString(block.date)}</span>
        <span css={style.empashis}>{stringToValue(block.value)}</span>
      </div>
      <ClickAwayListener
        onClickAway={
          showUndo && !animation ? () => setShowUndo(false) : () => null
        }
      >
        <div
          onMouseLeave={
            showUndo && !animation ? () => setShowUndo(false) : undefined
          }
        >
          <ButtonSquared
            minor
            ccss={style.button}
            onClick={
              showUndo
                ? async () => {
                    await contabilizeBlock(block.id, link);
                    setAllBlocks((list) =>
                      list?.map((e) =>
                        e.id === block.id
                          ? { ...e, contabilizedDatetime: undefined }
                          : e
                      )
                    );
                  }
                : () => {
                    setShowUndo(true);
                    setAnimation(true);
                    setTimeout(() => setAnimation(false), style.fast);
                  }
            }
            color={
              showUndo
                ? "primary"
                : brighter
                ? "secondaryWhite20"
                : "secondaryWhite10"
            }
          >
            <>
              <span
                css={[
                  style.undo,
                  showUndo ? style.undoExpanded : undefined,
                  showUndo ? style.undoShow : undefined,
                ]}
              >
                Desfazer
              </span>
              <span css={style.icon} className="material-symbols-outlined">
                undo
              </span>
            </>
          </ButtonSquared>
        </div>
      </ClickAwayListener>
    </div>
  );
}

export default ContabilizedItem;
