import useGetAccoutingInfo from "Hooks/GetAccountingInfo";
import { useEffect, useRef, useState } from "react";
import useGetFrameOperator from "../../../Hooks/GetFrameOperator";
import useLoadingTransition from "../../../Hooks/LoadingTransition";
import { TextEntryWithErrorState } from "../../../Hooks/TextEntryWithError/Model";
import { emailValidation } from "../../../Hooks/TextEntryWithError/Presenter";
import { AccountingInvited } from "../../../Models/AccountingModel";
import { HomeClient } from "../../../Models/ClientModel";
import {
  AccountingServices,
  ClientServices,
} from "../../../Services/Interfaces";
import {
  dateToString,
  normalizeString,
  onlyDigits,
} from "../../../Utils/String";

export function useClientsPresenter() {
  const [clients, setClients] = useState<HomeClient[]>();

  const [searchText, setSearchTest] = useState("");
  const operatorState = useGetFrameOperator();
  const accountingState = useGetAccoutingInfo();

  const [changeLoading] = useLoadingTransition(
    operatorState[0].id !== "" &&
      clients !== undefined &&
      accountingState[0]?.address?.cep !== ""
  );

  const searchRef =
    useRef<React.Dispatch<React.SetStateAction<string>>>(setSearchTest);
  const addDrawerRef = useRef(() => null);

  useEffect(() => {
    getClients(setClients);
  }, []);

  const clientActivated = (index: number) =>
    searchText === "" ||
    dateToString(clients![index].modifiedDatetime).includes(searchText) ||
    normalizeString(clients![index].name).includes(searchText) ||
    onlyDigits(dateToString(clients![index].modifiedDatetime)).includes(
      searchText
    ) ||
    clients![index].name.includes(searchText);

  const refresh = () => getClients(setClients);

  return {
    changeLoading,
    refresh,
    operatorState,
    searchRef,
    addDrawerRef,
    clients,
    clientActivated,
    accountingState,
  };
}

const getClients = async (
  setClients: React.Dispatch<React.SetStateAction<HomeClient[] | undefined>>
) => setClients(await ClientServices.getClients());

export const invitedList = async (
  setInviteds: React.Dispatch<
    React.SetStateAction<AccountingInvited[] | undefined>
  >
) => setInviteds(await AccountingServices.listInvited());

export const invitedAdd = async (
  emailState: React.MutableRefObject<TextEntryWithErrorState>,
  inviteds: AccountingInvited[] | undefined,
  setInviteds: React.Dispatch<
    React.SetStateAction<AccountingInvited[] | undefined>
  >
) => {
  var error = emailValidation(emailState) as string | boolean;
  const email = emailState.current.state.text!.current;
  if (!error) {
    for (const invited of inviteds!) {
      if (invited.email === email) {
        error = true;
        emailState.current.setState({ error: "E-mail já convidado" });
      }
    }
  }
  if (!error) {
    await AccountingServices.addInvited(email);
    emailState.current.setState({ text: "" });
    invitedList(setInviteds);
  }
};

export const invitedRemove = async (
  invitedId: string,
  setInviteds: React.Dispatch<
    React.SetStateAction<AccountingInvited[] | undefined>
  >
) => {
  await AccountingServices.removeInvited(invitedId);
  invitedList(setInviteds);
};
