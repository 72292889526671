import { ClientAccountModel } from "Models/AccountModel";
import { BlockModel } from "Models/BlockModel";
import { AccountServices, IContabilizationServices } from "Services/Interfaces";
import { callPromiseWrapper } from "Utils/Login";

class ContabilizationServicesImpl implements IContabilizationServices {
  contabilizationLinkState = {
    yearSelected: "",
    monthSelected: "",
    accountSelected: "",
  };

  async contabilizeSync(clientId: string) {
    return callPromiseWrapper("/api/operator/sync/contabilize", {
      clientId: clientId,
    });
  }

  async getContabilizationLink(clientId: string) {
    return (
      await callPromiseWrapper("/api/operator/contabilization/link", {
        clientId,
      })
    ).data;
  }

  async initContabilizationLink(link: string) {
    const fullList = await AccountServices.initializeAllAccounts();

    const response = (
      await callPromiseWrapper("/api/operator/contabilization/init", { link })
    ).data as {
      categorizedBlocks: BlockModel[];
      clientAccounts: ClientAccountModel[];
      name: string;
      id: string;
      pictureUrl?: string;
    };

    var data = [] as ClientAccountModel[];

    response.clientAccounts.forEach((clientAccountIds) => {
      let clientAccount = fullList.find(
        (elem: any) => elem.id === clientAccountIds.id
      );
      data.push({
        id: clientAccount?.id as string,
        description: clientAccount?.description as string,
        code: clientAccount?.code as string,
        yearOfExercise: clientAccount?.yearOfExercise as string,
        date: new Date(clientAccountIds.date),
        activated: true,
      });
    });

    response.clientAccounts = data;

    response.categorizedBlocks.forEach((block) => {
      block.date = new Date(block.date);
      block.value = block.value.toString();
      block.contabilizedDatetime = block.contabilizedDatetime
        ? new Date(block.contabilizedDatetime)
        : undefined;
    });

    return response;
  }

  async contabilizeBlock(blockId: string, link: string) {
    return callPromiseWrapper("/api/block/contabilize", { blockId, link });
  }
}

export default ContabilizationServicesImpl;
