/** @jsxImportSource @emotion/react */
import { useRef } from "react";
import { initTextEntryWithErrorState } from "../../../../../../Hooks/TextEntryWithError/Model";
import ButtonMain from "../../../../../ButtonMain/View";
import ProfileExpansivePanel from "../ProfileExpansivePanel/View";
import ProfileTextEntry from "../ProfileTextEntry/View";
import { useProfilePasswordPanelStyle } from "./Style";

function ProfilePasswordPanel() {
  const style = useProfilePasswordPanelStyle();

  const currentRef = useRef(initTextEntryWithErrorState);
  const newRef = useRef(initTextEntryWithErrorState);
  const confirmRef = useRef(initTextEntryWithErrorState);

  return (
    <ProfileExpansivePanel title={"Trocar senha"} height="463px">
      <div>
        <div css={style.description}>
          A nova senha deve conter pelo menos uma letra maiúscula e um caractér
          especial.
        </div>
        <ProfileTextEntry
          subtitle="Senha atual"
          editMode={true}
          backgroundColor={true}
          padding={true}
          stateRef={currentRef}
          password
        />
        <ProfileTextEntry
          subtitle="Nova senha"
          editMode={true}
          backgroundColor={true}
          padding={true}
          stateRef={newRef}
          password
        />
        <ProfileTextEntry
          subtitle="Confirmar nova senha"
          editMode={true}
          backgroundColor={true}
          padding={true}
          stateRef={confirmRef}
          password
        />
        <ButtonMain ccss={style.marginTop}>Alterar senha</ButtonMain>
      </div>
    </ProfileExpansivePanel>
  );
}

export default ProfilePasswordPanel;
