import { css } from "@emotion/react";
import CacheTheme from "Theme/CacheTheme";
import useCustomTheme from "Theme/CustomTheme";

var styleCached = false;
export function useEditBlockEntriesStyle() {
  const name = "EditBlockEntriesCSS";
  const theme = useCustomTheme();
  if (!styleCached) {
    const label = theme.extras.label(name);
    var _style = {
      dateRow: css({
        label: label("dateRow"),
        display: "flex",
        justifyContent: "space-between",
        marginBottom: theme.spacing(4),
      }),
      dayTextEntry: css({
        label: label("dayTextEntry"),
        width: theme.spacing(9),
      }),
      monthSelect: css({
        label: label("monthSelect"),
        width: theme.spacing(20),
      }),
      yearSelect: css({
        label: label("yearSelect"),
        width: theme.spacing(15),
      }),
      stack: css({
        label: label("stack"),
        display: "flex",
        flexDirection: "column",
      }),
      mask: css({
        label: label("mask"),
        display: "flex",
        position: "absolute",
        backgroundColor: theme.palette.background.main,
        transition: theme.extras.transition.fast("opacity"),
        opacity: 1,
        zIndex: 1,
      }),
      accountMask: css({
        label: label("accountMask"),
        height: theme.spacing(7),
        width: theme.spacing(48),
        borderRadius: theme.extras.borderRadius,
      }),
      buttonMask: css({
        label: label("buttonMask"),
        height: theme.spacing(3),
        width: theme.spacing(3),
      }),
      maskTransiton: css({
        label: label("maskTransiton"),
        opacity: 0,
      }),
      maskHidden: css({
        label: label("maskHidden"),
        zIndex: -1,
      }),
      yearMask: css({
        label: label("yearMask"),
        top: theme.spacing(22),
        left: theme.spacing(47),
      }),
      monthMask: css({
        label: label("monthMask"),
        top: theme.spacing(22),
        left: theme.spacing(30),
      }),
      noInteractions: css({
        label: label("noInteractions"),
        pointerEvents: "none",
      }),
      time: theme.extras.transitionTime.fast,
    };
    CacheTheme(name, theme.palette.type, _style);
    styleCached = true;
  }
  var style = CacheTheme(name, theme.palette.type);
  return style as typeof _style;
}
