/** @jsxImportSource @emotion/react */
import { ClassNames } from "@emotion/react";
import { ExpandMore } from "@mui/icons-material";
import { Autocomplete } from "@mui/material";
import { useMemo } from "react";
import { onlyDigits } from "../../../../../../Utils/String";
import TextEntry from "../../../../../TextEntry/View";
import { FavoriteAccountModel } from "./Model";
import { getAccountInBlockContext } from "./Presenter";
import { useViewBlockAutocompleteStyle } from "./Style";

function ViewBlockAutocomplete({
  value,
  setValue,
  month,
  year,
}: {
  value: string;
  setValue: React.Dispatch<React.SetStateAction<string>>;
  month: string;
  year: string;
}) {
  const style = useViewBlockAutocompleteStyle();

  const favoriteAccounts = useMemo<FavoriteAccountModel[]>(() => {
    const { organizedAccountList, fullAccountList } =
      getAccountInBlockContext();

    // Se não conseguir, é prq não existem contas ativas no mês e ano selecionados
    var _favoriteAccounts = [];
    try {
      _favoriteAccounts = fullAccountList.map((e) => ({
        ...e,
        favorite: organizedAccountList[year][month].find(
          (_e) => _e.activated && _e.id === e.id
        )
          ? true
          : false,
      }));
    } catch {
      _favoriteAccounts = fullAccountList.map((e) => ({
        ...e,
        favorite: false,
      }));
    }
    _favoriteAccounts.sort((a, b) =>
      a.favorite === b.favorite
        ? b.description.localeCompare(a.description)
        : a.favorite
        ? -1
        : 1
    );
    return _favoriteAccounts;
  }, [year, month]);

  return (
    <ClassNames>
      {({ css }) => (
        <Autocomplete
          noOptionsText="Sem Opções"
          clearText="Limpar"
          openText="Abrir"
          closeText="Fechar"
          options={favoriteAccounts}
          groupBy={(option) =>
            option.favorite ? "Contas ativas" : "Todas as contas"
          }
          getOptionLabel={(option) =>
            option.code +
            " - " +
            option.description +
            " " +
            "(" +
            onlyDigits(option.code) +
            ")"
          }
          popupIcon={<ExpandMore />}
          renderOption={(liRef, option) => (
            <li {...liRef}>
              <span css={style.optionCode}>{option.code}</span>
              <br />
              <span>{option.description}</span>
            </li>
          )}
          classes={{
            paper: css(style.papper),
            noOptions: css(style.noOptions),
          }}
          value={favoriteAccounts.find((elem) => elem.id === value) ?? null}
          onChange={(_, option) => setValue(option?.id ?? "")}
          renderInput={(params) => {
            return (
              <TextEntry
                ccss={style.autocomplete}
                hiddenClear
                params={params}
                placeholder="Selecionar conta"
              />
            );
          }}
        />
      )}
    </ClassNames>
  );
}

export default ViewBlockAutocomplete;
