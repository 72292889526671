import { useRef, useState } from "react";
import { useFrameDrawerStyle } from "../Components/Frame/Components/FrameDrawer/Style";

function useContentExpansion() {
  const frameModalStyle = useFrameDrawerStyle();

  const [expanded, setExpanded] = useState(false);
  const [showContent, setShowContent] = useState(false);

  const timeoutRef = useRef<NodeJS.Timeout>();

  const changeExpanded = (isExpanded: boolean) => {
    setExpanded(isExpanded);
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    if (isExpanded) {
      setShowContent(true);
    } else {
      timeoutRef.current = setTimeout(
        () => setShowContent(false),
        frameModalStyle.time
      );
    }
  };

  return [showContent, expanded, changeExpanded] as [
    boolean,
    boolean,
    React.Dispatch<React.SetStateAction<boolean>>
  ];
}

export default useContentExpansion;
