import { css } from "@emotion/react";
import CacheTheme from "Theme/CacheTheme";
import useCustomTheme from "Theme/CustomTheme";

var styleCached = false;
export function useChangeAccountingPanelStyle() {
  const name = "ChangeAccountingPanelCSS";
  const theme = useCustomTheme();
  if (!styleCached) {
    const label = theme.extras.label(name);
    var _style = {
      changeAccountingPanel: (client: boolean) =>
        css({
          label: label("changeAccountingPanel"),
          display: "flex",
          flexDirection: "column",
          color: "white",
          position: "absolute",
          alignItems: "center",
          zIndex: "2",
          top: theme.spacing(14),
          right: client ? theme.spacing(8) : theme.spacing(6),
          padding: theme.spacing(2),
          backgroundColor: theme.palette.primary.main,
          borderRadius: theme.extras.borderRadius,
        }),
      item: css({
        label: label("item"),
        display: "flex",
        transition: theme.extras.transition.faster("background-color"),
        width: theme.spacing(24),
      }),
      notSelected: css({
        label: label("notSelected"),
        ":hover": {
          backgroundColor: theme.palette.primary.white(10),
        },
      }),
      selected: css({
        label: label("selected"),
        backgroundColor: theme.palette.primary.white(20),
        cursor: "default",
        ":hover": {
          backgroundColor: theme.palette.primary.white(20),
        },
      }),
      icon: css({
        label: label("icon"),
        marginLeft: theme.spacing(2),
      }),
      bold: css({
        label: label("bold"),
        fontWeight: "bold",
      }),
      separator: css({
        label: label("separator"),
        height: theme.spacing(1),
      }),
    };
    CacheTheme(name, theme.palette.type, _style);
    styleCached = true;
  }
  var style = CacheTheme(name, theme.palette.type);
  return style as typeof _style;
}
