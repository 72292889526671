import { css } from "@emotion/react";
import CacheTheme from "Theme/CacheTheme";
import useCustomTheme from "Theme/CustomTheme";
import { ButtonColorsType } from "./Model";

var styleCached = false;
export function useButtonColorsPick(color: ButtonColorsType) {
  const name = "ButtonColorsPick";
  const theme = useCustomTheme();
  if (!styleCached) {
    const label = theme.extras.label(name);
    var _style = {
      primary: css({
        label: label("primaryColor"),
        color: "white",
        backgroundColor: theme.palette.primary.main,
        ":hover": {
          backgroundColor: theme.palette.primary.white(10),
        },
      }),
      primaryWhite10: css({
        label: label("primaryWhite10Color"),
        color: "white",
        backgroundColor: theme.palette.primary.white(10),
        ":hover": {
          backgroundColor: theme.palette.primary.white(20),
        },
      }),
      secondarySuccessHover: css({
        label: label("secondarySuccessHoverColor"),
        color: "white",
        backgroundColor: theme.palette.secondary.main,
        ":hover": {
          backgroundColor: theme.palette.success.white(10),
        },
      }),
      secondary: css({
        label: label("secondaryColor"),
        color: "white",
        backgroundColor: theme.palette.secondary.main,
        ":hover": {
          backgroundColor: theme.palette.secondary.white(10),
        },
      }),
      secondaryWhite10: css({
        label: label("secondaryColor"),
        color: "white",
        backgroundColor: theme.palette.secondary.white(10),
        ":hover": {
          backgroundColor: theme.palette.secondary.white(20),
        },
      }),
      secondaryWhite10PrimaryHover: css({
        label: label("secondaryWhite10PrimaryHover"),
        color: "white",
        backgroundColor: theme.palette.secondary.white(10),
        ":hover": {
          backgroundColor: theme.palette.primary.main,
        },
      }),
      secondaryWhite20: css({
        label: label("secondaryColor"),
        color: "white",
        backgroundColor: theme.palette.secondary.white(20),
        ":hover": {
          backgroundColor: theme.palette.secondary.white(30),
        },
      }),
      transparentWhite10PrimaryHover: css({
        label: label("transparentWhite10PrimaryHover"),
        color: "white",
        backgroundColor: theme.palette.transparency.white(10),
        ":hover": {
          backgroundColor: theme.palette.primary.main,
        },
      }),
      transparentWhite10SuccessHover: css({
        label: label("transparentWhite10SuccessHover"),
        color: "white",
        backgroundColor: theme.palette.transparency.white(10),
        ":hover": {
          backgroundColor: theme.palette.success.main,
        },
      }),
      transparentWhite10ErrorHover: css({
        label: label("transparentWhite10ErrorHover"),
        color: "white",
        backgroundColor: theme.palette.transparency.white(10),
        ":hover": {
          backgroundColor: theme.palette.error.main,
        },
      }),
      secondaryWhite20SuccessHover: css({
        label: label("secondaryWhite20SuccessHoverColor"),
        color: "white",
        backgroundColor: theme.palette.secondary.white(20),
        ":hover": {
          backgroundColor: theme.palette.success.main,
          color: "white",
        },
      }),
      secondaryWhite20ErrorHover: css({
        label: label("secondaryWhite20ErrorHoverColor"),
        color: "white",
        backgroundColor: theme.palette.secondary.white(20),
        ":hover": {
          backgroundColor: theme.palette.error.main,
          color: "white",
        },
      }),
      secondaryWhite20WarningHover: css({
        label: label("secondaryWhite20WarningHoverColor"),
        color: "white",
        backgroundColor: theme.palette.secondary.white(20),
        ":hover": {
          backgroundColor: theme.palette.warning.main,
        },
      }),
      backgroundWhite10: css({
        label: label("backgroundWhite10Color"),
        color: "white",
        backgroundColor: theme.palette.background.white(10),
        ":hover": {
          backgroundColor: theme.palette.background.white(20),
        },
      }),
      backgroundWhiteHover: css({
        label: label("backgroundWhiteHoverColor"),
        color: "white",
        backgroundColor: theme.palette.background.main,
        ":hover": {
          backgroundColor: theme.palette.secondary.main,
          color: "white",
        },
      }),
      warning: css({
        label: label("warningColor"),
        color: "white",
        backgroundColor: theme.palette.warning.main,
        ":hover": {
          backgroundColor: theme.palette.warning.white(10),
        },
      }),
      warningWhite10: css({
        label: label("warningWhite10Color"),
        color: "white",
        backgroundColor: theme.palette.warning.white(10),
        ":hover": {
          backgroundColor: theme.palette.warning.white(20),
        },
      }),
      success: css({
        label: label("successColor"),
        color: "white",
        backgroundColor: theme.palette.success.main,
        ":hover": {
          backgroundColor: theme.palette.success.white(10),
        },
      }),
    };
    CacheTheme(name, theme.palette.type, _style);
    styleCached = true;
  }
  var style = CacheTheme(name, theme.palette.type);
  return (style as typeof _style)[color];
}
