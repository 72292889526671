/** @jsxImportSource @emotion/react */
import { useRef } from "react";
import { useFrameDrawerStyle } from "../../../FrameDrawer/Style";
import ProfileEditButtons from "../ProfileEditButtons/View";
import ProfileTextEntry from "../ProfileTextEntry/View";
import useEditButtonAnimation from "../../../../../../Hooks/EditButtonAnimation";
import ProfileExpansivePanel from "../ProfileExpansivePanel/View";
import { OperatorProfile } from "../../../../../../Models/OperatorModel";
import { initTextEntryWithErrorState } from "../../../../../../Hooks/TextEntryWithError/Model";
import { onSaveProfile } from "./Presenter";
import ProfilePhotoButton from "../ProfilePhotoButton/View";
import React from "react";
import { Client } from "../../../../../../Models/ClientModel";

function ProfileEditPanel({
  operatorState,
  clientState,
}: {
  operatorState: [
    OperatorProfile,
    React.Dispatch<React.SetStateAction<OperatorProfile>>
  ];
  clientState?: [Client, React.Dispatch<React.SetStateAction<Client>>];
}) {
  const frameDrawerStyle = useFrameDrawerStyle();

  const cancelRef = useRef(false);
  const resetEditButtonRef = useRef<() => void>(() => null);
  const setExpandedAvatarRef = useRef<
    React.Dispatch<React.SetStateAction<boolean>>
  >(() => null);

  const nameRef = useRef(initTextEntryWithErrorState);
  const cpfRef = useRef(initTextEntryWithErrorState);
  const emailRef = useRef(initTextEntryWithErrorState);
  const phoneRef = useRef(initTextEntryWithErrorState);
  const photoRef = useRef<string>();

  const [edit, padding, backgroundColor, setEdit] = useEditButtonAnimation();

  return (
    <>
      <span css={frameDrawerStyle.title}>Conta</span>
      <ProfilePhotoButton
        photoUrl={operatorState[0].pictureUrl}
        name={operatorState[0].name}
        edit={edit}
        setExpandedAvatarRef={setExpandedAvatarRef}
        photoRef={photoRef}
        cancelRef={cancelRef}
        profileEditPanel
      />
      <ProfileExpansivePanel
        profileEditPanel
        title="Perfil"
        height="484px"
        onClosePanel={() => {
          setExpandedAvatarRef.current(false);
          if (edit) {
            resetEditButtonRef?.current?.apply([]);
          }
        }}
        onOpenPanel={() => setExpandedAvatarRef.current(true)}
      >
        <>
          <ProfileTextEntry
            subtitle="Nome"
            initialValue={operatorState[0].name}
            editMode={edit}
            backgroundColor={backgroundColor}
            padding={padding}
            cancelRef={cancelRef}
            stateRef={nameRef}
          />
          <ProfileTextEntry
            subtitle="CPF"
            initialValue={operatorState[0].code}
            editMode={edit}
            backgroundColor={backgroundColor}
            padding={padding}
            cancelRef={cancelRef}
            stateRef={cpfRef}
            patternMask={"000.000.000-00"}
          />
          <ProfileTextEntry
            subtitle="E-mail"
            initialValue={operatorState[0].email}
            editMode={edit}
            backgroundColor={backgroundColor}
            padding={padding}
            cancelRef={cancelRef}
            stateRef={emailRef}
          />
          <ProfileTextEntry
            subtitle="Telefone"
            initialValue={operatorState[0].phone}
            editMode={edit}
            backgroundColor={backgroundColor}
            padding={padding}
            cancelRef={cancelRef}
            stateRef={phoneRef}
            patternMask={"(00) 0 0000-0000"}
          />
          <ProfileEditButtons
            setEdit={setEdit}
            onSave={() =>
              onSaveProfile(
                nameRef,
                cpfRef,
                emailRef,
                phoneRef,
                cancelRef,
                photoRef.current,
                operatorState[1],
                clientState
              )
            }
            onCancel={() => (cancelRef.current = true)}
            resetEditButtonRef={resetEditButtonRef}
          />
        </>
      </ProfileExpansivePanel>
    </>
  );
}

export default ProfileEditPanel;
