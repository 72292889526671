import { css } from "@emotion/react";
import CacheTheme from "Theme/CacheTheme";
import useCustomTheme from "Theme/CustomTheme";

var styleCached = false;
export function useFrameOperatorItemStyle() {
  const name = "FrameOperatorItemCSS";
  const theme = useCustomTheme();
  if (!styleCached) {
    const label = theme.extras.label(name);
    var _style = {
      wrapper: css({
        label: label("wrapper"),
        display: "flex",
        alignItems: "center",
        width: theme.spacing(32),
        marginTop: theme.spacing(-0.5),
        marginBottom: theme.spacing(-0.5),
        marginRight: theme.spacing(1),
        marginLeft: theme.spacing(-1),
        "> div": {
          marginRight: theme.spacing(2),
        },
      }),
      overflow: css({
        label: label("overflow"),
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOoverflow: "ellipsis",
      }),
    };
    CacheTheme(name, theme.palette.type, _style);
    styleCached = true;
  }
  var style = CacheTheme(name, theme.palette.type);
  return style as typeof _style;
}
