import { css } from "@emotion/react";
import CacheTheme from "Theme/CacheTheme";
import useCustomTheme from "Theme/CustomTheme";

var styleCached = false;
export function useCarouselStyle() {
  const name = "CarouselCSS";
  const theme = useCustomTheme();
  const marginSizeSpacing = 16;
  if (!styleCached) {
    const label = theme.extras.label(name);
    var _style = {
      carousel: css({
        label: label("carousel"),
        width: "calc(100% + " + theme.spacing(2 * marginSizeSpacing) + ")",
      }),
      slideItem: css({
        label: label("slideItem"),
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        userSelect: "none",
        pointerEvents: "none",
        margin: theme.spacing(12, marginSizeSpacing),
      }),
      slideText: css({
        label: label("slideText"),
        color: "white",
        fontSize: "56px",
        fontWeight: "800",
        lineHeight: "70px",
        width: "691px",
        height: "210px",
      }),
      slideImage: css({
        label: label("slideImage"),
        width: "391px",
        height: "293px",
      }),
      imagem1: css({
        label: label("imagem1"),
        marginRight: "-50px",
      }),
      dot: css({
        label: label("dot"),
        transition: "background-color 300ms",
        width: theme.spacing(1.5),
        height: theme.spacing(1.5),
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        borderRadius: theme.spacing(0.75),
      }),
      active: css({
        label: label("active"),
        backgroundColor: "white",
      }),
      notActive: css({
        label: label("notActive"),
        backgroundColor: theme.palette.transparency.white(20),
        cursor: "pointer",
        ":hover": {
          backgroundColor: theme.palette.transparency.white(50),
        },
      }),
    };
    CacheTheme(name, theme.palette.type, _style);
    styleCached = true;
  }
  var style = CacheTheme(name, theme.palette.type);
  return style as typeof _style;
}
