/** @jsxImportSource @emotion/react */

import { Logout } from "@mui/icons-material";
import { useAuthActions } from "use-eazy-auth";
import ButtonSquared from "../../../../../ButtonSquared/View";
import { useHistory } from "react-router-dom";

function LogoutButton() {
  const { logout } = useAuthActions();
  const history = useHistory();

  return (
    <ButtonSquared
      onClick={() => {
        history.push("/home");
        logout();
      }}
    >
      <Logout />
    </ButtonSquared>
  );
}

export default LogoutButton;
