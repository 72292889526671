/** @jsxImportSource @emotion/react */
import { MoreVert } from "@mui/icons-material";
import ButtonMain from "../../../../../ButtonMain/View";
import TurquesaAvatar from "../../../../../TurquesaAvatar/View";
import { useProfileOfficePanelStyle } from "./Style";

function ProfileOfficePanel({
  closeDrawer,
  openProfileOfficeDialog,
  client,
  name,
  src,
}: {
  closeDrawer: () => void;
  openProfileOfficeDialog: React.MutableRefObject<() => void>;
  client: boolean;
  name?: string;
  src?: string;
}) {
  const style = useProfileOfficePanelStyle();

  return (
    <ButtonMain
      color="secondaryWhite10"
      ccss={style.buttonContent}
      onClick={() => {
        closeDrawer();
        openProfileOfficeDialog.current();
      }}
    >
      <>
        <span>{client ? (name ? "Empresa" : "Endereço") : "Escritório"}</span>
        {name ? (
          <TurquesaAvatar
            nameToInitials={name}
            size={4}
            src={src}
          ></TurquesaAvatar>
        ) : (
          <MoreVert css={style.homeButton} />
        )}
      </>
    </ButtonMain>
  );
}

export default ProfileOfficePanel;
