import useCustomTheme from "Theme/CustomTheme";
import { css } from "@emotion/react";
import CacheTheme from "Theme/CacheTheme";

var styleCached = false;
export function useTextEntryStyle() {
  const name = "TextEntryCSS";
  const theme = useCustomTheme();
  if (!styleCached) {
    const label = theme.extras.label(name);
    var _style = {
      textEntry: css({
        label: label("textEntry"),
        transition: theme.extras.transition.fast("background-color"),
        borderRadius: theme.extras.borderRadius,
        "& .MuiInputBase-root": {
          paddingRight: theme.spacing(1),
          paddingLeft: theme.spacing(2),
        },
        "& .MuiInputBase-input": {
          transition: theme.extras.transition.fast("color"),
          paddingLeft: theme.spacing(0),
        },
        "& .MuiOutlinedInput-notchedOutline": {
          border: "none",
        },
        "& .MuiOutlinedInput-input.Mui-disabled": {
          WebkitTextFillColor: "unset",
        },
      }),
      enabled: (white: boolean) =>
        css({
          label: label("enabled"),
          backgroundColor: white
            ? theme.palette.transparency.black(10)
            : theme.palette.background.main,
          "& .MuiInputBase-input": {
            color: white ? "black" : "white",
          },
          "& .MuiInputBase-input::placeholder": {
            color: white
              ? theme.palette.transparency.black(30)
              : theme.palette.background.white(30),
            opacity: 1,
          },
        }),
      disabled: (white: boolean) =>
        css({
          label: label("disabled"),
          backgroundColor: white
            ? theme.palette.transparency.black(5)
            : theme.palette.secondary.black(10),
          "& .MuiInputBase-input": {
            color: theme.palette.secondary.white(30),
          },
          "& .MuiInputBase-input::placeholder": {
            color: theme.palette.secondary.white(10),
            opacity: 1,
          },
        }),
      error: css({
        label: label("error"),
        backgroundColor: theme.palette.warning.main + "1A",
      }),
      opacityTransition: css({
        label: label("opacityTransition"),
        transition: theme.extras.transition.fast("opacity"),
      }),
      hidden: css({
        label: label("hidden"),
        opacity: 0,
      }),
      iconRow: css({
        label: label("iconRow"),
        display: "flex",
        alignItems: "center",
      }),
      separator: css({
        label: label("separator"),
        width: theme.spacing(1),
      }),
      searchIcon: css({
        label: label("searchIcon"),
        transition: theme.extras.transition.fast("color"),
      }),
      enabledIcon: css({
        label: label("enabledIcon"),
        color: "white",
      }),
      disabledIcon: css({
        label: label("disabledIcon"),
        color: theme.palette.secondary.white(20),
      }),
      clearIcon: (white: boolean) =>
        css({
          label: label("clearIcon"),
          transition: theme.extras.transition.fast("color"),
          color: white
            ? theme.palette.transparency.black(30)
            : theme.palette.background.white(30),
        }),
      disabledClearIcon: css({
        label: label("disabledClearIcon"),
        color: theme.palette.background.white(10),
      }),
      visibilityIcon: css({
        label: label("visibilityIcon"),
        width: theme.spacing(5),
        height: theme.spacing(5),
      }),
      time: theme.extras.transitionTime.fast,
    };
    CacheTheme(name, theme.palette.type, _style);
    styleCached = true;
  }
  var style = CacheTheme(name, theme.palette.type);
  return style as typeof _style;
}
