import { css } from "@emotion/react";
import CacheTheme from "Theme/CacheTheme";
import useCustomTheme from "Theme/CustomTheme";

var styleCached = false;
export function useCreateAccountingDialogStyle() {
  const name = "CreateAccountingDialogCSS";
  const theme = useCustomTheme();
  if (!styleCached) {
    const label = theme.extras.label(name);
    var _style = {
      wrapper: css({
        label: label("wrapper"),
        lineHeight: "20.08px",
        "& .MuiDialog-paper": {
          width: theme.spacing(62),
          borderRadius: "24px",
          padding: theme.spacing(8, 6, 6, 6),
          flexShrink: 0,
        },
      }),
      title: css({
        label: label("title"),
        fontSize: "24px",
        textAlign: "center",
        fontWeight: 700,
        marginBottom: theme.spacing(4),
      }),
      body: css({
        label: label("body"),
        width: "calc(2px + " + theme.spacing(32) + ")",
        alignSelf: "center",
        marginTop: theme.spacing(4),
        textAlign: "center",
      }),
      button: css({
        label: label("button"),
        padding: theme.spacing(4),
        width: theme.spacing(32),
        marginTop: theme.spacing(6),
        fontWeight: 400,
        alignSelf: "center",
        fontSize: "20px",
        lineHeight: "18px",
        backgroundColor: theme.palette.primary.main,
        transition: "background-color 300ms",
        ":hover": {
          backgroundColor: theme.palette.primary.white(10),
        },
        "&.Mui-disabled": {
          backgroundColor: theme.palette.primary.white(50),
          transition: theme.extras.transition.faster("color"),
          color: "white",
        },
      }),
      alreadyRegistered: css({
        label: label("alreadyRegistered"),
        alignSelf: "center",
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(5),
      }),
      logo: css({
        label: label("logo"),
        padding: theme.spacing(5, 15, 0, 15),
        margin: theme.spacing(0, 2),
        boxSizing: "border-box",
        width: theme.spacing(46),
        borderTop: "solid 2px " + theme.palette.transparency.black(5),
      }),
    };
    CacheTheme(name, theme.palette.type, _style);
    styleCached = true;
  }
  var style = CacheTheme(name, theme.palette.type);
  return style as typeof _style;
}
