import { css } from "@emotion/react";
import CacheTheme from "Theme/CacheTheme";
import useCustomTheme from "Theme/CustomTheme";
import { FrameColorOptions } from "./Model";

var styleCached = false;
export function useFrameSelectStyle() {
  const name = "FrameSelectCSS";
  const theme = useCustomTheme();
  const separatorHeight = "2px";
  if (!styleCached) {
    const label = theme.extras.label(name);
    var _style = {
      frameSelect: (color: FrameColorOptions) =>
        css({
          label: label("frameSelect"),
          color: "white",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          position: "relative",
          userSelect: "none",
          zIndex: 1,
          backgroundColor:
            color === "brighter"
              ? theme.palette.secondary.white(20)
              : color === "darker"
              ? theme.palette.background.main
              : color === "dark"
              ? theme.palette.secondary.main
              : color === "primary"
              ? theme.palette.primary.main
              : theme.palette.secondary.white(10),
          borderRadius: theme.extras.borderRadius,
          transition:
            theme.extras.transition.faster("background-color") +
            ", " +
            theme.extras.transition.fast("height"),
          overflow: "hidden",
          minHeight: theme.spacing(7),
        }),
      positionFrame: css({
        label: label("positionFrame"),
        height: theme.spacing(7),
      }),
      frameSelectNotExpanded: (color: FrameColorOptions) =>
        css({
          label: label("frameSelectNotExpanded"),
          height: theme.spacing(7),
          cursor: "pointer",
          ":hover": {
            backgroundColor:
              color === "brighter"
                ? theme.palette.secondary.white(30)
                : color === "darker"
                ? theme.palette.background.white(10)
                : color === "dark"
                ? theme.palette.secondary.white(10)
                : color === "primary"
                ? theme.palette.primary.white(10)
                : theme.palette.secondary.white(20),
          },
        }),
      frameSelectExpanded: (
        currentScreenHeight: number,
        color: FrameColorOptions,
        lenght: number,
        heightOfFixContent?: number,
        showLenghtOptions?: number
      ) =>
        css({
          label: label("frameSelectExpanded"),
          backgroundColor:
            color === "brighter"
              ? theme.palette.secondary.white(30)
              : color === "darker"
              ? theme.palette.background.white(20)
              : color === "dark"
              ? theme.palette.secondary.white(10)
              : color === "primary"
              ? theme.palette.primary.white(10)
              : theme.palette.secondary.white(20),
          height: showLenghtOptions
            ? `calc(${theme.spacing(7)} * ${
                showLenghtOptions + 1
              } + ${separatorHeight})`
            : `min(calc(${theme.spacing(7)} * ${
                lenght + 1
              } + ${separatorHeight}), calc(${currentScreenHeight}px - ${heightOfFixContent}px - ${theme.spacing(
                3
              )}))`,
        }),
      options: (
        currentScreenHeight: number,
        heightOfFixContent?: number,
        showLenghtOptions?: number,
        itemNumber?: number
      ) =>
        css({
          label: label("options"),
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          overflowY:
            showLenghtOptions && itemNumber
              ? showLenghtOptions >= itemNumber
                ? "unset"
                : "auto"
              : itemNumber && heightOfFixContent
              ? theme.extras.spacingNumber(7) * (itemNumber + 1) <
                currentScreenHeight -
                  heightOfFixContent -
                  theme.extras.spacingNumber(3)
                ? "unset"
                : "auto"
              : "auto",
          scrollbarWidth: "thin",
          //Configurações do Chrome
          "&::-webkit-scrollbar": {
            display: "block",
            width: "8px",
          },
          "&::-webkit-scrollbar-track": {
            background: theme.palette.secondary.white(10),
          },
          "&::-webkit-scrollbar-thumb": {
            background: theme.palette.secondary.white(40),
            outline: "0px",
          },
          "&::-webkit-scrollbar-thumb:hover": {
            background: theme.palette.secondary.white(30),
            outline: "0px",
          },
          "&::-webkit-scrollbar-thumb:active": {
            background: theme.palette.secondary.white(20),
            outline: "0px",
          },
        }),
      separator: css({
        label: label("separator"),
        backgroundColor: theme.palette.transparency.black(10),
        minHeight: separatorHeight,
        display: "flex",
        width: "calc(100% - " + theme.spacing(4) + ")",
        margin: theme.spacing(0, 2),
      }),
      icon: (color: FrameColorOptions) =>
        css({
          label: label("icon"),
          color:
            color === "brighter"
              ? theme.palette.secondary.white(40)
              : color === "darker"
              ? theme.palette.background.white(30)
              : color === "dark"
              ? theme.palette.secondary.white(20)
              : color === "primary"
              ? theme.palette.primary.white(50)
              : theme.palette.secondary.white(30),
          transition: theme.extras.transition.fast("transform"),
        }),
      iconRotated: css({
        label: label("iconRotated"),
        transform: "rotate(-0.5turn)",
      }),
      noInteractions: css({
        label: label("noInteractions"),
        pointerEvents: "none",
      }),
      display: css({
        label: label("display"),
        display: "flex",
        justifyContent: "space-between",
      }),
      item: css({
        label: label("item"),
        padding: theme.spacing(2, 2, 2, 3),
        width: "100%",
        cursor: "pointer",
        display: "flex",
        justifyContent: "start",
      }),
      itemSelected: (color: FrameColorOptions) =>
        css({
          label: label("itemSelected"),
          backgroundColor:
            color === "primary"
              ? theme.palette.primary.white(20)
              : theme.palette.primary.main,
        }),
      itemNotSelected: (color: FrameColorOptions) =>
        css({
          label: label("itemNotSelected"),
          ":hover": {
            backgroundColor:
              color === "brighter"
                ? theme.palette.secondary.white(40)
                : color === "darker"
                ? theme.palette.background.white(30)
                : color === "dark"
                ? theme.palette.secondary.white(20)
                : color === "primary"
                ? theme.palette.primary.white(20)
                : theme.palette.secondary.white(30),
          },
        }),
    };
    CacheTheme(name, theme.palette.type, _style);
    styleCached = true;
  }
  var style = CacheTheme(name, theme.palette.type);
  return style as typeof _style;
}
