import { createContext } from "react";
import { BlockModel } from "../../../Models/BlockModel";

export const EditHomeContext = createContext<
  React.Dispatch<React.SetStateAction<BlockModel[] | undefined>>
>(() => {});

export type HomeInfos = {
  blocks: BlockModel[];
  pendencyNumber: number;
};
