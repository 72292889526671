import { css } from "@emotion/react";
import CacheTheme from "Theme/CacheTheme";
import useCustomTheme from "Theme/CustomTheme";

var styleCached = false;
export function useFrameViewBlockStyle() {
  const name = "FrameViewBlockCSS";
  const theme = useCustomTheme();
  if (!styleCached) {
    const label = theme.extras.label(name);
    var _style = {
      screen: (modal: boolean) =>
        css({
          label: label("screen"),
          padding: modal ? theme.spacing(0) : theme.spacing(2),
          height: "100vh",
          display: "flex",
          minHeight: modal ? theme.spacing(80) : theme.spacing(100),
          minWidth: theme.spacing(130),
        }),
    };
    CacheTheme(name, theme.palette.type, _style);
    styleCached = true;
  }
  var style = CacheTheme(name, theme.palette.type);
  return style as typeof _style;
}
