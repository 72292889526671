import {
  AccountModel,
  ClientAccountModel,
} from "../../../../../Models/AccountModel";
import { AccountServices } from "../../../../../Services/Interfaces";

export const addAccount = async (
  clientId: string,
  account: AccountModel,
  accountingListContext: React.Dispatch<
    React.SetStateAction<ClientAccountModel[] | undefined>
  >
) => {
  await AccountServices.changeAccountState({
    clientId: clientId,
    accountId: account.id,
  });
  accountingListContext((list) => [
    ...list!,
    {
      ...account,
      activated: true,
      date: new Date(),
    },
  ]);
};

export const removeAccount = async (
  clientId: string,
  account: AccountModel,
  accountingListContext: React.Dispatch<
    React.SetStateAction<ClientAccountModel[] | undefined>
  >
) => {
  await AccountServices.changeAccountState({
    clientId: clientId,
    accountId: account.id,
  });
  accountingListContext((list) => list?.filter((e) => e.code !== account.code));
};
