import { css } from "@emotion/react";
import CacheTheme from "Theme/CacheTheme";
import useCustomTheme from "Theme/CustomTheme";

var styleCached = false;
export function useProfilePasswordPanelStyle() {
  const name = "ProfilePasswordPanelCSS";
  const theme = useCustomTheme();
  if (!styleCached) {
    const label = theme.extras.label(name);
    var _style = {
      description: css({
        label: label("description"),
        color: theme.palette.secondary.white(60),
        lineHeight: "20px",
        marginBottom: theme.spacing(4),
      }),
      marginTop: css({
        label: label("marginTop"),
        marginTop: theme.spacing(2),
      }),
    };
    CacheTheme(name, theme.palette.type, _style);
    styleCached = true;
  }
  var style = CacheTheme(name, theme.palette.type);
  return style as typeof _style;
}
