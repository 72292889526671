import { css } from "@emotion/react";
import CacheTheme from "Theme/CacheTheme";
import useCustomTheme from "Theme/CustomTheme";

var styleCached = false;
export function useProfileOfficePanelStyle() {
  const name = "ProfileOfficePanelCSS";
  const theme = useCustomTheme();
  if (!styleCached) {
    const label = theme.extras.label(name);
    var _style = {
      buttonContent: css({
        label: label("content"),
        fontSize: "16px",
        justifyContent: "space-between",
        marginBottom: theme.spacing(2),
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(1.5),
      }),
      homeButton: css({
        label: label("homeButton"),
        marginRight: theme.spacing(0.5),
      }),
    };
    CacheTheme(name, theme.palette.type, _style);
    styleCached = true;
  }
  var style = CacheTheme(name, theme.palette.type);
  return style as typeof _style;
}
