/** @jsxImportSource @emotion/react */
import { useEffect, useRef, useState } from "react";
import { useFrameStyle } from "../../../../../../../Components/Frame/Style";
import { PendencyModel } from "../../../../../../../Models/PendencyModel";
import DayRow from "../../../../../../Client/BlockSend/Components/DayRow/View";
import DialogViewBlock from "../../../DialogViewBlock/View";
import PendencyBlock from "../../../PendencyBlock/View";
import { useMainColumnStyle } from "../../Style";

function PendencyBody({
  accountingExpanded,
  pendencyItens,
  expandSideBar,
}: {
  accountingExpanded: boolean;
  expandSideBar: boolean;
  pendencyItens: { [day: string]: PendencyModel[] };
}) {
  const mainColumnStyle = useMainColumnStyle();
  const frameStyle = useFrameStyle();

  const [openDetails, setOpenDetails] = useState(false);
  const [pendency, setPendency] = useState<PendencyModel>();

  const scrollRef = useRef<HTMLDivElement>(null);
  const timeoutRef = useRef<NodeJS.Timeout>();

  useEffect(() => {
    if (accountingExpanded) {
      timeoutRef.current = setTimeout(
        () => scrollRef.current?.scrollTo({ top: 0 }),
        mainColumnStyle.time
      );
    } else {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    }
    // eslint-disable-next-line
  }, [accountingExpanded]);

  return (
    <div
      ref={scrollRef}
      css={[
        frameStyle.panelContent,
        mainColumnStyle.panelContent(!accountingExpanded),
      ]}
    >
      <span
        css={mainColumnStyle.placeholder(!accountingExpanded, expandSideBar)}
      />
      {Object.keys(pendencyItens)
        .sort((a, b) => -1 * a.localeCompare(b))
        .map((e, index) => (
          <div key={"pendencyDiv" + index.toString()}>
            <DayRow day={e} />
            <div css={mainColumnStyle.displayPendencyBlocks}>
              {pendencyItens[e].map((e, index2) => (
                <PendencyBlock
                  key={
                    "sentItems-" + index.toString() + "_" + index2.toString()
                  }
                  onSelect={() => {
                    setOpenDetails(true);
                    setPendency(e);
                  }}
                  date={e.date}
                />
              ))}
            </div>
          </div>
        ))}
      <DialogViewBlock
        openDetails={openDetails}
        setOpenDetails={setOpenDetails}
        pendency={pendency}
      />
    </div>
  );
}

export default PendencyBody;
