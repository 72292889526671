/** @jsxImportSource @emotion/react */

import { useDayRowStyle } from "./Style";

function DayRow({ day }: { day: string }) {
  const style = useDayRowStyle();
  return (
    <div css={[style.subtitle, style.dayRow]}>
      Dia {day.split("/")[0]}
      <div css={style.daySeparator} />
    </div>
  );
}

export default DayRow;
