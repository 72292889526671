// Third party libs
import React from "react";
import ReactDOM from "react-dom";

// Internal imports
import "./index.css";
import "@fontsource/mulish";
import "@fontsource/mulish/200.css";
import "@fontsource/mulish/300.css";
import "@fontsource/mulish/400.css";
import "@fontsource/mulish/500.css";
import "@fontsource/mulish/600.css";
import "@fontsource/mulish/700.css";
import "@fontsource/mulish/800.css";
import "@fontsource/mulish/900.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import ThemeContextProdiver from "./Theme/ThemeContext";
import { CssBaseline } from "@mui/material";

ReactDOM.render(
  <React.StrictMode>
    <ThemeContextProdiver>
      <CssBaseline />
      <App />
    </ThemeContextProdiver>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
