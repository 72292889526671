/** @jsxImportSource @emotion/react */

import { Button, Dialog } from "@mui/material";
import { useCreateAccountingDialogStyle } from "./Style";
import LogoBlintesBottom from "./../../../../../Assets/logo_blintes_bottom.png";
import { useRef, useState } from "react";
import SiteTextEntry from "Components/SiteTextEntry/View";

function CreateAccoungingDialog({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) {
  const style = useCreateAccountingDialogStyle();
  const [disabledButton, setDisabledButton] = useState(true);
  const notifyRef = useRef<React.Dispatch<React.SetStateAction<string>>>(
    (value) => setDisabledButton(value === "")
  );

  return (
    <Dialog css={style.wrapper} onClose={onClose} open={open}>
      <div css={style.title}>Criar conta</div>
      <SiteTextEntry placeholder="Insira seu e-mail" notifyRef={notifyRef} />
      <div css={style.body}>
        Ao clicar <strong>Continuar</strong>, você concorda com os{" "}
        <a href="http://hikers.io">Termos de Serviço</a> e nossa{" "}
        <a href="http://hikers.io">Política de Privacidade</a>.
      </div>
      <Button disabled={disabledButton} color="primary" css={style.button}>
        Continuar
      </Button>
      <a css={style.alreadyRegistered} href="http://hikers.io">
        Já tenho uma conta
      </a>
      <img src={LogoBlintesBottom} css={style.logo} alt="logo" />
    </Dialog>
  );
}

export default CreateAccoungingDialog;
