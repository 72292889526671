/** @jsxImportSource @emotion/react */
import { Close } from "@mui/icons-material";
import { Dialog } from "@mui/material";
import { Person } from "Models/AccountingModel";
import { useRef, useState } from "react";
import useEditButtonAnimation from "../../../../../../Hooks/EditButtonAnimation";
import { initTextEntryWithErrorState } from "../../../../../../Hooks/TextEntryWithError/Model";
import { Client } from "../../../../../../Models/ClientModel";
import ButtonSquared from "../../../../../ButtonSquared/View";
import { useFrameStyle } from "../../../../Style";
import ProfileEditButtons from "../ProfileEditButtons/View";
import ProfilePhotoButton from "../ProfilePhotoButton/View";
import ProfileTextEntry from "../ProfileTextEntry/View";
import { onSaveOffice } from "./Presenter";
import { useProfileOfficeDialogStyle } from "./Style";

function ProfileOfficeDialog({
  openProfileOfficeDialog,
  clientState,
  accountingState,
}: {
  openProfileOfficeDialog: React.MutableRefObject<() => void>;
  clientState?: [Client, React.Dispatch<React.SetStateAction<Client>>];
  accountingState?: [Person, React.Dispatch<React.SetStateAction<Person>>];
}) {
  const style = useProfileOfficeDialogStyle();
  const frameStyle = useFrameStyle();

  const person = clientState
    ? clientState[0].type === "juridicPerson"
      ? clientState[0].person
      : undefined
    : accountingState
    ? accountingState[0]
    : undefined;
  const address = (
    clientState ? clientState[0].person.address : accountingState![0].address
  )!;
  const type = clientState
    ? clientState[0].type === "juridicPerson"
      ? "juridic"
      : "individual"
    : "accounting";

  const [edit, padding, backgroundColor, setEdit] = useEditButtonAnimation();
  const [showDialog, setShowDialog] = useState(false);

  const fantasyNameRef = useRef(initTextEntryWithErrorState);
  const emailRef = useRef(initTextEntryWithErrorState);
  const cepRef = useRef(initTextEntryWithErrorState);
  const numberRef = useRef(initTextEntryWithErrorState);
  const cityRef = useRef(initTextEntryWithErrorState);
  const cnpjRef = useRef(initTextEntryWithErrorState);
  const phoneRef = useRef(initTextEntryWithErrorState);
  const streetRef = useRef(initTextEntryWithErrorState);
  const neighboorRef = useRef(initTextEntryWithErrorState);
  const stateRef = useRef(initTextEntryWithErrorState);
  const photoRef = useRef<string>();

  const cancelRef = useRef(false);

  const onClose = () => {
    setShowDialog(false);
    setTimeout(() => setEdit(false), style.timeFast);
  };

  openProfileOfficeDialog.current = () =>
    setTimeout(() => setShowDialog(true), style.time);

  return (
    <Dialog open={showDialog} onClose={onClose} css={frameStyle.dialog}>
      <>
        <div css={style.dialogHead}>
          <ButtonSquared onClick={onClose}>
            <Close />
          </ButtonSquared>
          <ProfileEditButtons
            setEdit={setEdit}
            onCancel={() => (cancelRef.current = true)}
            onSave={() =>
              onSaveOffice(
                type,
                fantasyNameRef,
                emailRef,
                cepRef,
                numberRef,
                cityRef,
                cnpjRef,
                phoneRef,
                streetRef,
                neighboorRef,
                stateRef,
                cancelRef,
                photoRef,
                clientState ? clientState[1] : undefined,
                accountingState ? accountingState[1] : undefined
              )
            }
          />
        </div>
        <div css={[frameStyle.panelSeparator, style.separator]} />
        <div css={style.dialogContent}>
          {person ? (
            <ProfilePhotoButton
              photoUrl={person.pictureUrl}
              name={person.name}
              photoRef={photoRef}
              edit={edit}
              cancelRef={cancelRef}
            />
          ) : undefined}
          <div css={style.formColumn}>
            {person ? (
              <>
                <ProfileTextEntry
                  subtitle="Nome"
                  initialValue={person.name}
                  editMode={edit}
                  backgroundColor={backgroundColor}
                  padding={padding}
                  stateRef={fantasyNameRef}
                  cancelRef={cancelRef}
                />
                <ProfileTextEntry
                  subtitle="E-mail"
                  initialValue={person.email}
                  editMode={edit}
                  backgroundColor={backgroundColor}
                  padding={padding}
                  stateRef={emailRef}
                  cancelRef={cancelRef}
                />
              </>
            ) : undefined}
            <ProfileTextEntry
              subtitle="CEP"
              initialValue={address.cep}
              editMode={edit}
              backgroundColor={backgroundColor}
              padding={padding}
              stateRef={cepRef}
              patternMask="00000-000"
              cancelRef={cancelRef}
            />
            <ProfileTextEntry
              subtitle="Número"
              initialValue={address.number}
              editMode={edit}
              backgroundColor={backgroundColor}
              padding={padding}
              stateRef={numberRef}
              patternMask="0000000000"
              cancelRef={cancelRef}
            />
            <ProfileTextEntry
              subtitle="Cidade"
              initialValue={address.city}
              editMode={edit}
              backgroundColor={backgroundColor}
              padding={padding}
              stateRef={cityRef}
              cancelRef={cancelRef}
            />
          </div>
          <div css={style.formColumn}>
            {person ? (
              <>
                <ProfileTextEntry
                  subtitle={"CPNJ"}
                  initialValue={person.code}
                  editMode={edit}
                  backgroundColor={backgroundColor}
                  padding={padding}
                  stateRef={cnpjRef}
                  patternMask={"00.000.000/0000-00"}
                  cancelRef={cancelRef}
                />
                <ProfileTextEntry
                  subtitle="Telefone"
                  initialValue={person.phone}
                  editMode={edit}
                  backgroundColor={backgroundColor}
                  padding={padding}
                  stateRef={phoneRef}
                  patternMask="(00) 0 0000-0000"
                  cancelRef={cancelRef}
                />
              </>
            ) : undefined}
            <ProfileTextEntry
              subtitle="Rua"
              initialValue={address.street}
              editMode={edit}
              backgroundColor={backgroundColor}
              padding={padding}
              stateRef={streetRef}
              cancelRef={cancelRef}
            />
            <ProfileTextEntry
              subtitle="Bairro"
              initialValue={address.neighboor}
              editMode={edit}
              backgroundColor={backgroundColor}
              padding={padding}
              stateRef={neighboorRef}
              cancelRef={cancelRef}
            />
            <ProfileTextEntry
              subtitle="Estado"
              initialValue={address.state}
              editMode={edit}
              backgroundColor={backgroundColor}
              padding={padding}
              stateRef={stateRef}
              cancelRef={cancelRef}
            />
          </div>
        </div>
      </>
    </Dialog>
  );
}

export default ProfileOfficeDialog;
