/** @jsxImportSource @emotion/react */
import React from "react";
import DisplayList from "../../../Components/DisplayList/View";
import FramePanel from "../../../Components/FramePanel/View";
import FrameSelect from "../../../Components/FrameSelect/View";
import LoadingTransition from "../../../Components/LoadingTransition/View";
import { selectItemByDate } from "../../../Hooks/ShowAccountingsBlocks/Presenter";
import { monthSort } from "../../../Utils/Utils";
import DayRow from "./Components/DayRow/View";
import SentBlock from "./Components/SentBlock/View";
import { useBlockSendPresenter } from "./Presenter";
import { useBlockSendStyle } from "./Style";

function BlockSend() {
  const style = useBlockSendStyle();
  const presenter = useBlockSendPresenter();
  return (
    <LoadingTransition>
      {(_changeLoading) => {
        presenter.changeLoading.current = _changeLoading;
        return (
          <FramePanel
            title="Blocos enviados"
            icon={
              <span css={style.icon} className="material-symbols-outlined">
                drive_file_move_rtl
              </span>
            }
            documents={presenter.documents}
            operatorAvatar={presenter.operatorAvatar}
            emptyMessage={presenter.emptyMessage}
          >
            <>
              <div css={style.separator} />
              <div css={style.description}>
                Aqui você pode ver os blocos que foram enviados e que serão
                categorizados pelo seu contador. Blocos podem ser desfeitos até
                15 minutos após sua criação.
              </div>
              <div css={style.operationRow}>
                <div>
                  <div css={[style.subtitle, style.selectMargin]}>Operador</div>
                  <FrameSelect
                    options={presenter.operatorsOptions}
                    value={presenter.currentOperator}
                    onSelect={presenter.setOperatorSelectedId}
                    showLenghtOptions={presenter.operatorShowLenghtOptions}
                    hiddenSelected
                    color="primary"
                  />
                </div>
                <div>
                  <div css={[style.subtitle, style.selectMargin]}>Período</div>
                  <div css={style.dateSelection}>
                    <FrameSelect
                      ccss={style.order}
                      options={presenter.datesSelect[presenter.yearSelected]}
                      value={presenter.monthSelected}
                      onSelect={presenter.setMonthSelected}
                      showLenghtOptions={presenter.itemNumber(
                        presenter.datesSelect[presenter.yearSelected]?.length
                      )}
                    />
                    <FrameSelect
                      ccss={style.year}
                      value={presenter.yearSelected}
                      showLenghtOptions={presenter.itemNumber(
                        Object.keys(presenter.datesSelect)?.length
                      )}
                      onSelect={presenter.setYearSelected}
                      options={Object.keys(presenter.datesSelect).sort(
                        monthSort
                      )}
                    />
                  </div>
                </div>
              </div>
              {Object.keys(presenter.organizedSentBlocks).length > 0 ? (
                <DisplayList
                  ccss={style.fullWidth}
                  heightOfFixContent="281px"
                  paddingBottomHeight={2}
                  paddingTopHeight={2}
                  itens={Object.keys(
                    selectItemByDate(
                      presenter.yearSelected,
                      presenter.monthSelected,
                      presenter.organizedSentBlocks
                    )
                  )
                    .sort((a, b) => a.localeCompare(b))
                    .map((day, index) => (
                      <React.Fragment key={"sentItem-" + index.toString()}>
                        <DayRow
                          day={day}
                          key={"separator-" + index.toString()}
                        />
                        <div css={style.displaySentBlocks}>
                          {presenter.organizedSentBlocks[
                            presenter.yearSelected
                          ][presenter.monthSelected][day].map(
                            (block, index2) => {
                              const isSelected = presenter.isSelected(
                                index,
                                index2
                              );
                              return (
                                <SentBlock
                                  ofOperator
                                  onUndo={() => presenter.onUndo(block.id)}
                                  selected={isSelected}
                                  onSelect={() =>
                                    presenter.onSelect(
                                      index,
                                      index2,
                                      isSelected,
                                      block
                                    )
                                  }
                                  key={
                                    "sentItems-" +
                                    index.toString() +
                                    "_" +
                                    index2.toString() +
                                    block.date.toString() +
                                    block.accountId
                                  }
                                  date={block.date}
                                />
                              );
                            }
                          )}
                        </div>
                      </React.Fragment>
                    ))}
                />
              ) : (
                <div css={style.noItems}>Sem blocos</div>
              )}
            </>
          </FramePanel>
        );
      }}
    </LoadingTransition>
  );
}

export default BlockSend;
