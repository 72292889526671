import { css } from "@emotion/react";
import CacheTheme from "Theme/CacheTheme";
import useCustomTheme from "Theme/CustomTheme";

var styleCached = false;
export function useOpacityTwoTimesTransitionStyle() {
  const name = "OpacityTwoTimesTransitionCSS";
  const theme = useCustomTheme();
  if (!styleCached) {
    const label = theme.extras.label(name);
    var _style = {
      fade: css({
        label: label("fade"),
        transition: theme.extras.transition.fast("opacity"),
        height: "100%",
      }),
      hide: css({
        label: label("hide"),
        opacity: 0,
      }),
      noInteractions: css({
        label: label("noInteractions"),
        pointerEvents: "none",
      }),
      time: theme.extras.transitionTime.fast,
    };
    CacheTheme(name, theme.palette.type, _style);
    styleCached = true;
  }
  var style = CacheTheme(name, theme.palette.type);
  return style as typeof _style;
}
