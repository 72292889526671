/** @jsxImportSource @emotion/react */
import { Add, Delete } from "@mui/icons-material";
import ButtonSquared from "../../../../../../Components/ButtonSquared/View";
import { useDrawerAccountingItemStyle } from "./Style";

function DrawerAccountingItem({
  code,
  description,
  addIcon = false,
  onClick,
}: {
  description: string;
  code: string;
  addIcon?: boolean;
  onClick?: () => void;
}) {
  const style = useDrawerAccountingItemStyle();
  return (
    <div css={style.item}>
      <div css={style.column}>
        <span css={style.number}>{code}</span>
        <span css={style.name}>{description}</span>
      </div>

      <ButtonSquared
        ccss={style.icon}
        transparentIcon
        color={
          addIcon
            ? "secondaryWhite20SuccessHover"
            : "secondaryWhite20ErrorHover"
        }
        onClick={onClick}
        minor
      >
        {addIcon ? <Add /> : <Delete />}
      </ButtonSquared>
    </div>
  );
}

export default DrawerAccountingItem;
