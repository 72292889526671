/** @jsxImportSource @emotion/react */
import TurquesaAvatar from "../../../../../Components/TurquesaAvatar/View";
import { OperatorAvatar } from "../../../../../Models/OperatorModel";
import { useFrameOperatorItemStyle } from "./Style";

function FrameOperatorItem({
  operatorAvatar,
}: {
  operatorAvatar: OperatorAvatar;
}) {
  const style = useFrameOperatorItemStyle();
  return (
    <div css={style.wrapper}>
      <TurquesaAvatar
        src={operatorAvatar.pictureUrl}
        nameToInitials={operatorAvatar.name}
        size={4}
      />
      <span>{operatorAvatar.name}</span>
    </div>
  );
}

export default FrameOperatorItem;
