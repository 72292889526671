/** @jsxImportSource @emotion/react */
import { ErrorOutline } from "@mui/icons-material";
import { useFrameStyle } from "../../../../../../../Components/Frame/Style";
import FrameSelect from "../../../../../../../Components/FrameSelect/View";
import { KeyedDates } from "../../../../../../../Hooks/ShowAccountingsBlocks/Model";
import { selectItemByDate } from "../../../../../../../Hooks/ShowAccountingsBlocks/Presenter";
import { monthSort } from "../../../../../../../Utils/Utils";
import { KeyedPendencyCount } from "../../../../Model";
import { useMainColumnStyle } from "../../Style";
import FramePendencyItem from "./FramePendencyItem/View";
import { usePendencyHeadStyle } from "./Style";

function PendencyHead({
  accountingExpanded,
  setAccountingExpanded,
  datesSelect,
  yearSelected,
  setYearSelected,
  monthSelected,
  setMonthSelected,
  keyedPendencyCount,
}: {
  accountingExpanded: boolean;
  setAccountingExpanded: React.Dispatch<React.SetStateAction<boolean>>;
  datesSelect: KeyedDates;
  yearSelected: string;
  setYearSelected: React.Dispatch<React.SetStateAction<string>>;
  monthSelected: string;
  setMonthSelected: React.Dispatch<React.SetStateAction<string>>;
  keyedPendencyCount: KeyedPendencyCount;
}) {
  const style = usePendencyHeadStyle();
  const mainColumnStyle = useMainColumnStyle();
  const frameStyle = useFrameStyle();

  return (
    <div
      onClick={() =>
        accountingExpanded ? setAccountingExpanded(false) : undefined
      }
      css={[
        frameStyle.panelHead,
        mainColumnStyle.panelHead(!accountingExpanded),
      ]}
    >
      <div css={frameStyle.panelHeadRow}>
        <div css={mainColumnStyle.panelOptions(true)}>
          <ErrorOutline css={mainColumnStyle.pendencyIcon} />
          <span css={mainColumnStyle.pendencyText}>Pendências</span>
        </div>
        <div css={mainColumnStyle.panelOptions(!accountingExpanded)}>
          <FrameSelect
            ccss={style.monthSelect}
            value={{
              value: (
                <FramePendencyItem
                  month={monthSelected}
                  number={selectItemByDate(
                    yearSelected,
                    monthSelected,
                    keyedPendencyCount
                  )}
                />
              ),
              key: monthSelected,
            }}
            onSelect={setMonthSelected}
            heightOfFixContent={209}
            options={datesSelect[yearSelected].map((month) => ({
              value: (
                <FramePendencyItem
                  month={month}
                  number={selectItemByDate(
                    yearSelected,
                    month,
                    keyedPendencyCount
                  )}
                />
              ),
              key: month,
            }))}
          />
          <div css={frameStyle.panelHeadSpacer} />
          <FrameSelect
            ccss={mainColumnStyle.selectYear}
            value={yearSelected}
            heightOfFixContent={112}
            onSelect={setYearSelected}
            options={Object.keys(datesSelect).sort(monthSort)}
          />
        </div>
      </div>
      <div css={frameStyle.panelSeparator} />
    </div>
  );
}

export default PendencyHead;
