import { useEffect, useState } from "react";
import { ClientAccountModel } from "../../Models/AccountModel";
import { BlockModel } from "../../Models/BlockModel";
import useCheckFrameDate from "../CheckFrameDate";
import {
  KeydedDatesBlockModel,
  KeyedDates,
  KeyedDatesAccountModel,
  ShowAccountingsBlocksState,
} from "./Model";
import { getKeyedState } from "./Presenter";

function useShowAccountingsBlocks(
  allBlocks?: BlockModel[],
  clientAccounts?: ClientAccountModel[],
  initialMonth = "",
  initialYear = ""
) {
  const [datesSelect, setDatesSelect] = useState<KeyedDates>({});
  const [keyedBlocks, setKeyedBlocks] = useState<KeydedDatesBlockModel>({});
  const [yearSelected, setYearSelected] = useState(initialYear);
  const [monthSelected, setMonthSelected] = useState(initialMonth);
  const [keyedAccounts, setKeyedAccounts] = useState<KeyedDatesAccountModel>(
    {}
  );

  useEffect(() => {
    if (clientAccounts && allBlocks) {
      getKeyedState(
        clientAccounts,
        allBlocks,
        setKeyedAccounts,
        setKeyedBlocks,
        setYearSelected,
        setMonthSelected,
        setDatesSelect
      );
    }
  }, [clientAccounts, allBlocks]);

  useCheckFrameDate(datesSelect, yearSelected, monthSelected, setMonthSelected);

  return [
    keyedBlocks,
    keyedAccounts,
    datesSelect,
    yearSelected,
    setYearSelected,
    monthSelected,
    setMonthSelected,
  ] as ShowAccountingsBlocksState;
}

export default useShowAccountingsBlocks;
