/** @jsxImportSource @emotion/react */

import { ArrowBack } from "@mui/icons-material";
import { Button } from "@mui/material";
import { useHistory } from "react-router-dom";
import ButtonSquared from "../../../../../../../Components/ButtonSquared/View";
import { useFrameStyle } from "../../../../../../../Components/Frame/Style";
import FrameSelect from "../../../../../../../Components/FrameSelect/View";
import TextEntry from "../../../../../../../Components/TextEntry/View";
import TurquesaAvatar from "../../../../../../../Components/TurquesaAvatar/View";
import { KeyedDates } from "../../../../../../../Hooks/ShowAccountingsBlocks/Model";
import { monthSort } from "../../../../../../../Utils/Utils";
import { useMainColumnStyle } from "../../Style";

function AccountingHead({
  showAccountings,
  accountingExpanded,
  setAccountingExpanded,
  setClientDialog,
  clientName,
  datesSelect,
  yearSelected,
  setYearSelected,
  monthSelected,
  setMonthSelected,
  logoUrl,
  notifyRef,
}: {
  showAccountings: boolean;
  accountingExpanded: boolean;
  setAccountingExpanded: React.Dispatch<React.SetStateAction<boolean>>;
  setClientDialog: React.Dispatch<React.SetStateAction<boolean>>;
  clientName: string;
  datesSelect: KeyedDates;
  yearSelected: string;
  setYearSelected: React.Dispatch<React.SetStateAction<string>>;
  monthSelected: string;
  setMonthSelected: React.Dispatch<React.SetStateAction<string>>;
  logoUrl?: string;
  notifyRef: React.MutableRefObject<
    React.Dispatch<React.SetStateAction<string>>
  >;
}) {
  const history = useHistory();

  const mainColumnStyle = useMainColumnStyle();
  const frameStyle = useFrameStyle();

  return (
    <div
      css={[
        frameStyle.panelHead,
        mainColumnStyle.panelHead(accountingExpanded),
      ]}
      onClick={() =>
        !accountingExpanded ? setAccountingExpanded(true) : undefined
      }
    >
      <div css={frameStyle.panelHeadRow}>
        <div css={mainColumnStyle.panelOptions(true)}>
          <ButtonSquared
            onClick={(e) => {
              e.stopPropagation();
              history.goBack();
            }}
            ccss={mainColumnStyle.backButton}
          >
            <ArrowBack />
          </ButtonSquared>
          <TurquesaAvatar src={logoUrl} nameToInitials={clientName} size={7} />
          <Button
            onClick={(e) => {
              e.stopPropagation();
              setClientDialog(true);
            }}
            css={mainColumnStyle.clientTextButton}
          >
            <span css={frameStyle.panelHeadTitle}>{clientName}</span>
          </Button>
        </div>
        <div css={mainColumnStyle.panelOptions(accountingExpanded)}>
          <FrameSelect
            ccss={mainColumnStyle.selectMonth}
            value={monthSelected}
            onSelect={setMonthSelected}
            heightOfFixContent={112}
            options={datesSelect[yearSelected]}
          />
          <div css={frameStyle.panelHeadSpacer} />
          <FrameSelect
            ccss={mainColumnStyle.selectYear}
            value={yearSelected}
            heightOfFixContent={112}
            onSelect={setYearSelected}
            options={Object.keys(datesSelect).sort(monthSort)}
          />
          <div css={frameStyle.panelHeadSpacer} />
          <TextEntry
            placeholder={showAccountings ? "Buscar conta" : "Buscar valor"}
            ccss={frameStyle.textEntry}
            searchIcon
            notifyRef={notifyRef}
          />
        </div>
      </div>
      <div css={frameStyle.panelSeparator} />
    </div>
  );
}

export default AccountingHead;
