import { css } from "@emotion/react";
import CacheTheme from "Theme/CacheTheme";
import useCustomTheme from "Theme/CustomTheme";

var styleCached = false;
export function useSideBarIconStyle() {
  const name = "SideBarIconCSS";
  const theme = useCustomTheme();
  if (!styleCached) {
    const label = theme.extras.label(name);
    var _style = {
      noPadding: css({
        label: label("noPadding"),
        padding: theme.spacing(0),
      }),
      item: css({
        label: label("item"),
        backgroundColor: theme.palette.background.white(20),
        ":hover": {
          backgroundColor: theme.palette.background.white(30),
        },
      }),
      transparency: css({
        label: label("transparency"),
        backgroundColor: theme.palette.transparency.white(0),
        color: "white",
      }),
      slowBackgroundTransition: css({
        label: label("slowBackgroundTransition"),
        transition: theme.extras.transition.fast("background-color"),
      }),
      badge: css({
        "& .MuiBadge-badge": {
          label: label("badge"),
          width: theme.spacing(4),
          height: theme.spacing(4),
          borderRadius: theme.spacing(4),
          userSelect: "none",
          cursor: "pointer",
          fontWeight: "bold",
          textShadow: "none",
          transform: "scale(1) translate(60%, -60%)",
        },
      }),
      greenBadge: css({
        "& .MuiBadge-badge": {
          label: label("greenBadge"),
          backgroundColor: theme.palette.success.main,
        },
      }),
      textItem: css({
        label: label("textItem"),
        margin: "auto 0px",
        transition: theme.extras.transition.fast("width"),
        overflow: "hidden",
        display: "flex",
        justifyContent: "end",
      }),
      textItemNotExpanded: css({
        label: label("textItemNotExpanded"),
        width: "0px",
      }),
      textItemExpanded: css({
        label: label("textItemExpanded"),
        width: theme.spacing(21),
      }),
      text: css({
        label: label("text"),
        color: "inherit",
        whiteSpace: "nowrap",
        textTransform: "none",
      }),
      noInteractions: css({
        label: label("noInteractions"),
        pointerEvents: "none",
        color: theme.palette.transparency.white(30),
      }),
    };
    CacheTheme(name, theme.palette.type, _style);
    styleCached = true;
  }
  var style = CacheTheme(name, theme.palette.type);
  return style as typeof _style;
}
