/** @jsxImportSource @emotion/react */
import { ContentCopy } from "@mui/icons-material";
import { useEffect, useState } from "react";
import ButtonMain from "../../../../../Components/ButtonMain/View";
import useEditButtonAnimation from "../../../../../Hooks/EditButtonAnimation";
import BlockTimer from "./BlockTimer/View";
import { useSentBlockStyle } from "./Style";

function SentBlock({
  selected,
  onSelect,
  onUndo,
  date,
  ofOperator,
}: {
  selected: boolean;
  onSelect: () => void;
  onUndo: () => void;
  date: Date;
  ofOperator?: boolean;
}) {
  const [edit, padding, backgroundColor, setEdit] = useEditButtonAnimation();
  const [timerRunning, setTimerRunning] = useState(false);

  useEffect(() => {
    setEdit(selected);
  }, [selected, setEdit]);

  const style = useSentBlockStyle();
  const showBackButton = ofOperator && timerRunning;

  return (
    <ButtonMain
      onClick={onSelect}
      color={edit ? "primary" : "secondaryWhite10"}
      ccss={style.wrapper}
    >
      <>
        <ContentCopy css={style.iconMirror} fontSize="small" />
        <div
          onClick={selected && timerRunning ? onUndo : undefined}
          css={[
            style.displayTimer,
            timerRunning ? style.displayTimerSelected : undefined,
            edit && showBackButton ? style.displayTimerAction : undefined,
          ]}
        >
          <BlockTimer date={date} setTimerRunning={setTimerRunning} />
          <span
            css={[
              style.smallIcon,
              backgroundColor && showBackButton ? undefined : style.hiddenIcon,
              padding && showBackButton ? undefined : style.contractIcon,
            ]}
            className="material-symbols-outlined"
          >
            undo
          </span>
        </div>
      </>
    </ButtonMain>
  );
}

export default SentBlock;
