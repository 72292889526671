import { css } from "@emotion/react";
import CacheTheme from "Theme/CacheTheme";
import useCustomTheme from "Theme/CustomTheme";

var styleCached = false;
export function useCardSignatureStyle() {
  const name = "CardSignatureCSS";
  const theme = useCustomTheme();
  if (!styleCached) {
    const label = theme.extras.label(name);
    var _style = {
      signatureCard: css({
        label: label("signatureCard"),
        padding: theme.spacing(3, 4),
        borderRadius: "12px",
        backgroundColor: "#22B473",
        color: "white",
        display: "flex",
        flexDirection: "row",
        marginBottom: theme.spacing(3),
        marginTop: theme.spacing(10),
      }),
      column: css({
        label: label("column"),
        display: "flex",
        flexDirection: "column",
        width: theme.spacing(12),
      }),
      signature: css({
        label: label("signature"),
        fontSize: "14px",
        lineHeight: "18px",
      }),
      blintes: css({
        label: label("blintes"),
        fontSize: "24px",
        lineHeight: "30px",
        fontWeight: 700,
      }),
      bold: css({
        label: label("bold"),
        backgroundColor: theme.palette.transparency.black(20),
        borderRadius: "12px",
        padding: theme.spacing(1, 2),
        fontSize: "24px",
        lineHeight: "30px",
        fontWeight: 700,
        marginLeft: theme.spacing(2),
        width: theme.spacing(24),
      }),
      normal: css({
        label: label("normal"),
        fontWeight: 400,
      }),
    };
    CacheTheme(name, theme.palette.type, _style);
    styleCached = true;
  }
  var style = CacheTheme(name, theme.palette.type);
  return style as typeof _style;
}
