/** @jsxImportSource @emotion/react */
import { SerializedStyles } from "@emotion/react";
import { Avatar, Tooltip } from "@mui/material";
import { fullNameToShortName } from "../../Utils/String";
import { useTurquesaAvatarStyle } from "./Style";

function TurquesaAvatar({
  size,
  nameToInitials,
  src,
  ccss,
  id,
  tooltip,
  tooltipCss,
  placement,
}: {
  size: number;
  nameToInitials: string;
  src?: string;
  ccss?: SerializedStyles | (SerializedStyles | undefined)[];
  id?: string; // ID necessário para edição de avatar
  tooltip?: string;
  tooltipCss?: React.CSSProperties | undefined;
  placement?: "left";
}) {
  const style = useTurquesaAvatarStyle();

  return (
    <Tooltip
      componentsProps={{
        tooltip: { style: tooltipCss },
      }}
      placement={placement}
      title={tooltip ?? ""}
    >
      <Avatar id={id} src={src} css={[style.avatar(size), ccss]}>
        {fullNameToShortName(nameToInitials)}
      </Avatar>
    </Tooltip>
  );
}

export default TurquesaAvatar;
