import { useEffect, useRef, useState } from "react";
import { AccountModel, ClientAccountModel } from "../../../Models/AccountModel";
import { BlockModel } from "../../../Models/BlockModel";
import {
  BlockServices,
  ContabilizationServices,
} from "../../../Services/Interfaces";
import { accountSort, SYNC_DELAY } from "../../../Utils/Utils";
import { CategorizationStateArgs } from "../Categorization/Model";
import { selectItemByDate } from "Hooks/ShowAccountingsBlocks/Presenter";
import useLoadingTransition from "Hooks/LoadingTransition";
import { useHistory } from "react-router-dom";
import useShowAccountingsBlocks from "Hooks/ShowAccountingsBlocks/View";

export const contabilizeSyncJob = async (
  clientId: string,
  divRef: React.RefObject<HTMLDivElement>
) => {
  do {
    await ContabilizationServices.contabilizeSync(clientId);
    await new Promise((resolve) => setTimeout(resolve, SYNC_DELAY));
  } while (divRef.current);
};

export const contabilizeBlock = ContabilizationServices.contabilizeBlock;

export const getClientBlocks = async (
  clientId: string,
  setAllBlocks: React.Dispatch<React.SetStateAction<BlockModel[] | undefined>>
) => setAllBlocks((await BlockServices.getClientBlocks(clientId)).blocks);

export const ContabilizedAccountSort = (
  a: ClientAccountModel,
  b: ClientAccountModel,
  blocksNumberByAccount: (e: AccountModel) => number
) => {
  const aNumber = blocksNumberByAccount(a);
  const bNumber = blocksNumberByAccount(b);
  if ((aNumber > 0 && bNumber > 0) || (aNumber === 0 && bNumber === 0)) {
    return accountSort(a, b);
  } else {
    if (aNumber > 0) {
      return -1;
    } else {
      return 1;
    }
  }
};

export const useContabilizationPresenter = (
  client: CategorizationStateArgs
) => {
  const history = useHistory();
  const divRef = useRef<HTMLDivElement>(null);
  const [historyBlocks, setHistoryBlocks] = useState<BlockModel[]>();
  const [showHistory, setShowHistory] = useState(false);
  const [accountSelected, setAccountSelected] = useState("");
  const [allBlocks, setAllBlocks] = useState<BlockModel[]>();
  const [clientAccounts, setClientAccounts] = useState<ClientAccountModel[]>();

  const [link, setLink] = useState("not-initialized");

  useEffect(() => {
    ContabilizationServices.getContabilizationLink(client.id).then(setLink);
  }, [setLink, client]);

  const [clientName, setClientName] = useState("");
  const [clientPictureUrl, setClientPictureUrl] = useState<string>();
  const [clientId, setClientId] = useState("not-initialized");

  useEffect(() => {
    if (link !== "not-initialized") {
      ContabilizationServices.initContabilizationLink(link).then((response) => {
        setClientId(response.id);
        setClientName(response.name);
        setClientPictureUrl(response.pictureUrl);
        setAllBlocks(response.categorizedBlocks);
        setClientAccounts(response.clientAccounts);
      });
    }
  }, [link]);

  const [
    keyedBlocks,
    keyedAccounts,
    datesSelect,
    yearSelected,
    setYearSelected,
    monthSelected,
    setMonthSelected,
  ] = useShowAccountingsBlocks(allBlocks, clientAccounts);

  const [changeLoading] = useLoadingTransition(
    yearSelected !== "" && monthSelected !== "" && clientName !== ""
  );

  const blocksNumberByAccount = (e: AccountModel) => {
    const keyedList = selectItemByDate(
      yearSelected,
      monthSelected,
      keyedBlocks
    );
    try {
      return (keyedList[e.id] as BlockModel[]).filter(
        (e) => e.contabilizedDatetime === undefined
      ).length;
    } catch {
      return 0;
    }
  };

  const sortedAccounts = (
    selectItemByDate(
      yearSelected,
      monthSelected,
      keyedAccounts
    ) as ClientAccountModel[]
  ).sort((a, b) => ContabilizedAccountSort(a, b, blocksNumberByAccount));

  const currentBlocks = (selectItemByDate(
    yearSelected,
    monthSelected,
    keyedBlocks
  )[accountSelected] ?? []) as BlockModel[];

  useEffect(() => {
    if (clientId !== "not-initialized") {
      // contabilizeSyncJob(clientId, divRef);
    }
    //eslint-disable-next-line
  }, [client, clientId]);

  useEffect(() => setAccountSelected(""), [yearSelected, monthSelected]);

  return {
    changeLoading,
    divRef,
    history,
    datesSelect,
    yearSelected,
    monthSelected,
    setMonthSelected,
    setYearSelected,
    sortedAccounts,
    accountSelected,
    setAccountSelected,
    blocksNumberByAccount,
    setAllBlocks,
    currentBlocks,
    setShowHistory,
    setHistoryBlocks,
    showHistory,
    historyBlocks,
    link,
    clientName,
    clientPictureUrl,
  };
};
