import { Person } from "./AccountingModel";

export type Client = {
  id: string;
  type: "individualPerson" | "juridicPerson" | "owner";
  isResponsable: boolean;
  person: Person;
};

export type HomeClient = {
  id: string;
  name: string;
  operatorName: string;
  photoOperatorUrl?: string;
  modifiedDatetime: Date;
  isShared: boolean;
  isOwner: boolean;
  hasUncategorizedBlocks: boolean;
  hasPendencies: boolean;
};

export const empytPerson = {
  name: "",
  code: "",
  phone: "",
  email: "",
  address: {
    number: "",
    state: "",
    city: "",
    neighboor: "",
    street: "",
    cep: "",
  },
};

export const emptyClient = {
  type: "juridicPerson",
  person: empytPerson,
} as Client;
