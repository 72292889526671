import { useEffect, useState } from "react";
import { getOperatorProfile } from "../Components/Frame/Presenter";
import { emptyProfileOperator, OperatorProfile } from "../Models/OperatorModel";

function useGetFrameOperator() {
  const operatorState = useState<OperatorProfile>(emptyProfileOperator);

  useEffect(() => {
    getOperatorProfile(operatorState[1]);
    //eslint-disable-next-line
  }, []);

  return operatorState;
}

export default useGetFrameOperator;
