/** @jsxImportSource @emotion/react */
import {
  AlternateEmailOutlined,
  ArrowBack,
  PasswordOutlined,
} from "@mui/icons-material";
import KeyOutlinedIcon from "@mui/icons-material/KeyOutlined";
import ButtonMain from "../../Components/ButtonMain/View";
import ButtonSquared from "../../Components/ButtonSquared/View";
import LogoBlintes from "./../../Assets/logo_blintes.svg";
import { useLoginPresenter } from "./Presenter";

import { useLoginStyle } from "./Style";
import LoginTextEntry from "./Components/LoginTextEntry/View";
import SelectPlataformButton from "./Components/SelectPlataformButton/View";

function Login() {
  const style = useLoginStyle();
  const presenter = useLoginPresenter();

  return (
    <div css={style.wrapper}>
      <div css={style.panel}>
        <div
          css={[
            style.panelWrapper,
            presenter.recovery ? style.translate : undefined,
          ]}
        >
          <div css={[style.panelHead, style.logoMargin]}>
            <img css={style.logo} src={LogoBlintes} alt="blints_logomarca" />
            <div css={style.placeholder} />
          </div>
          <LoginTextEntry
            placeholder="E-mail"
            setDisableRef={presenter.setUsernameDisabledRef}
            stateRef={presenter.usernameRef}
            ccss={style.bottomMargin}
            onChangeInputs={() => presenter.onChangeInputs()}
            startIcon={
              <AlternateEmailOutlined css={style.icon} fontSize="small" />
            }
          />
          <LoginTextEntry
            placeholder="Senha"
            setDisableRef={presenter.setPasswordDisabledRef}
            stateRef={presenter.passwordRef}
            ccss={style.bottomMargin}
            onChangeInputs={() => presenter.onChangeInputs()}
            startIcon={<KeyOutlinedIcon css={style.icon} fontSize="small" />}
            password
          />
          <div
            css={[
              style.errorDisplay,
              presenter.showError ? style.showError : undefined,
            ]}
          >
            {presenter.error}
          </div>
          <div
            css={[
              style.loginOptions,
              presenter.showSelectPlataform
                ? style.showSelectPlataformOptions
                : undefined,
            ]}
          >
            <div
              css={[
                style.buttonRow,
                presenter.hideLoginButtons ? style.hidden : undefined,
              ]}
            >
              <ButtonMain
                onClick={presenter.changeRecovery}
                ccss={style.whiteButton}
              >
                Recuperar senha
              </ButtonMain>
              <ButtonMain
                disabled={presenter.disabled}
                onClick={presenter.preLogin}
                ccss={style.disabled}
              >
                Entrar
              </ButtonMain>
            </div>
            <div
              css={[
                style.selectPlataformOptions,
                presenter.showSelectPlataform ? undefined : style.hidden,
              ]}
            >
              <div css={style.choosePlataform}>
                Escolha qual plataforma acessar:
              </div>
              <SelectPlataformButton
                title="Business"
                onClick={() => presenter.login("bussiness")}
              />
              <SelectPlataformButton
                title="Accounting"
                onClick={() => presenter.login("accounting")}
              />
            </div>
          </div>
        </div>
        <div
          css={[
            style.panelWrapper,
            style.leftMargin,
            presenter.recovery ? style.translate : undefined,
          ]}
        >
          <div css={style.panelHead}>
            <ButtonSquared
              onClick={presenter.changeRecovery}
              ccss={style.backButton}
            >
              <ArrowBack />
            </ButtonSquared>
            <PasswordOutlined css={style.icon} fontSize="small" />
          </div>
          <div css={style.forgetTitle}>Esqueceu a senha?</div>
          <div css={style.forgetDescription}>
            Digite seu e-mail de registro no Blintes e te enviaremos uma senha
            de acesso rápido. Depois de acessar, aproveite para escolher uma
            nova senha para manter sua conta ainda mais segura!
          </div>
          <LoginTextEntry
            placeholder="Seu e-mail"
            stateRef={presenter.emailRef}
            startIcon={
              <AlternateEmailOutlined css={style.icon} fontSize="small" />
            }
          />
          <ButtonMain
            ccss={style.forgetRecoveryButton}
            onClick={presenter.changeRecovery}
          >
            Recuperar senha
          </ButtonMain>
        </div>
      </div>
      <div css={style.allRights}>Desenvolvido pela Hikers.</div>
    </div>
  );
}

export default Login;
